import moneyFormat from '../functions/moneyFormat';

const MultipartWorkflowTableRow = ({ row, count, tableHeader }) => { 
    return (
        <>
            {tableHeader.map(el =>
                el['columnname'] == 'grossspend' || el['columnname'] == 'grossinterchange' || el['columnname'] == 'incentiveamount' ? 
                    <td>{moneyFormat(row[el['columnname']])}</td>
                :<td>{row[el['columnname']]}</td>
            )}
        </> 
    );
}

export default MultipartWorkflowTableRow;