import { useContext, useState, useEffect, useRef } from 'react';
import { Modal } from 'react-bootstrap';
import PageContext from './PageContext';
import { HttpContext } from '../../api-services/HttpService';
import OrderedDataTable from './OrderedDataTable';
import SubCompanyRow from './SubCompanyRow';

const ViewSubCompaniesModal = () => {
    // get context variables from parent
    const {
        viewModal, 
        setViewModal, 
        viewCompany, 
        setViewCompany, 
    } = useContext(PageContext);
    // initialize http context 
    const {
        GET,  
    } = useContext(HttpContext);
    // initialize table state
    const [subCompanyDataList, setSubCompanyDataList] = useState([{}]);
    const [subCompanyTableHeader, setSubCompanyTableHeader] = useState([{}]);
    const [showSpinner, setShowSpinner] = useState(false);
    // initialize useRef for initial render
    const initialRender = useRef(true);

    // function wrapper to pass row component to DataTable
    const RowComponent = (row, count, tableHeader) => {
        return(
            <>
                <SubCompanyRow row={row} count={count} tableHeader={tableHeader} />
            </>
        );
    }

    // make http request to get sub companies for given company
    const getSubCompanyData = () => {
        return new Promise ((resolve, reject) => {
            setShowSpinner(showSpinner => showSpinner = true);
            GET('/data/subCompanyData', {mastercompany: viewCompany['mastercompanyname'], mastercompanyid: viewCompany['mastercompanyid']}).then(res => {
                // set table state
                setSubCompanyDataList(subCompanyDataList => subCompanyDataList = res['data']['SubCompanyData']);
                setSubCompanyTableHeader(subCompanyTableHeader => subCompanyTableHeader = res['data']['SubCompanyCols']);
                // set show spinner to false
                setShowSpinner(showSpinner => showSpinner = false);
                resolve(res);
            }).catch(err => {
                // show show spinner to false
                setShowSpinner(showSpinner => showSpinner = false);
                reject(err);
            });
        });
    }

    // useEffect to run when view company changes
    useEffect(() => {
        //do not run on initial render
        if (initialRender.current){
            initialRender.current = false;
        }
        else{
            // get sub companies for view company
            getSubCompanyData().then(res => {
                console.log(res);
            }).catch(err => {
                console.log(err);
            });
        }
    }, [viewCompany]);

    return(
        <> 
            <Modal className="rebate-summary-modal"
                show={viewModal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                scrollable={true}
                >
                <Modal.Header closeButton onClick={()=>setViewModal(viewModal => viewModal = false)}>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {viewCompany['mastercompanyname']}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <OrderedDataTable RowComponent={RowComponent} tableHeader={subCompanyTableHeader} dataList={subCompanyDataList} showSpinner={showSpinner} numToPage={25} skeletonHeight='14vh'/>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default ViewSubCompaniesModal;