import { useState, useEffect, useRef, useContext } from 'react';
import HeaderBanner from '../sub-components/HeaderBanner';
import Navslider from '../sub-components/Navslider';
import PageInput from '../sub-components/PageInput';
import OrderedDataTable from '../sub-components/OrderedDataTable';
import UserEntitlementRow from '../sub-components/UserEntitlementRow';
import PageContext from '../sub-components/PageContext';
import UserEntitlementModal from '../sub-components/UserEntitlementModal';
import standardSearch from '../functions/standardSearch';
import applyFilters from '../functions/applyFilters';
import { HttpContext } from '../../api-services/HttpService';
import ExportExcelButton from '../sub-components/ExportExcelButton';
import SnackbarAlert from '../sub-components/SnackbarAlert';

const UserEntitlementPage = () =>{
    // initialize http context 
    const {
        GET, 
    } = useContext(HttpContext);
    // state for search component
    const [searchValue, setSearchValue] = useState('');
    // state for data table
    const [dataList, setDataList] = useState([]);
    const [tableHeader, setTableHeader] = useState([]);
    const [showDataList, setShowDataList] = useState([]);
    // state for edit modal
    const [editModal, setEditModal] = useState(false);
    const [roleList, setRoleList] = useState([]); 
    const [companyList, setCompanyList] = useState([]);
    const [userList, setUserList] = useState([]);
    const [editUser, setEditUser] = useState();
    // reference to determine initial render
    const initialRender = useRef(true);
    // initialize spinner state
    const [showSpinner, setShowSpinner] = useState(false);
    // initialize snackbar state
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarColor, setSnackbarColor] = useState('info'); 

    // get data to show in table
    const getUserEntitlementsCounts = () => {
        return new Promise ((resolve, reject) => {
            GET('/user', {}).then(res => {
                setDataList(dataList => dataList = [...res.data.UserEntitlementCounts]); 
                setShowDataList(showDataList => showDataList = [...res.data.UserEntitlementCounts]);
                setTableHeader(tableHeader => tableHeader = res['data']['GuiTableCols']);
                resolve(res);
            }).catch(err => {
                reject(err);
            });
        }); 
    }

    // function wrapper to pass row component to datatable
    const RowComponent = (row, count, tableHeader) =>{
        return( 
            <>
                <UserEntitlementRow row={row} count={count} tableHeader={tableHeader}/>
            </>
        );
    } 

    //function to set spinners, get data, disable spinners
    const getInfo = () => {
        setShowSpinner(showSpinner => showSpinner = true);
        return new Promise((resolve, reject) => {
            // state for table 
            getUserEntitlementsCounts().then(res => {
                setShowSpinner(showSpinner => showSpinner = false);
                resolve(res);
            }).catch(err => {
                setShowSpinner(showSpinner => showSpinner = false);
                reject(err);
            });
        })
    }

    // useEffect to run on initial render
    useEffect(() => {
        getInfo()
    }, []);

    // useEffect to run list of search/filter functions on table data
    useEffect(() => {
        // do not run on initial render
        if (!initialRender.current){
            // declare list of functions with parameters to filter/search list
            const functionList = [
                {function: standardSearch, value: searchValue, flag: 0, cols: ['userid']},
            ];
            // use applyFilters function to search by running all functions declared above
            let filteredList = applyFilters(dataList, functionList);
            // update showDataList
            setShowDataList(showDataList => showDataList = filteredList);
        }
        else{
            initialRender.current = false;
        }
    }, [searchValue]);

    // function to set snackbar state
    const snackbarAlert = (show, message, color) => {
        setSnackbarMessage(snackbarMessage => snackbarMessage = message);
        setSnackbarColor(snackbarColor => snackbarColor = color);
        setShowSnackbar(showSnackbar => showSnackbar = show);
    }

    // object to pass variables to PageContext
    const settings = {
        editModal, 
        setEditModal, 
        editUser, 
        setEditUser, 
        getInfo,
        snackbarAlert,
        showSnackbar,
        snackbarColor,
        snackbarMessage,
        setShowSnackbar, 
        getUserEntitlementsCounts
    };

    return(
        <div class='summary-page'>
            {/* wrap all children so they can access PageContext variables */}
            <PageContext.Provider value={ settings }>
                {/* header and navslider present on every page */}
                <HeaderBanner indicator={1}/>
                <Navslider indicator={-1} backPath='/UserAdminLanding'/>
                {/* modal that can be activated on page. Default is hidden state */}
                <UserEntitlementModal />
                <SnackbarAlert />
                {/* top div for heading and user search */}
                <div class='top-label-div'>
                    <label class='top-label-text'><b>Update User Entitlements</b></label>
                    <br/>
                    <div class='label-text-div'>
                        <span class='label-text-span-left'>      
                        </span>
                        <span class='label-text-span-mid'>     
                        </span>
                        <span class='label-text-span-right'>
                            {/* page input to set search value */}
                            <PageInput tag='Search UserId:' value={searchValue} setValue={setSearchValue} />
                        </span>
                    </div>
                    <br/><br/>
                </div>
                {/* export filtered table */}
                <div class='summary-page-button-div'>
                    <ExportExcelButton tag='ccap-user-entitlements' dataList={showDataList} tableHeader={tableHeader}/>
                </div>
                {/* table to show user entitlements with buttons to activate user entitlements modal for specified user */}
                <div class='data-table-div'>
                    <label><b>User Entitlements:</b></label>
                    <OrderedDataTable RowComponent={RowComponent} tableHeader={tableHeader} dataList={showDataList} showSpinner={showSpinner} numToPage={10} skeletonHeight='28vh'/>
                </div>
            </PageContext.Provider>
        </div>
    );
}

export default UserEntitlementPage;