import { useContext, useState, useEffect, useRef } from 'react';
import { Modal } from "react-bootstrap";
import PageContext from './PageContext'; 
import { HttpContext } from '../../api-services/HttpService';
import OrderedDataTable from './OrderedDataTable'; 
import AccountIdsRow from './AccountIdsRow'; 
import EntitledButton from './EntitledButton'; 
import PageInput from './PageInput'; 
import isNumeric from '../functions/isNumeric'; 

const EditAccountIdsModal = () => {
    // get context from parent 
    const {
        editAccountIdsModal, 
        setEditAccountIdsModal, 
        editSubCompanyRow,
        setEditSubCompanyRow,
        masterCompanyData, 
        snackbarAlert, 
        editButtonBool, 
    } = useContext(PageContext);
    // get HttpContext from parent
    const {
        GET, 
        POST,
    } = useContext(HttpContext); 
    // initialize state for account ids table 
    const [accountIdsDataList, setAccountIdsDataList] = useState([]); 
    const [accountIdsTableHeader, setAccountIdsTableHeader] = useState([]);
    const [showSpinner, setShowSpinner] = useState(false); 
    // initialize state for add account id
    const [accountId, setAccountId] = useState(''); 
    // useRef to determine intial render 
    const initialRender = useRef(true); 

    // function wrapper to pass row component to datatable 
    const RowComponent = (row, count, tableHeader) => {
        return (
            <>
                <AccountIdsRow row={row} count={count} tableHeader={tableHeader} />
            </>
        ); 
    }

    // event handler for submit new account id 
    const onSubmitClick = () => {
        // validate account id is numeric
        if(!isNumeric(accountId)){
            snackbarAlert(true, 'Error: Invalid Account ID.', 'error'); 
            return; 
        }
        // make post request to api 
        POST('/data/accountids', {
            mastercompanyid: masterCompanyData['mastercompanyid'], 
            companyid: editSubCompanyRow['companyid'],
            accountid: accountId, 
        }).then(res => {
            console.log(res); 
            // default accountId
            setAccountId(accountId => accountId = ''); 
            // show success on snackbar
            snackbarAlert(true, 'Successfully uploaded data.', 'success');
            // refresh data on page
            return getAccountIdsData();
        }).then(res => {
            console.log(res);
        }).catch(err => {
            console.log(err); 
            // show error on snackbar
            snackbarAlert(true, 'Error uploading data.', 'error');
        }); 
    }

    // get account ids to display on table 
    const getAccountIdsData = () => {
        setShowSpinner(showSpinner => showSpinner = true); 
        return new Promise((resolve, reject) => {
            GET('/data/accountids', {
                mastercompanyid: masterCompanyData['mastercompanyid'], 
                companyid: editSubCompanyRow['companyid']
            }).then(res => {
                // set table data 
                setAccountIdsDataList(accountIdsDataList => accountIdsDataList = res['data']['AccountIdsData']); 
                setAccountIdsTableHeader(accountIdsTableHeader => accountIdsTableHeader = res['data']['AccountIdsCols']); 
                // hide spinner
                setShowSpinner(showSpinner => showSpinner = false); 
                // resolve on success
                resolve(res); 
            }).catch(err => {
                // hide spinner
                setShowSpinner(showSpinner => showSpinner = false); 
                // reject on error 
                reject(err); 
            })
        })
    }

    // useEffect to get accountIdsDatalist on render and editSubCompanyRow change
    useEffect(() => {
        if (!initialRender.current){
            getAccountIdsData().then(res => {
                console.log(res); 
            }).catch(err => {
                console.log(err); 
            }); 
        }
        else {
            initialRender.current = false; 
        }
    }, [editSubCompanyRow]); 

    const settings = {
        getAccountIdsData, 
        editAccountIdsModal, 
        setEditAccountIdsModal, 
        editSubCompanyRow,
        setEditSubCompanyRow,
        masterCompanyData, 
        snackbarAlert, 
        editButtonBool, 
    }

    return(
        <> 
            <PageContext.Provider value={ settings }>
            <Modal className="rebate-summary-modal"
                show={editAccountIdsModal}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                scrollable={true}
                >
                <Modal.Header closeButton onClick={()=>setEditAccountIdsModal(editAccountIdsModal => editAccountIdsModal = false)}>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Edit Account Ids for Sub Company: {editSubCompanyRow['companyid']}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    {/* { editButtonBool ? 
                        <>
                            <div class='modal-div'>
                                <PageInput tag='Add Account ID: ' value={accountId} setValue={setAccountId} />
                                <EntitledButton entitledBool={editButtonBool} tag='Add Account ID: ' cssClass='accountid-modal-button' onClick={()=>onSubmitClick()}/>
                            </div>
                            <br/>
                        </>
                        :<></> } */}
                    <OrderedDataTable RowComponent={RowComponent} tableHeader={accountIdsTableHeader} dataList={accountIdsDataList} showSpinner={showSpinner} skeletonHeight='10vh'/>
                </Modal.Body>
            </Modal>
            </PageContext.Provider>
        </>
    );
}

export default EditAccountIdsModal; 