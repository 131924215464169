import { useContext, useState, useEffect } from 'react';
import { Modal } from "react-bootstrap";
import PageContext from "./PageContext";
import ReadOnlyInputAutoSize from './ReadOnlyInputAutoSize'

const ContractUploadCommentModal = () =>{
    // get context from parent
    const {
        showContractCommentModal, 
        setShowContractCommentModal, 
        contractUploadRow, 
        setContractUploadRow,
    } = useContext(PageContext);


    return(
        <> 
            <Modal className="rebate-summary-modal"
                show={showContractCommentModal}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                scrollable={true}
                >
                <Modal.Header closeButton onClick={()=>setShowContractCommentModal(showContractCommentModal => showContractCommentModal = false)}>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Upload Comment for {contractUploadRow['displayfilename']}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <ReadOnlyInputAutoSize tag='comment' value={contractUploadRow['contractcomment']}/>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default ContractUploadCommentModal;