// return true if element equals value
const standardFilter = (el, val, filterCol) => {
    if (val == 'None'){
        return el[filterCol] == null; 
    }
    else {
        return el[filterCol] == val;
    }
}

export default standardFilter;