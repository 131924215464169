import { useContext, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Modal } from "react-bootstrap";
import PageContext from "./PageContext";
import PageDropdown from './PageDropdown';
import PageInput from './PageInput';
import { HttpContext } from '../../api-services/HttpService';
import DatePicker from './DatePicker';
import { AuthContext } from '../../auth/AuthRoute';
import ApiRequestButton from './ApiRequestButton';

const AddSubCompanyModal = () =>{
    // get context from parent
    const {
        addSubCompanyModal, 
        setAddSubCompanyModal,
        masterCompanyData,
        getCompanyDetailsData,  
        snackbarAlert, 
        processorList,  
        processorDefaultData, 
        portfolio,
        editableBillingRate, 
        billingRateList, 
        editableProcessor, 
    } = useContext(PageContext);
    // initialize http context 
    const {
        POST, 
    } = useContext(HttpContext);
    // initialize context variables from auth parent
    const {
        getTokenSession, 
    } = useContext(AuthContext);

    // get persistant redux global state from store
    const company = useSelector((state) => state.company);
    // set state
    const [companyName, setCompanyName] = useState('')
    const [companyId, setCompanyId] = useState('');
    const [status, setStatus] = useState('Active');
    const [statusList, setStatusList] = useState(['Active', 'Inactive']);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(null);
    const [processor, setProcessor] = useState('');
    const [billingRate, setBillingRate] = useState('--select--'); 

    // set processor to default using portfolio and processorDefaultData
    const defaultProcessor = () => {
        for (let i = 0; i < processorDefaultData.length; i++){
            if (portfolio == processorDefaultData[i]['incentivetype']){
                setProcessor(processor => processor = processorDefaultData[i]['processorid']); 
                break; 
            }
        }
    }
    // event handler for submit click
    const onSubmitClick = () =>{
        return new Promise((resolve, reject) => {
            let response; 
            // make sure company name and id are valid
            if (companyName == '' || companyId == ''){
                // show alert on invalid input
                snackbarAlert(true, 'Invalid company name or ID.', 'error');
                reject(); 
                return;
            }
            // check that billing rate has been selected
            if (billingRate == '--select--'){
                // show alert on invalid input
                snackbarAlert(true, 'Invalid billing rate selected.', 'error'); 
                reject(); 
                return; 
            }
            // set active bool
            let active = false; 
            if (status == 'Active'){
                active = true;
            }
            // make post request to insert data into aurora db
            POST('/data/subCompanyData', {
                mastercompanyid: masterCompanyData['mastercompanyid'], 
                companyname: companyName, 
                companyid: companyId, 
                active: active, 
                processorid: processor, 
                billingratedescription: billingRate, 
                startdate: startDate, 
                enddate: endDate, 
            }).then(res => {
                console.log(res);
                response = res; 
                // hide modal
                setAddSubCompanyModal(addSubCompanyModal => addSubCompanyModal = false);
                // trigger success alert
                snackbarAlert(true, 'Successfully uploaded data.', 'success');
                // reset state variables
                setCompanyName(companyName => companyName = '');
                setCompanyId(companyId => companyId = '');
                setStatus(status => status = 'Active');
                setStartDate(startDate => startDate = new Date());
                setEndDate(endDate => endDate = null);
                defaultProcessor(); 
                setBillingRate(billingRate => billingRate = '--select--'); 
                // refresh company details page using function from parent
                return getCompanyDetailsData(company);
            }).then(res => {
                console.log(res);
                // resolve on success
                resolve(response); 
            }).catch(err => {
                console.log(err);
                // show alert on error
                snackbarAlert(true, 'Error uploading data.', 'error');
                // reject on error
                reject(err); 
            }); 
        });  
    }

    // useEffect to determine default processor from portfolio
    useEffect(() => {   
        defaultProcessor(); 
    }, [portfolio, processorDefaultData]); 

    return(
        <> 
            <Modal className="rebate-summary-modal"
                show={addSubCompanyModal}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                scrollable={true}
                >
                <Modal.Header closeButton onClick={()=>setAddSubCompanyModal(addSubCompanyModal => addSubCompanyModal = false)}>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Add Sub Company
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    {/* inputs and dropdowns for sub company info */}
                    <div class='modal-div'>
                        <PageInput tag='Company Name: ' value={companyName} setValue={setCompanyName} />
                    </div>
                    <br/>
                    <div class='modal-div'>
                        <PageInput tag='Company ID: ' value={companyId} setValue={setCompanyId} />
                    </div>
                    <br/>
                    <div class='modal-div'>
                        <PageDropdown tag='Status: ' value={status} setValue={setStatus} list={statusList}/>
                    </div>
                    <br/>
                    {/* only show processor as editable field if flag is true */}
                    { editableProcessor ? 
                    <>
                        <div class='modal-div'>
                            <PageDropdown tag='Processor: ' value={processor} setValue={setProcessor} list={processorList}/>
                        </div>
                        <br/>
                    </>
                    : <></>}
                    {/* only show billing rate as editable field if flag is true */}
                    { editableBillingRate ?
                    <>
                        <div class='modal-div'>
                            <PageDropdown tag='Billing Rate: ' value={billingRate} setValue={setBillingRate} list={billingRateList} />
                        </div>
                        <br/>
                    </>
                    : <></>}
                    <div class='modal-div'>
                        <DatePicker tag='Start Date: ' value={startDate} setValue={setStartDate} disable={endDate} indicator={3} /> 
                    </div>
                    <br/>
                    <div class='modal-div'>
                        <DatePicker tag='End Date: ' value={endDate} setValue={setEndDate} disable={startDate} indicator={4}/>
                    </div>
                    {/* button to submit sub company */}
                    <ApiRequestButton onButtonClick={onSubmitClick} />
                </Modal.Body>
            </Modal>
        </>
    );
}

export default AddSubCompanyModal;