import { useState, useEffect, useContext, useRef } from 'react';
import PageContext from './PageContext';
import redMinusIcon from '../../svgs/red-minus-icon.svg';
import greenCircleIcon from '../../svgs/green-circle-icon.svg';
import redCircleIcon from '../../svgs/red-circle-icon.svg';
import pencilIcon from '../../svgs/pencil-icon.svg';
import checkIcon from '../../svgs/check-icon.svg';
import xCircleIcon from '../../svgs/x-circle-icon.svg';
import RowDropdown from './RowDropdown';
import { HttpContext } from '../../api-services/HttpService';
import PageTooltip from './PageTooltip';
import checkChange from '../functions/checkChange';

// pass row as prop
const EditEntitlementModalRow = ({ row }) => {
    //get page context
    const {
        roleList, 
        editUser,
        getUserEntitlements,
        snackbarAlert,
        tableHeader, 
        getUserEntitlementsCounts,
    } = useContext(PageContext);

    // initialize http context 
    const {
        GET, 
        PUT, 
        DELETE, 
    } = useContext(HttpContext);

    // initialize state
    const [edit, setEdit] = useState(false);
    const [role, setRole] = useState(row.userroledescription);
    const [status, setStatus] = useState('');
    const [statusList, setStatusList] = useState(['Active', 'Inactive']);
    const [searchCompany, setSearchCompany] = useState(row.companyname);
    // intialize refs for intial values 
    const initialValues = useRef({}); 


    const settings = {};

    // event handler for edit click
    const onEditClick = () =>{
        setEdit(edit => edit = !edit);
    }

    // event handler for submit click
    const onSubmitClick = () => {
        setEdit(edit => edit = false);
    }

    // useEffect that runs when row prop changes
    useEffect(() => {
        setRole(role => role = row.userroledescription);
        setStatus(status => status = row.active ? 'Active' : 'Inactive'); 
        setSearchCompany(searchCompany => searchCompany = row.companyname);
    }, [row]);

    // create function for editing a new user entitlement data in the DB, function 
    const editUserEntitlements = (userentitlementid, newuserroledescription, olduserroledescription, mastercompanyid, editUser, status) => {
        // set active variable from statusTag
        let active = false;
        if (status == 'Active'){
            active = true; 
        }
        // check change for entitlement edit
        let args = checkChange(initialValues, { 
            mastercompanyid: mastercompanyid, 
            userroledescription: newuserroledescription, 
            active: active, 
            userentitlementid: userentitlementid, 
            userid: editUser, 
        }); 
        // attach oldValues to args 
        args['oldValues'] = initialValues.current; 
        // use PUT function to make request to /data/companyEntitlements PUT endpoint
        PUT('/data/companyEntitlements', args).then(res => {
            console.log(res); 
            // set edit to false 
            setEdit(edit => edit = false);
            // show snackbar on success
            snackbarAlert(true, `Changed User Role to: ${newuserroledescription}`, 'success'); 
            // refresh entitlements
            return getUserEntitlements(editUser); 
        }).then(res => {
            console.log(res); 
            return getUserEntitlementsCounts(); 
        }).then(res => {
            console.log(res); 
        }).catch(err => {
            console.log(err);
            // show snackbar on failure
            snackbarAlert(true, `Error Updating User Role to: ${newuserroledescription}`, 'error') 
        });  
    }       

    const deleteUserEntitlements = ( userentitlementid, userroledescription, mastercompanyid) => {     
        DELETE('/user/entitlements/delete', {
            active: row['active'], 
            userentitlementid: userentitlementid, 
            userroledescription: userroledescription, 
            mastercompanyid: mastercompanyid, 
            userid: editUser, 
        }).then(res => { 
            console.log(res); 
            // set edit to false 
            setEdit(edit => edit = false)
            // show snackbar on success
            snackbarAlert(true, `Deleted Entitlement: ${userentitlementid}`, 'success')
            // refresh user entitlements for user
            return getUserEntitlements(editUser); 
        }).then(res => {
            console.log(res); 
            return getUserEntitlementsCounts(); 
        }).then(res => {
            console.log(res); 
        }).catch(err => {
            console.log(err); 
            // show snackbar on error 
            snackbarAlert(true, `Error Deleting Entitlement: ${userentitlementid}`, 'error'); 
        });
    }
    
    //when user double clicks on delete, call the deleteUserEntitlements function
    const handleClick = (event, userentitlementid, userroledescription, mastercompanyid) => {
        if (event.detail == 2) { //on double click
            deleteUserEntitlements(userentitlementid, userroledescription, mastercompanyid); 
        }
    }

    // useEffect to run on initial render
    useEffect(() => {
        // assign initialValues the row value
        initialValues.current = {
            userroledescription: row['userroledescription'], 
            active: row['active'], 
        };  
    }, []); 

    return(
        <PageContext.Provider value={ settings }>
        <>
            {/* map logic over ever column to determine what to display */}
            {tableHeader.map(el => 
                el['columnname'] == 'active' ?
                    <td>{
                        edit ?
                            <td>
                                <RowDropdown tag='' value={status} setValue={setStatus} list={statusList}/>
                            </td>
                        : row[el['columnname']] == true ?
                            <td><img src={greenCircleIcon} class='data-table-icon-left'/></td>
                            :<td><img src={redCircleIcon} class='data-table-icon-left'/></td>
                    }</td>

                : el['columnname'] == 'userroledescription' ?
                    <td>
                        { edit ? 
                        <td>
                            <RowDropdown tag='' value={role} setValue={setRole} list={roleList}/>
                        </td>
                        :<td>{row[el['columnname']]}</td>
                        }
                    </td>
                : el['columnname']  == 'date' ? 
                    <td>{row[el].split('T')[0]}</td>
                : el['columnname']  == 'mastercompanyid' ?
                    <td></td>
                : el['columnname']  == 'blank' ?
                    <>{/* logic to determine what to display if the row is being edited or not */}
                    { edit ?
                    <td>
                        <PageTooltip title='Submit'>
                            <img src={checkIcon} className='data-table-icon' onClick={() => editUserEntitlements(row.userentitlementid, role, row.userroledescription, row.mastercompanyid, editUser, status)}/>
                        </PageTooltip>
                        <PageTooltip title='Close'>
                            <img src={xCircleIcon} className='data-table-close-icon' onClick={(e) => onEditClick(e)}/>
                        </PageTooltip>
                    </td>
                    :<td>
                        <PageTooltip title='Edit'>
                            <img src={pencilIcon} className='data-table-icon' onClick={() => onEditClick()}/>
                        </PageTooltip>
                        <PageTooltip title='Double-Click to Delete'>
                            <img src={redMinusIcon} className='data-table-icon' onClick={(e) => handleClick(e, row.userentitlementid, row.userroledescription, row.mastercompanyid)}/> 
                        </PageTooltip>
                    </td>}            </>
                :<td>{row[el['columnname'] ]}</td>             
            )}
            
        </>
        </PageContext.Provider>
    );
}

export default EditEntitlementModalRow;