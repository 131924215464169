import { useContext, useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../redux/index';
import { Modal } from "react-bootstrap";
import PageContext from "./PageContext";
import PageDropdown from './PageDropdown';
import PageInput from './PageInput';
import AutoCompleteInput from './AutoCompleteInput';
import { HttpContext } from '../../api-services/HttpService';
import checkChange from '../functions/checkChange'; 
import ApiRequestButton from './ApiRequestButton';

const EditCompanyDetailsModal = () =>{
    // get context variables from parent
    const { 
        editCompanyDetailsModal, 
        setEditCompanyDetailsModal,
        masterCompanyData, 
        rebateFrequencyList, 
        userList, 
        portfolioList, 
        getCompanyDetailsData,  
        snackbarAlert,
        processorDefaultData, 
        consortiumFlag, 
        anniversaryMonth,
        setAnniversaryMonth, 
        monthList, 
        setMonthList,
    } = useContext(PageContext);
    // initialize http context 
    const {
        PUT, 
    } = useContext(HttpContext);
    // get persistant redux global state from store
    const company = useSelector((state) => state.company);
    // initialize reducers to update redux global state
    const dispatch = useDispatch();
    const { setCompany } = bindActionCreators(actionCreators, dispatch);
    // initialize state
    const [companyName, setCompanyName] = useState('');
    const [rebateFrequency, setRebateFrequency] = useState('');
    const [primaryCCRM, setPrimaryCCRM] = useState('');
    const [portfolio, setPortfolio] = useState('');
    // useRef for initial values 
    const initialValues = useRef({}); 

    // event handler for submit company details click
    const onSubmitClick = () =>{
        return new Promise((resolve, reject) => {
            let response; 
            // check to make sure inputs are valid
            if (companyName == '' || rebateFrequency == null || portfolio == null ){
                snackbarAlert(true, 'Invalid input.', 'error');
                reject(); 
                return; 
            };
            // check to make sure primaryCCRM is in user list 
            if (primaryCCRM != masterCompanyData['primaryccrm'] && 
                (!userList.includes(primaryCCRM) && primaryCCRM != '' && primaryCCRM != null)){
                snackbarAlert(true, 'Choose valid user for Primay CCRM.', 'error'); 
                reject(); 
                return; 
            }
            // check that portfolio is no being changed between consortium and non consortium
            for (let i = 0; i < processorDefaultData.length; i++){
                if (portfolio == processorDefaultData[i]['incentivetype'] && consortiumFlag != processorDefaultData[i]['consortium']){
                    snackbarAlert(true, 'Please contact Argus Support to switch portfolio between Consortium and Non-Consortium.', 'error'); 
                    reject();
                    return; 
                }
            }
            // check that anniversary month is not null (and flag is true)
            if (process.env.REACT_APP_ANNIVERSARY_MONTH == '1' && anniversaryMonth == null){
                snackbarAlert(true, 'Invalid anniversary month.', 'error'); 
                reject();
                return; 
            }
            // nullify primary ccrm if there is no change
            let args = {}; 
            if (primaryCCRM != masterCompanyData['primaryccrm'] && primaryCCRM != '' && primaryCCRM != null){
                args = {
                    mastercompanyname: companyName, 
                    mastercompanyid: masterCompanyData['mastercompanyid'], 
                    incentivefreq: rebateFrequency,
                    primaryccrm: primaryCCRM, 
                    incentivetype: portfolio,  
                    payoutmonth: anniversaryMonth, // column name in db is payoutmonth
                }
            }
            else if (primaryCCRM != masterCompanyData['primaryccrm'] && (primaryCCRM == '' || primaryCCRM == null)){
                args = {
                    mastercompanyname: companyName, 
                    mastercompanyid: masterCompanyData['mastercompanyid'], 
                    incentivefreq: rebateFrequency,
                    primaryccrm: 'None', 
                    incentivetype: portfolio,  
                    payoutmonth: anniversaryMonth, // column name in db is payoutmonth
                }
            }
            else {
                args = {
                    mastercompanyname: companyName, 
                    mastercompanyid: masterCompanyData['mastercompanyid'], 
                    incentivefreq: rebateFrequency,
                    incentivetype: portfolio,  
                    payoutmonth: anniversaryMonth, // column name in db is payoutmonth
                }
            }
            // remove values that have not been changed from body of request
            args = checkChange(initialValues, args); 
            // add old values to arguments 
            args['oldValues'] = initialValues.current; 
            // make put request to update data in aurora db
            PUT('/data/companyDetailsData', args).then(res => {
                console.log(res);
                response = res; 
                // set company to new company name
                setCompany(companyName);
                // hide modal on success
                setEditCompanyDetailsModal(editCompanyDetailsModal => editCompanyDetailsModal = false);
                // trigger success alert
                snackbarAlert(true, 'Successfully uploaded data.', 'success');
                // refresh CompanyDetailsPage using function from parent
                return getCompanyDetailsData(companyName);
            }).then(res => {
                console.log(res);
                // resolve on success
                resolve(response); 
            }).catch(err => {
                console.log(err);
                // show alert on error
                snackbarAlert(true, 'Error uploading data.', 'error');
                // reject on error
                reject(err); 
            });
        }); 
    }

    // useEffect to update state on company change
    useEffect(() => {
        setCompanyName(companyName => companyName = company);
        // set initial value for company name 
        initialValues.current = {
            ...initialValues.current, 
            mastercompanyname: company
        }; 
    }, [company]);

    // useEffect to update company info state variables on master company data change
    useEffect(() => {
        setRebateFrequency(rebateFrequency => rebateFrequency = masterCompanyData['incentivefreq']);
        setPrimaryCCRM(primaryCCRM => primaryCCRM = masterCompanyData['primaryccrm']);
        setPortfolio(portfolio => portfolio = masterCompanyData['incentivetype']); 
        // set initial value for master company data
        initialValues.current = {
            ...initialValues.current, 
            incentivefreq: masterCompanyData['incentivefreq'], 
            primaryccrm: masterCompanyData['primaryccrm'], 
            incentivetype: masterCompanyData['incentivetype'],
            payoutmonth: anniversaryMonth, 
        }
    }, [masterCompanyData]);

    return( 
        <> 
            <Modal className="rebate-summary-modal"
                show={editCompanyDetailsModal}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                scrollable={true}
                >
                <Modal.Header closeButton onClick={()=>setEditCompanyDetailsModal(editCompanyDetailsModal => editCompanyDetailsModal = false)}>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Edit Company Details 
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    {/* page inputs/dropdowns to input company details */}
                    <div class='modal-div'>
                        <PageInput tag='Company: ' value={companyName} setValue={setCompanyName}/>
                    </div>
                    <br/>
                    <div class='modal-div'>
                        <PageDropdown tag='Rebate Frequency: ' value={rebateFrequency} setValue={setRebateFrequency} list={rebateFrequencyList}/>
                    </div>
                    <br/>
                    <div class='modal-div'>
                        <AutoCompleteInput tag='Primary CCRM: ' value={primaryCCRM} setValue={setPrimaryCCRM} list={userList} width='16.5vw' />
                    </div>  
                    <br/>
                    <div class='modal-div'>
                        <PageDropdown tag='Portfolio: ' value={portfolio} setValue={setPortfolio} list={portfolioList}/>
                    </div>
                    <br/>
                    { process.env.REACT_APP_ANNIVERSARY_MONTH == '1' ?
                    <>
                        <div class='modal-div'>
                            <PageDropdown tag='Anniversary Month: ' value={anniversaryMonth} setValue={setAnniversaryMonth} list={monthList}/>
                        </div>
                        <br/>
                    </>
                    :<></>}
                    {/* button to submit company details */}
                    <ApiRequestButton onButtonClick={onSubmitClick} />
                    <br/><br/>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default EditCompanyDetailsModal;