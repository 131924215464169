import { useState, useEffect, useContext } from 'react'; 
import greenCircleIcon from '../../svgs/green-circle-icon.svg';
import redCircleIcon from '../../svgs/red-circle-icon.svg';
import RowEditIcon from './RowEditIcon';
import checkIcon from '../../svgs/check-icon.svg';
import xCircleIcon from '../../svgs/x-circle-icon.svg';
import PageInput from './PageInput'; 
import PageDropdown from './PageDropdown'; 
import { HttpContext } from '../../api-services/HttpService'; 
import PageContext from './PageContext';
import isNumeric from '../functions/isNumeric'; 

const AccountIdsRow = ({ row, count, tableHeader }) => {
    // get PageContext from parent 
    const {
        getAccountIdsData, 
        masterCompanyData, 
        editSubCompanyRow,
        snackbarAlert, 
        editButtonBool
    } = useContext(PageContext); 
    // get api functions from HttpContext
    const {
        PUT, 
    } = useContext(HttpContext); 
    // initialize state
    const [enableEdit, setEnableEdit] = useState(true); 
    const [rowEdit, setRowEdit] = useState(false); 
    const [status, setStatus] = useState(); 
    const [statusList, setStatusList] = useState(['Active', 'Inactive']); 
    const [accountId, setAccountId] = useState(''); 
    // event handler for edit click 
    const onEditClick = () => {
        // set row to edit to true
        setRowEdit(rowEdit => rowEdit = true); 
    }
    // event handler to close edit
    const onCloseEdit = () => {
        setRowEdit(rowEdit => rowEdit = false); 
    }
    // event handler for submit click
    const onSubmitClick = () => {
        // validate account id is numeric
        if (!isNumeric(accountId) ){
            snackbarAlert(true, 'Error: Invalid Account ID.', 'error'); 
            return; 
        }
        // set active based on status
        let active = false; 
        if (status == 'Active'){
            active = true; 
        }
        // make put request
        PUT('/data/accountids', {
            mastercompanyid: masterCompanyData['mastercompanyid'], 
            companyid: editSubCompanyRow['companyid'], 
            active: active, 
            accountid: accountId, 
            id: row['id'], 
        }).then(res => {
            console.log(res);
            // make edit row false
            setRowEdit(rowEdit => rowEdit = false); 
            // show success on snackbar
            snackbarAlert(true, 'Successfully uploaded data.', 'success');
            // refresh table
            return getAccountIdsData(); 
        }).then(res => {
            console.log(res); 
        }).catch(err => {
            console.log(err); 
            // show error on snackbar
            snackbarAlert(true, 'Error uploading data.', 'error');
        })
    }

    // display red circle for disabled (0) status or green circle for active (1) status
    const statusIcon = (status) =>{
        if ( status == true){
            return <img src={greenCircleIcon} class='data-table-icon-left'/>
        }
        else {
            return <img src={redCircleIcon} class='data-table-icon-left'/>
        }
    }

    // useEffect to set accountId and status on render and row change
    useEffect(() => {
        // set status 
        if (row['active'] == true){
            setStatus(status => status = 'Active'); 
        }
        else {
            setStatus(status => status = 'Inactive'); 
        }
        // set accountId
        setAccountId(accountId => accountId = row['accountid']); 
    }, [row, rowEdit]); 

    return (
        <>
            {/* map logic over ever column in row object */}
            {tableHeader.map(el =>
                // if status column apply statusIcon function 
                el['columnname'] == 'active' ?
                    !rowEdit ? 
                        <td>{statusIcon(row[el['columnname']])}</td>
                        : <td><PageDropdown tag='-' value={status} setValue={setStatus} list={statusList} width='7.2vw' font={15}/></td>
                // show edit column if entitled
                : el['columnname'] == 'blank' && editButtonBool ?
                    <td >
                        <span style={{float: 'right'}}>
                            {!rowEdit ? 
                                /* show edit icon to trigger EditSubCompanyRow modal */
                                <RowEditIcon enable={enableEdit} onClick={onEditClick}/>
                                :<>
                                    <img src={xCircleIcon} class='data-table-icon-xCircle' onClick={()=>onCloseEdit()}/>
                                    <img src={checkIcon} class='data-table-icon-right' onClick={()=>onSubmitClick()}/>
                                </>
                            }
                            
                        </span>  
                    </td>
                // account id column, show page input when edit
                : el['columnname'] == 'accountid' ? 
                    !rowEdit ? 
                        <td>{row[el['columnname']]}</td>
                        :<td><PageInput tag='-' value={accountId} setValue={setAccountId} width='14.3vw' font={15}/></td>
                // standard display for all other columns 
                :<td>{row[el['columnname']]}</td>
            )}
        </>
    ); 
}

export default AccountIdsRow; 