import { useContext, useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import PageContext from "./PageContext";
import DataTable from './DataTable';
import OrderedDataTable from '../sub-components/OrderedDataTable';
import StandardRow from './StandardRow';
import { HttpContext } from '../../api-services/HttpService';

// temporary data to show in table
//const trailData = require('../../TempValueFiles/rebateCycleAggregateData.js');
//const auditTrailData = require('../../TempValueFiles/auditTrailData.js');

const RebateTrailModal = () =>{
    // get context variables from parent 
    const { 
        trailModal, 
        setTrailModal,
        editRow,  
        setEditRow, 
    } = useContext(PageContext);
    // initialize http context 
    const {
        GET, 
    } = useContext(HttpContext);

    // set state to display
    const [trailDataList, setTrailDataList] = useState([]);
    const [trailTableHeaders, setTrailTableHeaders] = useState([]);
    const [auditTrailDataList, setAuditTrailDataList] = useState([]);
    const [auditTrailTableHeaders, setAuditTrailTableHeaders] = useState([]);
    // initialize spinner state
    const [showSpinner, setShowSpinner] = useState(false);

    //function to call api to get rebate workflow trail. takes in a workflowid
    const getWorkflowTrail = (workflowid, mastercompanyid, cyclestart, cycleend) => {
        setShowSpinner(showSpinner => showSpinner = true);
        return new Promise((resolve, reject) => {
            GET('/rebate/summary/trail', { workflowid: workflowid, mastercompanyid: mastercompanyid, cyclestart: cyclestart, cycleend: cycleend }).then(res => {console.log(res.data);
                setAuditTrailDataList(auditTrailDataList => auditTrailDataList = res.data.Trail);
                setAuditTrailTableHeaders(auditTrailTableHeaders => auditTrailTableHeaders =  res['data']['GuiTableColsTrail']);
                setTrailDataList(trailData => trailData = res.data.RebateAgg);
                setTrailTableHeaders(trailTableHeaders => trailTableHeaders = res['data']['GuiTableColsAgg']);            
                // hide spinner
                setShowSpinner(showSpinner => showSpinner = false);
                resolve(res);
            }).catch(err => {
                // hide spinner
                setShowSpinner(showSpinner => showSpinner = false);
                reject(err);
            });
        });
    }

    // function wrapper to pass row component to datatable
    const RowComponent = (row) =>{
        return(
            <> 
                <StandardRow row={row}/>
            </>
        );
    }

    // update company info every time row changes
    useEffect(()=>{
        getWorkflowTrail(editRow.workflowid, editRow.mastercompanyid, editRow.cyclestart, editRow.cycleend);
    }, [editRow.workflowid]);

    return(
        <> 
            <Modal className="rebate-summary-modal"
                show={trailModal} // state from context
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                scrollable={true}
                >
                <Modal.Header closeButton onClick={()=>setTrailModal(trailModal => trailModal = false)}>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Audit Trail 
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    {/* read only inputs for data about rebate workflow */}
                    <div class='modal-div'>
                        <label><b>Company: </b>{editRow['mastercompanyname']}</label>
                    </div>
                    <br/>
                    <div class='modal-div'>
                        <label><b>Payout Cycle: </b>{editRow.periodcyclestart + '-' + editRow.periodcycleend + ' [' + editRow.incentivefreq + ']'}</label>
                    </div>
                    <br/>
                    <OrderedDataTable RowComponent={RowComponent} tableHeader={trailTableHeaders} dataList={trailDataList} showSpinner={showSpinner}/>
                    <OrderedDataTable RowComponent={RowComponent} tableHeader={auditTrailTableHeaders} dataList={auditTrailDataList} showSpinner={showSpinner}/>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default RebateTrailModal;