import { useEffect, useState, useRef } from 'react';
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';

// button to export dataList to .xlsx file which is downloaded on to user's device
const ExportExcelButton = ({ tag, dataList, tableHeader, formatList }) => {
    // initialize state for date
    const [currentDate, setCurrentDate] = useState('');
    // initialize ref for formatting
    const fmtList = useRef([]); 
    
    // set global variables to be used in handler function
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    // format list as empty array if null
    if (formatList){
        fmtList.current = formatList; 
    }

    // event handler to export excel
    const onExport = () => {
        // set list to parse col to floats
        let floatList = []; 
        for (let i = 0; i < fmtList.current.length; i++){
            if (fmtList.current[i]['float'] == true){
                floatList.push(fmtList.current[i]['columnname']);
            }
        }
        // initialize variable to export
        let exportList = []; 
        // use data from dataList for each tableHeader column
        if (tableHeader){
            for (let i = 0; i < dataList.length; i++){
                let elem = {};
                for (let k = 0; k < tableHeader.length; k++){
                    if (floatList.includes(tableHeader[k]['columnname']) && dataList[i][tableHeader[k]['columnname']]){ // element cannot be null
                        elem[tableHeader[k]['columntag']] = parseFloat(dataList[i][tableHeader[k]['columnname']]);
                    }
                    else {
                        elem[tableHeader[k]['columntag']] = dataList[i][tableHeader[k]['columnname']];
                    } 
                }
                exportList.push(elem);
            }
        }
        // if no tableHeader is present export dataList without transforming it
        else {
            exportList = dataList; 
        }
        // use library to create .xlsx sheet from exportList
        const ws = XLSX.utils.json_to_sheet(exportList);
        // format columns as currency 
        let C1 = []; 
        for (let i = 0; i < fmtList.current.length; i++){
            C1.push(XLSX.utils.decode_col(fmtList.current[i]['col'])); 
        }
        // get worksheet range 
        var range = XLSX.utils.decode_range(ws['!ref']);
        for(var i = range.s.r; i <= range.e.r; ++i) {
            for (let k = 0; k < C1.length; k++){
                // find the data cell Transaction Amt col 
                var ref1 = XLSX.utils.encode_cell({r:i, c:C1[k]}); 
                //  if the particular row did not contain data for the column, the cell will not be generated 
                // .t == "n"` for number cells 
                if (ws[ref1] && ws[ref1].t == 'n'){
                    //  assign the `.z` number format 
                    ws[ref1].z = fmtList.current[k]['fmt'];
                    // clear .w text format for cell 
                    delete ws[ref1].w; 
                }
            }
        }
        // use library to add sheet to .xlsx file
        const wb = { Sheets: {[tag]: ws}, SheetNames: [tag]};
        const excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type: 'array'});
        const data = new Blob([excelBuffer], {type: fileType});
        // export file with date in name 
        FileSaver.saveAs(data, tag + '-' + currentDate + fileExtension);
    }

    // get date using react built in function and set state
    useEffect(()=>{
        setCurrentDate(currentDate => currentDate = Date().toLocaleString().substring(0, 15).replace(/ /g, '-').toLowerCase());
    }, []);

    return (
        <>
            <button class='summary-page-button' onClick={()=>onExport()}>Export Excel</button>
        </>
    );
}

export default ExportExcelButton;