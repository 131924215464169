import { useContext, useState, useRef, useEffect } from 'react';
import ExportPayoutsButton from '../sub-components/ExportPayoutsButton';
import PageContext from '../sub-components/PageContext';

const MulitpartWorkflowButton = () => {
    // initialize context from parent
    const {
        workflowAction, //not sure if we need this
        setWorkflowAction,
        workflowActionData, 
        workflowActionStatus,
        multipartWorkflowModal, 
        setMultipartWorkflowModal,
        workflowButtonCss, 
        workflowOptOut, 
        workflowReviewAction, 
        multipartWorkflowAction, 
        setMultipartWorkflowAction,
    } = useContext(PageContext);

    // initialize state
    const [workflowActionDataRow, setWorkflowActionDataRow] = useState({});

    // event handler for submit click
    const onSubmit = () => { 
        workflowButtonCss.current = 'modal-button-full-blue';
        workflowOptOut.current = true; 
        workflowReviewAction.current = false; 
        setMultipartWorkflowAction(multipartWorkflowAction => multipartWorkflowAction = 'Submit');
        setMultipartWorkflowModal(multipartWorkflowModal => multipartWorkflowModal = true);
    }

    // event handler for accept click
    const onAccept = () => {
        workflowButtonCss.current = 'modal-button-full-green';
        workflowOptOut.current = false; 
        workflowReviewAction.current = true; 
        setMultipartWorkflowAction(multipartWorkflowAction => multipartWorkflowAction = 'Accept');
        setMultipartWorkflowModal(multipartWorkflowModal => multipartWorkflowModal = true);
    }

    // event handler for reject click
    const onReject = () => {
        workflowButtonCss.current = 'modal-button-full-red';
        workflowOptOut.current = false; 
        workflowReviewAction.current = true; 
        setMultipartWorkflowAction(multipartWorkflowAction => multipartWorkflowAction = 'Reject');
        setMultipartWorkflowModal(multipartWorkflowModal => multipartWorkflowModal = true);
    }

    // change row object when workflow action changes 
    useEffect(() => {
        for (let i = 0; i < workflowActionData.current.length; i++){
            if (workflowAction == workflowActionData.current[i]['tag']){
                setWorkflowActionDataRow(workflowActionDataRow => workflowActionDataRow = workflowActionData.current[i])
            }
        }   
    }, [workflowAction, workflowActionData.current]);

    // workflow button component
    const WorkflowButton = ({tag, func}) => {
        return <>
            <button class='summary-page-button' onClick={()=>func()}>{tag}</button>
        </>;
    }

    return (
        <>
            {/* logic to determine which workflow button to display based on workflow action status passed from parent */}
            { workflowActionStatus == 'Export Payout' ?
                <ExportPayoutsButton/>
            : workflowActionStatus == 'Pending' ?
                <WorkflowButton tag='Submit' func={()=>onSubmit()}/>
            : workflowActionStatus == 'Rejected' ?
                <WorkflowButton tag='Submit' func={()=>onSubmit()}/>
            : workflowActionStatus == 'Initial Review' ?
            <>
                <WorkflowButton tag='Accept' func={()=>onAccept()}/>
                <WorkflowButton tag='Reject' func={()=>onReject()}/>
            </>
            : workflowActionStatus == 'Final Review' ?
            <>
                <WorkflowButton tag='Accept' func={()=>onAccept()}/>
                <WorkflowButton tag='Reject' func={()=>onReject()}/>
            </>
            :<></>}
        </>
    );
}

export default MulitpartWorkflowButton; 