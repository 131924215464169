import { useState, useEffect, useRef, useContext } from 'react';
import { useSelector } from 'react-redux';
import PageContext from '../sub-components/PageContext';
import HeaderBanner from '../sub-components/HeaderBanner';
import Navslider from '../sub-components/Navslider';
import PageDropdown from '../sub-components/PageDropdown';
import PageInput from '../sub-components/PageInput'; 
import ApiDataTable from '../sub-components/ApiDataTable';
import TransactionDataRow from '../sub-components/TransactionDataRow';
import DatePicker from '../sub-components/DatePicker';
import AutoCompleteInput from '../sub-components/AutoCompleteInput';
import moment from 'moment'; 
import { HttpContext } from '../../api-services/HttpService';
import AutoCompleteInputWithOptions from '../sub-components/AutoCompleteInputWithOptions';
import ExportS3ExcelButton from '../sub-components/ExportS3ExcelButton';
import SnackbarAlert from '../sub-components/SnackbarAlert';
import removeStrCommas from '../functions/removeStrCommas';

const TransactionDetailsPage = () =>{ 
    // global redux state for company (passed in from other route)
    const company = useSelector((state) => state.company);
    const companyInfo = useSelector((state) => state.companyInfo);
    const transactionDetails = useSelector((state) => state.transactionDetails);
    // initialize http context 
    const {
        GET,
        GETQ,  
    } = useContext(HttpContext);
    // initialize state
    const [accountInput, setAccountInput] = useState('');
    // const [accountList, setAccountList] = useState(['All']);
    const [subCompanyInput, setSubCompanyInput] = useState('All');
    const [subCompanyList, setSubCompanyList] = useState(['All']);
    const [feeCategorySelected, setFeeCategorySelected] = useState('All');
    const [feeCategoryList, setFeeCategoryList] = useState(['All']);
    const [productType, setProductType] = useState('All');
    const [productTypeList, setProductTypeList] = useState(['All']);
    const [transactionDescription, setTransactionDescription] = useState('');
    const [transactionLow, setTransactionLow] = useState('');
    const [transactionHigh, setTransactionHigh] = useState('');
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    // initialize state for table
    const [dataList, setDataList] = useState([]); 
    const [tableHeader, setTableHeader] = useState([]);
    // initialize state for spinner
    const [showSpinner, setShowSpinner] = useState(false);
    // initialize master company list for dropdown 
    const [masterCompany, setMasterCompany] = useState('--select--');
    const [masterCompanyObj, setMasterCompanyObj] = useState({label: '--select--', id: '--select--'});
    const [masterCompanyList, setMasterCompanyList] = useState([{label: '--select--', id: '--select--'}]);
    // initialize state for num of pages 
    const [pages, setPages] = useState(0);
    // initialize ref for params for pagination request
    const pageParams = useRef({});
    // initialize ref for num to page
    const numToPage = useRef(10);
    // initialize state for snackbar 
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarColor, setSnackbarColor] = useState('info');
    

    // function wrapper to pass row component to datatable
    const RowComponent = (row, count, tableHeader) =>{
        return( 
            <>
                <TransactionDataRow row={row} count={count} tableHeader={tableHeader}/>
            </>
        );
    } 

    // function to set snackbar state
    const snackbarAlert = (show, message, color) => {
        setSnackbarMessage(snackbarMessage => snackbarMessage = message);
        setSnackbarColor(snackbarColor => snackbarColor = color);
        setShowSnackbar(showSnackbar => showSnackbar = show);
    }

    const getRequestParams = (pageNum) => {
        let params = {
            mastercompanyid: masterCompanyObj['id'], 
            startdate: moment(startDate).format('YYYY-MM-DD'), 
            enddate: moment(endDate).format('YYYY-MM-DD'), 
            numToPage: numToPage.current, 
            pageNum: pageNum, 
        }
        // validate inputs by nullifying those that are not filtering table 
        if (subCompanyInput != 'All'){
            params['companyid'] = subCompanyInput;
        }

        if (feeCategorySelected != 'All'){
            params['feecategorydescription'] = feeCategorySelected;
        }

        if (productType != 'All'){
            params['producttypedescription'] = productType;
        }

        if (transactionLow != ''){
            params['transactionlow'] = removeStrCommas(transactionLow);
        }

        if (transactionHigh != ''){
            params['transactionhigh'] = removeStrCommas(transactionHigh);
        }

        if (accountInput != ''){
            params['accountid'] = accountInput; 
        }

        if (transactionDescription != ''){
            params['merchantdescription'] = transactionDescription;
        }

        return params; 
    }

    // promise to get data for excel export
    const getTransactionDataExcel = () => {
        return new Promise((resolve, reject) => {
            // get params from pageParams and without pageNum and numToPage
            let params = {}; 
            Object.keys(pageParams.current).map(el => {
                if (el != 'pageNum' && el != 'numToPage'){
                    params[el] = pageParams.current[el];
                }
            });
            // make request to /data/transaction/excel endpoint to create xlsx files and get presigned urls
            GET('/data/transactions/excel', params).then(res =>{
                console.log(res);
                resolve(res);
            }).catch(err => {
                // if 413 error is thrown show message to limit export amount 
                if (err == 'Error: Request failed with status code 413'){
                    snackbarAlert(true, 'Error: Please limit export to 60,000 transactions.', 'error');
                }
                else {
                    snackbarAlert(true, 'Error retrieving data.', 'error');
                }
                reject(err);
            })  
        });        
    }

    // promise to make get request for search transaction data 
    const getTransactionDataSearch = (params) => {
        setShowSpinner(showSpinner => showSpinner = true);
        return new Promise ((resolve, reject) => {
            console.log(params);
            // use GET function to make request
            GET('/data/transactions/filter', params).then(res => {
                // set state for table 
                setDataList(dataList => dataList = res['data']['TransactionData']);
                setTableHeader(tableHeader => tableHeader = res['data']['TransactionCols']);
                setPages(pages => pages = res['data']['TransactionPages']);
                setSubCompanyList(subCompanyList => subCompanyList = ['All', ...res['data']['SubCompanyList']]);
                // default filter parameters
                setFeeCategorySelected(feeCategorySelected => feeCategorySelected = 'All');
                setProductType(productType => productType = 'All');
                setSubCompanyInput(subCompanyInput => subCompanyInput = 'All');
                setAccountInput(accountInput => accountInput = '');
                setTransactionDescription(transactionDescription => transactionDescription = '');
                setTransactionHigh(transactionHigh => transactionHigh = '');
                setTransactionLow(transactionLow => transactionLow = '');
                // set params for pagination request
                pageParams.current = params; 
                // hide spinner
                setShowSpinner(showSpinner => showSpinner = false);
                // resolve on success
                resolve(res);
            }).catch(err => {
                snackbarAlert(true, 'Error retrieving data.', 'error');
                // hide spinner
                setShowSpinner(showSpinner => showSpinner = false);
                reject(err);
            });
        });
    }

    // promise to make get request for filtered transaction data 
    const getTransactionDataFilter = (params) => {
        setShowSpinner(showSpinner => showSpinner = true);
        return new Promise ((resolve, reject) => {
            console.log(params);
            // use GET function to make request
            GET('/data/transactions/filter', params, 'transactions').then(res => {
                // set state for table 
                setDataList(dataList => dataList = res['data']['TransactionData']);
                setTableHeader(tableHeader => tableHeader = res['data']['TransactionCols']);
                setPages(pages => pages = res['data']['TransactionPages']);
                setSubCompanyList(subCompanyList => subCompanyList = ['All', ...res['data']['SubCompanyList']]);
                // set params for pagination request
                pageParams.current = params; 
                // hide spinner
                setShowSpinner(showSpinner => showSpinner = false);
                // resolve on success
                resolve(res);
            }).catch(err => {
                snackbarAlert(true, 'Error retrieving data.', 'error');
                // hide spinner
                setShowSpinner(showSpinner => showSpinner = false);
                reject(err);
            });
        });
    }

    // get data for dropdowns 
    const getMasterCompanyData = () => {
        return new Promise ((resolve, reject) => {
            GET('/data/transactions/transactionPage', {}).then(res => {
                // set state
                setMasterCompanyList(masterCompanyList => masterCompanyList = [{label: '--select--', id: '--select--'}, ...res['data']['MasterCompanyData']]);
                setFeeCategoryList(feeCategoryList => feeCategoryList = ['All', ...res['data']['FeeCategoryList']]);
                setProductTypeList(productTypeList => productTypeList = ['All', ...res['data']['ProductTypeList']]);
                // resolve on success
                resolve(res);
            }).catch(err => {
                reject(err);
            });
        });
    }

    const onSearchSubmit = () => {
        let params = {
            mastercompanyid: masterCompanyObj['id'], 
            startdate: moment(startDate).format('YYYY-MM-DD'), 
            enddate: moment(endDate).format('YYYY-MM-DD'), 
            numToPage: numToPage.current, 
            pageNum: 1, 
        }
        // get transaction data from params 
        getTransactionDataSearch(params).then(res => {
            console.log(res);
        }).catch(err => {
            console.log(err);
        });      
    }

    const onFilterSubmit = () => {
        // get validated params 
        let params = getRequestParams(1);
        // get transaction data from params 
        getTransactionDataFilter(params).then(res => {
            console.log(res);
        }).catch(err => {
            console.log(err);
        }); 
    }

    const onPagination = (page) => {
        return new Promise((resolve, reject) => {
            // use pageParams to make request 
            let params = pageParams.current;
            params['pageNum'] = page; 
            // get transaction data from params 
            getTransactionDataFilter(params).then(res => {
                resolve(res);
            }).catch(err => {
                reject(err);
            }); 
        });
    }

    // useEffect to run on initial render
    useEffect(() => {
        // set search params and filters from transaction data if present (page is clicked on details drilldown)
        if (transactionDetails){
            // set start date and end date from company info if it is present
            let year = parseInt(transactionDetails['periodid'].slice(0, 4));
            let month = parseInt(transactionDetails['periodid'].slice(4, transactionDetails['periodid'].length));
            // first day of month specified by periodid
            let tempStart = new Date(year, month-1, 1);
            setStartDate(startDate => startDate = tempStart);
            // last day of month specified by periodid
            let tempEnd = new Date(year, month, 0);
            setEndDate(endDate => endDate = tempEnd);
            // set sub company id 
            setSubCompanyInput(subCompanyInput => subCompanyInput = transactionDetails['companyid']);
            setSubCompanyList(subCompanyList => subCompanyList = ['All', transactionDetails['companyid']]);
            // set fee category 
            setFeeCategorySelected(feeCategorySelected => feeCategorySelected = transactionDetails['feecategorydescription']);
            setFeeCategoryList(feeCategoryList => feeCategoryList = ['All', transactionDetails['feecategorydescription']]);
            // set product type
            setProductType(productType => productType = transactionDetails['producttypedescription']); 
            setProductTypeList(productTypeList => productTypeList = ['All', transactionDetails['producttypedescription']])
            // set master company and obj
            setMasterCompany(masterCompany => masterCompany = company);
            setMasterCompanyObj(masterCompanyObj => masterCompanyObj = {'label': company, 'id': companyInfo['mastercompanyid']});
            // get master company list for dropdown
            getMasterCompanyData().then(res => {
                console.log(res);
                // set params for getting transaction data 
                let params = {
                    mastercompanyid: companyInfo['mastercompanyid'], 
                    startdate: moment(tempStart).format('YYYY-MM-DD'), 
                    enddate: moment(tempEnd).format('YYYY-MM-DD'), 
                    companyid: transactionDetails['companyid'], 
                    feecategorydescription: transactionDetails['feecategorydescription'], 
                    producttypedescription: transactionDetails['producttypedescription'], 
                    numToPage: numToPage.current, 
                    pageNum: 1, 
                }
                // get transaction data 
                return getTransactionDataFilter(params);
            }).then(res => {
                console.log(res);
            }).catch(err => {
                console.log(err);
            });
        }
        // on render when page not accessed by drilldown
        else{
            getMasterCompanyData().then(res => {
                console.log(res);
            }).catch(err => {
                console.log(err);
            });
        }
    }, []); 

    // object to pass variables to PageContext
    const settings = {
        showSnackbar, 
        setShowSnackbar,
        snackbarMessage, 
        snackbarColor, 
    };

    return (
        <div class='summary-page'>
            {/* wrap children so they can access PageContext variables */}
            <PageContext.Provider value={ settings }>
                {/* header and navslider present on ever screen */}
                <HeaderBanner indicator={1}/>
                <Navslider indicator={-1} backPath='/CompanyPayoutViewPage'/>
                {/* snackbar component for alerts */}
                <SnackbarAlert />
                {/* top label for company name and date range for transactions */}
                <div class='top-label-div'>
                    <label class='top-label-text'><b>Transaction Details</b></label>
                    <br/>
                    <div class='label-text-div'>
                        <span class='label-text-span-left'>
                            <AutoCompleteInputWithOptions tag='Master Company:' value={masterCompany} setValue={setMasterCompany} obj={masterCompanyObj} setObj={setMasterCompanyObj} list={masterCompanyList} />
                        </span>
                        <span>
                            <DatePicker tag='Start Date: ' value={startDate} setValue={setStartDate} disable={endDate} indicator={1}/>
                        </span>
                        <span class='label-text-span-right'>
                            <DatePicker tag='End Date: ' value={endDate} setValue={setEndDate} disable={startDate} indicator={2}/>
                        </span>
                    </div>
                    <div class='label-text-div'>
                        <span class='label-text-span-right'>
                            <button class='summary-page-button' style={{marginTop: '8px'}} onClick={()=>onSearchSubmit()}>Search</button>
                        </span>
                    </div>
                    <br/><br/>
                </div>
                <br/>
                <div class='data-table-div'>
                    <label><b>Filter Results Between {moment(startDate).format('MM-DD-YYYY')} and {moment(endDate).format('MM-DD-YYYY')}: </b></label>
                </div>
                {/* mid label for filtering results between start date and end date */}
                <div class='mid-label-div'>
                    <div class='mid-label-text-div'>
                        <div class='mid-label-text-div'>
                            <span class='label-text-span-left'>
                                <PageDropdown tag='Fee Category:' value={feeCategorySelected} setValue={setFeeCategorySelected} list={feeCategoryList}/>   
                            </span>
                            <span class='label-text-span-mid'>
                                <AutoCompleteInput tag='Sub Company: ' value={subCompanyInput} setValue={setSubCompanyInput} list={subCompanyList} width='16.5vw'/>
                            </span>
                            <span class='label-text-span-right'>
                                {/* <AutoCompleteInput tag='Account: ' value={accountInput} setValue={setAccountInput} list={accountList} width='16.5vw'/> */}
                                <PageInput tag='Account: ' value={accountInput} setValue={setAccountInput} />
                            </span>
                        </div>
                        <br/>
                        <div class='mid-label-text-div'>
                            <span class='label-text-span-left'>
                                <PageDropdown tag='Product Type' value={productType} setValue={setProductType} list={productTypeList} />
                            </span>
                            <span class='label-text-span-mid'>
                                <PageInput tag='Trans Amt >: ' value={transactionLow} setValue={setTransactionLow} />
                            </span>
                            <span class='label-text-span-right'>
                                <PageInput tag='Trans Amt <: ' value={transactionHigh} setValue={setTransactionHigh} />
                            </span>
                        </div>
                        <br/>
                        <div class='mid-label-text-div'>
                            <span class='label-text-span-left'>
                                <PageInput tag='Merch Desc: ' value={transactionDescription} setValue={setTransactionDescription} />
                            </span>
                            <span class='label-text-span-right'>
                                <button class='summary-page-button' style={{marginTop: '7px'}} onClick={()=>onFilterSubmit()}>Filter</button>
                            </span>
                        </div>
                        <br/><br/>
                    </div>                    
                </div>                
                <br/>
                {/* data table to show filtered transaction object array */}
                <div class='data-table-div'>
                    <ExportS3ExcelButton tag={'transaction-details-' + masterCompanyObj['label'].slice(0, 10)} getData={getTransactionDataExcel}/>
                    <ApiDataTable RowComponent={RowComponent} tableHeader={tableHeader} dataList={dataList} numOfPages={pages} showSpinner={showSpinner} setShowSpinner={setShowSpinner} skeletonHeight={'320px'} onPagination={onPagination} pageParams={pageParams} />
                </div>
            </PageContext.Provider>
        </div> 
    );
}

export default TransactionDetailsPage;