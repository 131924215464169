import { useContext, useState, useEffect, useRef } from 'react';
import PageContext from './PageContext';
import greenPlusIcon from '../../svgs/green-plus-icon.svg';
import redMinusIcon from '../../svgs/red-minus-icon.svg';
import PageInput from './PageInput';
import PageDropdown from './PageDropdown';
import AutoComplete from './AutoCompleteInput'; 
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import PageReadOnlyInput from './PageReadOnlyInput'; 
import TableInput from './TableInput'; 

const AddNewRebateSetupRow = ({row, count, tableHeader}) =>{
    // get context from parent
    const {
        rebateSetupDataList, 
        setRebateSetupDataList, 
        feeCategoryDescriptionList, 
        productTypeList, 
        billingRateList, 
        daysToPayList,  
        subCompanyIdsList, 
        masterTierIdsList, 
        setMasterTierIdsList, 
        setupToAllSubs,
        rebateSetupErrorList, 
        setRebateSetupErrorList,
        rebateInputTypeId, 
    } = useContext(PageContext);

    // set state
    const [status, setStatus] = useState('Active');
    const [statusList, setStatusList] = useState(['Active', 'Inactive']);
    const [masterSetupId, setMasterSetupId] = useState(''); 
    const [setupId, setSetupId] = useState(count+1); // setup id will always be count+1 (this is for adding identity column into database if master tier for another row)
    const [subCompany, setSubCompany] = useState('');
    const [product, setProduct] = useState('--select--');
    const [billingRate, setBillingRate] = useState('--select--'); 
    const [daysToPay, setDaysToPay] = useState('--select--');
    const [feeCategory, setFeeCategory] = useState('--select--');
    const [tierMin, setTierMin] = useState('');
    const [tierMax, setTierMax] = useState('');
    const [rebateRate, setRebateRate] = useState(null);
    const [masterSetupBool, setMasterSetupBool] = useState(false); 
    const [rateTag, setRateTag] = useState('Rate'); 

    // return null if empty string
    const nullIfEmpty = ( str ) => {
        if (str != null && str.length == 0){
            return null; 
        }
        else {
            return str; 
        }
    }

    // add row from data list
    const addRebateSetup = () =>{
        let temp2 = []; 
        for (let i = 0; i < tableHeader.length; i++){
            temp2.push(tableHeader[i]['columnname']);
        }
        let temp;
        // if there is a previous row get sub company id, master tier id, product, fee category, billing rate and days to pay from that row 
        if (count-1 >= 0){
            temp2.map((el) => {
                if (el == 'blank'){
                    temp = {...temp, [el]: false};
                }
                else if (el == 'active'){
                    temp = {...temp, [el]: 'Active'};
                }
                else if (el == 'companyid'){
                    temp = {...temp, [el]: rebateSetupDataList[count-1]['companyid']}; 
                }
                else if (el == 'masterincentivesetupid'){
                    temp = {...temp, [el]: rebateSetupDataList[count-1]['masterincentivesetupid']}; 
                }
                else if (el == 'producttypedescription'){
                    temp = {...temp, [el]: rebateSetupDataList[count-1]['producttypedescription']};
                }
                else if (el == 'feecategorydescription'){
                    temp = {...temp, [el]: rebateSetupDataList[count-1]['feecategorydescription']}; 
                }
                else if (el == 'billingratedescription'){
                    temp = {...temp, [el]: rebateSetupDataList[count-1]['billingratedescription']}; 
                }
                else if (el == 'daystopay') {
                    temp = {...temp, [el]: rebateSetupDataList[count-1]['daystopay']}; 
                }
                else if (el == 'incentivesetupid') {
                    temp = {...temp, [el]: count};
                }
                else {
                    temp = {...temp, [el]: ''};
                }
            }); 
        }
        // if no previous row all values are default 
        else{
            temp2.map((el) => {
                if (el == 'blank'){
                    temp = {...temp, [el]: false};
                }
                else if (el == 'active'){
                    temp = {...temp, [el]: 'Active'};
                }
                else if (el == 'feecategorydescription' || el == 'producttypedescription' || el == 'billingratedescription' || el == 'daystopay'){
                    temp = {...temp, [el]: '--select--'};
                }
                else if (el == 'incentivesetupid') {
                    temp = {...temp, [el]: count};
                }
                else{
                    temp = {...temp, [el]: ''};
                }
            });
        }
        setRebateSetupDataList(rebateSetupDataList => rebateSetupDataList = [...rebateSetupDataList.slice(0, rebateSetupDataList.length-1), temp, ...rebateSetupDataList.slice(rebateSetupDataList.length-1, rebateSetupDataList.length)]);
        // set error object 
        let temp5 = {}
        Object.keys(temp2).map((el) => {
            temp5 = {
                ...temp5, 
                el: false, 
            }
        }); 
        // add error object to sub company error list 
        setRebateSetupErrorList(rebateSetupErrorList => rebateSetupErrorList = [
            ...rebateSetupErrorList.slice(0, rebateSetupErrorList.length-1), 
            temp5, 
            ...rebateSetupErrorList.slice(rebateSetupErrorList.length-1, rebateSetupErrorList.length)
        ]); 
    }

    // remove row from data list 
    const removeRebateSetup = (index) =>{
        // remove datalist row
        setRebateSetupDataList(rebateSetupDataList => rebateSetupDataList = [...rebateSetupDataList.slice(0, index), ...rebateSetupDataList.slice(index + 1, rebateSetupDataList.length)]);
        // remove error object from error list
        setRebateSetupErrorList(rebateSetupErrorList => rebateSetupErrorList = [...rebateSetupErrorList.slice(0, index), ...rebateSetupErrorList.slice(index + 1, rebateSetupErrorList.length)]); 
    }

    // showCell for to determine how to display each column
    const showCell = ( el ) =>{
        if (el == 'blank'){
            if (row[el] == true){
                return <img src={greenPlusIcon} class='data-table-icon-right' onClick={()=>addRebateSetup()}/>
            }
            else{
                return <img src={redMinusIcon} class='data-table-icon-right' onClick={()=>removeRebateSetup(count)}/>
            }
        } 
        else if (row['blank'] == false && el == 'active'){
            return <PageDropdown tag='Status' value={status} setValue={setStatus} list={statusList} width='4.3vw' font={10} showError={rebateSetupErrorList[count]['active']}/>
        }
        else if (row['blank'] == false && el == 'masterincentivesetupid'){
            // if is master setup do not allow input for master setup
            if (!masterSetupBool){
                return <AutoComplete tag='Master Setup ID' value={masterSetupId} setValue={setMasterSetupId} list={masterTierIdsList} width='5.6vw' font={'10px'} showError={rebateSetupErrorList[count]['masterincentivesetupid']}/>
            }
            else {
                return <></>
            }
        }
        else if (row['blank'] == false && el == 'companyid'){
            // if adding setup to all sub companies do not allow input for sub company id
            if (!setupToAllSubs){
                return <AutoComplete tag='Sub Company ID' value={subCompany} setValue={setSubCompany} list={subCompanyIdsList} width='5.6vw' font={'10px'} showError={rebateSetupErrorList[count]['companyid']}/>
            }
            else {
                return <></>
            }
             
        }
        else if (row['blank'] == false && el == 'incentivesetupid'){
            return <PageReadOnlyInput tag='ID' value={setupId} width='40px' font={10}/>
        }
        else if (row['blank'] == false && el == 'producttypedescription'){
            return <PageDropdown tag='Product' value={product} setValue={setProduct} list={productTypeList} width='5.6vw' font={10} showError={rebateSetupErrorList[count]['producttypedescription']}/>
        }
        else if (row['blank'] == false && el == 'feecategorydescription'){
            return <PageDropdown tag='Fee Category' value={feeCategory} setValue={setFeeCategory} list={feeCategoryDescriptionList} width='5.6vw' font={10} showError={rebateSetupErrorList[count]['feecategorydescription']}/>
        }
        else if (row['blank'] == false && el == 'billingratedescription'){
            return <PageDropdown tag='Billing Rate' value={billingRate} setValue={setBillingRate} list={billingRateList} width='5.6vw' font={10} showError={rebateSetupErrorList[count]['billingratedescription']}/>
        }
        else if (row['blank'] == false && el == 'daystopay'){
            return <PageDropdown tag='Days To Pay' value={daysToPay} setValue={setDaysToPay} list={daysToPayList} width='5.6vw' font={10} showError={rebateSetupErrorList[count]['daystopay']}/>
        }
        else if (row['blank'] == false && el == 'minspend'){
            return <PageInput value={tierMin} setValue={setTierMin} tag='Tier Min' width='5.6vw' font={10} showError={rebateSetupErrorList[count]['minspend']}/>
            // return <TableInput tag='Tier Min' objKey={'minspend'} count={count} list={rebateSetupDataList} setList={setRebateSetupDataList} width='5.6vw' font={10} showError={rebateSetupErrorList[count]['minspend']} />
        }
        else if (row['blank'] == false && el == 'maxspend'){
            return <PageInput value={tierMax} setValue={setTierMax} tag='Tier Max' width='5.6vw' font={10} showError={rebateSetupErrorList[count]['maxspend']}/>
            // return <TableInput tag='Tier Max' objKey={'maxspend'} count={count} list={rebateSetupDataList} setList={setRebateSetupDataList} width='5.6vw' font={10} showError={rebateSetupErrorList[count]['maxspend']} />
        }
        else if (row['blank'] == false && el == 'incentiverate'){
            // if is master setup do not allow input for incentiverate
            if (!masterSetupBool){
                return <PageInput value={rebateRate} setValue={setRebateRate} tag={rateTag} width='5.6vw' font={10} showError={rebateSetupErrorList[count]['incentiverate']}/>
                // return <TableInput tag={rateTag} objKey={'incentiverate'} count={count} list={rebateSetupDataList} setList={setRebateSetupDataList} nullifyEmpty={true} width='5.6vw' font={10} showError={rebateSetupErrorList[count]['incentiverate']}/>
            }  
            else {
                return <></>
            } 
        }
        else if (row['blank'] == false && el == 'mastersetupbool'){
            return <FormControlLabel
                value="end"
                control={<Checkbox
                    sx={{
                        color: '#000000',
                        '&.Mui-checked': {
                        color: '#000000',
                        },
                    }} />}
                label=""
                labelPlacement="end"
                sx={{
                    color: 'black',
                    margin: 'auto', 
                    marginTop: '-5px', 
                }}
                checked={masterSetupBool}
                onChange={(e)=>{setMasterSetupBool(masterSetupBool => masterSetupBool = e.target.checked)}}
            />
        }
        else {
            return <>{row[el]}</>
        }
    }

    // useEffect to update data list row values 
    useEffect(() => {
        if (row['blank'] == false){
            setRebateSetupDataList(rebateSetupDataList => rebateSetupDataList = 
                [...rebateSetupDataList.slice(0, count), 
                { 
                    active: status, 
                    masterincentivesetupid: nullIfEmpty(masterSetupId), // must be null if empty in database
                    companyid: subCompany, 
                    incentivesetupid: setupId, 
                    producttypedescription: product, 
                    feecategorydescription: feeCategory,
                    billingratedescription: billingRate, 
                    daystopay: daysToPay,  
                    minspend: tierMin, 
                    maxspend: tierMax, 
                    incentiverate: nullIfEmpty(rebateRate), // must be null if empty in database
                    mastersetupbool: masterSetupBool, 
                    blank: false
                }, 
                ...rebateSetupDataList.slice(count + 1, rebateSetupDataList.length)]);
        }
    }, [status, masterSetupId, subCompany, product, feeCategory, billingRate, daysToPay, tierMin, tierMax, rebateRate, masterSetupBool]);

    // useEffect on to change row values when size of list changes
    useEffect(() => {
        setStatus(status => status = rebateSetupDataList[count]['active']);
        setMasterSetupId(masterSetupId => masterSetupId = rebateSetupDataList[count]['masterincentivesetupid']);
        setSubCompany(subCompany => subCompany = rebateSetupDataList[count]['companyid']);
        setSetupId(setupId => setupId = count+1); // setup id will always be count (this is for adding identity column into database if master tier for another row)
        setProduct(product => product = rebateSetupDataList[count]['producttypedescription']); 
        setFeeCategory(feeCategory => feeCategory = rebateSetupDataList[count]['feecategorydescription']);
        setBillingRate(billingRate => billingRate = rebateSetupDataList[count]['billingratedescription']);
        setDaysToPay(daysToPay => daysToPay = rebateSetupDataList[count]['daystopay']);
        setTierMin(tierMin => tierMin = rebateSetupDataList[count]['minspend']);
        setTierMax(tierMax => tierMax = rebateSetupDataList[count]['maxspend']);
        setRebateRate(rebateRate => rebateRate = rebateSetupDataList[count]['incentiverate']);
        setMasterSetupBool(masterSetupBool => masterSetupBool = rebateSetupDataList[count]['mastersetupbool']); 
    }, [rebateSetupDataList.length]);

    // useEffect to make updates on masterSetupBool toggle
    useEffect(() => {
        if (row['blank'] == false && masterSetupBool) {
            // if master setup id add to master tier ids list  
            setMasterTierIdsList(masterTierIdsList => masterTierIdsList = [
                ...masterTierIdsList.slice(0, count), 
                setupId.toString(), 
                ...masterTierIdsList.slice(count + 1, masterTierIdsList.length), 
            ]); 
            // nullify masterSetupId and rebateRate
            setMasterSetupId(masterSetupId => masterSetupId = null); 
            setRebateRate(rebateRate => rebateRate = null); 
        }
        else if (row['blank'] == false) {
            // if not master setup id remove from master tier list 
            setMasterTierIdsList(masterTierIdsList => masterTierIdsList = [
                ...masterTierIdsList.slice(0, count), 
                ...masterTierIdsList.slice(count + 1, masterTierIdsList.length), 
            ]); 
        }
    }, [masterSetupBool]); 

    // useEffect for rebateInputTypeId change
    useEffect(() => {
        if (rebateInputTypeId == 1){
            setRateTag(rateTag => rateTag = 'Rate (%)'); 
        }
        else {
            setRateTag(rateTag => rateTag = 'Rate '); 
        }
    }, [rebateInputTypeId]); 

    // useEffect(() => {
    //     console.log(rebateSetupDataList);
    // }, [rebateSetupDataList]); 

    return( 
        <>
            {/* map show cell function over every column */}
            {tableHeader.map(el => 
                <td>{showCell(el['columnname'])}</td>
            )}
        </>
    );
}

export default AddNewRebateSetupRow;