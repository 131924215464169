import TextField from '@mui/material/TextField';
import { useEffect, useState, useRef } from 'react'; 
import Skeleton from '@mui/material/Skeleton';

const PageInput = ({ tag, value, setValue, width, font, showError, disableEdit, isLoading }) =>{
    // initialize ref
    const ref = useRef();
    // set width if not given as prop
    if (width == null){
        width = '16.6vw';
    }
    // set font size if given as prop, else use default
    let style = {};
    if (font){
        style['fontSize'] = font;
    } 
    // handle showing error and setting focus
    const [error, setError] = useState(); 
    useEffect(() => {
        if (showError && showError == true) { 
            // set show error
            setError(error => error = true); 
            // focus on error element 
            ref.current.focus();
        }
        else {
            setError(error => error = false); 
        }
    }, [showError]); 
    return (
        <>
            { isLoading ? 
            <>
                <Skeleton variant="rectangular" width='100%' height={'40px'} sx={{ borderRadius: '5px' }}/>
            </>
            : /* material ui textfield for text input */
            <TextField 
                sx={{ width: width, maxWidth: 450 }} 
                id="outlined-basic" 
                size='small' 
                label={tag} 
                variant="outlined" 
                value={value} 
                onChange={(e)=>setValue(value => value = e.target.value)}
                inputProps={{style: style, readOnly: disableEdit}} // font size of input text
                InputLabelProps={{style: style}} // font size of input label
                error={error}
                inputRef={ref} 
            />}
        </> 
    );
} 

export default PageInput;