import { useContext, useState, useEffect, useRef } from 'react';
import { Modal } from "react-bootstrap";
import PageContext from './PageContext';
import PageDropdown from './PageDropdown';
import { HttpContext } from '../../api-services/HttpService';
import ApiRequestButton from './ApiRequestButton';

// import temp data to display
const roleData = ['Rebate Administrator', 'Rebate Data Technician','Regular'];

const EditUserModal = () => {
    // get context from parent
    const {
        editUserModal,
        setEditUserModal,
        editUserRow,
        setEditUserRow,
        getUsers,
        snackbarAlert
    } = useContext(PageContext);
    // initialize http context 
    const {
        PUT, 
    } = useContext(HttpContext);

    // set state
    const [status, setStatus] = useState('');
    const [statusList, setStatusList] = useState(['Active', 'Inactive']);
    const [userId, setUserId] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [emailAddress, setEmailAddress] = useState('');
    const [role, setRole] = useState('');
    const [roleList, setRoleList] = useState([]);
    // use ref to determine initial render
    const initialRender = useRef(true);


    // use api to edit user's cognito information
    const onSubmitClick = () => { 
        return new Promise((resolve, reject) => {
            let response; 
            PUT('/user/inv/edituser', { user: userId, status: status, role: role, oldRole: editUserRow['custom:Role'] }).then(res => {
                console.log(res); 
                response = res; 
                // show snackbar on success
                snackbarAlert(true, `Updated ${userId}`, 'success'); 
                // hide modal on success
                setEditUserModal(editUserModal => editUserModal = false);
                // refresh data on page
                return getUsers(); 
            }).then(res => {
                console.log(res); 
                // resolve on success
                resolve(response); 
            }).catch(err => {
                console.log(err);
                // if 499 error is thrown show message that user limit is reached
                if (err == 'Error: Request failed with status code 499'){
                    snackbarAlert(true, 'Error: Active user limit reached.', 'error');
                }
                // show snackbar on error
                else {
                    snackbarAlert(true, 'Error updating user: ' + userId, 'error'); 
                }
                // reject on error 
                reject(err); 
            });
        }); 
    }

    // useEffect to run when editUserRow is changed in parent
    useEffect(() => {
        // do not assign to state variables on initial render
        if (initialRender.current == true) {
            initialRender.current = false;
        }
        // assign to state variables from editUserRow object
        else {
            if (editUserRow['custom:Active'] == 1) {
                setStatus(status => status = 'Active');
            }
            else {
                setStatus(status => status = 'Inactive');
            }
            setUserId(userId => userId = editUserRow['username']);

            setFirstName(firstName => firstName = editUserRow['name'].split(', ')[1]);
            setLastName(lastName => lastName = editUserRow['name'].split(', ')[0]);
            setEmailAddress(emailAddress => emailAddress = editUserRow['email']);
            //conditional for role descrp change
            setRole(role => role = editUserRow['custom:Role'] == 'DataTechnician' ? 'Rebate Data Technician': editUserRow['custom:Role'] == 'Administrator' ? 'Rebate Administrator' : editUserRow['custom:Role']);
        }
    }, [editUserRow]);

    // useEffect to run on initial render
    useEffect(() => {
        setRoleList(roleList => roleList = roleData);
    }, [])

    const settings = {}
    return (
        <PageContext.Provider value={settings}>
            <>
                <Modal className="rebate-summary-modal"
                    show={editUserModal}
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    scrollable={true}
                >
                    <Modal.Header closeButton onClick={() => setEditUserModal(editUserModal => editUserModal = false)}>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Edit {firstName} {lastName}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body >
                        <div class='modal-div'>
                            <PageDropdown tag='Status: ' value={status} setValue={setStatus} list={statusList} />
                        </div>
                        <br />
                        <div class='modal-div'>
                            <PageDropdown tag='Role: ' value={role} setValue={setRole} list={roleList} />
                        </div>
                        <ApiRequestButton onButtonClick={onSubmitClick} />
                        <br /><br />
                    </Modal.Body>
                </Modal>
            </>
        </PageContext.Provider>
    );
}

export default EditUserModal;