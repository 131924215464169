import { useContext } from 'react';
import PageContext from './PageContext';
import greenCircleIcon from '../../svgs/green-circle-icon.svg';
import redCircleIcon from '../../svgs/red-circle-icon.svg';
import moneyFormat from '../functions/moneyFormat';
import RowEditIcon from './RowEditIcon';
import magnifyingGlassIcon from '../../svgs/magnifying-glass-icon.svg'; 
import decimalToPercent from '../functions/decimalToPercent'; 
import PageTooltip from './PageTooltip';

// pass row object and edit flag as props 
const RebateSetupRow = ({ row, count, tableHeader, enableEdit }) =>{
    // get context variables from parent 
    const {
        editRebateSetupModal, 
        setEditRebateSetupModal, 
        editRebateSetupRow, 
        setEditRebateSetupRow,
        showAdditionalDetailsModal, 
        setShowAdditionalDetailsModal, 
        additionalDetailsRow, 
        setAdditionalDetailsRow,
        rebateInputTypeId,
    } = useContext(PageContext);

    // event handler for edit click 
    const onEditClick = () =>{
        // make EditRebateSetupModal visible using state from parent 
        setEditRebateSetupModal(editRebateSetupModal => editRebateSetupModal = true);
        // pass current row to EditRebateSetupModal using context variable
        setEditRebateSetupRow(editRebateSetupRow => editRebateSetupRow = row);
    }

    // event handler for view click 
    const onViewClick = () => {
        // make AdditionalDetailsModal visible using state from parent
        setShowAdditionalDetailsModal(showAdditionalDetailsModal => showAdditionalDetailsModal = true); 
        // pass current row to AdditionalDetailsModal using context variable
        setAdditionalDetailsRow(additionalDetailsRow => additionalDetailsRow = row); 
    }

    // show red icon for disabled (0) status or green icon for Active (1) status 
    const statusIcon = (status) =>{
        if ( status == true){
            return <img src={greenCircleIcon} class='data-table-icon-left'/>
        }
        else {
            return <img src={redCircleIcon} class='data-table-icon-left'/>
        }
    }

    return(
        <> 
            {/* map logic over all columns in row object */}
            {tableHeader.map(el =>
                // apply statusIcon function to status column 
                el['columnname'] == 'active' ?
                    <td>{statusIcon(row[el['columnname']])}</td>
                // apply moneyFormat to TierMin and TierMax columns 
                : el['columnname'] == 'minspend' || el['columnname'] == 'maxspend' ?
                    <td>{moneyFormat(row[el['columnname']])}</td>
                // show edit icon for last row 
                : el['columnname'] == 'blank' ?
                    <td>
                        {/* show edit icon to trigger EditSubCompanyRow modal */}                      
                        <RowEditIcon enable={enableEdit} onClick={onEditClick}/>
                    </td> 
                // show magnifying glass icon 
                : el['columnname'] == 'additionaldetails' ? 
                    <td style={{ textAlign: 'center'}}>
                        {/* magnifying glass icon to trigger additional details modal */}
                        <PageTooltip title='view'>
                            <img src={magnifyingGlassIcon} style={{margin: 'auto'}} className='data-table-icon-center' onClick={(e)=>onViewClick(e)}/>
                        </PageTooltip>
                    </td>
                // convert to percent if rebateInputTypeId is 1
                : rebateInputTypeId == 1 && el['columnname'] == 'incentiverate' && row[el['columnname']] != null ? 
                    <td>{decimalToPercent(row[el['columnname']])}</td>
                // standard display for other columns 
                : row[el['columnname']] == null ?
                    <td>None</td>
                : <td>{row[el['columnname']]}</td>
            )}
        </>
    );
}

export default RebateSetupRow;