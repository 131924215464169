import { Link } from 'react-router-dom';
import HeaderBanner from '../sub-components/HeaderBanner';
import Navslider from '../sub-components/Navslider';
import viewUsersIcon from '../../svgs/view-users-icon.svg';
import addNewUserIcon from '../../svgs/add-new-user-icon.svg';
import plusProfileIcon from '../../svgs/plus-profile-icon.svg';

const UserAdminLanding = () =>{
    return(
        <div class='page'>
            {/* banner and navslider present on every page */}
            <HeaderBanner indicator={1}/>
            <Navslider indicator={4} backPath='/MainLanding'/>
            {/* navigation buttons */}
            <div class='landing-two-div'>
                <Link 
                    to={{
                        pathname: "/UserInventoryPage",
                    }}
                    ><button class='landing-button'>
                        <img src={viewUsersIcon} class="tile-icon"/>
                        <br/><br/>
                        User Inventory
                        <br/><br/>
                        <button class='landing-tile-button'>View</button>
                    </button>
                </Link>
                <Link
                    to={{
                        pathname: "/UserEntitlementPage",
                    }}
                    ><button class='landing-button'>
                        <img src={plusProfileIcon} class="tile-icon"/>
                        <br/><br/>
                        User Entitlements
                        <br/><br/>
                        <button class='landing-tile-button'>View</button>
                    </button>
                </Link>
            </div>
        </div>
    );
}

export default UserAdminLanding;