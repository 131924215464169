import { combineReducers } from 'redux';
import companyReducer from './companyReducer';
import companyInfoReducer from './companyInfoReducer';
import transactionDetailsReducer from './transactionDetailsReducer';
import userSessionReducer from './userSessionReducer';
import backPathReducer from './backPathReducer'; 
import rebateSummarySearchReducer from './rebateSummarySearchReducer'; 
import rebateSummaryPeriodReducer from './rebateSummaryPeriodReducer'; 
import rebateFrequencyReducer from './rebateFrequencyReducer'; 
import workflowStatusReducer from './workflowStatusReducer'; 
import companyProfileSearchReducer from './companyProfileSearchReducer'; 
import subCompanySearchReducer from './subCompanySearchReducer'; 
import adhocPaymentSearchReducer from './adhocPaymentSearchReducer'; 
import consortiumSearchReducer from './consortiumSearchReducer'; 
import contractSearchReducer from './contractSearchReducer'; 

const reducers = combineReducers({
    company: companyReducer,
    companyInfo: companyInfoReducer,
    transactionDetails: transactionDetailsReducer,  
    userSession: userSessionReducer, 
    backPath: backPathReducer,
    rebateSummarySearch: rebateSummarySearchReducer, 
    rebateSummaryPeriod: rebateSummaryPeriodReducer, 
    rebateFrequency: rebateFrequencyReducer, 
    workflowStatus: workflowStatusReducer, 
    companyProfileSearch: companyProfileSearchReducer, 
    subCompanySearch: subCompanySearchReducer, 
    adhocPaymentSearch: adhocPaymentSearchReducer, 
    consortiumSearch: consortiumSearchReducer, 
    contractSearch: contractSearchReducer, 
});
 
export default reducers; 