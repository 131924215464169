import { useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../auth/AuthRoute';
import HeaderBanner from '../sub-components/HeaderBanner';
import graphIcon from '../../svgs/graph-icon.svg';
import cardIcon from '../../svgs/card-icon.svg';
import companyIcon from '../../svgs/company-icon.svg';
import userAdminIcon from '../../svgs/user-admin-icon.svg';

const MainLanding = () =>{  
    // initialize context variables from parent 
    const {
        getTokenSession,  
    } = useContext(AuthContext);

    // FOR DEBUGGING ONLY. DELETE THIS FOR PRODUCTION
    // useEffect(() => {
    //     getTokenSession().then(res => {
    //         console.log(res);
    //     }).catch(err =>{
    //         console.log(err);
    //     })
    // }, []);

    return(
        <div class='page'>
            {/* header that is present on every page */}
            <HeaderBanner indicator={1}/> 
            {/* div for navigation buttons */}
            <div class='landing-four-div'>
                <Link
                    to={{
                        pathname: "/TableauDashboardEmbed",
                    }}
                    ><button class='landing-button'>
                        <img src={graphIcon} class="tile-icon"/>
                        <br/><br/>
                        Portfolio Management
                        <br/><br/> 
                        <button class='landing-tile-button'>View</button>
                    </button>
                </Link>
                <Link
                    to={{
                        pathname: "/RebateWorkflowLanding",
                    }}
                    ><button class='landing-button'>
                        <img src={cardIcon} class="tile-icon"/>
                        <br/><br/>
                        Rebate <br/> Workflow
                        <br/><br/>
                        <button class='landing-tile-button'>View</button>
                    </button>
                </Link>
                <Link
                    to={{
                        pathname: "/CompanyInfoLanding",
                    }}
                    ><button class='landing-button'>
                        <img src={companyIcon} class="tile-icon"/>
                        <br/><br/>
                        Client Profile <br/> Setup
                        <br/><br/>
                        <button class='landing-tile-button'>View</button>
                    </button>
                </Link>
                <Link
                    to={{
                        pathname: "/UserAdminLanding",
                    }}
                    ><button class='landing-button'>
                        <img src={userAdminIcon} class="tile-icon"/>
                        <br/><br/>
                        User Administration
                        <br/><br/>
                        <button class='landing-tile-button'>View</button>
                    </button>
                </Link>
            </div>
        </div>
    );
}

export default MainLanding;