//------------------------------------------------------------------------------
//  THIS FILE IS NOT IN USE AND IS CURRENTLY A PLACEHOLDER
//------------------------------------------------------------------------------

import { useContext, useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import PageContext from './PageContext';
import PageDropdown from './PageDropdown';
import PageInput from './PageInput';
import PageReadOnlyInput from './PageReadOnlyInput';

const EditPayoutTypeModal = ({ defaultFlag }) =>{
    const {
        editPayoutTypeModal, 
        setEditPayoutTypeModal, 
        payoutType, 
        setPayoutType, 
        glrc, 
        setGlrc, 
        payoutTypeList, 
    } = useContext(PageContext);
    // get global state
    const company = useSelector((state) => state.company);
    const companyInfo = useSelector((state) => state.companyInfo);
    // initialize state
    const [modalPayoutType, setModalPayoutType] = useState(payoutType);
    const [modalGlrc, setModalGlrc] = useState(glrc);
    // useRef to determine initial render
    const initialRender = useRef(true);

    const onSubmitClick = () =>{
        setEditPayoutTypeModal(editPayoutTypeModal => editPayoutTypeModal = false);
    }

    useEffect(() =>{
        if (initialRender.current){
            initialRender.current = false;
        }
        else{
            setPayoutType(payoutType => payoutType = 'ACH');
            setGlrc(glrc => glrc = '1001');
        }
    }, []);

    return(
        <> 
            <Modal className='rebate-summary-modal'
                show={editPayoutTypeModal}
                size='md'
                aria-labelledby='contained-modal-title-vcenter'
                centered
                scrollable={true}
                >
                <Modal.Header closeButton onClick={()=>setEditPayoutTypeModal(editPayoutTypeModal => editPayoutTypeModal = false)}>
                    <Modal.Title id='contained-modal-title-vcenter'>
                        Edit Payout Info
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    {/* read only input to specify company for which payout info is being edited */}
                    <div class='modal-div'>
                        <PageReadOnlyInput tag='Company:' value={company} />
                    </div>
                    <br/>
                    {/* if default flag is false show payout cycle */}
                    { defaultFlag == 0 ? 
                    <>
                        <div class='modal-div'>
                            <PageReadOnlyInput tag='Payout Cycle: ' value={companyInfo['RebateCycle'] + ' [' + companyInfo['RebateFrequency'] + ']'}/>
                        </div>
                        <br/>
                    </>
                    :<></>}
                    {/* inputs/dropdowns to change payout info */}
                    <div class='modal-div'>
                        <PageDropdown tag='Payout Type:' value={modalPayoutType} setValue={setModalPayoutType} list={payoutTypeList}/>
                    </div>
                    <br/>
                    <div class='modal-div'>
                        <PageInput tag={'GLRC:'} value={modalGlrc} setValue={setModalGlrc} />
                    </div>
                    {/* button to submit edits */}
                    <button class='modal-button-full-blue' onClick={(e)=>onSubmitClick()}>Submit</button>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default EditPayoutTypeModal;