import trimWhitespace from './trimWhitespace'; 
import Decimal from 'decimal.js'; 
import isNumeric from './isNumeric'; 

const percentToDecimal = ( str ) => {
    // try to convert percent to decimal using decimal.js for precise math
    try {
        // trim whitespace from either side of string
        let val = trimWhitespace(str); 
        // replace percent sign if present 
        val = val.replace('%', ''); 
        // check that string is now numeric 
        if (!isNumeric(val)){
            throw new Error ('Invalid number format.'); 
        }
        // convert string to Decimal for precise math  
        val = new Decimal(val);
        console.log(val.toString()); 
        // if float is return divide by 100 
        console.log(val.div(100.0).toString()); 
        return val.div(100.0).toString(); 
    }
    // return initial string on error
    catch(err) {
        console.log(err); 
        return str; 
    }
}

export default percentToDecimal; 