import TextField from '@mui/material/TextField';
import AutoComplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import { useEffect, useState, useRef } from 'react'; 

const AutoCompleteInput = ({tag, value, setValue, list, width, font, showError}) => {
    // initialize ref
    const ref = useRef();
    // set width if not passed as prop
    if (width == null){
        width = '16.6vw';
    }
    // set font size if given as prop, else use default
    let style = {};
    if (font){ 
        style['fontSize'] = font; 
    }
    // event hander to change state variable when a dropdown value is click
    const onInputChange = (e, values) => {
        setValue(value => value = values);
    }

    // event handler to change state variable when textfield changes
    const onTextFieldChange = (e) =>{
        setValue(value => value = e.target.value);
    }

    // event handler to clear state variable when clear is clicked
    const onClear = () => {
        setValue(value => value = '');
    }
    // handle showing error and setting focus
    const [error, setError] = useState(); 
    useEffect(() => {
        if (showError && showError == true) { 
            // set show error
            setError(error => error = true); 
            // focus on error element 
            ref.current.focus();
        }
        else {
            setError(error => error = false); 
        }
    }, [showError]); 
    return (
        // material ui autocomplete component
        <FormControl fullWidth size='small' sx={{ width: width }} >
            <AutoComplete
                id="combo-box-demo"
                options={ list }
                size='small'
                value={value}      
                renderInput={(params) => 
                    <TextField 
                        {...params} 
                        style={style} 
                        InputProps={{ ...params.InputProps, style: style }} 
                        InputLabelProps={{style: style}} 
                        label={tag} 
                        onChange={(e)=>onTextFieldChange(e)}
                        error={error}
                        inputRef={ref}
                    />
                }
                renderOption={(props, option) => 
                    <li {...props} style={style}>{option}</li>
                }
                onChange={(e, values)=>onInputChange(e, values)}
                onClear={()=>onClear()}
                disableClearable 
            />
        </FormControl>
    );
}

export default AutoCompleteInput;
