import { createStore, applyMiddleware, compose } from "redux";
import reducers from "./reducers/combineReducers"
import thunk from "redux-thunk"
import { persistStore, persistReducer, persistCombineReducers } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';

const persistConfig = {
    key: 'root', 
    storage
}

const persistedReducer = persistReducer(persistConfig, reducers)

export default () =>{
    let store = createStore(persistedReducer, applyMiddleware(thunk))
    let persistor = persistStore(store)
    return { store, persistor }
} 