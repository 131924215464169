import * as React from 'react';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

const BannerAlert = ({ show, type, message, width}) => {
    return (
        show ? 
            <Stack sx={{ width: width, margin: 'auto' }} spacing={2}>
                <Alert severity={type}>{message}</Alert>
            </Stack>
        :<></>
    );
}

export default BannerAlert; 