import './App.css';
import './css/pageStyle.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Route, Routes } from 'react-router-dom';
import { AuthRoute } from './auth/AuthRoute';
import { ReactSocket } from './api-services/ReactSocket';
import { HttpRoute } from './api-services/HttpService'; 
import LoginRedirect from './auth/LoginRedirect';
import LoginCallback from './auth/LoginCallback';
import MainLanding from './components/main-components/MainLanding';
import RebateWorkflowLanding from './components/main-components/RebateWorkflowLanding';
import CompanyInfoLanding from './components/main-components/CompanyInfoLanding';
import RebateSummaryPage from './components/main-components/RebateSummaryPage';
import CompanyPayoutViewPage from './components/main-components/CompanyPayoutViewPage';
import CompanyProfilePage from './components/main-components/CompanyProfilePage';
import CompanyDetailsPage from './components/main-components/CompanyDetailsPage';
import AddNewCompanyProfilePage from './components/main-components/AddNewCompanyProfilePage';
import UserInventoryPage from './components/main-components/UserInventoryPage';
// import UploadClientInfoPage from './components/main-components/UploadClientInfoPage';
import UserAdminLanding from './components/main-components/UserAdminLanding';
import UserEntitlementPage from './components/main-components/UserEntitlementPage';
import TransactionDetailsPage from './components/main-components/TransactionDetailsPage';
import TableauDashboardEmbed from './components/main-components/TableauDashboardEmbed';
import UnderConstructionPage from './components/main-components/UnderConstructionPage';
import ErrorPage from './components/main-components/ErrorPage';
// import MaintenancePage from './components/main-components/MaintenancePage'
import AdminErrorPage from './components/main-components/AdminErrorPage'; 
import AdhocPaymentPage from './components/main-components/AdhocPaymentPage'; 
import SubCompanySearchPage from './components/main-components/SubCompanySearchPage'; 
import ConsortiumSearchPage from './components/main-components/ConsortiumSearchPage'; 
import AdminDataTechErrorPage from './components/main-components/AdminDataTechErrorPage';
import PayoutSchedulePage from './components/main-components/PayoutSchedulePage';
import SearchClientContractPage from './components/main-components/SearchClientContractPage';
import SubscribeToResourcePage from './components/main-components/SubscribeToResourcePage'; 

function App() {
  return (
    <AuthRoute>
      <HttpRoute>
          <Routes>
            <Route path='/' element={<LoginRedirect/>}/> 
            <Route path='/callback' element={<LoginCallback/>}/>
            <Route exact path='/MainLanding' element={<MainLanding/>}/>
            <Route exact path='/RebateWorkflowLanding' element={<RebateWorkflowLanding/>}/>
            <Route exact path='/CompanyInfoLanding' element={<CompanyInfoLanding/>}/>
            <Route exact path='/RebateSummaryPage' element={<RebateSummaryPage/>}/>
            <Route exact path='/CompanyPayoutViewPage' element={<CompanyPayoutViewPage/>}/>
            <Route exact path='/CompanyProfilePage' element={<CompanyProfilePage/>}/>
            <Route exact path='/CompanyDetailsPage' element={<CompanyDetailsPage/>}/>
            <Route exact path='/AddNewCompanyProfilePage' element={<AddNewCompanyProfilePage/>}/>
            <Route exact path='/UserInventoryPage' element={<UserInventoryPage/>}/>
            <Route exact path='/UserAdminLanding' element={<UserAdminLanding/>}/>
            <Route exact path='/UserEntitlementPage' element={<UserEntitlementPage/>}/>
            <Route exact path='/TransactionDetailsPage' element={<TransactionDetailsPage/>}/>
            <Route exact path='/TableauDashboardEmbed' element={<TableauDashboardEmbed/>}/>
            <Route exact path='/UnderConstructionPage' element={<UnderConstructionPage/>}/>
            <Route exact path='/ErrorPage' element={<ErrorPage/>}/>
            <Route exact path='/AdminErrorPage' element={<AdminErrorPage/>}/>
            <Route exact path='/AdhocPaymentPage' element={<AdhocPaymentPage/>}/>
            <Route exact path='/SubCompanySearchPage' element={<SubCompanySearchPage/>}/>
            <Route exact path='/ConsortiumSearchPage' element={<ConsortiumSearchPage/>}/>
            <Route exact path='/AdminDataTechErrorPage' element={<AdminDataTechErrorPage/>}/>
            { process.env.REACT_APP_REBATE_PAYOUT_SCHEDULER == '1' ? 
            <Route exact path='/PayoutSchedulePage' element={<PayoutSchedulePage/>}/>
            :<Route exact path='/PayoutSchedulePage' element={<SubscribeToResourcePage/>}/>}
            { process.env.REACT_APP_UPLOAD_CLIENT_CONTRACTS == '1' ?
            <Route exact path='/SearchClientContractPage' element={<SearchClientContractPage/>}/>
            :<Route exact path='/SearchClientContractPage' element={<SubscribeToResourcePage/>}/>}
          </Routes>
      </HttpRoute>
    </AuthRoute>
  );
}

export default App;
