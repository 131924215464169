import { useContext } from 'react'; 
import PageContext from './PageContext'; 
import moneyFormat from '../functions/moneyFormat';
import RowEditIcon from './RowEditIcon';
import greenCircleIcon from '../../svgs/green-circle-icon.svg';
import redCircleIcon from '../../svgs/red-circle-icon.svg';

// pass row object as prop 
const AdhocDataRow = ({ row, count, tableHeader, editBool }) =>{
    // initialize PageContext from parent
    const {
        showEditAdhocModal, 
        setShowEditAdhocModal, 
        editAdhocRow, 
        setEditAdhocRow, 
    } = useContext(PageContext); 

    // event handler for edit click
    const onEditClick = () => {
        setEditAdhocRow(editAdhocRow => editAdhocRow = row); 
        setShowEditAdhocModal(showEditAdhocModal => showEditAdhocModal = true); 
    }

    // display red circle for disabled (0) status or green circle for active (1) status
    const statusIcon = (status) =>{
        if ( status == true){
            return <img src={greenCircleIcon} class='data-table-icon-left'/>
        }
        else {
            return <img src={redCircleIcon} class='data-table-icon-left'/>
        }
    }

    return(
        <>
            {/* map logic over all columns in object */}
            {tableHeader.map(el => 
                // apply money format to correct columns  
                el['columnname'] == 'amount' ?
                    row[el['columnname']] == null ?
                    <td class='table-col'>None</td>
                    :<td>{moneyFormat(row[el['columnname']])}</td>
                // display for companyid
                : el['columnname'] == 'companyid' ? 
                    row[el['columnname']] == '-999999999' ?
                    <td class='table-col'>MASTER</td>
                    :<td>{row[el['columnname']]}</td> 
                // display for blank column. Show edit if row has not been transmitted yet
                : el['columnname'] == 'blank' ?
                    row['transmitted'] == false && row['paymenttypeid'] == process.env.REACT_APP_PAYMENT_TYPE_ID ? 
                        <td>
                            {/* show edit icon to trigger EditSubCompanyRow modal */}                      
                            <RowEditIcon enable={editBool} onClick={onEditClick}/>
                        </td>
                        :<td></td>
                // display for status column 
                : el['columnname'] == 'active' ?
                    <td>{statusIcon(row[el['columnname']])}</td>
                // standard display for all other columns 
                : row[el['columnname']] == null ?
                    <td class='table-col'>None</td>
                // standard display for all other columns 
                :<td>{row[el['columnname']]}</td>          
            )}
        </>
    );
}

export default AdhocDataRow; 