import { useContext, useEffect } from 'react';
import { AuthContext } from './AuthRoute';

const LoginCallback = () => {
    const {
        getTokenSession, 
    } = useContext(AuthContext);

    useEffect(() => {
        getTokenSession().then(res => {
            // if tokens successfully acquired go to MainLanding
            window.location.href = `${window.location.origin}/MainLanding`;
        }).catch(err => {
            // DO NOTHING -- CHANGE THIS TO REROUTE TO LOGIN ERROR PAGE
        });
    }, []);

    return(
        <></>
    );

}

export default LoginCallback;