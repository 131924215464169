import { useState, useEffect, useRef } from 'react';
import * as ReactBootStrap from 'react-bootstrap';
import Skeleton from '@mui/material/Skeleton';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import sleep from '../functions/sleep'; 

const ApiDataTable = ({ RowComponent, tableHeader, dataList, numOfPages, showSpinner, setShowSpinner, skeletonHeight, onPagination, pageParams }) => {
    // initialize state
    const [tableCss, setTableCss] = useState('table');
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false); 
    // initialize ref
    const count = useRef(0);
    const pageNum = useRef(1); 
    const initialRender = useRef(true); 
    // function to make count equal to 0
    const zeroCount = () =>{
        count.current = 0;
    }
    // increment count by 1
    const incrementCount = () =>{
        count.current = count.current + 1;
    }

    // make request on page change using function from parent
    // to process the page requests in order use GETQ in onPagination function 
    const onPageChange = (event, value) => {
        setPage(page => page = parseInt(value));
        pageNum.current = parseInt(value); 
    }

    // useEffect to make request new page data 
    // wait until group of page changes stops to make request
    useEffect(() => {
        if (initialRender.current){
            initialRender.current = false; 
        }
        else {
            // set loading to show skeleton
            setLoading(loading => loading = true); 
            // wait to see if page continues to change
            sleep(300).then(res => {
                // check that page has not changed to make request
                // (state will remain the same but ref will change if pagination)
                console.log(pageNum.current + ' == ' + page); 
                if (pageNum.current == page){
                    return onPagination(pageNum.current); 
                }
                // return empty object if page changed
                else {
                    return; 
                }
            }).then(res => {
                console.log(res); 
                console.log(res + ' && ' + pageNum.current + ' == ' + page); 
                // only hide skeleton if it is request for current page
                if (res && pageNum.current == page) {
                    // set loading to false to hide skeleton
                    setLoading(loading => loading = false); 
                }
            }).catch(err => {
                console.log(err); 
                // set loading to false to hide skeleton
                setLoading(loading => loading = false); 
            }); 
        }
    }, [page]); 

    // set page to 1 on pageParams change
    useEffect(() => {
        setPage(page => page = 1);
        pageNum.current = 1; 
    }, [pageParams.current]);

    return(
        <>
            {!showSpinner && !loading ?
                <>
                    <div class={tableCss}>
                        <ReactBootStrap.Table className='data-table' striped>
                            {/* display headers */}
                            <thead className='data-table-header'>
                                <tr>
                                    {tableHeader.map( el => 
                                        <th>{el['columntag']}</th>
                                    )} 
                                </tr>
                            </thead>
                            {/* display body of table */}
                            <tbody className='data-table-body'>
                                {/* zero count at the start of each row */}
                                {zeroCount()}
                                {dataList.map( row =>
                                    <tr>
                                        {/* apply row component function to each row object */}
                                        {/* pass table headers to row component so order can be held */}
                                        {RowComponent(row, count.current, tableHeader)}  
                                        {/* increment count for each element in row */}
                                        {incrementCount()}                
                                    </tr>    
                                )}
                            </tbody>
                        </ReactBootStrap.Table>
                    </div>
                </>
            :<>
                {/* show skeleton while showSpinner prop is true */}
                {/* generally used for waiting for api response */}
                <Skeleton variant="rectangular" width='100%' height='7vh' sx={{ borderRadius: '10px', 'margin-bottom': '3px'}}/>
                <Skeleton variant="rectangular" width='100%' height={skeletonHeight} sx={{ borderRadius: '10px'}}/>
                <br/>
            </>}
            { !showSpinner && dataList.length == 0 && tableHeader.length > 0 ? 
            <div style={{textAlign: 'center'}}>
                No Data Available.
            </div>
            :<></>}
            {/* if specified number of rows to page and there is more than one page show pagination component */}
            { numOfPages > 1 ?
                <Stack spacing={2}>
                    <Pagination page={page} count={numOfPages} variant="outlined" shape="rounded" onChange={onPageChange}/>
                </Stack>
            :<></>}
            <br/>
        </>
    );
}

export default ApiDataTable; 