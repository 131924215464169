import { useContext, useState, useEffect, useRef } from "react";
import { Modal } from "react-bootstrap";
import PageContext from "./PageContext";
import PageDropdown from './PageDropdown';
import PageInput from './PageInput';
import PageReadOnlyInput from './PageReadOnlyInput'; 
import { HttpContext } from '../../api-services/HttpService';
import DatePicker from './DatePicker'; 
// import toDate from '../functions/toDate';
import MultiSelectAutocomplete from "./MultiSelectAutocomplete"; 
import ApiRequestButton from './ApiRequestButton';

// add function to Date class to compare if date greater than or equal to another date 
Date.prototype.greater = function(compDate) {
    // specify date1
    let date1 = new Date(this.valueOf()); 
    // set time to 4pm (1 hour before payout file would go out)
    date1.setHours(16,0,0,0); 
    // use getTime() function to compare dates
    if (date1.getTime() > compDate.getTime()){ 
        return true
    }
    return false; 
}

const PayoutScheduleModal = () => {
    // get context variables from parent
    const { 
        showScheduleModal, 
        setShowScheduleModal, 
        calendarDate, 
        scheduleTypeList,
        snackbarAlert, 
        setRefreshCalendar,
        getPayoutData, 
        payoutTypeList, 
        portfolioList, 
        paymentMethodList,
    } = useContext(PageContext);
    // initialize http context 
    const {
        POST, 
    } = useContext(HttpContext);
    // intialize state
    const [add, setAdd] = useState(true); 
    const [scheduleType, setScheduleType] = useState(''); 
    const [scheduleName, setScheduleName] = useState(''); 
    const [noEndDate, setNoEndDate] = useState(['One-Time']); 
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null); 
    const [status, setStatus] = useState('Active'); 
    const [updateType, setUpdateType] = useState('Series'); 
    const [today, setToday] = useState(new Date()); 
    const [payoutTypeFilters, setPayoutTypeFilters] = useState([]); 
    const [portfolioFilters, setPortfolioFilters] = useState([]); 
    const [paymentMethodFilters, setPaymentMethodFilters] = useState([]); 

    // event handler for submit click when it is an add
    const onAddClick = () => {
        return new Promise((resolve, reject) => {
            let response; 
            // validate data 
            if (!scheduleTypeList.includes(scheduleType)){
                snackbarAlert(true, 'Error: Invalid Schedule Type', 'error'); 
                reject();
                return; 
            }
            if (!calendarDate.greater(today)){
                snackbarAlert(true, 'Error: Start date has already passed', 'error'); 
                reject();
                return; 
            }
            if (scheduleName.length == 0){
                snackbarAlert(true, 'Error: Please provide Schedule Name', 'error');
                reject(); 
                return; 
            }
            if (!noEndDate.includes(scheduleType) && endDate == null){
                snackbarAlert(true, 'Error: Please provide End Date', 'error'); 
                reject();
                return; 
            }
            if (payoutTypeFilters.length < 1){
                snackbarAlert(true, 'Error: Please add at least one payout type filter', 'error'); 
                reject();
                return; 
            }
            if (portfolioFilters.length < 1){
                snackbarAlert(true, 'Error: Please add at least one portfolio filter', 'error'); 
                reject();
                return; 
            }
            if (paymentMethodFilters.length < 1){
                snackbarAlert(true, 'Error: Please add at least one payment method filter', 'error'); 
                reject();
                return; 
            }
            // use httpContext function to make POST request
            POST('/data/payoutSchedulePage', {
                scheduletypedescription: scheduleType, 
                schedulename: scheduleName, 
                startdate: calendarDate, 
                enddate: endDate,
                filters: {
                    0: payoutTypeFilters, 
                    1: portfolioFilters, 
                    2: paymentMethodFilters, 
                },
            }).then(res => {
                console.log(res)
                response = res; 
                // default inputs 
                if (scheduleTypeList.length > 0){
                    setScheduleType(scheduleType => scheduleType = scheduleTypeList[0])
                }
                setScheduleName(scheduleName => scheduleName = ''); 
                setEndDate(endDate => endDate = null); 
                // hide modal 
                setShowScheduleModal(showScheduleModal => showScheduleModal = false); 
                // show snackbar on error
                snackbarAlert(true, 'Successfully uploaded data', 'success'); 
                // refresh calendar data by toggling refresh state. This will cause calendar to make get request
                setRefreshCalendar(refreshCalendar => refreshCalendar = !refreshCalendar);  
                // refrest payout file data
                return getPayoutData(calendarDate);             
            }).then(res => {
                // resolve on success
                resolve(response); 
            }).catch(err => {
                console.log(err); 
                // show snackbar on error
                snackbarAlert(true, 'Error uploading data', 'error'); 
                // reject on error
                reject(err); 
            });
        }); 
    }

    // useEffect to run on show modal 
    useEffect(() => {
        if (showScheduleModal == true){
            // set scheduleType to first element of scheduleTypeList
            if (scheduleTypeList.length > 0){
                setScheduleType(scheduleType => scheduleType = scheduleTypeList[0]); 
            }
            // clear scheduleName
            setScheduleName(scheduleName => scheduleName = ''); 
            // add all filters to each list (default)
            setPayoutTypeFilters(payoutTypeFilters => payoutTypeFilters = payoutTypeList); 
            setPortfolioFilters(portfolioFilters => portfolioFilters = portfolioList); 
            setPaymentMethodFilters(paymentMethodFilters => paymentMethodFilters = paymentMethodList); 
        }     
    }, [showScheduleModal]); 

    // useEffect to run on scheduleTypeList change 
    // for if modal is opened before scheduleTypeList is populated from api request
    useEffect(() => {
        // set scheduleType to first element of scheduleTypeList
        if (scheduleTypeList.length > 0){
            setScheduleType(scheduleType => scheduleType = scheduleTypeList[0]); 
        }
    }, [scheduleTypeList]); 

    // useEffect to run on updateType change
    useEffect(() => {
        setStatus(status => status = 'Active');  
    }, [updateType]); 

    return( 
        <> 
            <Modal className="rebate-summary-modal"
                show={showScheduleModal}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                scrollable={true}
                >
                <Modal.Header closeButton onClick={()=>setShowScheduleModal(showScheduleModal => showScheduleModal = false)}>
                    <Modal.Title id="contained-modal-title-vcenter">
                        { add ? 'Add': 'Edit' } Payout Schedule 
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <>
                        {/* Add new schedule modal inputs */}
                        <div class='modal-div'>
                            <PageDropdown tag='Schedule Type:' value={scheduleType} setValue={setScheduleType} list={scheduleTypeList} width='20vw' />
                        </div>
                        <br/>
                        <div class='modal-div'>
                            <PageInput tag={'Schedule Name:'} value={scheduleName} setValue={setScheduleName} width='20vw' />
                        </div>  
                        <br/>
                        <div class='modal-div'>
                            <PageReadOnlyInput tag='Start Date:' value={calendarDate.getMonth()+1 + '/' + calendarDate.getDate() + '/' + calendarDate.getFullYear()} width='20vw' />
                        </div> 
                        <br/>
                        {/* Don't show end date if scheduleType is in noEndDate list */}
                        {!noEndDate.includes(scheduleType) ? <>
                            <div class='modal-div'>
                                <DatePicker tag='End Date:' value={endDate} setValue={setEndDate} disable={calendarDate} indicator={4} width='20vw'/>
                            </div>
                            <br />
                        </>
                        :<></>}
                        <div class='modal-div'>
                            <MultiSelectAutocomplete tag='Payout Types Included' values={payoutTypeFilters} setValues={setPayoutTypeFilters} list={payoutTypeList} width='20vw' />
                        </div>  
                        <br/>
                        <div class='modal-div'>
                            <MultiSelectAutocomplete tag='Portfolios Included' values={portfolioFilters} setValues={setPortfolioFilters} list={portfolioList} width='20vw' />
                        </div>
                        <br />
                        <div class='modal-div'>
                            <MultiSelectAutocomplete tag='Payment Methods Included' values={paymentMethodFilters} setValues={setPaymentMethodFilters} list={paymentMethodList} width='20vw'/>
                        </div>  
                        <br />
                        <ApiRequestButton onButtonClick={onAddClick} />
                        <br/><br />
                    </>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default PayoutScheduleModal; 