import { useState, useEffect, useRef, useContext } from 'react';
import HeaderBanner from '../sub-components/HeaderBanner';
import Navslider from '../sub-components/Navslider';
// import PageInput from '../sub-components/PageInput';
import OrderedDataTable from '../sub-components/OrderedDataTable';
import PageContext from '../sub-components/PageContext';
import standardSearch from '../functions/standardSearch';
import applyFilters from '../functions/applyFilters';
import { HttpContext } from '../../api-services/HttpService';
import { Link } from 'react-router-dom';
import SubCompanySearchRow from '../sub-components/SubCompanySearchRow'; 
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from 'redux';
import { actionCreators } from "../../redux/index";
import PageInputRedux from '../sub-components/PageInputRedux';

const SubCompanySearchPage = () => {
    // initialize http context 
    const {
        GET, 
    } = useContext(HttpContext);
    // initialize redux persisent state for search 
    const subCompanySearch = useSelector((state) => state.subCompanySearch);
    const dispatch = useDispatch();
    const { 
        setSubCompanySearch, 
    } = bindActionCreators(actionCreators, dispatch);
    // initialize state variables
    // const [searchValue, setSearchValue] = useState(''); 
    const [showSpinner, setShowSpinner] = useState(false); 
    // initialize state variables for table
    const [dataList, setDataList] = useState([]); 
    const [showDataList, setShowDataList] = useState([]); 
    const [tableHeader, setTableHeader] = useState([]); 
    // initialize useRef to determine initial render
    const initialRender = useRef(false); 

    // function wrapper to pass row component to datatable
    const RowComponent = (row, count, tableHeader) =>{
        return(
            <>
                <SubCompanySearchRow row={row} count={count} tableHeader={tableHeader}/>
            </>
        ); 
    }  

    // promise to fetch sub company data 
    const getSubCompanyData = () => {
        // show spinner while fetching data
        setShowSpinner(showSpinner => showSpinner = true); 
        // use GET function to fetch data 
        return new Promise((resolve, reject) => {
            GET('/data/subCompanySearchPage', {}).then(res => {
                // set state variables 
                setTableHeader(tableHeader => tableHeader = res['data']['SubCompanySearchTableHeader']); 
                setDataList(dataList => dataList = res['data']['SubCompanySearchDataList']); 
                setShowDataList(showDataList => showDataList = res['data']['SubCompanySearchDataList']); 
                // hide spinner 
                setShowSpinner(showSpinner => showSpinner = false); 
                // resolve on success
                resolve(res); 
            }).catch(err => {
                // hide spinner
                setShowSpinner(showSpinner => showSpinner = false); 
                // reject on error
                reject(err); 
            }); 
        }); 
    }

    // useEffect to run on initial render 
    useEffect(() => {
        getSubCompanyData().then(res => {
            console.log(res); 
        }).catch(err => {
            console.log(err); 
        }); 
    }, []); 

    // useEffect to run list of search/filter functions on table data
    useEffect(() => { 
        // do not run on initial render
        if (!initialRender.current){
            // declare list of functions with parameters to filter/search list
            const functionList = [
                {function: standardSearch, value: subCompanySearch, flag: 0, cols: ['companyname', 'companyid', 'mastercompanyid', 'primaryccrm']}
            ];
            // use applyFilters function to search by running all functions declared above
            let filteredList = applyFilters(dataList, functionList);
            // update showDataList
            setShowDataList(showDataList => showDataList = filteredList);
        }
        else{
            initialRender.current = false;
        }
        
    }, [dataList, subCompanySearch]);


    // state variables to pass to page context 
    const settings ={

    }
    
    return(
        <div class='summary-page'>
            {/* wrap children so they can access variables in PageContext */}
            <PageContext.Provider value={ settings }>
                {/* header and navslider present on every page */}
                <HeaderBanner indicator={1}/>
                <Navslider indicator={-1} backPath='/CompanyInfoLanding'/>
                {/* label for search column dropdown and search bar */}
                <div class='top-label-div'>
                    <label class='top-label-text'><b>Sub Company Search</b></label>
                    <br/>
                    <div class='label-dropdown-div'>
                        <span class='label-dropdown-left'>
                            {/* link button to navigate to master company search page */}
                            <Link
                                to={{
                                    pathname: "/CompanyProfilePage",
                                }}
                                ><button class='summary-page-button'>Master Company Search</button>
                            </Link>
                            {/* link button to navigate to consortium search page */}
                            <Link
                                to={{
                                    pathname: "/ConsortiumSearchPage",
                                }}
                                ><button class='summary-page-button'>Consortium Search</button>
                            </Link>
                        </span> 
                        <span class='label-dropdown-right'>
                            {/* search input to change value for search functionality*/} 
                            <PageInputRedux tag='Search: ' value={subCompanySearch} setValue={setSubCompanySearch}/>
                        </span>
                        <br/>
                    </div>
                    <br/>
                </div> 
                <br/>
                {/* table to display company data with links for drilldown */}
                <div class='data-table-div'>
                    <OrderedDataTable RowComponent={RowComponent} tableHeader={tableHeader} dataList={showDataList} showSpinner={showSpinner} numToPage={50} skeletonHeight='28vh'/>
                    <br/><br/>
                </div>                                      
            </PageContext.Provider>
        </div>
    ); 
}

export default SubCompanySearchPage; 