import { useContext, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import PageContext from './PageContext';
import PageReadOnlyInput from '../sub-components/PageReadOnlyInput';
import OrderedDataTable from './OrderedDataTable';
import WorkflowAgreggateRow from './WorkflowAggregateRow';
import WorkflowOptOut from './WorkflowOptOut';
import WorkflowCommentLog from './WorkflowCommentLog';
import PageDropdown from './PageDropdown';
import { HttpContext } from '../../api-services/HttpService';
import ApiRequestButton from './ApiRequestButton';

const WorkflowModal = () => { 
    // initialize PageContext 
    const {
        workflowModal, 
        setWorkflowModal, 
        workflowAction, 
        workflowNewStatus, 
        setWorkflowNewStatus, 
        workflowAggregateTableHeader, 
        workflowAggregateDataList, 
        workflowButtonCss, 
        workflowOptOut, 
        workflowReviewAction, 
        status, 
        getCompanyPayoutViewPageData,
        snackbarAlert, 
    } = useContext(PageContext);
    // initialize http context 
    const {
        PUT, 
    } = useContext(HttpContext);
    // initialize state
    const [submissionComment, setSubmissionComment ] = useState('');
    const [reviewType, setReviewType] = useState('--select--');
    const [reviewTypeList, setReviewTypeList] = useState(['--select--', 'Verified', 'Expected Match']);
    // initialize state for opt out
    const [optOut, setOptOut] = useState(false);
    // global redux state for company (passed in from other route)
    const company = useSelector((state) => state.company);
    const companyInfo = useSelector((state) => state.companyInfo);

    // function wrapper to pass row component to datatable
    const RowComponent = (row, count, tableHeader) =>{
        return(
            <>
                <WorkflowAgreggateRow row={row} count={count} tableHeader={tableHeader}/>
            </>
        );
    }  

    // handler for when action button is clicked
    const onAction = () => {
        return new Promise((resolve, reject) => {
            let response; 
            // validate comment
            if (submissionComment.length == 0){
                snackbarAlert(true, 'Error: Please input comment.', 'error');
                reject(); 
                return;
            }
            // if submitting to initial review, ensure there is a CCRM assigned to master company
            if (workflowNewStatus == 'Initial Review' && !companyInfo['primaryccrm']){
                snackbarAlert(true, 'Error: Cannot submit to Initial Review when no CCRM assigned.', 'error'); 
                reject(); 
                return; 
            }   
            // specify arguments based on current status
            let args = {};
            if (status == 'Pending' || status == 'Rejected'){
                args = {
                    workflowactionid: process.env.REACT_APP_SUBMIT_ACTION_ID,  
                    workflowid: companyInfo['workflowid'], 
                    mastercompanyid: companyInfo['mastercompanyid'],
                    status: workflowNewStatus, 
                    comment: submissionComment, 
                    oldstatus: status, 
                }
            }      
            else if (status == 'Initial Review'){
                args = {
                    workflowactionid: process.env.REACT_APP_INITIAL_REVIEW_ACTION_ID,  
                    workflowid: companyInfo['workflowid'], 
                    mastercompanyid: companyInfo['mastercompanyid'],
                    status: workflowNewStatus, 
                    comment: submissionComment, 
                    oldstatus: status, 
                }
            }  
            else if (status == 'Final Review'){
                args = {
                    workflowactionid: process.env.REACT_APP_FINAL_REVIEW_ACTION_ID,  
                    workflowid: companyInfo['workflowid'], 
                    mastercompanyid: companyInfo['mastercompanyid'],
                    status: workflowNewStatus, 
                    comment: submissionComment, 
                    oldstatus: status, 
                }
            }
            else if (status == 'Accepted'){
                args = {
                    workflowactionid: process.env.REACT_APP_REOPEN_ACTION_ID,  
                    workflowid: companyInfo['workflowid'], 
                    mastercompanyid: companyInfo['mastercompanyid'],
                    status: workflowNewStatus, 
                    comment: submissionComment, 
                    oldstatus: status, 
                }
            }
            // make put request for submitting a pending workflow
            PUT('/data/companyPayoutViewPage', args).then(res => {
                console.log(res);
                response = res; 
                // hide workflow modal
                setWorkflowModal(workflowModal => workflowModal = false);
                // reset comment
                setSubmissionComment(submissionComment => submissionComment = '');
                // reset opt out
                setOptOut(optOut => optOut = false);
                // show snackbar on success
                snackbarAlert(true, 'Successfully updated workflow.', 'success');
                // refresh data on screen
                return getCompanyPayoutViewPageData();
            }).then(res => {
                console.log(res); 
                // resolve on success
                resolve(res); 
            }).catch(err => {
                console.log(err);
                // show snackar on error
                snackbarAlert(true, 'Error updating workflow.', 'error');
                // reject on error
                reject(err); 
            });
        }); 
    }

    // // clear submissionComment when modal is toggled
    // useEffect(() => {
    //     setSubmissionComment(submissionComment => submissionComment = '');
    // }, [workflowModal]);

    // useEffect for opt out
    useEffect(() => {
        if (workflowAction == 'Submit'){
            if (optOut){
                // if opt out is true new status is final review to skip initial review
                setWorkflowNewStatus(workflowNewStatus => workflowNewStatus = 'Final Review');
            }
            else{
                // if op out is false new status is Initial Review to have initial review then final review
                setWorkflowNewStatus(workflowNewStatus => workflowNewStatus = 'Initial Review');
            }
        }
        else {
            setOptOut(optOut => optOut = false);
        }
    }, [optOut]);

    // useEffect to mark as opt out if no ccrm
    useEffect(() => {
        if (workflowAction == 'Submit' && !companyInfo['primaryccrm']){
            setOptOut(optOut => optOut = true);
        }
        else {
            setOptOut(optOut => optOut = false);
        }
    }, [workflowModal]); 

    return(
        <>  
            <Modal className='rebate-summary-modal'
                show={workflowModal}
                size='lg'
                aria-labelledby='contained-modal-title-vcenter'
                centered
                scrollable={true}
                >
                <Modal.Header closeButton onClick={()=>setWorkflowModal(workflowModal => workflowModal = false)}>
                    <Modal.Title id='contained-modal-title-vcenter'>
                        {company} Rebate Form
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >   
                    <div class='modal-div'>
                        <PageReadOnlyInput tag='Company: ' value={company} width={'23vw'}/>
                    </div>
                    <br/>
                    <div class='modal-div'>
                        <PageReadOnlyInput tag='Payout Cycle: ' value={companyInfo['cyclestart'] + ' to ' + companyInfo['cycleend'] + ' [' + companyInfo['incentivefreq'] + ']'} width={'23vw'}/>
                    </div>
                    <br/>
                    <OrderedDataTable RowComponent={RowComponent} tableHeader={workflowAggregateTableHeader} dataList={workflowAggregateDataList}/>
                    <br/>
                    {/* show comment log */}
                    <div class='modal-div'>
                        <WorkflowCommentLog workflowid={companyInfo['workflowid']}/>
                    </div>
                    <br/>
                    {/* show dropdown when flag is true */}
                    {workflowReviewAction.current ?
                    <>
                        <div class='modal-div'>
                            <PageDropdown tag='Review Type: ' value={reviewType} setValue={setReviewType} list={reviewTypeList}/>
                        </div>
                        <br/>
                    </>
                    :<></>}
                    {/* input for comment on action */}
                    <div class='modal-div'>
                        <label><b>Comment: </b></label>
                        <br/>
                        <textarea class='modal-text-area' value={submissionComment} onChange={(e)=>setSubmissionComment(submissionComment => submissionComment = e.target.value)}></textarea>
                    </div>
                    <br/>
                    {/* show opt out option when flag is true */}
                    {workflowOptOut.current ?
                        <WorkflowOptOut value={optOut} setValue={setOptOut} />             
                    :<></>}
                    {/* show review submissionComments when flag is true */}
                    {/* <button class={workflowButtonCss.current} onClick={()=>onAction()}>{workflowAction}</button> */}
                    <ApiRequestButton onButtonClick={onAction} tag={workflowAction}/>
                    <br/><br/>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default WorkflowModal; 