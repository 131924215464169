import { useContext, useRef, useEffect, useState } from 'react'; 
import { Modal } from "react-bootstrap";
import PageContext from './PageContext';
import AddNewAccountIdsRow from './AddNewAccountIdsRow'; 
import OrderedDataTable from './OrderedDataTable'; 

const AddNewAccountIdsModal = () => {
    // get PageContext from parent 
    const {
        showAddAccountIdsModal, 
        setShowAddAccountIdsModal, 
        accountIdsRow, 
        setAccountIdsRow, 
        accountIdsDataList, 
        setAccountIdsDataList, 
        accountIdsTableHeader, 
        setAccountIdsTableHeader, 
        showProgress, 
    } = useContext(PageContext); 
    // initailize state for index
    const [index, setIndex] = useState(0); 
    // ref to determine initial render
    const initialRender = useRef(true); 
    // function wrapper to pass AddNewAccountIdsRow to ordered data table
    const RowComponent = (row, count, tableHeader) => {
        return ( 
            <>
                <AddNewAccountIdsRow row={row} count={count} tableHeader={tableHeader} index={index}/>
            </>
        ); 
    } 

    // useEffect to filter table based on account ids row 
    useEffect(() => {
        if (!initialRender.current){
            // set index for OrderedDataTable
            setIndex(index => index = accountIdsRow['index']); 
        }
        else {
            initialRender.current = false; 
        }
    }, [accountIdsRow, showAddAccountIdsModal]); 

    return(
        <> 
            <Modal className="rebate-summary-modal"
                show={showAddAccountIdsModal}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                scrollable={true}
                >
                <Modal.Header closeButton onClick={()=>setShowAddAccountIdsModal(showAddAccountIdsModal => showAddAccountIdsModal = false)}>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Add New Account Ids for 
                        {/* Only show company id if there is accountIdsRow */}
                        { accountIdsRow ? 
                            <>
                                {' ' + accountIdsRow['companyid']}
                            </>
                            : <></>}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    { index < accountIdsDataList.length ?
                        <OrderedDataTable RowComponent={RowComponent} tableHeader={accountIdsTableHeader} dataList={accountIdsDataList[index]} showSpinner={showProgress} skeletonHeight='7vh'/>
                        :<></> }
                    <button class='modal-button-full-blue' onClick={()=>setShowAddAccountIdsModal(showAddAccountIdsModal => showAddAccountIdsModal = false)}>Done</button>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default AddNewAccountIdsModal; 