import moneyFormat from '../functions/moneyFormat';

const WorkflowAggregateRow = ({ row, count, tableHeader }) => {
    return(
        <>
            {tableHeader.map(el =>
                // logic to apply money format to correct rows
                el['columnname'] == 'cyclenetspend' || el['columnname'] == 'cyclenetinterchange' || el['columnname'] == 'cycleincentiveamount' ?
                    <> 
                        {row[el['columnname']] == null ?
                            <td>None</td>
                        :<td>{moneyFormat(row[el['columnname']])}</td>}
                    </>
                : el['columnname'] == 'blank' ?
                <td></td>
                // standard display for other cols
                : <> {row[el['columnname']] == null ?
                        <td>None</td>
                    :<td>{row[el['columnname']]}</td>}
                </>
            )}
        </>
    );
}

export default WorkflowAggregateRow;