import HeaderBanner from '../sub-components/HeaderBanner';

// page to display error if a user is not entitled to module or page
const AdminErrorPage = () => {
    return (
        <div class='summary-page'>
            <HeaderBanner indicator={1}/>
            <br/>
            <h1 style={{textAlign: 'center'}}>User Access Error</h1>
            <h3 style={{textAlign: 'center'}}>Only Administrators can access this page.</h3>
            <br/>
        </div>
    );
}

export default AdminErrorPage; 