import { useContext, useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Modal } from "react-bootstrap";
import PageContext from "./PageContext";
import DatePicker from './DatePicker'
import PageInput from './PageInput';
import PageDropdown from './PageDropdown';
import { HttpContext } from '../../api-services/HttpService';
import removeStrCommas from '../functions/removeStrCommas';
import performanceInputValidationCheck from '../functions/performanceInputValidationCheck';
import toDate from '../functions/toDate'; 
import ApiRequestButton from './ApiRequestButton';

const EditPerformanceBonusModal = () => {
    // get context from parent
    const {
        editPerformanceBonusModal, 
        setEditPerformanceBonusModal, 
        editPerformanceBonusRow, 
        setEditPerformanceBonusRow,
        masterCompanyData,
        getCompanyDetailsData,  
        snackbarAlert, 
    } = useContext(PageContext);
    // initialize http context 
    const {
        POST, 
        PUT, 
    } = useContext(HttpContext);
    // get persistant redux global state from store
    const company = useSelector((state) => state.company);
    // initialize state
    const [status, setStatus] = useState();
    const [statusList, setStatusList] = useState(['Active', 'Inactive']);
    const [spendByDate, setSpendByDate] = useState();
    const [spendCommitment, setSpendCommitment] = useState();
    const [bonusAmount, setBonusAmount] = useState();
    const [statusChange, setStatusChange] = useState(false);

    // initialize reference for initial render flag
    const initialRender = useRef(true);

    // event handler for submit button click
    const onSubmitClick = () => {
        return new Promise((resolve, reject) => {
            let response; 
            const input = {
                incentivesetupid: editPerformanceBonusRow['incentivesetupid'], 
                spendbydate: spendByDate, 
                spendcommitment: spendCommitment, 
                bonusamount: bonusAmount, 
                mastercompanyid: masterCompanyData['mastercompanyid'],  
                incentiveid: 1, 
            }
            // make sure that inputs are valid
            if (!performanceInputValidationCheck(input, snackbarAlert)){
                reject(); 
                return;
            }
            // set active arg from status
            let active = false; 
            if (status == 'Active') {
                active = true;
            }
            // if not status change make post request to add new row and make others with same incentivesetupid inactive
            if (!statusChange){
                // make sure that inputs are valid
                if ( bonusAmount == '' || spendCommitment == ''){
                    snackbarAlert(true, 'Error: Invalid input.', 'error');
                    reject(); 
                    return;
                }
                // make post request 
                PUT('/data/performanceBonus', {
                    incentivesetupid: editPerformanceBonusRow['incentivesetupid'], 
                    active: active, 
                    spendbydate: spendByDate, 
                    spendcommitment: removeStrCommas(spendCommitment), 
                    bonusamount: removeStrCommas(bonusAmount), 
                    mastercompanyid: masterCompanyData['mastercompanyid'],  
                    incentiveid: 1, 
                    versionnumber: editPerformanceBonusRow['versionnumber'], 
                }).then(res => {
                    console.log(res);
                    response = res; 
                    // hide modal on success
                    setEditPerformanceBonusModal(editPerformanceBonusModal => editPerformanceBonusModal = false);
                    // trigger success alert
                    snackbarAlert(true, 'Successfully uploaded data.', 'success');
                    // refresh CompanyDetailsPage using function from parent
                    return getCompanyDetailsData(company);
                }).then(res => {
                    console.log(res); 
                    // resolve on success
                    resolve(response); 
                }).catch(err => {
                    console.log(err);
                    // show alert on error
                    snackbarAlert(true, 'Error uploading data.', 'error');
                    // reject on error
                    reject(err); 
                });
            }
            // else change the status using PUT request
            else{
                PUT('/data/incentiveSetups', {
                    incentivesetupid: editPerformanceBonusRow['incentivesetupid'], 
                    mastercompanyid: masterCompanyData['mastercompanyid'], 
                    versionnumber: editPerformanceBonusRow['versionnumber'], 
                    active: active, 
                }).then(res => {
                    console.log(res);
                    // hide modal on success
                    setEditPerformanceBonusModal(editPerformanceBonusModal => editPerformanceBonusModal = false);
                    // trigger success alert
                    snackbarAlert(true, 'Successfully uploaded data.', 'success');
                    // refresh CompanyDetailsPage using function from parent
                    return getCompanyDetailsData(company);
                }).then(res => {
                    console.log(res); 
                    // resolve on success
                    resolve(response); 
                }).catch(err => {
                    console.log(err);
                    // show alert on error
                    snackbarAlert(true, 'Error uploading data.', 'error');
                    // reject on error
                    reject(err); 
                });
            }
        });         
    }

    // useEffect to run on row change
    useEffect(() => {
        if (initialRender.current){
            initialRender.current = false; 
        }
        else {
            // set state values on row change
            if (editPerformanceBonusRow['active'] == true){
                setStatus(status => status = 'Active');
            } 
            else{
                setStatus(status => status = 'Inactive');
            }
            setSpendByDate(spendByDate => spendByDate = toDate(editPerformanceBonusRow['spendbydate']));
            setSpendCommitment(spendCommitment => spendCommitment = editPerformanceBonusRow['spendcommitment']);
            setBonusAmount(bonusAmount => bonusAmount = editPerformanceBonusRow['bonusamount']);
        }
    }, [editPerformanceBonusRow, editPerformanceBonusModal]);

    // if status tag is changed check for status change
    useEffect(() => {
        // set active var to compare to row active col
        let active = false;
        if (status == 'Active'){
            active = true;
        }
        // compare col to active
        if (active == editPerformanceBonusRow['active']){
            // if they are the same no status change
            setStatusChange(statusChange => statusChange = false);
        }
        else {
            // if they are different flag status change
            setStatusChange(statusChange => statusChange = true);
        }
    }, [status, editPerformanceBonusRow]);

    return(
        <> 
            <Modal className="rebate-summary-modal"
                show={editPerformanceBonusModal}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                scrollable={true}
                >
                <Modal.Header closeButton onClick={()=>setEditPerformanceBonusModal(editPerformanceBonusModal => editPerformanceBonusModal = false)}>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Edit Setup ID: {editPerformanceBonusRow['incentivesetupid']}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <div class='modal-div'>
                        <PageDropdown tag='Status: ' value={status} setValue={setStatus} list={statusList}/>
                    </div>
                    <br/>
                    {/* only show inputs if not status change */}
                    { !statusChange ? 
                    <>
                        <div class='modal-div'>
                            <DatePicker tag='SpendByDate: ' value={spendByDate} setValue={setSpendByDate} disable={null} indicator={0}/>
                        </div>
                        <br/>
                        <div class='modal-div'>
                            <PageInput tag='Spend Commitment: ' value={spendCommitment} setValue={setSpendCommitment} />
                        </div>
                        <br/>
                        <div class='modal-div'>
                            <PageInput tag='Bonus Amount: ' value={bonusAmount} setValue={setBonusAmount} />
                        </div>
                    </>
                    :<></>}
                    {/* button to submit edits to rebate setup */}
                    <ApiRequestButton onButtonClick={onSubmitClick} />
                </Modal.Body>
            </Modal>
        </>
    );
}

export default EditPerformanceBonusModal; 