import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useEffect, useState, useRef } from 'react'; 
import Skeleton from '@mui/material/Skeleton';

const PageDropdown = ({ tag, list, value, setValue, width, font, showError, disableEdit, isLoading }) =>{
    // initialize ref
    const ref = useRef();
    // set width if not given as prop
    if (width == null){
        width = '16.6vw';
    }
    // set font size if given as prop, else use default
    let style = {};
    if (font != null){
        style['fontSize'] = font;
    }
    // event handler to set value on dropdown change
    const onDropdownChange = (e) =>{
        setValue(value => value = e.target.value);
    }
    // handle showing error and setting focus
    const [error, setError] = useState(); 
    useEffect(() => {
        if (showError && showError == true) { 
            // set show error
            setError(error => error = true); 
            // focus on error element 
            ref.current.focus();
        }
        else {
            setError(error => error = false); 
        }
    }, [showError]); 
    return(
        <>
            { isLoading ? 
            <>
                <Skeleton variant="rectangular" width='100%' height={'40px'} sx={{ borderRadius: '5px' }}/>
            </>
            : /* material ui dropdown component */
            <FormControl fullWidth size='small' sx={{ width: width, maxWidth: 450 }}>
                <InputLabel style={style} id="demo-simple-select-label">{tag}</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={value}
                    label={tag}
                    style={style}                 
                    onChange={(e)=>onDropdownChange(e)}
                    error={error}
                    inputRef={ref}
                    readOnly={disableEdit} 
                >
                    {list.map( el =>    
                        <MenuItem style={style} id={el} value={el}>{el}</MenuItem>
                    )}
                </Select>
            </FormControl>
            }
        </>
    );
}

export default PageDropdown;