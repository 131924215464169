import HeaderBanner from '../sub-components/HeaderBanner';

// page for all routes that are currently being developed 
const UnderConstructionPage = () => {
    return (
        <div class='summary-page'>
            <HeaderBanner indicator={1}/>
            <br/>
            <h1 style={{textAlign: 'center'}}>This Page Is Under Construction</h1>
            <br/>
        </div>
    );
}

export default UnderConstructionPage; 