import { useContext } from 'react';
import PageContext from './PageContext';
import greenCircleIcon from '../../svgs/green-circle-icon.svg';
import redCircleIcon from '../../svgs/red-circle-icon.svg';
import RowEditIcon from './RowEditIcon';

// pass row object as prop
const CompanyEntitlementsRow = ({ row, count, tableHeader, enableEdit }) => {
    // get context variables from parent
    const {
        editEntitlementsModal, 
        setEditEntitlementsModal, 
        editEntitlementsRow, 
        setEditEntitlementsRow, 
    } = useContext(PageContext);
     
    // event handler for edit button click
    const onEditClick = () => {
        // set row to be used in modal 
        setEditEntitlementsRow(editEntitlementsRow => editEntitlementsRow = row);
        // make EditEntitlementsModal visible
        setEditEntitlementsModal(editEntitlementsModal => editEntitlementsModal = true);
    }

    // display red circle for disabled (0) status or green circle for active (1) status
    const statusIcon = (status) =>{
        if ( status == true){
            return <img src={greenCircleIcon} class='data-table-icon-left'/>
        }
        else {
            return <img src={redCircleIcon} class='data-table-icon-left'/>
        }
    }

    return(
        <>
            {/* display all columns */}
            {tableHeader.map(el =>
                el['columnname'] == 'blank' ? 
                // icon to activate edit modal on click 
                <td>
                    {/* show edit icon to trigger EditSubCompanyRow modal */}                      
                    <RowEditIcon enable={enableEdit} onClick={onEditClick}/>
                </td>
                : el['columnname'] == 'active' ? 
                    <td>{statusIcon(row[el['columnname']])}</td>
                :<td>{row[el['columnname']]}</td>
            )}            
        </>
    );
}

export default CompanyEntitlementsRow; 