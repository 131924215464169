import isAlphaNumeric from "./isAlphaNumeric";
import isNumeric from './isNumeric';

const payoutInputValidationChecksSubCompany = ( input, snackbarAlert, payoutMethodData) => {
    // validate payout info inputs. Return false and show snackbar if invalid. 
    if (input.companyid && !isAlphaNumeric(input.companyid)){
        snackbarAlert(true, 'Invalid Sub Company Id.', 'error');
        return false;
    }
    if (input.payoutmethoddescription == '--select--'){
        snackbarAlert(true, 'Invalid Payout Type.', 'error');
        return false; 
    }

    // check payoutMethodData to determine if ach info is required
    for (let i = 0; i < payoutMethodData.length; i++){
        // find if ach info is required for that payoutmethoddescription
        if (payoutMethodData[i]['payoutmethoddescription'] == input['payoutmethoddescription'] && payoutMethodData[i]['requireachinfo'] == true){
            // check that account numbers are valid numeric input
            if (!isNumeric(input.achaccountnumber)){
                snackbarAlert(true, 'Invalid ACH Account #.', 'error');
                return false;
            }
            if (!isNumeric(input.achbankabanumber)){
                snackbarAlert(true, 'Invalid ACH ABA #.', 'error');
                return false;
            }
            if (input.achaccounttype == '--select--'){
                snackbarAlert(true, 'Invalid ACH Account Type.', 'error');
                return false;
            }
            // check that account numbers are correct lengths
            if (input.achaccountnumber.toString().length > parseInt(process.env.REACT_APP_ACH_ACCOUNT_NUMBER_LENGTH)){
                snackbarAlert(true, 'ACH Account Number longer than configuration allows.', 'error');
                return false; 
            }
            if (input.achbankabanumber.toString().length > parseInt(process.env.REACT_APP_ACH_BANK_ABA_NUMBER_LENGTH)){
                snackbarAlert(true, 'ACH Bank ABA Number longer than configuration allows.', 'error'); 
                return false; 
            }
        }
    }

    // if validation checks are passed return true
    return true; 

}

export default payoutInputValidationChecksSubCompany;