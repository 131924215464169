import { useContext, useRef } from 'react';
import PageContext from '../sub-components/PageContext';
import { useSelector } from 'react-redux';

const WorkflowButton = ({ disable }) =>{
    // initialize context from parent
    const {
        workflowModal,  
        setWorkflowModal, 
        workflowAction, 
        setWorkflowAction,
        workflowNewStatus, 
        setWorkflowNewStatus, 
        workflowButtonCss,  
        workflowOptOut, // flag to allow toggle to opt out of rebate workflow
        workflowReviewAction, // flag to determine whether to show dropdown
        userEntitlementId, 
        status, 
        enableReopen, // flag to enable button for reopen action 
    } = useContext(PageContext);

    // global redux state for company (passed in from other route)
    const company = useSelector((state) => state.company);
    const companyInfo = useSelector((state) => state.companyInfo);

    // event handler for submit click
    const onSubmit = () => { 
        workflowButtonCss.current = 'modal-button-full-blue';
        workflowOptOut.current = true; 
        workflowReviewAction.current = false; 
        setWorkflowAction(workflowAction => workflowAction = 'Submit');
        setWorkflowNewStatus(workflowNewStatus => workflowNewStatus = 'Initial Review');
        setWorkflowModal(workflowModal => workflowModal = true);
    }

    // event handler for accept click
    const onAccept = () => {
        workflowButtonCss.current = 'modal-button-full-green';
        workflowOptOut.current = false; 
        workflowReviewAction.current = false; 
        setWorkflowAction(workflowAction => workflowAction = 'Accept');
        if (status == 'Initial Review'){
            setWorkflowNewStatus(workflowNewStatus => workflowNewStatus = 'Final Review');
        }
        else if (status == 'Final Review') {
            setWorkflowNewStatus(workflowNewStatus => workflowNewStatus = 'Accepted');
        }
        setWorkflowModal(workflowModal => workflowModal = true);
    }

    // event handler for reject click
    const onReject = () => {
        workflowButtonCss.current = 'modal-button-full-red';
        workflowOptOut.current = false; 
        workflowReviewAction.current = false; 
        setWorkflowAction(workflowAction => workflowAction = 'Reject');
        setWorkflowNewStatus(workflowNewStatus => workflowNewStatus = 'Rejected');
        setWorkflowModal(workflowModal => workflowModal = true);
    }

    const onReopen = () => {
        workflowButtonCss.current = 'modal-button-full-blue';
        workflowOptOut.current = false; 
        workflowReviewAction.current = false; 
        setWorkflowAction(workflowAction => workflowAction = 'Re-open');
        setWorkflowNewStatus(workflowNewStatus => workflowNewStatus = 'Pending');
        setWorkflowModal(workflowModal => workflowModal = true);
    }

    // ADD TOOLTIPS TO DENOTE WHEN ACTION IS DISABLED
    return(
        <>
            { (status == 'Pending' || status == 'Rejected') && userEntitlementId == process.env.REACT_APP_SUBMITTER_ROLE_ID && !disable ? 
            <>
                {/* Role: [Submitter], status: PENDING */   }
                <button class='summary-page-button' onClick={()=>onSubmit()}>Submit</button>
            </>
            : (status == 'Pending' || status == 'Rejected') ? 
            <>
                {/* Role: [CCRM, Reviewer],  status: PENDING */}
                <button class='summary-page-button-disabled' >Submit</button>
            </>
            : status == 'Initial Review' && userEntitlementId == process.env.REACT_APP_CCRM_ROLE_ID && !disable ? 
            <>
                {/* Role: [CCRM], status: Initial Review */}
                <button class='summary-page-button' onClick={()=>onAccept()}>Accept</button>
                <button class='summary-page-button' onClick={()=>onReject()}>Reject</button>                    
            </>
            : status == 'Initial Review'  ? 
            <> 
                {/* Role: [Reviewer, Submitter], status: Initial Review */}
                <button class='summary-page-button-disabled'>Accept</button>
                <button class='summary-page-button-disabled'>Reject</button>
            </>
            : status == 'Final Review' && userEntitlementId == process.env.REACT_APP_REVIEWER_ROLE_ID && !disable ? 
            <> 
                {/* Role: [Reviewer], status: FINAL REVIEW */}
                <button class='summary-page-button' onClick={()=>onAccept()}>Accept</button>
                <button class='summary-page-button' onClick={()=>onReject()}>Reject</button>                    
            </>
            : status == 'Final Review' ?
            <>
                {/* Role: [CCRM, Submitter], status: FINAL REVIEW */}
                <button class='summary-page-button-disabled'>Accept</button>
                <button class='summary-page-button-disabled'>Reject</button>
            </>
            : status == 'Accepted' && enableReopen && userEntitlementId == process.env.REACT_APP_SUBMITTER_ROLE_ID && !disable ? 
            <>
                {/* Role: [Submitter], status: ACCEPTED */}
                <button class='summary-page-button' onClick={()=>onReopen()}>Re-open</button>
            </>
            : status == 'Accepted' ?
            <>
                {/* Role: [CCRM, Reviewer], status: ACCEPTED */}
                <button class='summary-page-button-disabled'>Re-open</button>
            </>
            :<>
                <span style={{color: 'transparent'}}>
                    ----
                </span>
            </>}
        </>
    );
} 

export default WorkflowButton;  