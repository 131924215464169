import { useContext, useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import PageContext from './PageContext';
import PageDropdown from './PageDropdown';
import { HttpContext } from '../../api-services/HttpService';
import checkChange from '../functions/checkChange'; 
import ApiRequestButton from './ApiRequestButton';

const EditCompanyEntitlementsModal = () => {
    // get context variables from parent
    const {
        editEntitlementsModal, 
        setEditEntitlementsModal, 
        editEntitlementsRow, 
        roleList, 
        masterCompanyData, 
        getCompanyDetailsData,
        snackbarAlert,
    } = useContext(PageContext);
    // initialize http context 
    const {
        PUT, 
    } = useContext(HttpContext);
    // get persistant redux global state from store
    const company = useSelector((state) => state.company);
    // initialize role state
    const [statusTag, setStatusTag] = useState('Active');
    const [statusList, setStatusList] = useState(['Active', 'Inactive']);
    const [role, setRole] = useState('--select--');
    const [statusChange, setStatusChange] = useState(false);
    // useRef to determine initial render
    const initialRender = useRef(true);
    // initialize useRef for initial values
    const initialValues = useRef({});  

    // event handler for submit click
    const onSubmitClick = () => { 
        return new Promise((resolve, reject) => {
            let response; 
            // check for valid input 
            if ( role == '--select--' ){
                snackbarAlert(true, 'Error: Invalid input.', 'error');
                reject(); 
                return 
            }
            // set active variable from statusTag
            let active = false;
            if (statusTag == 'Active'){
                active = true; 
            }
            // remove params that have not changed
            let args = checkChange(initialValues, {
                mastercompanyid: masterCompanyData['mastercompanyid'], 
                userroledescription: role,
                active: active, 
                userentitlementid: editEntitlementsRow['userentitlementid'], 
                userid: editEntitlementsRow['userid'], 
            }); 
            // all oldValues to body
            args['oldValues'] = initialValues.current; 
            // make PUT request
            PUT('/data/companyEntitlements', args).then(res => {
                console.log(res);
                response = res; 
                // hide modal
                setEditEntitlementsModal(editEntitlementsModal => editEntitlementsModal = false);
                // trigger success alert
                snackbarAlert(true, 'Successfully uploaded data.', 'success');
                // refresh CompanyDetailsPage using function from parent
                return getCompanyDetailsData(company);
            }).then(res => {
                console.log(res);
                // resolve on success
                resolve(response); 
            }).catch(err => {
                console.log(err);
                // show alert on error
                snackbarAlert(true, 'Error uploading data.', 'error');
                // reject on error
                reject(err); 
            });
        }); 
    }

    // useEffect to run when editEntitlements row is changed
    useEffect(() => {
        console.log(editEntitlementsRow);
        // do not set role on initial render
        if (initialRender.current){
            initialRender.current = false;
        }
        else{
            // set role when row is changed
            setRole(role => role = editEntitlementsRow['userroledescription']);
            // set status tag based on active element of editEntitlementsRow
            if (editEntitlementsRow['active'] == true){
                setStatusTag(statusTag => statusTag = 'Active');
            }
            else {
                setStatusTag(statusTag => statusTag = 'Inactive');
            }
            // set initial values 
            initialValues.current = {
                userroledescription: editEntitlementsRow['userroledescription'], 
                active: editEntitlementsRow['active'], 
            }
        }
    }, [editEntitlementsRow]);

    return(
        <> 
            <Modal className="rebate-summary-modal"
                show={editEntitlementsModal}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                scrollable={true}
                >
                <Modal.Header closeButton onClick={()=>setEditEntitlementsModal(editEntitlementsModal => editEntitlementsModal = false)}>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Edit Entitlements for User: {editEntitlementsRow['userid']}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    {/* read only input to show user being edited */}
                    <div class='modal-div'>
                        <PageDropdown tag='Status' value={statusTag} setValue={setStatusTag} list={statusList}/>
                    </div>
                    <br/>
                    {/* page dropdown to choose new role. do not show if status change */}
                    { statusTag == 'Active' ? 
                    <>
                        <div class='modal-div'>
                            <PageDropdown tag='Role: ' list={roleList} value={role} setValue={setRole} />
                        </div>
                        <br/>
                    </>
                    :<></>}
                    {/* button to submit company entitlement edit */}
                    <ApiRequestButton onButtonClick={onSubmitClick} />
                </Modal.Body>
            </Modal>
        </>
    );
}

export default EditCompanyEntitlementsModal; 