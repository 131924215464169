import { useContext } from 'react';
import PageContext from './PageContext';
import greenCircleIcon from '../../svgs/green-circle-icon.svg';
import redCircleIcon from '../../svgs/red-circle-icon.svg';
import RowEditIcon from './RowEditIcon';
import PageTooltip from './PageTooltip';
import commentIcon from '../../svgs/comment-icon.svg';
import downloadIcon from '../../svgs/download-icon.svg';  
import ExportS3Icon from './ExportS3Icon'; 
import { HttpContext } from '../../api-services/HttpService';

// pass row object as prop
const SubCompanyRow = ({ row, count, tableHeader, enableEdit }) =>{
    // get context variables from parent 
    const {
        showContractCommentModal, 
        setShowContractCommentModal, 
        showEditContractModal, 
        setShowEditContractModal, 
        contractUploadRow, 
        setContractUploadRow,
    } = useContext(PageContext);  
    // get request functions from HttpContext
    const {
        GET,
    } = useContext(HttpContext); 

    // event handler for edit icon click 
    const onEditClick = () => {
        // pass row to modal 
        setContractUploadRow(contractUploadRow => contractUploadRow = row); 
        // show modal 
        setShowEditContractModal(showEditContractModal => showEditContractModal = true); 
    }

    // event handler for view comment click 
    const onViewClick = () => {
        // pass row to modal 
        setContractUploadRow(contractUploadRow => contractUploadRow = row); 
        // show modal 
        setShowContractCommentModal(showContractCommentModal => showContractCommentModal = true); 
    }

    // event handler for downloading pdf
    const onDownloadClick = () => {
        // get presignedURL (this function is passed to ExportS3Icon)
        return new Promise((resolve, reject) => {
            GET('/presigned/contract', {
                fileName:  row['uploadfilename'], 
            }).then(res => {
                resolve({ data: {
                    'PresignedUrlDownload': res['data']['downloadURL'] // key for compatibility with ExportS3Icon
                }}); 
            }).catch(err => {
                reject(err);
            });
        }); 
    }

    // display red circle for disabled (0) status or green circle for active (1) status
    const statusIcon = (status) =>{
        if ( status == true){
            return <img src={greenCircleIcon} class='data-table-icon-left'/>
        }
        else {
            return <img src={redCircleIcon} class='data-table-icon-left'/>
        }
    }

    return(
        <>
            {/* map logic over ever column in row object */}
            {tableHeader.map(el =>
                // if status column apply statusIcon function 
                el['columnname'] == 'active' ?
                    <td>{statusIcon(row[el['columnname']])}</td>
                : el['columnname'] == 'blank' ?
                    <td>
                        {/* show icon to download pdf */}
                        <PageTooltip title='download'>
                            <ExportS3Icon tag={row['displayfilename']} getData={onDownloadClick} removeDate={true} deleteFile={false} icon={downloadIcon} css='data-table-icon' fileType={'application/pdf'} fileExtension={'.pdf'}/>
                        </PageTooltip>
                        {/* show icon to view comment */}
                        <PageTooltip title='view'>
                            <img src={commentIcon} className='data-table-icon' onClick={onViewClick}/> 
                        </PageTooltip>
                        {/* show edit icon to trigger EditSubCompanyRow modal */}
                        <RowEditIcon enable={enableEdit} onClick={onEditClick}/>
                    </td>
                : row[el['columnname']] == null ?
                    <td class='table-col'>None</td>
                // standard display for all other columns 
                :<td>{row[el['columnname']]}</td>
            )}
            
        </>
    );
}

export default SubCompanyRow; 