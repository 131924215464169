import { useContext, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import PageContext from './PageContext';
import PageDropdown from './PageDropdown';
import PageInput from './PageInput';
import AutoCompleteInput from './AutoCompleteInput';
import { AuthContext } from '../../auth/AuthRoute';
import { HttpContext } from '../../api-services/HttpService';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import payoutInputValidationChecksSubCompany from '../functions/payoutInputValidationChecksSubCompany';
import payoutInputValidationChecksMasterCompany from '../functions/payoutInputValidationChecksMasterCompany';
import isEmptyString from '../functions/isEmptyString'; 
import ApiRequestButton from './ApiRequestButton';

const AddPayoutInfoModal = () =>{
    const {
        addPayoutInfoModal, 
        setAddPayoutInfoModal, 
        payoutMethodList, 
        subCompanyList, 
        getCompanyDetailsData,   
        snackbarAlert,
        masterCompanyData,
        payoutMethodData, 
    } = useContext(PageContext);
    // initialize http context 
    const {
        POST, 
    } = useContext(HttpContext);
    // initialize context variables from auth parent
    const {
        getUsername, 
    } = useContext(AuthContext);
    // get global state
    const company = useSelector((state) => state.company);
    // initialize state
    const [status, setStatus] = useState('Active');
    const [statusList, setStatusList] = useState(['Active', 'Inactive']);
    const [companyId, setCompanyId] = useState('');
    const [accountId, setAccountId] = useState('');
    const [payoutType, setPayoutType] = useState('--select--');
    const [achAccount, setAchAccount] = useState('');
    const [achAba, setAchAba] = useState('');
    const [achType, setAchType] = useState('--select--');
    const [achTypeList, setAchTypeList] = useState(['--select--', 'checking', 'savings']);
    const [showAchInfo, setShowAchInfo] = useState(true); 
    // initialize state for master setup
    const [masterSetupBool, setMasterSetupBool] = useState(false);

    const onSubmitClick = () =>{
        return new Promise((resolve, reject) => {
            let response; 
            // set active bool
            let active = false; 
            if (status == 'Active'){
                active = true;
            }
            // get username from auth context 
            let username = getUsername();
            // add params based on masterSetupBool
            let params = {};
            if (masterSetupBool){
                params = {
                    mastercompanyid: masterCompanyData['mastercompanyid'], 
                    companyid: process.env.REACT_APP_MASTER_PAYOUT_INFO_PLACEHOLDER,
                    accountid: accountId, 
                    payoutmethoddescription: payoutType, 
                    achaccountnumber: achAccount, 
                    achbankabanumber: achAba, 
                    achaccounttype: achType, 
                    lastupdatedby: username, 
                    active: active, 
                }
                // make sure inputs are valid
                if (!payoutInputValidationChecksMasterCompany(params, snackbarAlert, payoutMethodData)){
                    reject(); 
                    return;
                }
            }
            else {
                params = {
                    mastercompanyid: masterCompanyData['mastercompanyid'], 
                    companyid: companyId, 
                    accountid: accountId, 
                    payoutmethoddescription: payoutType, 
                    achaccountnumber: achAccount, 
                    achbankabanumber: achAba, 
                    achaccounttype: achType, 
                    lastupdatedby: username, 
                    active: active, 
                }
                // make sure inputs are valid
                if (!payoutInputValidationChecksSubCompany(params, snackbarAlert, payoutMethodData)){
                    reject(); 
                    return;
                }
            }
            // if ach info does not need to be added and is blank make null 
            if (isEmptyString(params['achaccountnumber'])){
                params['achaccountnumber'] = null; 
            }
            if (isEmptyString(params['achbankabanumber'])){
                params['achbankabanumber'] = null; 
            }
            if (params['achaccounttype'] == '--select--'){
                params['achaccounttype'] = null; 
            } 

            // make POST request
            POST('/data/payoutInfo', params).then(res => { 
                console.log(res);
                response = res; 
                // hide modal 
                setAddPayoutInfoModal(addPayoutInfoModal => addPayoutInfoModal = false);
                // trigger success alert
                snackbarAlert(true, 'Successfully uploaded data.', 'success');
                // reset state variables
                setStatus(status => status = 'Active');
                setCompanyId(companyId => companyId ='');
                setAccountId(accountId => accountId = '');
                setPayoutType(payoutType => payoutType = '--select--');
                setAchAccount(achAccount => achAccount = '');
                setAchAba(achAba => achAba = '');
                setAchType(achType => achType = '--select--');
                // refresh company details page using function from parent
                return getCompanyDetailsData(company);
            }).then(res => {
                console.log(res);
                // resolve on success
                resolve(response); 
            }).catch(err => {
                console.log(err);
                // show alert on error
                snackbarAlert(true, 'Error uploading data.', 'error');
                // reject on error
                reject(err); 
            });
        }); 
    }

    // check whether ach type will clear ach info. If clears info return false so ach info does not show on modal
    const checkShowAchInfo = (method) => {
        // find method in payoutMethodData
        for (let i = 0; i < payoutMethodData.length; i++){
            if (payoutMethodData[i]['payoutmethoddescription'] == method){
                // return !clearachinfo to determine whether it should be shown in modal 
                return !payoutMethodData[i]['clearachinfo']; 
            }
        }
        // default return is true 
        return true; 
    }

    // useEffect to determine whether to allow input info ach info 
    useEffect(() => {
        if (checkShowAchInfo(payoutType)) {
            setShowAchInfo(showAchInfo => showAchInfo = true); 
        }
        else {
            setShowAchInfo(showAchInfo => showAchInfo = false); 
        }
    }, [payoutType]); 

    return(
        <> 
            <Modal className='rebate-summary-modal'
                show={addPayoutInfoModal}
                size='md'
                aria-labelledby='contained-modal-title-vcenter'
                centered
                scrollable={true}
                >
                <Modal.Header closeButton onClick={()=>setAddPayoutInfoModal(addPayoutInfoModal => addPayoutInfoModal = false)}>
                    <Modal.Title id='contained-modal-title-vcenter'>
                        Add Payout Info
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    {/* read only input to specify company for which payout info is being edited */}
                    <div class='modal-div'>
                        <PageDropdown tag='Status:' value={status} setValue={setStatus} list={statusList} />
                    </div>
                    <br/>
                    <div class='modal-div'>
                        <FormControlLabel
                            value="end"
                            control={<Checkbox
                                sx={{
                                    color: '#000000',
                                    '&.Mui-checked': {
                                    color: '#000000',
                                    },
                                }} />}
                            label="Add Master Company Info"
                            labelPlacement="end"
                            sx={{
                                color: 'black',
                            }}
                            checked={masterSetupBool}
                            onChange={(e)=>{setMasterSetupBool(masterSetupBool => masterSetupBool = e.target.checked)}}
                        />
                    </div>
                    <br/>
                    { !masterSetupBool ? 
                    <>
                        <div class='modal-div'>
                            <AutoCompleteInput tag='Sub Company:' value={companyId} setValue={setCompanyId} list={subCompanyList} width='16.5vw'/>
                        </div>
                        <br/>
                    </>
                    :<></>}
                    <div class='modal-div'>
                        <PageInput tag='Account ID:' value={accountId} setValue={setAccountId}/>
                    </div>
                    <br/>
                    <div class='modal-div'>
                        <PageDropdown tag='Payout Method:' value={payoutType} setValue={setPayoutType} list={payoutMethodList} />
                    </div>
                    <br/>
                    { showAchInfo ? 
                        <>
                            <div class='modal-div'>
                                <PageInput tag='ACH Account #:' value={achAccount} setValue={setAchAccount} />
                            </div>
                            <br/>
                            <div class='modal-div'>
                                <PageInput tag='ACH ABA #:' value={achAba} setValue={setAchAba} />
                            </div>
                            <br/>
                            <div class='modal-div'>
                                <PageDropdown tag='ACH Account Type:' value={achType} setValue={setAchType} list={achTypeList} />
                            </div>
                        </>
                        : <></>
                    }
                    {/* button to submit sub company */}
                    <ApiRequestButton onButtonClick={onSubmitClick} />
                    <br/><br/>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default AddPayoutInfoModal;