import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import TextField from '@mui/material/TextField';
import moment from 'moment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const MonthPicker = ({tag, value, setValue, width, font}) => {
        // set width if not passed as prop
        if (width == null){
            width = '16.5vw';
        }
        // set font size if given as prop, else use default
        let style = {};
        if (font){
            style['fontSize'] = font;
        }
    
        // handler to set date on input 
        const handleChange = (newVal) => {
            setValue(value => value = newVal ); 
        }
    
        return (
            // date picker from material ui
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker 
                    label={tag}
                    size = 'small'
                    value={value}
                    onChange={handleChange}
                    inputProps={{style: style}}       
                    renderInput={(params) => <TextField InputLabelProps={{style: style}} sx={{ width: width }} size='small' {...params} />}
                    emptyLabel='None' 
                    views={['month', 'year']} 
                    slotProps={{ textField: { size: 'small' } }}
                />
            </LocalizationProvider>
        );
}

export default MonthPicker; 