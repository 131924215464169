import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Modal } from "react-bootstrap";
import PageContext from './PageContext';
import OrderedDataTable from './OrderedDataTable';
import OverrideRow from './OverrideRow';
import PageDropdown from './PageDropdown';
import OverrideInput from './OverrideInput';
import PageReadOnlyInput from '../sub-components/PageReadOnlyInput';
import WorkflowCommentLog from './WorkflowCommentLog';
import { HttpContext } from '../../api-services/HttpService';
import isNumeric from '../functions/isNumeric';
import decimalToPercent from '../functions/decimalToPercent'; 
import percentToDeciaml from '../functions/percentToDecimal'; 
import ApiRequestButton from './ApiRequestButton';

const OverrideModal = () =>{
    // global state
    const company = useSelector((state) => state.company);
    const companyInfo = useSelector((state) => state.companyInfo);

    // context from parent
    const {
        editRow,  
        setEditRow, 
        overrideModal, 
        setOverrideModal,
        aggregateTableHeader, 
        overrideTypeList,
        overrideType, 
        setOverrideType, 
        subCompany, 
        getCompanyPayoutViewPageData, 
        snackbarAlert,
        rebateInputTypeId, 
    } = useContext(PageContext);
    // initialize http context 
    const {
        PUT, 
    } = useContext(HttpContext);

    // state for table
    const [overrideData, setOverrideData] = useState([]);
    const [overrideHeader, setOverrideHeader] = useState([]);
    // state for override type
    const [overrideRate, setOverrideRate] = useState(0);
    const [overrideSignAmt, setOverrideSignAmt] = useState(0.0);
    const [overrideComment, setOverrideComment] = useState('');

    // function wrapper to pass row component to datatable
    const RowComponent = (row, count, tableHeader) =>{
        return(
            <>
                <OverrideRow row={row} count={count} tableHeader={tableHeader}/>
            </>
        );
    } 

    // event handler for submit button click 
    const onSubmitClick = () =>{
        return new Promise((resolve, reject) => {
            let response; 
            // apply transformation if necessary because of rebateInputTypeId if override type is rate
            let ovrRate = overrideRate; 
            if (overrideType == 'Rate' && rebateInputTypeId == 1){ // id of 1 as percent
                ovrRate = percentToDeciaml(ovrRate); 
            }
            // validate rate and amount are numeric 
            if (overrideType == 'Rate' && !isNumeric(ovrRate)){
                snackbarAlert(true, 'Error: Invalid override rate.', 'error');
                reject(); 
                return;
            }
            if (overrideType == 'Amount' && !isNumeric(overrideSignAmt.replaceAll(",", ""))){
                snackbarAlert(true, 'Error: Invalid override amount.', 'error');
                reject(); 
                return;
            }
            // validate comment input
            if (overrideComment.length==0){
                snackbarAlert(true, 'Error: Please add override comment.', 'error');
                reject(); 
                return; 
            }
            // use PUT function to make request 
            PUT('/data/override', {
                mastercompanyid: companyInfo['mastercompanyid'], 
                rebateid: editRow['rebateid'], 
                overridetypedescription: overrideType, 
                overriderate: ovrRate, 
                overrideamt: overrideSignAmt.replaceAll(",", ""), 
                workflowid: companyInfo['workflowid'], 
                commenttext: overrideComment, 
            }).then(res => {
                console.log(res);
                response = res; 
                // hide modal 
                setOverrideModal(overrideModal => overrideModal = false);
                // show snackbar on success
                snackbarAlert(true, 'Override successfully uploaded.', 'success');
                // make comment default
                setOverrideComment(overrideComment => overrideComment = '');
                // refresh data
                return getCompanyPayoutViewPageData(); 
            }).then(res => {
                console.log(res);
                // resolve on success
                resolve(response); 
            }).catch(err => {
                console.log(err);
                // show snackbar on error
                snackbarAlert(true, 'Error uploading override.', 'error');
                // reject on error
                reject(err); 
            });
        }); 
    }

    // event handler for value change in commment textarea
    const onTextAreaChange = (e) =>{
        // set state to value passed in event object
        setOverrideComment(overrideComment => overrideComment = e.target.value);
    }

    // useEffect to run on change of overrideModal state variable. Toggles visibility of modal
    useEffect(() => {
        // set state for table
        setOverrideData(overrideData => overrideData = [editRow]);
        setOverrideHeader(overrideHeader => overrideHeader = [...aggregateTableHeader.slice(0, aggregateTableHeader.length-1)]);
        // set state for override type dropdown
        setOverrideType(overrideType => overrideType = overrideTypeList[0]);
        if (!isNumeric(editRow['incentiverate']) ){
            console.log('NaN'); 
            setOverrideRate(overrideRate => overrideRate = 0);
        }
        else{
            console.log('Num'); 
            // set override rate for input (initialized to existing rate)
            // apply transformation if necessary because of rebateInputTypeId
            if (rebateInputTypeId == 1){ // id of 1 as percent
                setOverrideRate(overrideRate => overrideRate = decimalToPercent(editRow['incentiverate'])); 
            }
            else { // default id of 0 as decimal 
                setOverrideRate(overrideRate => overrideRate = parseFloat(editRow['incentiverate']));
            }
        }
        setOverrideSignAmt(overrideSignAmt => overrideSignAmt = editRow['cycleincentiveamount'])
    }, [overrideModal]);

    // object to pass variables to PageContext
    const settings = {
        overrideRate, 
        setOverrideRate,
        overrideSignAmt,
        setOverrideSignAmt,
        overrideComment, 
        setOverrideComment,  
        overrideType,
        rebateInputTypeId, 
    };

    return(
        <> 
            <PageContext.Provider value={ settings }>
                <Modal className="rebate-summary-modal"
                    show={overrideModal} // controls visibility of modal 
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    scrollable={true}
                    >
                    <Modal.Header closeButton onClick={()=>setOverrideModal(overrideModal => overrideModal = false)}>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Rebate Override
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body >
                        {/* PageReadOnlyInputs to show information on the rebate workflow being overridden */}
                        <div class='modal-div'>
                            <PageReadOnlyInput tag='Company: ' value={company} width='24vw'/>
                        </div>
                        <br/>
                        <div class='modal-div'>
                            <PageReadOnlyInput tag='Sub Company: ' value={subCompany} width='24vw'/>
                        </div>
                        <br/>
                        <div class='modal-div'>
                            <PageReadOnlyInput tag='Payout Cycle: ' value={companyInfo['cyclestart'] + ' to ' + companyInfo['cycleend'] + ' [' + companyInfo['incentivefreq'] + ']'} width='24vw'/>
                        </div>
                        <br/>
                        {/* DataTable to show rebate information for fee Category being overridden */}
                        <OrderedDataTable RowComponent={RowComponent} tableHeader={overrideHeader} dataList={overrideData} showSpinner={false} />
                        <br/>
                        {/* show comment log of all comments made on rebate workflow */}
                        <div class='modal-div'>
                            <WorkflowCommentLog workflowid={companyInfo['workflowid']}/>
                        </div>
                        <br/>
                        {/* dropdown to choose override type */}
                        <div class='modal-div'>
                            <PageDropdown tag='Override Type:' value={overrideType} setValue={setOverrideType} list={overrideTypeList}/>  
                        </div>
                        {/* component for inputting override based on override type */}
                        <OverrideInput />
                        <br/>
                        {/* text area to add comment with override submission */}
                        <div class='modal-div'>
                            <label><b>Override Comment: </b></label>
                            <br/>
                            <textarea class='modal-text-area' value={overrideComment} onChange={(e)=>onTextAreaChange(e)}></textarea>
                        </div>
                        <br/>
                        {/* button to submit override  */}
                        <ApiRequestButton onButtonClick={onSubmitClick} />
                        <br/><br/>
                    </Modal.Body>
                </Modal>
            </PageContext.Provider>
        </>
    );
}

export default OverrideModal;