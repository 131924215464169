// check if given string is alpha numeric
const isAlphaNumeric = (str) => {
    // is not alpha numeric if empty string
    if (str.length == 0){
      return false; 
    }
    // check for non alpha numeric characters
    var code;
    for (let i = 0; i < str.length; i++) {
      code = str.charCodeAt(i);
      if (!(code > 47 && code < 58) && // numeric (0-9)
          !(code > 64 && code < 91) && // upper alpha (A-Z)
          !(code > 96 && code < 123)) { // lower alpha (a-z)
        return false;
      }
    }
    return true;
};

export default isAlphaNumeric;