import * as React from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

const NoMaxWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
      [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.black,
      },
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.black,
        maxWidth: 'none',
        fontSize: '13px', 
        whiteSpace: 'pre-line', 
      },
}));

const PageTooltip = (props) => {
    return (
        <>
            <NoMaxWidthTooltip title={props.title}>
                {props.children}
            </NoMaxWidthTooltip>
        </>
    ); 
}

export default PageTooltip; 