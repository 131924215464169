// return true if element is less than value
const lessThanOrEqualFilter = (el, val, filterCol) => {
    if (val == ''){
        // handle edge case where there is no input 
        return true; 
    }
    else{
        return el[filterCol] <= parseFloat(val); 
    }
}

module.exports = lessThanOrEqualFilter; 