import { useContext } from 'react';
import PageContext from './PageContext';
import greenCircleIcon from '../../svgs/green-circle-icon.svg';
import redCircleIcon from '../../svgs/red-circle-icon.svg';
import pencilIcon from '../../svgs/pencil-icon.svg';
import moneyFormat from '../functions/moneyFormat';
import PageTooltip from './PageTooltip'; 

// pass row object and flag as props 
const PerformanceBonusRow = ({ row, count, tableHeader }) => {
    // get context variables from parent
    const {
        editPerformanceBonusModal, 
        setEditPerformanceBonusModal, 
        editPerformanceBonusRow, 
        setEditPerformanceBonusRow, 
    } = useContext(PageContext);

    // event handler for edit icon click
    const onEditClick = () => {
        // set state variable with row object for use in modal 
        setEditPerformanceBonusRow(editPerformanceBonusRow => editPerformanceBonusRow = row);
        // make EditPerformanceBonusModal visible
        setEditPerformanceBonusModal(editPerformanceBonusModal => editPerformanceBonusModal = true);
    }

    // show red icon for disabled (0) status or green icon for Active (1) status 
    const statusIcon = (status) =>{
        if ( status == true){
            return <img src={greenCircleIcon} class='data-table-icon-left'/>
        }
        else {
            return <img src={redCircleIcon} class='data-table-icon-left'/>
        }
    } 

    return (
        <>
            {/* map logic over all elements of row */}
            {tableHeader.map(el => 
                // show circles for status 
                el['columnname'] == 'active' ?
                    <td>{statusIcon(row[el['columnname']])}</td>
                //format money columns
                : el['columnname'] == 'spendcommitment' || el['columnname'] == 'bonusamount' ?
                    <td>{moneyFormat(row[el['columnname']])}</td>
                // if allowEdit flag is true show edit icon to activate EditRebateSetupModal 
                : el['columnname'] == 'blank' ?
                    <td>
                        {/* <button class='payout-table-button' onClick={()=>onEditClick()}>Edit</button> */}
                        <PageTooltip filter='edit'>
                            <img src={pencilIcon} className='data-table-icon' onClick={(e)=>onEditClick(e)}/>
                        </PageTooltip>
                    </td>
                // default standard display
                : <td>{row[el['columnname']]}</td>
            )}
            
        </>
    );
}   

export default PerformanceBonusRow;