import { useState } from 'react'; 
import CircularProgress from '@mui/material/CircularProgress';
import Skeleton from '@mui/material/Skeleton';

const ApiRequestButton = ({ onButtonClick, isLoading, tag='Submit', css='modal-button-full-blue' }) => {
    // state to show spinner on request progress
    const [showSpinner, setShowSpinner] = useState(false); 
    // event handler for click 
    const onClickEvent = () => {
        // show spinner during request progress
        setShowSpinner(showSpinner => showSpinner = true); 
        // call function that is passed in parameters (must be a promise with no arguments)
        onButtonClick().then(res => {
            console.log(res); 
            // hide spinner on request completion 
            setShowSpinner(showSpinner => showSpinner = false ); 
        }).catch(err => {
            console.log(err); 
            // hide spinner on request completion 
            setShowSpinner(showSpinner => showSpinner = false );
        }); 
    }
    // render
    return(
        <>
            { isLoading ?
            <>
                <Skeleton variant="rectangular" width='100%' height={'40px'} sx={{ borderRadius: '5px' }}/>
            </>
            : showSpinner ? 
            <button class={css} style={{backgroundColor: 'gray', borderColor: 'gray'}}>
                <CircularProgress size='25px' sx={{ color: 'white', margin: '2.5px' }}/>
            </button>
            :<button class={css} onClick={()=>onClickEvent()}>{tag}</button>
            } 
        </>
    ); 
}

export default ApiRequestButton