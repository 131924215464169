// pass row object as prop
const StandardRow = ({ row }) =>{
    return(
        <>
            {/* map display over all columns of row component */}
            {Object.keys(row).map(el =>
                <td>{row[el]}</td>
            )}
        </>
    );
}

export default StandardRow; 