import { useContext, useEffect } from 'react';
import PageContext from './PageContext';
import greenCircleIcon from '../../svgs/green-circle-icon.svg';
import redCircleIcon from '../../svgs/red-circle-icon.svg';
import pencilIcon from '../../svgs/pencil-icon.svg';
import PageTooltip from './PageTooltip';
 
const UserInventoryRow = ({ row }) =>{
    const {
        editUserModal, 
        setEditUserModal,
        editUserRow,  
        setEditUserRow,
        getUsers, 
    } = useContext(PageContext);

    let showElements = (el) => {
        if (el == 'custom:Active'){
            if (row[el] == 1){
                return <img src={greenCircleIcon} class='data-table-icon-left'/> 
            }
            else{
                return <img src={redCircleIcon} class='data-table-icon-left'/>  
            }
        }
        else if (el == 'last_updated') {
            let date = row[el].split('T')[0].split('-');
            return <>{date[1].concat('-', date[2], '-', date[0])}</>
        }
        else if (el == 'custom:Role') {
            if (row[el] == 'DataTechnician')
                return <td>{'Rebate Data Technician'}</td>
            else if(row[el] == 'Administrator')
                return <td>{'Rebate Administrator'}</td>;
            else {
                return <td>{row[el]}</td>
            }
        }
        else {
            return <>{row[el]}</>
        }
    }

    const settings = {
        editUserModal, 
        setEditUserModal,
        editUserRow,  
        setEditUserRow, 
        getUsers,
        showElements
    }
    
    const onEditClick = () => {
        setEditUserModal(editUserModal => editUserModal = true);
        setEditUserRow(editUserRow => editUserRow = row);
    }

    useEffect(() => {
        showElements(row);
    }, [row['custom:Active'], row['custom:Role']])

    return(
        <PageContext.Provider value={ settings }>
        <>
            {Object.keys(row).map(el =>
                <td>{showElements(el)}</td>
            )}
            <td>
                {/* <button class='payout-table-button' onClick={()=>onEditClick()}>Edit</button> */}
                <PageTooltip title='edit'>
                    <img src={pencilIcon} className='data-table-icon' onClick={(e)=>onEditClick(e)}/>
                </PageTooltip>
            </td>
        </>
        </PageContext.Provider>
            );
}

export default UserInventoryRow; 