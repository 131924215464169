import HeaderBanner from '../sub-components/HeaderBanner';

// page for all routes that are currently being developed 
const SubscribeToResourcePage = () => {
    return (
        <div class='summary-page'>
            <HeaderBanner indicator={1}/>
            <br/>
            <h1 style={{textAlign: 'center'}}>Please subscribe to this resource to view the page</h1>
            <br/>
        </div>
    );
}

export default SubscribeToResourcePage; 