import removeStrCommas from './removeStrCommas';

// validate numeric input that may have commas
const validateNumericInput = ( input ) => {
    try{
        // empty string returns false
        if (input.length == 0){
            return false;
        }
        // remove commas from string 
        let temp = removeStrCommas(input);
        // use isNaN built in function to determine if string is a valid number
        return !isNaN(temp);
    }
    catch (err) {
        console.log(err);
        // return false if error
        return false; 
    }
}

export default validateNumericInput;