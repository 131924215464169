import { useContext, useState, useEffect } from 'react';
import PageContext from './PageContext';
import { Modal } from 'react-bootstrap';
import OrderedDataTable from './OrderedDataTable';
import MultipartWorkflowTableRow from './MultipartWorkflowTableRow';
import { HttpContext } from '../../api-services/HttpService';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import BannerAlert from '../sub-components/BannerAlert';
import ApiRequestButton from './ApiRequestButton';

const MulitpartWorkflowModal = ({ checkList }) => {
    //get context from parent
    const {
        multipartWorkflowModal,
        setMultipartWorkflowModal,
        workflowButtonCss,
        workflowOptOut,
        multipartWorkflowAction,
        workflowActionCheckedList,
        workflowActionStatus,
        refreshSummaryData, 
        snackbarAlert, 
    } = useContext(PageContext);
    // initialize http context 
    const {
        POST, 
    } = useContext(HttpContext);
    // initialize state
    const [submissionComment, setSubmissionComment] = useState('');
    const [optOut, setOptOut] = useState(false);

    // initialize state for tableHeader
    const [tableHeader, setTableHeader] = useState([
        { columnname: 'mastercompanyname', columntag: 'Company' },
        { columnname: 'status', columntag: 'Status' },
        { columnname: 'rebatecycle', columntag: 'Rebate Cycle' },
        { columnname: 'incentivefreq', columntag: 'Rebate Frequency' },
        { columnname: 'grossspend', columntag: 'Spend Amount' },
        { columnname: 'grossinterchange', columntag: 'Interchange Amount' },
        { columnname: 'incentiveamount', columntag: 'Rebate Amount' },
    ]); 

    // create function for workflow action: submit. function takes in a checklist, parses workflowid and calls api 
    const sendWorkflowAction = (buttonaction, action, checklist, comment) => {
        return new Promise((resolve, reject) => {
            let response; 
            console.log('/rebate/summary/multicompany/' + buttonaction + '/' + action); 
            // check to make sure there is a comment 
            if (comment.length == 0){
                // show snackar on error
                snackbarAlert(true, 'Please enter a comment', 'error');
                reject(); 
                return; 
            }
            // use POST function to make request
            POST('/rebate/summary/multicompany/' + buttonaction + '/' + action, { 
                workflowids: checklist.map(workflow => workflow.workflowid),
                comment: comment, 
            }).then(res => {
                console.log(res);
                response = res; 
                // hide modal on success
                setMultipartWorkflowModal(multipartWorkflowModal => multipartWorkflowModal = false);
                // default sta value 
                setSubmissionComment(submissionComment => submissionComment = '');
                setOptOut(optOut => optOut = false); 
                // show snackbar on success
                snackbarAlert(true, 'Successfully updated workflow.', 'success');
                // refresh data on success
                return refreshSummaryData();
            }).then(res => {
                console.log(res); 
                // resolve on success
                resolve(response); 
            }).catch(err => {
                console.log(err);
                // show snackar on error
                snackbarAlert(true, 'Error updating workflow.', 'error');
                // reject on error
                reject(err); 
            });
        }); 
    }

    // create function for opting out of workflow . function takes in a checklist, parses workflowid and calls api 
    const sendOptOut = (buttonAction, checklist, comment) => {
        return new Promise((resolve, reject) => {
            let response; 
            console.log('opt out');
            // check to make sure there is a comment 
            if (comment.length == 0){
                // show snackar on error
                snackbarAlert(true, 'Please enter a comment', 'error');
                reject(); 
                return; 
            }
            // use POST function to make request
            POST('/rebate/summary/multicompany/' + buttonAction + '/optout', { 
                workflowids: checklist.map(workflow => workflow.workflowid), 
                comment: comment, 
            }).then(res => {
                console.log(res);
                response = res; 
                // hide modal on success
                setMultipartWorkflowModal(multipartWorkflowActionModal => multipartWorkflowActionModal = false); 
                // default comment value
                setSubmissionComment(submissionComment => submissionComment = '');
                setOptOut(optOut => optOut = false); 
                // show snackbar on success
                snackbarAlert(true, 'Successfully updated workflow.', 'success');
                // refresh data on success
                return refreshSummaryData(); 
            }).then(res => {
                console.log(res); 
                // resolve on success
                resolve(response)
            }).catch(err => {
                console.log(err); 
                // show snackar on error
                snackbarAlert(true, 'Error updating workflow.', 'error');
                // reject on error
                reject(err); 
            });
        }); 
    }

    // function wrapper to pass row component to table
    const RowComponent = (row, count, tableHeader) => {
        return <>
            <MultipartWorkflowTableRow row={row} count={count} tableHeader={tableHeader} />
        </>;
    }

    // event handler for action
    const onAction = () => {
        return new Promise((resolve, reject) => {
            //send workflow
            if (optOut){
                sendOptOut(workflowActionStatus, checkList, submissionComment).then(res => {
                    resolve(res); 
                }).catch(err => {
                    reject(err); 
                }); 
            }
            else {
                sendWorkflowAction(workflowActionStatus, multipartWorkflowAction, checkList, submissionComment).then(res => {
                    resolve(res); 
                }).catch(err => {
                    reject(err); 
                }); ;
            }
        });      
    }

    return (
        <Modal className='rebate-summary-modal'
            show={multipartWorkflowModal}
            size='lg'
            aria-labelledby='contained-modal-title-vcenter'
            centered
            scrollable={true}
        >
            <Modal.Header closeButton onClick={() => setMultipartWorkflowModal(multipartWorkflowModal => multipartWorkflowModal = false)}>
                <Modal.Title id='contained-modal-title-vcenter'>
                    Multi Company {multipartWorkflowAction} Rebate Form
                </Modal.Title>
            </Modal.Header>
            <Modal.Body >
                <OrderedDataTable RowComponent={RowComponent} tableHeader={tableHeader} dataList={workflowActionCheckedList} numToPage={100} />
                {/* input for comment on action */}
                <div class='modal-div'>
                    <label><b>Comment: </b></label>
                    <br />
                    <textarea class='modal-text-area' value={submissionComment} onChange={(e) => setSubmissionComment(submissionComment => submissionComment = e.target.value)}></textarea>
                </div>
                <br />
                {/* show opt out option when flag is true */}
                {workflowOptOut.current ?
                    <>
                        <div class='modal-div' style={{marginBottom: '10px'}}>
                            <FormControlLabel
                                value="end"
                                control={<Checkbox
                                    sx={{
                                        color: '#ff0f0f',
                                        '&.Mui-checked': {
                                            color: '#ff0f0f',
                                        },
                                    }} />}
                                onClick={(e) => setOptOut(optOut => optOut = e.target.checked)}
                                label="Opt out of Rebate Workflow"
                                labelPlacement="end"
                                sx={{
                                    color: 'red',
                                }}
                            />
                        </div>
                        {/* show alert for submitting companies without ccrms */ }
                        <BannerAlert show={true} type='info' message={'Companies without a CCRM will automatically be opted out of CCRM review.'} width='100%' />                  
                    </>
                    : <></>}             
                {/* show review submissionComments when flag is true */}
                {/* <button class={workflowButtonCss.current} onClick={() => onAction()}>{multipartWorkflowAction}</button> */}
                <ApiRequestButton onButtonClick={onAction} tag={multipartWorkflowAction}/>
                <br /><br />
            </Modal.Body>
        </Modal>
    );
}

export default MulitpartWorkflowModal; 