import { useState, useContext } from 'react';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import { HttpContext } from '../../api-services/HttpService';

const UploadS3ExcelButton = ({ tag, folder, filename, file, fileInput, putMetadata, refreshData, validateData, snackbarAlert, css, showSpinner}) => {
     // initialize http context 
     const {
        GET, 
    } = useContext(HttpContext);
    // intialize state
    const [block, setBlock] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState('');
    // set css class
    if(css == null){
        css = 'summary-page-button'; 
    }

    // handler to get presigned url and upload file to s3 bucket
    const uploadExcel = () => {

        if (validateData()){
            if (file.current){
                console.log(file.current); 
                // show spinner bar
                setBlock(block => block = true); 
                setLoadingMessage(loadingMessage => loadingMessage = 'Uploading file...'); 
                // set fileName
                let fileName = file.current.name; 
                if (filename){
                    fileName = filename;
                }
                console.log(fileName); 
                // get presigned url for s3 bucket
                putMetadata().then(res => {
                    console.log(res);
                    // make put request to presigned url
                    return  GET('/presigned/upload?', {'fileName': folder + fileName}); 
                }).then(res => {
                    console.log(res);              
                    // add to processing log table
                    return axios({
                        method: 'PUT', 
                        url: res.data.uploadURL, 
                        data: file.current, 
                        headers: { 
                            'Content-Type': file.current.type,
                        }, 
                    }); 
                }).then(res => {
                    console.log(res);
                    // stop showing spinner
                    setBlock(block => block = false); 
                    // set state to show snackbar alert success
                    snackbarAlert(true, 'File successfully uploaded.', 'success');
                    // reset fileInput 
                    fileInput.current.value = '';
                    file.current = null; 
                    // refresh info for table
                    return refreshData(); 
                }).then(res => {
                    console.log(res);
                }).catch(err => {   
                    console.log(err);
                    // stop showing spinner
                    setBlock(block => block = false); 
                    // set state to show snackbar alert error
                    snackbarAlert(true, 'Error uploading file.', 'error');
                });
            }
            else{
                // set state to show snackbar alert error
                snackbarAlert(true, 'Please choose file to upload.', 'error');
            }
        }
    }

    // event handler to initiate export excel
    const onUploadClick = () => {
        // check if block is false
        if (!block){
            // trigger export excel 
            uploadExcel();
        }
        else {
            console.log('Blocked!');
        }
    }

    return (
        <>
            {/* <button style={{marginRight: '50px'}} class={css} onClick={()=>onUploadClick()}>{tag}</button>
            { block && showSpinner ? 
            <>
                <CircularProgress style={{height: '25px', width: '25px', marginTop: '5px', marginLeft: '10px', marginBottom: '-5px'}}/>
                <span style={{marginLeft: '10px'}}>{loadingMessage}</span>
            </>
            :<></>} */}
            { block && !showSpinner? 
            <button class={css} style={{backgroundColor: 'gray', borderColor: 'gray'}}>
                <CircularProgress size='25px' sx={{ color: 'white', margin: '2.5px' }}/>
            </button>
            : <button style={{marginRight: '50px'}} class={css} onClick={()=>onUploadClick()}>{tag}</button>}
            { block && showSpinner ? 
            <>
                <CircularProgress style={{height: '25px', width: '25px', marginTop: '5px', marginLeft: '10px', marginBottom: '-5px'}}/>
                <span style={{marginLeft: '10px'}}>{loadingMessage}</span>
            </>
            :<></>}
        </>
    ); 
}

export default UploadS3ExcelButton; 