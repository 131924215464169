import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from 'redux';
import { actionCreators } from "../../redux/index";
import moneyFormat from '../functions/moneyFormat';

// pass row object as prop
const RebateBreakupRow = ({ row, count, tableHeader }) =>{
    // get redux persistant state from redux store 
    const transactionDetails = useSelector((state) => state.transactionDetails);
    const dispatch = useDispatch();
    const { setTransactionDetails } = bindActionCreators(actionCreators, dispatch);
    // event handler for link click to pass row data to transaction data
    const onClick = () => {
        setTransactionDetails(row);
    }
    return( 
        <>
            {/* map logic over all columns in row object */}
            {tableHeader.map(el =>
                // apply moneyFormat to correct columns 
                el['columnname'] == 'netspend' || el['columnname'] == 'netinterchange' ?
                <> 
                    {row[el['columnname']] == null ?
                        <td>None</td>
                    :<td>{moneyFormat(row[el['columnname']])}</td>}
                </>
                : el['columnname'] == 'blank' ?
                <td> 
                    {/* link to transaction details page with event handler to pass props */}
                    <Link onClick={()=>onClick()}
                        to={{
                            pathname: "/TransactionDetailsPage",
                        }}
                        >
                        <button class='payout-table-button'>Details</button>
                    </Link>
                </td>  
                // standard display for other cols
                :<td>{row[el['columnname']]}</td>
            )}
            
        </>
    );
}

export default RebateBreakupRow;