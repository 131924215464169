import { useContext, useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import PageContext from './PageContext';
import PageDropdown from './PageDropdown';
import AutoCompleteInputWithOptions from '../sub-components/AutoCompleteInputWithOptions';
import EditEntitlementModalRow from './EditEntitlementModalRow';
import { HttpContext } from '../../api-services/HttpService';
import OrderedDataTable from '../sub-components/OrderedDataTable';
import ApiRequestButton from './ApiRequestButton';

const UserEntitlementModal = () => {
    // get context variables from parent
    const {
        editModal, 
        setEditModal, 
        editUser,
        getInfo,
        snackbarAlert,
        getUserEntitlementsCounts
    } = useContext(PageContext);

    // initialize http context 
    const {
        GET, 
        POST, 
    } = useContext(HttpContext);
    
    // state for role dropdown
    const [role, setRole] = useState('--select--');
    const [roleList, setRoleList] = useState([]); 
    // state for company autocomplete
    const [searchCompany, setSearchCompany] = useState('');
    const [companyObj, setCompanyObj] = useState({ label: '' , id: null })
    const [companyList, setCompanyList] = useState(['']);
    // state for entitlement table
    const [tableHeader, setTableHeader] = useState([]);
    const [showDataList, setShowDataList] = useState([]);
    // initialize spinner state
    const [showSpinner, setShowSpinner] = useState(false);

    // create function for getting/reading user entitlement data from DB, function takes in 1 userid and returns a promise
    const getUserEntitlements = (user) => {
        setShowSpinner(showSpinner => showSpinner = true);
        return new Promise((resolve, reject) => {
            GET('/user/entitlements', { userid: user }).then(res => {
                // set table state
                setShowDataList(showDataList => showDataList = res.data.UserEntitlements);
                setTableHeader(tableHeader => tableHeader = res['data']['GuiTableCols']);
                // set dropdown state 
                setRoleList(roleList => roleList = ['--select--', ...res.data.UserRolesLookup.map(role => role.userroledescription)]);
                setCompanyList(companyList => companyList = [...res.data.CompaniesLookup]);
                // set showSpinner to false
                setShowSpinner(showSpinner => showSpinner = false);
                resolve(res);
            }).catch(err => {
                setShowSpinner(showSpinner => showSpinner = false);
                reject(err);
            });
        });
    }

    // create function for posting/creating a new user entitlement data in the DB, function takes in 1 userid and returns a promise
    const postUserEntitlements = () => {
        return new Promise((resolve, reject) => {
            let response; 
            // check that role is not select
            if (role == '--select--'){
                snackbarAlert(true, 'Please choose role for entitlement.', 'error'); 
                reject(); 
                return; 
            }
            // check that company has been selected
            if (companyObj['id'] == null){
                snackbarAlert(true, 'Please choose company for entitlement.', 'error'); 
                reject(); 
                return; 
            }
            // use POST function to make request
            POST('/data/companyEntitlements', {
                userid: editUser, 
                mastercompanyid: companyObj['id'], 
                userroledescription: role, 
                active: true, 
            }).then(res => { 
                console.log(res); 
                response = res; 
                // show snackbar on success
                snackbarAlert(true, `Created New Entitlement For ${editUser}`, 'success');
                // default state
                setRole(role => role = '--select--');
                setSearchCompany(searchCompany => searchCompany = ''); 
                setCompanyObj(companyObj => companyObj = { label: '' , id: 0 }); 
                // refresh data for user 
                return getUserEntitlements(editUser);
            }).then(res => {
                console.log(res); 
                // refresh counts data
                return getUserEntitlementsCounts(); 
            }).then(res => {
                console.log(res); 
                // resolve on success
                resolve(res); 
            }).catch(err => {
                console.log(err); 
                // show snackbar on error
                snackbarAlert(true, `Failed to Create Entitlement For ${editUser}`, 'error');
                // reject on error
                reject(err); 
            }); 
        }); 
    }
    
    // function wrapper to pass row component to datatable
    const RowComponent = (row, tableHeader) =>{
        return( 
            <>
                <EditEntitlementModalRow row={row} tableHeader={tableHeader}/>
            </>
        );
    } 

    // useEffect to run when modal is toggled
    useEffect(() => {
        if (editModal){
            //get entitlement data for user selected on GUI
            getUserEntitlements(editUser).then(res => {
                console.log(res); 
            }).catch(err => {
                console.log(err); 
            }); 
            //reset dropdowns
            setRole(role => role = '--select--');
            setSearchCompany(searchCompany => searchCompany = '');
        }
    }, [editModal]);


    const settings = {
        roleList, 
        editUser, 
        getUserEntitlements,
        snackbarAlert,
        tableHeader, 
        getUserEntitlementsCounts
    };

    return(
        <PageContext.Provider value={ settings }>
        <> 
            <Modal className="rebate-summary-modal"
                show={editModal} // from parent context
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                scrollable={true}
                >
                <Modal.Header closeButton onClick={()=>setEditModal(editModal => editModal = false)}>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Entitlements for {editUser}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    {/* top label to add to user entitlements */}
                    <label><b>Add Company to User Entitlements:</b> </label>
                    <div class='modal-div'>
                        {/* page dropdown to choose role for new company entitlement */}
                        <span className='span-left'>
                            <PageDropdown tag='Role:' value={role} setValue={setRole} list={roleList}/>
                        </span>
                        {/* submit addition of user entitlement */}
                        <span class='span-right'>
                            <ApiRequestButton onButtonClick={postUserEntitlements} css='entitlement-modal-button'/>
                        </span>
                        {/* autocomplete to search complete list of companies  */}
                        <span className='entitlement-input-span-right'>
                            <AutoCompleteInputWithOptions tag='Search Company: ' value={searchCompany} setValue={setSearchCompany} obj={companyObj} setObj={setCompanyObj} list={companyList} width='350px'/>
                        </span>
                        <br/><br/>
                    </div>
                    <br/>
                    {/* DataTable to view and edit existing entitlements  */}
                    <b><label>User Entitlements: </label></b>
                    <OrderedDataTable RowComponent={RowComponent} tableHeader={tableHeader} dataList={showDataList} showSpinner={showSpinner} numToPage={10} skeletonHeight='28vh'/>
                </Modal.Body>
            </Modal>
        </>
        </PageContext.Provider>
    );
}

export default UserEntitlementModal;