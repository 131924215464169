import { useState, useEffect, useContext, useRef } from 'react';
import PageContext from '../sub-components/PageContext';
import HeaderBanner from '../sub-components/HeaderBanner';
import Navslider from '../sub-components/Navslider';
import PageDropdown from '../sub-components/PageDropdown';
// import PageInput from '../sub-components/PageInput';
import OrderedDataTable from '../sub-components/OrderedDataTable';
import RebateSummaryEditRowModal from '../sub-components/RebateSummaryEditRowModal';
import RebateCommentModal from '../sub-components/RebateCommentModal';
import RebateTrailModal from '../sub-components/RebateTrailModal';
import RebateSummaryRow from '../sub-components/RebateSummaryRow';
import ExportExcelButton from '../sub-components/ExportExcelButton';
import standardSearch from '../functions/standardSearch';
import standardFilter from '../functions/standardFilter';
import applyFilters from '../functions/applyFilters';
import MultipartWorkflowButton from '../sub-components/MultipartWorkflowButton';
import MulitpartWorkflowModal from '../sub-components/MultipartWorkflowModal';
import { HttpContext } from '../../api-services/HttpService';
import { AuthContext } from '../../auth/AuthRoute';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import eligibleFilter from '../functions/eligibleFilter'; 
import SnackbarAlert from '../sub-components/SnackbarAlert'; 
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from 'redux';
import { actionCreators } from "../../redux/index";
import PageInputRedux from '../sub-components/PageInputRedux'; 
import PageDropdownRedux from '../sub-components/PageDropdownRedux'; 
import PageInputReduxTooltip from '../sub-components/PageInputReduxTooltop'; 

// import temp data to display 
const workflowActionInfo = require('../../TempValueFiles/workflowActionData');

const RebateSummaryPage = () =>{
    // initialize http context 
    const {
        GET, 
    } = useContext(HttpContext);
    // initialize redux persistant state
    const rebateSummarySearch = useSelector((state) => state.rebateSummarySearch);
    const rebateSummaryPeriod = useSelector((state) => state.rebateSummaryPeriod); 
    const rebateFrequency = useSelector((state) => state.rebateFrequency); 
    const workflowStatus = useSelector((state) => state.workflowStatus); 
    const dispatch = useDispatch();
    const { 
        setRebateSummarySearch, 
        setRebateSummaryPeriod, 
        setRebateFrequency, 
        setWorkflowStatus,  
    } = bindActionCreators(actionCreators, dispatch);
    // search dropdown states
    const [periodList, setPeriodList] = useState([]);
    const [periodId, setPeriodId] = useState('');
    const [frequencyList, setFrequencyList] = useState([]);
    // const [frequency, setFrequency] = useState('');
    // const [companySearch, setCompanySearch] = useState('');
    // state for table 
    const [showDataList, setShowDataList] = useState([]); // show data list for displaying filtered list in table
    const [dataList, setDataList] = useState([]); // data list to have complete list if filters are reset/changed
    const [tableHeader, setTableHeader] = useState([]);
    // set editRow modal variables
    const [editRowModal, setEditRowModal] = useState(false);
    const [commentModal, setCommentModal] = useState(false);
    const [trailModal, setTrailModal] = useState(false);
    const [editRow, setEditRow] = useState({});
    // state to make check box change in sub component
    const [checkBoxChange, setCheckBoxChange] = useState(false);
    // useRef to determine if initialRender
    const initialRender = useRef(true);
    // initialize state for workflow actions
    const [userRole, setUserRole] = useState('0');
    const [userRoleList, setUserRoleList] = useState([]);    
    const workflowActionData = useRef([]);
    //workflow action
    const [workflowAction, setWorkflowAction] = useState('');
    const [workflowActionList, setWorkflowActionList] = useState([]);
    //workflow status
    const [workflowActionStatus, setWorkflowActionStatus] = useState('');
    const [workflowActionStatusList, setWorkflowActionStatusList] = useState([]);
    const [workflowActionCheckedList, setWorkflowActionCheckedList] = useState([]);
    // initialize multipart workflow modal state
    const [multipartWorkflowModal, setMultipartWorkflowModal] = useState(false);
    const [multipartWorkflowAction, setMultipartWorkflowAction] = useState('');
    // initialize reference for workflow modal
    const workflowButtonCss = useRef('modal-button-full-blue');
    const workflowOptOut = useRef(false);
    const workflowReviewAction = useRef(false);
    // check all boxes state var and list to hold checked rows var
    const [checkAll, setCheckAll] = useState(false);
    // initialize spinner state
    const [showSpinner, setShowSpinner] = useState(false);
    // initalize state to filter out non-eligible workflows from table 
    const [showNonEligible, setShowNonEligible] = useState(true); 
    const [eligiblePeriod, setEligiblePeriod] = useState(null);
    // initialize state for snackbar
    const [showSnackbar, setShowSnackbar] = useState(false); 
    const [snackbarMessage, setSnackbarMessage] = useState(''); 
    const [snackbarColor, setSnackbarColor] = useState('warning'); 
    // set state for excel export format list 
    const [fmtList, setFmtList] = useState([
        {col: 'E', columnname: 'grossspend', float: true, fmt: '"$"#,##0.00_);[Red]\\("$"#,##0.00\\)'}, // money format
        {col: 'F', columnname: 'grossinterchange', float: true, fmt: '"$"#,##0.00_);[Red]\\("$"#,##0.00\\)'}, // money format
        {col: 'G', columnname: 'incentiveamount', float: true, fmt: '"$"#,##0.00_);[Red]\\("$"#,##0.00\\)'}, // money format
    ]); 

    // function to set snackbar state
    const snackbarAlert = (show, message, color) => {
        setShowSnackbar(showSnackbar => showSnackbar = show); 
        setSnackbarMessage(snackbarMessage => snackbarMessage = message); 
        setSnackbarColor(snackbarColor => snackbarColor = color); 
    } 

    //get lookups for rebate summary page
    const getSummaryLookups = () => {
        return new Promise((resolve, reject) => {
            GET('rebate/summary/lookups').then(res => {
                // set frequency states
                setFrequencyList(frequencyList => frequencyList = ['All', ...res.data.FreqDescriptionsLookup.map(freq => freq.frequencydescription)]);
                // set period states
                setPeriodList(periodList => periodList = [...res.data.PeriodIdsLookup.map(period => period.periodid)]);
                // if there is no periodid in redux persistant state set periodid 
                if (rebateSummaryPeriod == '' ){
                    setRebateSummaryPeriod(res.data.PeriodIdsLookup[0].periodid); 
                }
                //set workflow action status
                setWorkflowActionStatusList(workflowActionStatusList => workflowActionStatusList =['All', ...res.data.WorkflowStatusLookup.map(status => status.workflowstatusdescription)]);
                setWorkflowActionStatus(workflowActionStatus => workflowActionStatus = workflowStatus);
                setUserRoleList(userRoleList => userRoleList = ['0', ...res.data.WorkflowStatusLookup.map(status => status.userroleid)]);
                // setUserRole(userRole => userRole = '0');
                // resolve on success
                resolve(res);
           }).catch(err => {
                reject(err);
           }) 
        });
    }

    // create function for getting/reading resbate summary data from DB for a specfic users entitlements, function takes in 1 userid and returns a promise
    const getSummaryData = (periodId) => {
        setShowSpinner(showSpinner => showSpinner = true);
        let user = getUsername();
        return new Promise((resolve, reject) => {
            GET('/rebate/summary', { userid: user, periodId: periodId }).then(res => {
                setDataList(dataList => dataList = res.data.SummaryData);
                setShowDataList(showDataList => showDataList = res.data.SummaryData);
                setTableHeader(tableHeader => tableHeader = res['data']['GuiTableCols']);
                // hide spinner
                setShowSpinner(showSpinner => showSpinner = false);
                console.log(res);
                resolve(res);
            }).catch(err => {
                setShowSpinner(showSpinner => showSpinner = false);
                reject(err);
            });
        });
    }

    // function wrapper to refresh summary data 
    const refreshSummaryData = () => {
        getSummaryData(rebateSummaryPeriod).then(res => {
            console.log(res); 
        }).catch(err => {
            console.log(err); 
        }); 
    }

    //get getTokenSession function from Authorization context    
    const {
        getTokenSession, 
        getUsername, 
    } = useContext(AuthContext);

    // event handler for check all box click
    const onCheckboxClick = () =>{
        // check all checkboxes with given status and add to workflowActionCheckedList
        setCheckBoxChange(checkBoxChange => checkBoxChange = !checkBoxChange); // to alert child components of change
        if (checkAll == false){
            setCheckAll(checkAll => checkAll = true);
            setWorkflowActionCheckedList( workflowActionCheckedList => workflowActionCheckedList = dataList.filter((el) => {
                return el['status'] == workflowStatus; 
            }));
        }
        // uncheck all boxes
        else{
            setCheckAll(checkAll => checkAll = false);
            setWorkflowActionCheckedList( workflowActionCheckedList => workflowActionCheckedList = []);
        }
    }

    //event handler for PeriodId change
    // const onPeriodIdChange = (e) => {
    //     console.log(e)
    //     getSummaryData(periodId).then(res => {
    //         console.log(res);
    //     }).catch(err => {
    //         console.log(err);
    //     });
    // }

    // function wrapper to pass row component to datatable
    const RowComponent = (row, count, tableHeader) =>{
        return(
            <>
                <RebateSummaryRow row={row} count={count} tableHeader={tableHeader}/>
            </>
        );
    } 

    // list of workflow actions user can do based on thier role
   /* const setActionListWithRole = (data) => {
        let tempList = [];
        let firstElem = true; 
        // add to list if the user is allowed to do that action
        for (let i = 0; i < data.length; i++){
            tempList.push(data[i]['tag']);
            if (firstElem == true){
                // make workflow action and status that of the first item added
                setWorkflowAction(workflowAction => workflowAction = data[i]['tag']);
                setWorkflowActionStatus(workflowActionStatus => workflowActionStatus = data[i]['status']);
                firstElem = false;
            }
        }
        setWorkflowActionList(workflowActionList => workflowActionList = tempList);
    }*/

    useEffect(() => {
        setWorkflowActionStatus(workflowActionStatus => workflowActionStatus = workflowStatus); 
        let index = workflowActionStatusList.indexOf(workflowStatus);
        setUserRole(userRole => userRole = userRoleList[index]); 
    }, [workflowStatus, workflowActionStatusList, userRoleList]); 

    // useEffect to run on initial render
    useEffect(() => {
        //grab summary data using tokens
        getSummaryLookups(); 
        //setActionListWithRole(workflowActionInfo);
        workflowActionData.current = workflowActionInfo;
    }, []);

    //usereffect to get data on PeriodId change
    useEffect(() => {
        setPeriodId(periodId => periodId = rebateSummaryPeriod); 
        getSummaryData(rebateSummaryPeriod);
    }, [rebateSummaryPeriod]);

    // useEffect to run list of search/filter functions on table data
    useEffect(() => {
        // do not run on initial render
        if (!initialRender.current){
            // declare list of functions with parameters to filter/search list
            const functionList = [
                {function: standardFilter, value: rebateFrequency, flag: 1, cols: ['incentivefreq']},
                // {function: betweenFilter, value: periodId, flag: 1, cols: ['rebatecycle']},                
                {function: standardSearch, value: rebateSummarySearch, flag: 0, cols: ['mastercompanyname', 'mastercompanyid', 'primaryccrm']},
                {function: standardFilter, value: workflowStatus, flag: 1, cols: ['status']}, 
                {function: eligibleFilter, value: eligiblePeriod, flag: 1, cols: ['rebatecycle']}, 
            ];
            // use applyFilters function to search by running all functions declared above
            let filteredList = applyFilters(dataList, functionList);
            // update showDataList
            setShowDataList(showDataList => showDataList = filteredList);
        }
        else{
            initialRender.current = false;
        }
           
    }, [dataList, rebateFrequency, rebateSummarySearch, workflowStatus, eligiblePeriod]);

    // use effect to handle change in show data list 
    useEffect(() => {
        // if item is not in showDataList remove from workflowActionCheckedList
        let tempList = workflowActionCheckedList.filter((row)=>{
            return userRole.includes(row['userroleid']) && showDataList.includes(row); 
        });
        setWorkflowActionCheckedList(workflowActionCheckedList => workflowActionCheckedList = tempList);
    }, [showDataList, checkAll]);

    // use effect to handle change in workflowAction
    useEffect(() => {
        // set workflowActionStatus
        for (let i = 0; i < workflowActionData.current.length; i++){
            // check workflowActionData to change workflowActionStatus to correct value
            if (workflowAction == workflowActionData.current[i]['tag']){
                setWorkflowStatus(workflowActionData.current[i]['status']);
                break;
            }
        }
        // reset workflowActionCheckedList 
        setWorkflowActionCheckedList(workflowActionCheckedList => workflowActionCheckedList = []);
        // reset checkall box
        setCheckAll(checkAll => checkAll = false);
    }, [workflowAction]);
    
    // useEffect to update elidgeablePeriod filter
    useEffect(() => {
        if (showNonEligible == false){
            setEligiblePeriod(eligiblePeriod => eligiblePeriod = rebateSummaryPeriod); 
        } 
        else {
            setEligiblePeriod(eligiblePeriod => eligiblePeriod = null); 
        }
    }, [rebateSummaryPeriod, showNonEligible]); 

    const settings = {
        dataList, 
        tableHeader, 
        editRowModal, 
        setEditRowModal,
        commentModal, 
        setCommentModal, 
        trailModal, 
        setTrailModal,
        editRow, 
        setEditRow,
        checkAll,
        setCheckAll,
        workflowActionCheckedList, 
        setWorkflowActionCheckedList,
        checkBoxChange,  
        showDataList, 
        setShowDataList,
        workflowAction, 
        setWorkflowAction,
        workflowActionList, 
        setWorkflowActionList,
        workflowActionData, 
        workflowActionStatus, 
        setWorkflowActionStatus,
        multipartWorkflowModal, 
        setMultipartWorkflowModal, 
        workflowButtonCss, 
        workflowOptOut, 
        workflowReviewAction, 
        multipartWorkflowAction, 
        setMultipartWorkflowAction,
        userRole,
        getSummaryData,
        periodId, 
        refreshSummaryData, 
        snackbarAlert, 
        showSnackbar, 
        setShowSnackbar, 
        snackbarMessage, 
        snackbarColor, 
        workflowStatus, 
    };

    return(
        <div class='summary-page'>
            {/* wrap children so they can access PageContext variables */}
            <PageContext.Provider value={ settings }>
                {/* header and navslider present on every page */}
                <HeaderBanner indicator={1}/>
                <Navslider indicator={-1} backPath='/RebateWorkflowLanding'/> 
                {/* all modals that can be activated on page. Default for each modal is hidden state */}
                <RebateSummaryEditRowModal/> 
                <RebateCommentModal/>
                <RebateTrailModal/>
                <MulitpartWorkflowModal checkList={workflowActionCheckedList}/>
                {/* snackbar to show alerts on screen */}
                <SnackbarAlert />

                <div class='top-label-div'>
                    <label class='top-label-text'><b>Rebate Summary</b></label>
                    <br/>
                    <div class='label-dropdown-div'>
                        <span class='label-dropdown-left'>
                            <PageDropdownRedux tag='Payout Period:' value={rebateSummaryPeriod} list={periodList} setValue={setRebateSummaryPeriod} />
                        </span>
                        <span > 
                            {/* filter table on rebate frequency */}
                            <PageDropdownRedux tag='Rebate Frequency:' list={frequencyList} value={rebateFrequency} setValue={setRebateFrequency} /> 
                        </span>
                        <span class='label-dropdown-right'>
                            {/* filter table by company search */}
                            <PageInputReduxTooltip tag='Search:' tooltip='Company Name | Rebate ID | Primary CCRM' value={rebateSummarySearch} setValue={setRebateSummarySearch} />
                        </span>
                    </div>
                    <br/>
                </div>
                {/* mid label for exporting and multipart workflow actions */}
                <div class='mid-label-div'>
                    <span class='label-text-span-left'>
                        <span class='span-left'>
                            {/* export filtered table */}
                            <ExportExcelButton tag='ccap-rebate-summary' dataList={showDataList} tableHeader={tableHeader.slice(0, tableHeader.length-2)} formatList={fmtList}/>
                        </span>
                    </span>
                    <span class='label-text-span-mid'>
                        {/* dropdown to choose workflow action */}
                        <PageDropdownRedux tag='Workflow Status: ' list={workflowActionStatusList} value={workflowStatus} setValue={setWorkflowStatus}/>
                    </span>
                    <span class='label-text-span-right'>
                        {/* dynamic multipart workflow action button that changes based on action chosen in dropdown  */}
                        <span class='span-right'>
                            <MultipartWorkflowButton />
                            <input class='summary-page-checkbox' type="checkbox" checked={checkAll} onChange={(e)=>onCheckboxClick(e)}/>
                        </span>
                    </span>
                    <br/>
                </div>
                <br/>
                {/* table to show rebate summary data */}
                <div class='data-table-div'>
                    <FormControlLabel
                        value="end"
                        control={<Checkbox
                            sx={{
                                color: '#000000',
                                '&.Mui-checked': {
                                color: '#000000',
                                },
                            }} />}
                        label="Show Non-Eligible Rebate Workflows"
                        labelPlacement="end"
                        sx={{
                            color: 'black',
                            float: 'right', 
                        }}
                        checked={showNonEligible}
                        onChange={(e)=>{setShowNonEligible(showNonEligible => showNonEligible = e.target.checked)}}
                    />
                    <OrderedDataTable RowComponent={RowComponent} tableHeader={tableHeader} dataList={showDataList} showSpinner={showSpinner} numToPage={50} skeletonHeight='28vh'/>
                </div>
            </PageContext.Provider>
        </div>
    );
}

export default RebateSummaryPage;