// RowDropdown component for use in DataTable/OrderedDataTable row components
const RowDropdown = ({ tag, list, value, setValue}) =>{
    // event handler to set state passed as prop on dropdown change 
    const onDropdownChange = (e) =>{
        setValue(value => value = e.target.value);
    }

    return(
        <>
            {/* standard html dropdown component with label */}
            <label><b>{ tag }</b></label>{" "}
            <select class="page-dropdown" value={ value } onChange={(e)=>onDropdownChange(e)}>
                {list.map( el => 
                    <option id={el}>{el}</option>
                )}                                        
            </select>
        </>
    );
}

export default RowDropdown;