import moneyFormat from '../functions/moneyFormat'; 
import decimalToPercent from '../functions/decimalToPercent'; 

const ConsortiumViewFooter = ({tableHeader, footerObj}) => {
    return(
        <>
            {tableHeader.map( el => 
                el['columnname'] == 'cyclegrossspend' || el['columnname'] == 'cyclegrossinterchange'
                || el['columnname'] == 'ytdgrossspend' || el['columnname'] == 'ytdgrossinterchange' ? 
                    <th>{moneyFormat(footerObj[el['columnname']])}</th>
                : <th>{footerObj[el['columnname']]}</th>
            )} 
        </>
    ); 
}

export default ConsortiumViewFooter; 