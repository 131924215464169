import toDate from './toDate'; 

const checkChange = (initialValues, params) => {
    // if element exists in initial values and hasn't changed in params remove from params
    Object.keys(initialValues.current).map(el => {
        // check if params element is a date
        let initial = initialValues.current[el]; 
        let current = params[el]; 
        if (Object.prototype.toString.call(params[el]) === '[object Date]'){
            if (initialValues.current[el]){
                initial = toDate(initialValues.current[el]).getTime(); 
            }
            current = params[el].getTime(); 
        }
        console.log(initial + ' == ' + current); 
        // if initial is equal to params element remove it 
        if (initial == current) {
            delete params[el]; 
        }
    }); 
    // return object  
    return params; 
}

export default checkChange; 