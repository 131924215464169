import { useContext, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import PageContext from './PageContext';
import PageDropdown from './PageDropdown';
import AutoCompleteInput from './AutoCompleteInput';
import { HttpContext } from '../../api-services/HttpService';
import ApiRequestButton from './ApiRequestButton';

const AddCompanyEntitlementsModal = () => {
    // initialize PageContext from parent
    const {
        addEntitlementsModal, 
        setAddEntitlementsModal, 
        roleList, 
        userList, 
        masterCompanyData, 
        getCompanyDetailsData,
        snackbarAlert,
    } = useContext(PageContext);

    // initialize http context 
    const { 
        POST, 
    } = useContext(HttpContext);

    // get persistant redux global state from store
    const company = useSelector((state) => state.company);
    // set state for role dropdown
    const [role, setRole] = useState('--select--');
    // set state for user autocomplete
    const [searchUser, setSearchUser] = useState('');

    // event handler for submit click
    const onSubmitClick = () => {
        return new Promise((resolve, reject) => {
            let response; 
            // check for valid input 
            if (role == '--select--' || searchUser == ''){
                snackbarAlert(true, 'Error: Invalid input.', 'error');
                reject(); 
                return 
            }
            // check to make sure primaryCCRM is in user list 
            if (!userList.includes(searchUser)){
                snackbarAlert(true, 'Choose valid user for Primay CCRM.', 'error'); 
                reject(); 
                return; 
            }
            // make POST request
            POST('/data/companyEntitlements', {
                userroledescription: role, 
                userid: searchUser, 
                mastercompanyid: masterCompanyData['mastercompanyid'],
                active: true, 
            }).then(res => {
                console.log(res);
                response = res;
                // make modal hidden
                setAddEntitlementsModal(addEntitlementsModal => addEntitlementsModal = false);
                // default state 
                setRole(role => role = '--select--'); 
                setSearchUser(searchUser => searchUser = ''); 
                // trigger success alert
                snackbarAlert(true, 'Successfully uploaded data.', 'success');
                // refresh CompanyDetailsPage using function from parent
                return getCompanyDetailsData(company);
            }).then(res => {
                console.log(res);
                // resolve on success
                resolve(response); 
            }).catch(err => {
                console.log(err);
                // show alert on error
                snackbarAlert(true, 'Error uploading data.', 'error');
                // reject on error
                reject(err); 
            });
        }); 
    }

    return(
        <> 
            <Modal className="rebate-summary-modal"
                show={addEntitlementsModal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                scrollable={true}
                >
                <Modal.Header closeButton onClick={()=>setAddEntitlementsModal(addEntitlementsModal => addEntitlementsModal = false)}>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Add Entitlement
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <label><b>Add User Entitlements:</b> </label>
                    <div class='modal-div'>
                        {/* dropdown to choose role for adding user */}
                        <span className='span-left'>
                            <PageDropdown tag='Role:' value={role} setValue={setRole} list={roleList}/>
                        </span>
                        {/* button to submit user addition */}
                        <span class='span-right'>
                            {/* <button className='entitlement-modal-button' onClick={(e)=>onSubmitClick(e)}>Submit</button> */}
                            <ApiRequestButton onButtonClick={onSubmitClick} css='entitlement-modal-button'/>
                        </span>
                        {/* autocomplete that allows a search of active users */}
                        <span className='entitlement-input-span-right'>
                            <AutoCompleteInput tag='Search User: ' value={searchUser} setValue={setSearchUser} list={userList} width='320px'/>
                        </span>
                        <br/><br/>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default AddCompanyEntitlementsModal;