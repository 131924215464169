import { useContext, useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import PageContext from './PageContext';
import PageDropdown from './PageDropdown';
import PageInput from './PageInput';
import { HttpContext } from '../../api-services/HttpService';
import payoutInputValidationChecksMasterCompany from '../functions/payoutInputValidationChecksMasterCompany';
import isEmptyString from '../functions/isEmptyString'; 
import checkChange from '../functions/checkChange'; 
import ApiRequestButton from './ApiRequestButton';

const EditPayoutInfoModal = () =>{
    const {
        editPayoutInfoModal, 
        setEditPayoutInfoModal,  
        editPayoutInfoRow, 
        payoutMethodList, 
        masterCompanyData,
        getCompanyDetailsData,  
        snackbarAlert, 
        payoutMethodData, 
    } = useContext(PageContext);
    // initialize http context 
    const {
        PUT, 
    } = useContext(HttpContext);
    // get global state
    const company = useSelector((state) => state.company);
    // initialize state
    const [status, setStatus] = useState('Active');
    const [statusList, setStatusList] = useState(['Active', 'Inactive']);
    const [companyName, setCompanyName] = useState('');
    const [accountId, setAccountId] = useState('');
    const [payoutType, setPayoutType] = useState('--select--');
    const [achAccount, setAchAccount] = useState('');
    const [achAba, setAchAba] = useState('');
    const [achType, setAchType] = useState('--select--');
    const [achTypeList, setAchTypeList] = useState(['--select--', 'checking', 'savings']);
    const [showAchInfo, setShowAchInfo] = useState(true); 
    // useRef for initial render 
    const initialRender = useRef(true);
    // initialize useRef for initial values 
    const initialValues = useRef({}); 

    const onSubmitClick = () =>{ 
        return new Promise((resolve, reject) => {
            let response; 
            const input = {
                companyid: editPayoutInfoRow['companyid'], 
                accountid: accountId, 
                payoutmethoddescription: payoutType, 
                achaccountnumber: achAccount, 
                achbankabanumber: achAba, 
                achaccounttype: achType, 
            };
            // make sure inputs are valid
            if (!payoutInputValidationChecksMasterCompany(input, snackbarAlert, payoutMethodData)){
                reject(); 
                return;
            }
            // if ach info does not need to be added and is blank make null 
            let tempAchAccount = achAccount; 
            if (isEmptyString(tempAchAccount)){
                tempAchAccount = null; 
            }
            let tempAchAba = achAba; 
            if (isEmptyString(tempAchAba)){
                tempAchAba = null; 
            }
            let tempAchType = achType; 
            if (tempAchType == '--select--'){
                tempAchType = null; 
            }
            // set active bool
            let active = false; 
            if (status == 'Active'){
                active = true;
            }
            // do not include unchanged inputs in request
            let args = checkChange(initialValues, {
                mastercompanyid: masterCompanyData['mastercompanyid'],
                companyid: editPayoutInfoRow['companyid'], 
                accountid: accountId, 
                payoutmethoddescription: payoutType, 
                achaccountnumber: tempAchAccount, 
                achbankabanumber: tempAchAba, 
                achaccounttype: tempAchType, 
                active: active, 
            }); 
            // add old values 
            args['oldValues'] = initialValues.current; 
            // use PUT function to make request
            PUT('/data/payoutInfo', args).then(res => {
                console.log(res); 
                response = res; 
                // hide modal 
                setEditPayoutInfoModal(editPayoutInfoModal => editPayoutInfoModal = false);
                // trigger success alert
                snackbarAlert(true, 'Successfully uploaded data.', 'success');
                // refresh company details page using function from parent
                return getCompanyDetailsData(company);
            }).then(res => {
                console.log(res);
                // resolve on success
                resolve(response); 
            }).catch(err => {
                console.log(err);
                // show alert on error
                snackbarAlert(true, 'Error uploading data.', 'error');
                // reject on error
                reject(err); 
            });
        }); 
    }

    // check whether ach type will clear ach info. If clears info return false so ach info does not show on modal
    const checkShowAchInfo = (method) => {
        // find method in payoutMethodData
        for (let i = 0; i < payoutMethodData.length; i++){
            if (payoutMethodData[i]['payoutmethoddescription'] == method){
                // return !clearachinfo to determine whether it should be shown in modal 
                return !payoutMethodData[i]['clearachinfo']; 
            }
        }
        // default return is true 
        return true; 
    }

    // useEffect to determine whether to allow input info ach info 
    useEffect(() => {
        if (checkShowAchInfo(payoutType)) {
            setShowAchInfo(showAchInfo => showAchInfo = true); 
        }
        else {
            setShowAchInfo(showAchInfo => showAchInfo = false); 
        }
    }, [payoutType]); 

    // useEffect to run on row change
    useEffect(() => {
        if (!initialRender.current){
            // set status based on active column
            if (editPayoutInfoRow['active'] == true){
                setStatus(status => status = 'Active');
            }
            else {
                setStatus(status => status = 'Inactive');
            }
            // set other state 
            setCompanyName(companyName => companyName = editPayoutInfoRow['companyname']);
            setAccountId(accountId => accountId = editPayoutInfoRow['accountid']);
            setPayoutType(payoutType => payoutType = editPayoutInfoRow['payoutmethoddescription']);
            setAchAccount(achAccount => achAccount = editPayoutInfoRow['achaccountnumber']);
            setAchAba(achAba => achAba = editPayoutInfoRow['achbankabanumber']);
            setAchType(achType => achType = editPayoutInfoRow['achaccounttype']);
            // set initial values reference
            initialValues.current = {
                companyname: editPayoutInfoRow['companyname'], 
                accountid: editPayoutInfoRow['accountid'], 
                payoutmethoddescription: editPayoutInfoRow['payoutmethoddescription'], 
                achaccountnumber: editPayoutInfoRow['achaccountnumber'], 
                achbankabanumber: editPayoutInfoRow['achbankabanumber'], 
                achaccounttype: editPayoutInfoRow['achaccounttype'], 
                active: editPayoutInfoRow['active'], 
            }
        }
        else {
            initialRender.current = false;
        }
        
    }, [editPayoutInfoRow]);

    return(
        <> 
            <Modal className='rebate-summary-modal'
                show={editPayoutInfoModal}
                size='md'
                aria-labelledby='contained-modal-title-vcenter'
                centered
                scrollable={true}
                >
                <Modal.Header closeButton onClick={()=>setEditPayoutInfoModal(editPayoutInfoModal => editPayoutInfoModal = false)}>
                    <Modal.Title id='contained-modal-title-vcenter'>
                        Edit Payout Info for {companyName}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    {/* read only input to specify company for which payout info is being edited */}
                    <div class='modal-div'>
                        <PageDropdown tag='Status:' value={status} setValue={setStatus} list={statusList} />
                    </div>
                    <br/>
                    <div class='modal-div'>
                        <PageInput tag='Account ID:' value={accountId} setValue={setAccountId}/>
                    </div>
                    <br/>
                    <div class='modal-div'>
                        <PageDropdown tag='Payout Method:' value={payoutType} setValue={setPayoutType} list={payoutMethodList} />
                    </div>
                    <br/>
                    { showAchInfo ? 
                        <>
                            <div class='modal-div'>
                                <PageInput tag='ACH Account #:' value={achAccount} setValue={setAchAccount} />
                            </div>
                            <br/>
                            <div class='modal-div'>
                                <PageInput tag='ACH ABA #:' value={achAba} setValue={setAchAba} />
                            </div>
                            <br/>
                            <div class='modal-div'>
                                <PageDropdown tag='ACH Account Type:' value={achType} setValue={setAchType} list={achTypeList} />
                            </div>
                        </>
                        : <></>
                    }
                    {/* button to submit sub company */}
                    <ApiRequestButton onButtonClick={onSubmitClick} />
                    <br/><br/>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default EditPayoutInfoModal;