import { useContext, useState, useEffect, useRef } from 'react';
import { Modal } from "react-bootstrap";
import PageContext from "./PageContext";
import OrderedDataTable from './OrderedDataTable'; 
import AdditionalDetailsRow from './AdditionalDetailsRow'; 
import StandardRow from './StandardRow'; 

const AdditionalDetailsModal = () => {
    // initialize PageContext
    const {
        showAdditionalDetailsModal,
        setShowAdditionalDetailsModal,
        showSpinner, 
        rebateSetupDataList, 
        additionalDetailsTableHeader, 
        additionalDetailsRow, 
    } = useContext(PageContext);

    // initialize state
    const [additionalDetailsDataList, setAdditionalDetailsDataList] = useState([]); 

    // function wrapper to pass row component to datatable
    const RowComponent = (row, count, tableHeader) =>{
        return(
            <>
                <AdditionalDetailsRow row={row} count={count} tableHeader={tableHeader} />
            </>
        );
    } 

    // useEffect to run on row change
    useEffect(() => {
        console.log(additionalDetailsRow); 
        // set dataList 
        setAdditionalDetailsDataList(additionalDetailsDataList => additionalDetailsDataList = [additionalDetailsRow]); 
    }, [additionalDetailsRow]); 

    useEffect(() => {
        console.log(additionalDetailsDataList);
    }, [additionalDetailsDataList]); 

    return (
        <>
            <Modal className="rebate-summary-modal"
                show={showAdditionalDetailsModal}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                scrollable={true}
            >
                <Modal.Header closeButton onClick={() => setShowAdditionalDetailsModal(showAdditionalDetailsModal => showAdditionalDetailsModal = false)}>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Additional Details for Setup Id: {additionalDetailsRow['incentivesetupid']}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <OrderedDataTable RowComponent={RowComponent} tableHeader={additionalDetailsTableHeader} dataList={additionalDetailsDataList} showSpinner={showSpinner} numToPage={25} skeletonHeight='7vh'/>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default AdditionalDetailsModal; 