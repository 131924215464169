import { useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from "../../redux/index";
import { Link } from 'react-router-dom';
import moneyFormat from '../functions/moneyFormat';

const ConsortiumViewRow = ({ row, count, tableHeader }) => {
    // get redux persistant state from redux store 
    const company = useSelector((state) => state.company);
    const companyInfo = useSelector((state) => state.companyInfo);
    const dispatch = useDispatch();
    const { setCompany, setCompanyInfo } = bindActionCreators(actionCreators, dispatch);
    // event handler when company link is clicked 
    const onCompanyClick = (e) =>{
        console.log(row);
        // set company state to bring to new route (CompanyPagoutViewPage)
        setCompany(e.target.innerText.toString());
        setCompanyInfo(row);
    }

    return (    
        <>
            {tableHeader.map(el =>
                // show link on company name 
                el['columnname'] == 'mastercompanyname' ?
                <td>
                    {/* only show link if user is entitled */}
                    {row['userroleid'] ? 
                        <Link onClick={(e)=>onCompanyClick(e)}
                            to={{
                                pathname: "/CompanyPayoutViewPage",
                            }}>
                            {row[el['columnname']]}
                        </Link>
                    : <> {row[el['columnname']]} </> }
                </td>       
                // logic to apply money format to correct rows
                : el['columnname'] == 'cyclegrossspend' || el['columnname'] == 'cyclegrossinterchange' 
                || el['columnname'] == 'ytdgrossspend' || el['columnname'] == 'ytdgrossinterchange' ?
                    <> 
                        {row[el['columnname']] == null ?
                            <td>None</td>
                        :<td>{moneyFormat(row[el['columnname']])}</td>}
                    </>
                // standard display for other cols
                : <> {row[el['columnname']] == null ?
                        <td>None</td>
                :<td>{row[el['columnname']]}</td>}
                </>
            )}
        </>
    ); 
}

export default ConsortiumViewRow; 