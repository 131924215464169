const isEmptyString = (str) => {
    if (str && str.length > 0){
        // if string is not null check for chars other than space
        for (let i = 0; i < str.length; i++){
            if (str.substring(i, i+1) != ' '){
                return false; 
            }
        }
        // if array is traversed without return it only contains spaces so return true (string is empty)
        return true; 
    }
    // return false if length is null or less than 0 
    else {
        return true; 
    }
}

export default isEmptyString; 