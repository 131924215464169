import trimWhitespace from './trimWhitespace'; 
import Decimal from 'decimal.js'; 
import isNumeric from './isNumeric'; 

const decimalToPercent = (str) => {
        // try to convert decimal to percent using decimal.js for precise math
        try {
            // trim whitespace from either side of string
            let val = trimWhitespace(str); 
            // replace commas if present 
            val = val.replace(',', ''); 
            // check that string is now numeric 
            if (!isNumeric(val)){
                throw new Error ('Invalid number format.'); 
            }
            // convert string to Decimal for precise math  
            val = new Decimal(val);
            // if float is return val times 100 
            return val.times(100.0).toString() + '%'; 
        }
        // return initial string on error
        catch(err) {
            console.log(err); 
            return str; 
        }
        
}

export default decimalToPercent; 