const isNumeric = ( value ) => {
    // if null return false
    if (value == null){
        return false; 
    }
    // check for spaces in number
    let noSpace = true; 
    for (let i = 0; i < value.length; i++){
        if (value.substring(i, i+1) == ' '){
            noSpace = false; 
            break; 
        }
    }
    // check that length is greater than 0
    let validLength = false; 
    if (value.length > 0){
        validLength = true; 
    }
    // check that there are no letters in character
    // check for non alpha numeric characters
    var code;
    for (let i = 0; i < value.length; i++) {
      code = value.charCodeAt(i);
      if ((code > 64 && code < 91) || // upper alpha (A-Z)
          (code > 96 && code < 123)) { // lower alpha (a-z)
        return false;
      }
    }
    // return bool using isNaN function with other validations 
    return !isNaN(value) && noSpace && validLength;  
}

export default isNumeric; 