import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import TextField from '@mui/material/TextField';
import moment from 'moment';
import Skeleton from '@mui/material/Skeleton';

const DatePicker = ({ tag, value, setValue, disable, indicator, width, font, disableEdit, isLoading }) => {
    // set width if not passed as prop
    if (width == null){
        width = '16.5vw';
    }
    // set font size if given as prop, else use default
    let style = {};
    if (font){
        style['fontSize'] = font;
    }

    // handler to set date on input 
    const handleChange = (newVal) => {
        setValue(value => value = newVal ); 
    }

    // function to diable dates greater than disable
    const disableGreater = (date) => {
        if (moment(date).isAfter(disable)){
            return true;
        }
        else{
            return false; 
        }
    }

    // funciton to disable dates less than disable
    const disableLessThan = (date) => {
        if (moment(date).isBefore(disable)){
            return true;
        }
        else{
            return false; 
        }
    }

    // function to disable all dates more than 12 months before disable
    const disableMoreThanYearBefore = (date) => {
        let temp = new Date(disable);
        temp.setFullYear((parseInt(temp.getFullYear()) - 1).toString());
        if (moment(date).isBefore(temp)){
            return true;
        }
        else{
            return false; 
        }
    }

    // function to disable all dates more than 12 months after disable
    const disableMoreThanYearAfter = (date) => {
        let temp = new Date(disable);
        temp.setFullYear((parseInt(temp.getFullYear()) + 1).toString());
        if (moment(date).isAfter(temp)){
            return true;
        }
        else{
            return false; 
        }
    }

    // function to disable dates
    const disableDate = (date) => {
        if (indicator == 1){
            return disableGreater(date) || disableMoreThanYearBefore(date);
        }
        else if (indicator == 2){
            return disableLessThan(date) || disableMoreThanYearAfter(date); 
        }
        else if (indicator == 3){
            return disableGreater(date); 
        }
        else if (indicator == 4){
            return disableLessThan(date); 
        }
        else {
            return false; 
        } 
    }

    return (
        <>
        { isLoading ? 
            <>
                <Skeleton variant="rectangular" width='100%' height={'40px'} sx={{ borderRadius: '5px' }}/>
            </>
            : // date picker from material ui
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                    label={tag} 
                    inputFormat="MM-dd-yyyy"
                    value={value}
                    shouldDisableDate={disableDate}
                    onChange={handleChange}
                    inputProps={{style: style}}       
                    renderInput={(params) => <TextField 
                        inputProps={{style: style}} 
                        InputLabelProps={{style: style}} 
                        sx={{ width: width }} 
                        size='small' {...params} 
                    />}
                    emptyLabel='None'
                    slotProps={{ textField: { size: 'small' } }}
                    readOnly={disableEdit}
                />
            </LocalizationProvider>}
        </>
        
    );
}

export default DatePicker;