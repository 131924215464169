import { useContext, createContext, useRef } from 'react';
import axios from 'axios';
import { AuthContext } from '../auth/AuthRoute';
import Queue from './Queue'; 

// create context to pass functions to child components
const HttpContext = createContext();

const HttpRoute = (props) => {
    // use AuthContext to get session tokens 
    const {
        getTokenSession, 
    } = useContext(AuthContext);

    // initialize ref to block other PUT/POST/DELETE requests before intial request response
    const block = useRef(false); 
    // initialise queue obj for GETQ requests
    const queueObj = useRef({}); 

    // random number for Queue id 
    const sha256 = async (str) => {
        return await crypto.subtle.digest("SHA-256", new TextEncoder().encode(str));
    };
    const generateNonce = async () => {
        const hash = await sha256(crypto.getRandomValues(new Uint32Array(4)).toString());
        // https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest
        const hashArray = Array.from(new Uint8Array(hash));
        return hashArray.map(b => b.toString(16).padStart(2, "0")).join("");
    }

    // initialize axios api obj
    const InitHttpService = async () => {
        // initialize axios using url for api
        const api = axios.create({
            baseURL: process.env.REACT_APP_REST_API_URL,
        });
        // get token from localStorage
        await getTokenSession().then(tokens => {
            // add access token to header for all requests. This is necessary to pass auth function
            const accessToken = tokens.access_token['token'];  
            api.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
        }).catch(err => {
            throw new Error(err);
        });
        // return api object with Authorization header attached to all outgoing requests
        return api;    
    }

    // function to execute GET request with query string parameters (No get request are blocked)
    const GET = async (path, params) => {
        // initialize api object with proper base url and headers
        const api = await InitHttpService();
        // return promise that makes get request
        return new Promise((resolve, reject) => {
            api.get(path, {params: params}).then( res => {
                resolve(res);
            }).catch(err => {
                reject(err);
            });
        });
    } 

    // function to execute get in same group requests that resolve in order 
    const GETQ = async(path, params, group) => {
        // intialize api object with proper base url and headers
        const api = await InitHttpService(); 
        // generate nonce 
        let nonce = generateNonce(); 
        // add nonce to queue
        if (queueObj.current[group]){
            // add to queue
            queueObj.current[group].enqueue(nonce); 
        }
        // if queue does not exist for group create queue and add nonce
        else {
            queueObj.current[group] = new Queue(); 
            // add to quere 
            queueObj.current[group].enqueue(nonce); 
        }
        // return promise that make get request and will resolve in order
        return new Promise((resolve, reject) => {
            api.get(path, {params: params}).then( res => {
                // wait for nonce to be at beginning of Queue
                while (queueObj.current[group].peek() != nonce){
                    // wait breifly 
                    setTimeout(resolve, 100); 
                }
                // deqeue nonce
                queueObj.current[group].dequeue(); 
                // resolve on success
                resolve(res); 
            }).catch(err => {
                // deqeue nonce
                queueObj.current[group].dequeue(); 
                // reject on error
                reject(err); 
            }); 
        });
    }

    // function to execute POST request with body (block other PUT/POST/DELETE requests while request is completing)
    const POST = async (path, body) => {
        // initialize api object with proper base url and headers 
        const api = await InitHttpService();
        // return promise that makes post request 
        return new Promise((resolve, reject) => {
            // make http request if no block on requests
            if (!block.current){
                // block other requests
                block.current = true; 
                // make http request
                api.post(path, body).then(res => {
                    // unblock other requests
                    block.current = false; 
                    // resolve on sucess
                    resolve(res);
                }).catch(err => {
                    // unblock other requests
                    block.current = false; 
                    // reject on error
                    reject(err);
                });
            }
            else {
                // do not make request if it is blocked
                reject('Request blocked do to another PUT/POST/DELETE request being processed.'); 
            }
        });
    }

    // function to execute PUT request with body (block other PUT/POST/DELETE requests while request is completing)
    const PUT = async (path, body) => {
        // initialize api object with proper base url and headers 
        const api = await InitHttpService();
        // return promise that makes put request
        return new Promise((resolve, reject) => {
            // make http request if no block on requests 
            if (!block.current){
                // block other requests
                block.current = true; 
                // make http request
                api.put(path, body).then(res => {
                    // unblock other requests
                    block.current = false; 
                    // resolve on sucess
                    resolve(res);
                }).catch(err => {
                    // unblock other requests
                    block.current = false; 
                    // reject on error
                    reject(err);
                });
            }
            else {
                // do not make request if it is blocked
                reject('Request blocked do to another PUT/POST/DELETE request being processed.'); 
            }
            
        });
    }

    // function to execute DELETE request with queryStringParameters (block other PUT/POST/DELETE requests while request is completing)
    const DELETE = async (path, params) => {
        // initialize api object with proper base url and headers
        const api = await InitHttpService(); 
        // return promise that makes delete request
        return new Promise((resolve, reject) => {
            // make DELETE request if no block on requests
            if (!block.current){
                // block other PUT/POST/DELETE requests
                block.current = true; 
                // make request
                api.delete(path, {data: params}).then(res => {
                    // unblock other requests
                    block.current = false; 
                    // resolve on success
                    resolve(res); 
                }).catch(err => {
                    // unblock other requests
                    block.current = false; 
                    // reject on error
                    reject(err); 
                }); 
            }
            else {
                // do not make request if it is blocked
                reject('Request blocked do to another PUT/POST/DELETE request being processed.'); 
            }            
        }); 
    }

    // pass request functions to context
    const settings = {
        GET, 
        POST,
        PUT, 
        DELETE, 
        GETQ, 
    };

    // wrap all children in HttpContext 
    return (
        <HttpContext.Provider value={settings}>
            {props.children}
        </HttpContext.Provider>
    );
}

export { HttpRoute, HttpContext };