import { useContext } from 'react';
import PageContext from '../sub-components/PageContext';
import commentIcon from '../../svgs/comment-icon.svg';
import greenCircleIcon from '../../svgs/green-circle-icon.svg';
import redCircleIcon from '../../svgs/red-circle-icon.svg';
import blueCircleIcon from '../../svgs/blue-circle-icon.svg'; 
import yellowCircleIcon from '../../svgs/yellow-circle-icon.svg'; 
import PageTooltip from './PageTooltip'; 

const ProcessingLogTableRow = ({ row, count, tableHeader }) =>{
    // get context variables from parent 
    const {
        showProcessingCommentModal, 
        setShowProcessingCommentModal,
        processingRow,
        setProcessingRow, 
    } = useContext(PageContext);

    // event handler to make comment modal visible
    const onViewClick = () =>{
        // show modal with processing comment
        setShowProcessingCommentModal(showProcessingCommentModal => showProcessingCommentModal = true); 
        setProcessingRow(processingRow => processingRow = row); 
    }

    // display red circle for disabled (0) status or green circle for active (1) status
    const statusIcon = (status) =>{
        if ( status == 'Processed'){
            return <img src={greenCircleIcon} className='data-table-icon-left'/>
        }
        else if (status == 'Uploaded' || status == 'Re-Uploaded'){
            return <img src={blueCircleIcon} className='data-table-icon-left' /> 
        }
        else if (status == 'Processing') {
            return <img src={yellowCircleIcon} className='data-table-icon-left' />
        }
        else {
            return <img src={redCircleIcon} className='data-table-icon-left'/>
        }
    }

    return(  
        <>
            {tableHeader.map(el => 
                el['columnname'] == 'status' ? 
                    <td>
                        <PageTooltip title={row[el['columnname']]}>
                            {statusIcon(row[el['columnname']])}
                        </PageTooltip>
                    </td>
                // logic to determine how to display column
                : el['columnname'] == 'blank' ?
                    // display icon for processingcomment column
                    <td>
                        <PageTooltip title='view'>
                            <img src={commentIcon} className='data-table-icon' onClick={()=>onViewClick()}/> 
                        </PageTooltip>
                    </td>
                // default standard display 
                : row[el['columnname']] ? 
                    <td>{row[el['columnname']]}</td>
                : <td>None</td>
            )}
        </>
    );
}

export default ProcessingLogTableRow;