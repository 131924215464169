import { useEffect, useState, useRef } from 'react';
import { useLocation } from "react-router-dom";
import { createContext } from 'react';
import { Amplify, Auth } from 'aws-amplify';
import parseJwt from './ParseJWT';
import reduxStore from '../redux/store';
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from 'redux';
import { actionCreators } from "../redux/index";
import amplifyConfigure from './AmplifyConfigure';

// create AuthContext so child components can be passed functions and variables from this component
const AuthContext = createContext();

// configure amplify settings 
Amplify.configure(amplifyConfigure);

const AuthRoute = (props) => {
    // init redux global persistant state
    const userSession = useSelector((state) => state.userSession);
    const dispatch = useDispatch();
    const { setUserSession } = bindActionCreators(actionCreators, dispatch);
    // init useLocation
    var location = useLocation();

    // function that redirects page to cognito login using authorization code flow with pkce parameters
    const redirectToLogin = async () => {
        await Auth.federatedSignIn();
    };

    // retrieve parsed tokens from Auth.getCurrentAuthenticatedUser() which will automatically use refresh token if access and id token have timed out 
    const getTokenSession = () => {
        return new Promise ((resolve, reject) => {
            // retrieve the current session with Amplify get authenticated user      
            // Auth.currentAuthenticatedUser({bypassCache: true}).then(user => { 
            Auth.currentAuthenticatedUser().then(user => { 
                // console.log('--------------------------------'); 
                // console.log('Get USER: '); 
                // console.log(user); 
                // console.log('JTI: ', user['signInUserSession']['accessToken']['payload']['jti']); 
                // console.log('Origin JTI: ', user['signInUserSession']['accessToken']['payload']['origin_jti']);
                // console.log('--------------------------------'); 
                setUserSession(user);
                // resolve tokens after refresh
                resolve({
                    access_token: parseJwt(user['signInUserSession']['accessToken']['jwtToken']), 
                    id_token: parseJwt(user['signInUserSession']['idToken']['jwtToken']), 
                    refresh_token: parseJwt(user['signInUserSession']['refreshToken']['token']), 
                });
            }).catch(err => {
                // no user session error
                console.log(err);
                reject(redirectToLogin());
                // reject(); 
            });
        }); 
    }

    // check if user is authenticated and tokens exist
    const checkAuth = () => {
        if (userSession){
            return true;
        }
        else{
            return false;
        }
    }

    // return true if active and false if not
    const checkActive = async () => {
        // get id token from session storage
        let active = false;
        await getTokenSession().then(tokens => {
            // look for active group in cognito:groups
            for (let i = 0; i < tokens.id_token['cognito:groups'].length; i++){
                if (tokens.id_token['cognito:groups'][i] == 'Active'){
                    active = true; 
                    break;
                }
            }
        });
        return active;
    }

    // return true if admin and false if not
    const checkAdmin = async () => { 
        // get id token from session storage
        let admin = false;
        await getTokenSession().then(tokens => {
            for (let i = 0; i < tokens.id_token['cognito:groups'].length; i++){
                // look for active group in cognito:groups
                if (tokens.id_token['cognito:groups'][i] == 'Administrator'){ //admin group has top precendence in groups, hence index [0]
                    admin = true; 
                }
            }
        });      
        return admin;   
    }

    // check for data tech, admin and active groups in token 
    const checkGroups = async () => { 
        // get id token from session storage
        let active = false; 
        let admin = false;
        let tech = false; 
        await getTokenSession().then(tokens => {
            for (let i = 0; i < tokens.id_token['cognito:groups'].length; i++){
                // look for active group in cognito:groups
                if (tokens.id_token['cognito:groups'][i] == 'Active'){
                    active = true; 
                }
                // look for admin group in cognito:groups
                if (tokens.id_token['cognito:groups'][i] == 'Administrator'){ 
                    admin = true; 
                }
                // look for data tech group in cognito:groups 
                if (tokens.id_token['cognito:groups'][i] == 'DataTechnician'){
                    tech = true; 
                }
            }
        });      
        return {
            'Active': active, 
            'Administrator': admin, 
            'DataTechnician': tech, 
        };   
    }

    // function to initiate logout and redirect
    const sessionLogout = async () => {
        // clear redux persistant store 
        const { persistor } = reduxStore();
        persistor.purge();
        // sign user out of cognito
        Auth.signOut({ global: true });
    }

    // function to return username from userSession obj
    const getUsername = () => {
        return userSession['signInUserSession']['idToken']['payload']['cognito:username'];
    }

    // function to return email from userSession obj
    const getEmail = () => {
        return userSession['signInUserSession']['idToken']['payload']['email'];
    }

    // function to return name from userSession obj
    const getName = () => {
        return userSession['signInUserSession']['idToken']['payload']['name']; 
    }

    // function to return true if admin group is present in id token
    const getAdmin = () => {
        let admin = false; 
        for(let i = 0; i < userSession['signInUserSession']['idToken']['payload']['cognito:groups'].length; i++){
            if (userSession['signInUserSession']['idToken']['payload']['cognito:groups'][i] == 'Administrator'){ 
                admin = true; 
            }
        }
        return admin; 
    }

    // function to return true if data technician group is present in id token 
    const getDataTech = () => {
        let dataTech = false; 
        for (let i = 0; i < userSession['signInUserSession']['idToken']['payload']['cognito:groups'].length; i++){
            if (userSession['signInUserSession']['idToken']['payload']['cognito:groups'][i] == 'DataTechnician'){
                dataTech = true; 
            }
        }
        return dataTech; 
    }

    // series of session checks to do on each location change
    const locationCheck = async () => {
        // Don't redirect if user is authenticated or location is in auth flow
        if (!checkAuth() && location.pathname != '/' && location.pathname != '/callback'){
            redirectToLogin();
        }
        else {
            let groups = await checkGroups();  
            // CHECK ACTIVE FLAG HERE 
            if (checkAuth() && !groups['Active'] && location.pathname != '/ErrorPage') {
                window.location.replace(window.location.origin + '/ErrorPage');
            }
            //redirect non admins  if they access user inventory page
            else if (checkAuth() && location.pathname == '/UserInventoryPage' && !groups['Administrator']) {
                window.location.replace(window.location.origin + '/AdminErrorPage');
            }
            //redirect non admins  if they access user inventory entitlement page
            else if (checkAuth() && location.pathname == '/UserEntitlementPage' && !groups['Administrator']) {
                window.location.replace(window.location.origin + '/AdminErrorPage');
            }
            // redirect non admin or technichian if they access add new company page 
            else if (checkAuth() && location.pathname == '/AddNewCompanyProfilePage' && !(groups['Administrator'] || groups['DataTechnician'])){
                window.location.replace(window.location.origin + '/AdminDataTechErrorPage');
            }
        }
    }
    // re-route to login if user is not authenitcated on location change
    useEffect(() => {      
        locationCheck();         
    }, [location]);

    // useEffect with timer to refresh access and id tokens
    useEffect(() => {
        var seconds = 1000 * 300; // interval for every 5 minutes. This is the lifespan of the access and id tokens
        const interval = setInterval(() => {
            console.log('vvv--TIME--vvv'); 
            // use getTokenSession to refresh access and id tokens
            getTokenSession();                
        }, seconds);
        return () => clearInterval(interval);       
    }, []);

    const settings = {
        redirectToLogin, 
        getTokenSession,  
        sessionLogout,
        getUsername, 
        getEmail, 
        checkAdmin, 
        getAdmin,
        getDataTech, 
        getName, 
    };

    return(
        // wrap all children in auth context
        <AuthContext.Provider value={settings}>
            {/* <Routes> */}
                {props.children}
            {/* </Routes> */}
        </AuthContext.Provider>
    );
}

export {AuthRoute, AuthContext};