// create tabs object for all active tabs. returns object: {tag, index, key}
const getPageTabs = (pageTabs, addTabs = []) => {
    // put default tabs and add tabs 
    let allTabs = [...pageTabs, ...addTabs]; 
    // initialize variables for add
    let returnList = []; 
    let index = 0; // actual index of tab in list
    // iterate through allTabs 
    for (let i = 0; i < allTabs.length; i++) {
        // add tab to return list if active
        if (allTabs[i]['active'] == true) {
            // create element
            let elem = {
                ...allTabs[i], 
                index: index, 
            }; 
            // add element 
            returnList.push(elem); 
            // iterate actual index
            index = index + 1; 
        }
    }
    // return 
    return returnList; 
}

export default getPageTabs; 