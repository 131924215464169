import { useState, useEffect, useRef, useContext } from 'react';
import PageContext from '../sub-components/PageContext';
import HeaderBanner from '../sub-components/HeaderBanner';
import Navslider from '../sub-components/Navslider';
import PageInput from '../sub-components/PageInput';
import PageDropdown from '../sub-components/PageDropdown';
import OrderedDataTable from '../sub-components/OrderedDataTable';
import UserInventoryRow from '../sub-components/UserInventoryRow';
import EditUserModal from '../sub-components/EditUserModal';
import ExportExcelButton from '../sub-components/ExportExcelButton';
import AddNewUserModal from '../sub-components/AddNewUserModal';
import standardSearch from '../functions/standardSearch';
import standardFilter from '../functions/standardFilter';
import statusFilter from '../functions/statusFilter';
import applyFilters from '../functions/applyFilters';
import { HttpContext } from '../../api-services/HttpService';
import { AuthContext } from '../../auth/AuthRoute';
import SnackbarAlert from '../sub-components/SnackbarAlert';

// import temp data to display
const roleData = ['Rebate Administrator', 'Rebate Data Technician','Regular'];

const UserInventoryPage = () =>{ 
    // initialize http context 
    const {
        GET, 
    } = useContext(HttpContext);
    // set state for search filters
    const [searchValue, setSearchValue] = useState('');
    const [role, setRole] = useState('All');
    const [roleList, setRoleList] = useState(['All']);
    const [addRoleList, setAddRoleList] = useState(['--select--']);
    const [status, setStatus] = useState('All');
    const [statusList, setStatusList] = useState(['All', 'Active', 'Inactive']);
    const [roleFilterData, setRoleFilterData] = useState({}); 
    // set state for table
    const [showDataList, setShowDataList] = useState([]);
    // const [showDataListChange, setShowDataListChange] = useState(true);
    const [dataList, setDataList] = useState([]);
    const [tableHeader, setTableHeader] = useState([]);
    // set state for modal
    const [editUserModal, setEditUserModal] = useState(false);
    const [editUserRow, setEditUserRow] = useState({});
    const [addNewUserModal, setAddNewUserModal] = useState(false);
    // useRef to determine if initialRender
    const initialRender = useRef(true);
    // initialize spinner state
    const [showSpinner, setShowSpinner] = useState(false);
    // initialize snackbar state
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarColor, setSnackbarColor] = useState('info');

    //get getTokenSession function from Authorization context    
    const {
        getTokenSession, 
    } = useContext(AuthContext);

    // return true if element equals roleFilterData[val] 
    const userInventoryFilter = (el, val, filterCol) => {
        if (roleFilterData[val]){
            return roleFilterData[val] == el[filterCol]; 
        }
    }

    // get data to show in table
    const getUsers = () => { 
        setShowSpinner(showSpinner => showSpinner = true);
        return new Promise((resolve, reject) => {
            GET('/user/inv/headers', {}).then(res => {;
                console.log(res); 
                let userInventoryData = [...res.data.UserInventory.Users];
                userInventoryData.forEach(user => user.Attributes.push( {Name: 'username', Value: user.Username}, {Name: 'last_updated', Value: user.UserLastModifiedDate}));
                //pull out custom attributes
                let userAttributes = userInventoryData.map(user => 
                    user.Attributes.reduce((acc, { Name, Value }) => ({ ...acc, [Name]: Value }), {}));
                //delete unwanted attributes
                userAttributes.forEach(user => ['sub', 'identities', 'email_verified'].forEach(key => delete user[key]))
                //set datalist and show datalist
                setDataList(dataList => dataList = userAttributes);
                setShowDataList(showDataList => showDataList = userAttributes);
                setTableHeader(tableHeader => tableHeader = res['data']['GuiTableCols']);
                // set role lists 
                setRoleList(roleList => roleList = ['All', ...res['data']['RolesList']]); 
                setAddRoleList(addRoleList => addRoleList = ['--select--', ...res['data']['RolesList']]); 
                // set role object for filter function
                setRoleFilterData(roleFilterData => roleFilterData = res['data']['RoleFilterObj']); 
                //set filters
                setRole(role => role = roleList[0]);
                setStatus(status => status = statusList[0]);
                //reset spinner
                setShowSpinner(showSpinner => showSpinner = false);
                resolve(res);
            }).catch(err => {
                //reset spinner
                setShowSpinner(showSpinner => showSpinner = false);
                reject(err);
            });
        });
    }


    // function wrapper to pass row component to datatable
    const RowComponent = (row, count, tableHeader) =>{
        return( 
            <>
                <UserInventoryRow row={row} count={count} tableHeader={tableHeader} /> 
            </>
        );
    } 

    // useEffect to run on initial render
    useEffect(() => {
        getUsers();
    }, []);

    // useEffect to run list of search/filter functions on table data
    useEffect(() => { 
        // do not run on initial render
        if (!initialRender.current){
            // declare list of functions with parameters to filter/search list
            const functionList = [
                {function: statusFilter, value: status, flag: 1, cols: ['custom:Active']}, 
                {function: userInventoryFilter, value: role, flag: 1, cols: ['custom:Role']},
                {function: standardSearch, value: searchValue, flag: 0, cols: ['name']}
            ];
            // use applyFilters function to search by running all functions declared above
            let filteredList = applyFilters(dataList, functionList);
            // update showDataList
            setShowDataList(showDataList => showDataList = filteredList);
        }
        else{
            initialRender.current = false;
        }
        
    }, [status, role, searchValue]);

    // function to set snackbar state
    const snackbarAlert = (show, message, color) => {
        setSnackbarMessage(snackbarMessage => snackbarMessage = message);
        setSnackbarColor(snackbarColor => snackbarColor = color);
        setShowSnackbar(showSnackbar => showSnackbar = show);
    }

    // object to pass variables to PageContext
    const settings = {
        editUserModal, 
        setEditUserModal,
        editUserRow, 
        setEditUserRow, 
        addNewUserModal, 
        setAddNewUserModal, 
        addRoleList, 
        setAddRoleList, 
        getUsers,
        snackbarAlert,
        showSnackbar,
        snackbarColor,
        snackbarMessage,
        setShowSnackbar
    };

    return( 
        <div class='summary-page'>
            {/* wrap children so they can access PageContext variables */}
            <PageContext.Provider value={ settings }>
                {/* header and navslider present on every page */}
                <HeaderBanner indicator={1}/>
                <Navslider indicator={4} backPath='/UserAdminLanding'/>
                {/* all modals that can be activated on page. Default is hidden state */}
                <EditUserModal />
                <SnackbarAlert />
                <AddNewUserModal />
                {/* top div to filter and search table  */}
                <div class='top-label-div'>
                    <label class='top-label-text'><b>User Inventory</b></label>
                    <div class='label-text-div'>   
                        <span class='label-text-span-left'>
                            <PageDropdown tag='Status:' list={statusList} value={status} setValue={setStatus} /> 
                        </span>
                        <span class='label-text-span-mid'>
                            <PageDropdown tag={'Filter Role'} list={roleList} value={role} setValue={setRole} />
                        </span>
                        <span class='label-text-span-right'>
                            <PageInput tag='Search:' value={searchValue} setValue={setSearchValue} />
                        </span>
                    </div>
                    <br/>
                </div>
                {/* export filtered table */}
                <div class='summary-page-button-div'>
                    {/*<button class='summary-page-button-right' onClick={()=>onAddNewUserClick()}>Add New User</button>*/}
                    <ExportExcelButton tag='ccap-user-inventory' dataList={dataList} tableHeader={tableHeader}/>
                </div>
                {/* table to show filtered object array of all users */}
                <div class='data-table-div'>
                    <OrderedDataTable RowComponent={RowComponent} tableHeader={tableHeader} dataList={showDataList} showSpinner={showSpinner} numToPage={10} skeletonHeight='28vh'/>
                </div>
                <br/>
            </PageContext.Provider>
        </div>
    );
}

export default UserInventoryPage;