import { useState, useEffect, useContext, useRef } from 'react';
import PageContext from '../sub-components/PageContext';
import { AuthContext } from '../../auth/AuthRoute'; 
import HeaderBanner from '../sub-components/HeaderBanner';
import Navslider from '../sub-components/Navslider';
import { HttpContext } from '../../api-services/HttpService';
import OrderedDataTable from '../sub-components/OrderedDataTable';
import PayoutFileRow from '../sub-components/PayoutFileRow'; 
import PageCalendar from '../sub-components/PageCalendar'; 
import PayoutScheduleModal from '../sub-components/PayoutScheduleModal';
import SnackbarAlert from '../sub-components/SnackbarAlert';
import ExportExcelButton from '../sub-components/ExportExcelButton'; 
// import toDate from '../functions/toDate';
import moment from 'moment'
import arrowCircleIcon from '../../svgs/arrow-circle-right.svg'; 
import checkCircleIcon from '../../svgs/check-circle-outline.svg'; 
import PayoutFileEditModal from '../sub-components/PayoutFileEditModal'; 
import CalendarTabs from '../sub-components/CalendarTabs'; 
import Grid from '@mui/material/Grid';

// add function to data class to compare if dates are equal
Date.prototype.equalsDate = function(month, day, year) { 
    // return false if month day or year do not equal that of date
    if (this.getMonth()+1 != month){
        return false; 
    }
    if (this.getDate() != day){
        return false; 
    }
    if (this.getFullYear() != year){
        return false; 
    }
    // return true if all are equal
    return true; 
}

const PayoutSchedulePage = () => {
    // initialize http context 
    const {
        GET,  
    } = useContext(HttpContext);
    // initialize auth context
    const { 
        getAdmin, 
    } = useContext(AuthContext); 
    // initialize state for next payout date
    const [nextPayoutDate, setNextPayoutDate] = useState(null);
    const [nextPayoutData, setNextPayoutData] = useState(null); 
    // initialize state for table 
    const [payoutDataList, setPayoutDataList] = useState([]); 
    const [payoutTableHeader, setPayoutTableHeader] = useState([]); 
    const [showSpinner, setShowSpinner] = useState(false); 
    // initialize state for calendar
    const [todayDate, setTodayDate] = useState(new Date()); 
    const [scheduleTypeList, setScheduleTypeList] = useState([]); 
    const [refreshCalendar, setRefreshCalendar] = useState(false); 
    // initialize state for modal
    const [showScheduleModal, setShowScheduleModal] = useState(false); 
    const [calendarDate, setCalendarDate] = useState(new Date());
    const [scheduleDays, setScheduleDays] = useState([]);  
    const [scheduleDaysData, setScheduleDaysData] = useState([]); 
    const [scheduleLookup, setScheduleLookup] = useState([]); 
    const [calendarLoading, setCalendarLoading] = useState(false); 
    const [highlightDays, setHighlightDays] = useState([]); 
    // initialize snackbar state
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarColor, setSnackbarColor] = useState('info');
    // initialize payout status modal 
    const [showPayoutStatusModal, setShowPayoutStatusModal] = useState(false);
    const [editPayoutFileRow, setEditPayoutFileRow] = useState({}); 
    // initialize payout filter lists
    const [payoutTypeList, setPayoutTypeList] = useState([]); 
    const [portfolioList, setPortfolioList] = useState([]); 
    const [paymentMethodList, setPaymentMethodList] = useState([]); 
    // initialize state for schedule day selected
    const [selectedDateData, setSelectedDateData] = useState(null); 
    const [selectedDateFilters, setSelectedDateFilters] = useState([]); 
    // initialize state to show display type (historical or future)
    const [historical, setHistorical] = useState(false); 
    // set fmtList for excel export
    const [fmtList, setFmtList] = useState([
        {col: 'F', columnname: 'amount', float: true, fmt: '"$"#,##0.00_);[Red]\\("$"#,##0.00\\)'}, // money format
    ]); 

    // function to set snackbar state
    const snackbarAlert = (show, message, color) => {
        setSnackbarMessage(snackbarMessage => snackbarMessage = message);
        setSnackbarColor(snackbarColor => snackbarColor = color);
        setShowSnackbar(showSnackbar => showSnackbar = show);
    }

    // wrap component in function to pass to OrderedDataTable
    const PayoutFileRowComponent = (row, count, tableHeader, enableEdit) =>{
        return <PayoutFileRow row={row} count={count} tableHeader={tableHeader} enableEdit={getAdmin()}/>
    }
    
    // event handler for calendar
    const onCalendarChange = (date) => {
        // only admins can make changes to schedule
        if (getAdmin() && !scheduleDays.includes(date.getDate())){
            // set date for modal 
            setCalendarDate(calendarDate => calendarDate = date); 
            // show modal 
            setShowScheduleModal(showScheduleModal => showScheduleModal = true);     
        }
        else if (scheduleDays.includes(date.getDate())){
            // set date for modal 
            setCalendarDate(calendarDate => calendarDate = date); 
            // check whether to get historical payment data or future payment data 
            for (let i = 0; i < scheduleDaysData.length; i++){
                if (date.equalsDate(scheduleDaysData[i]['month'], scheduleDaysData[i]['day'], scheduleDaysData[i]['year'])) {
                    if (scheduleDaysData[i]['filetransferred']) {
                        // get data for payout table (historical payment)
                        getHistoricalPayoutData(date).then(res => {
                            console.log(res); 
                        }).catch(err => {
                            console.log(err); 
                        }); 
                    }
                    else {
                        // get data for payout table (future payment)
                        getPayoutData(date).then(res => {
                            console.log(res); 
                        }).catch(err => {
                            console.log(err); 
                        }); 
                    }
                    // break out of loop once request is made
                    break; 
                }
            }
            
        }
    }

    // get days already schedules
    const getScheduleForMonth = (date) => {
        return new Promise((resolve, reject) => {
            setCalendarLoading(calendarLoading => calendarLoading = true); 
            GET('/data/payoutSchedulePage/month', {
                year: date.getFullYear(), 
                month: date.getMonth()+1, 
                date: date, 
            }).then(res => {
                console.log(res); 
                // set state 
                setScheduleDays(scheduleDays => scheduleDays = res['data']['DaysInMonthList']); 
                setScheduleDaysData(scheduleDaysData => scheduleDaysData = res['data']['DaysInMonthData']); 
                setScheduleLookup(scheduleLookup => scheduleLookup = res['data']['ScheduleData']); 
                setHighlightDays(highlightDays => highlightDays = res['data']['DaysInMonthList']); 
                // set iconList for response
                let iconList = []; 
                for (let i = 0; i < res['data']['DaysInMonthList'].length; i++){
                    for (let k = 0; k < res['data']['DaysInMonthData'].length; k++){
                        // check that comparing filetransferred of correct day to insure indexs will line up
                        if (res['data']['DaysInMonthList'][i] == res['data']['DaysInMonthData'][k]['day']){
                            // if file transferred icon should be circle with check
                            if (res['data']['DaysInMonthData'][k]['filetransferred'] == true){
                                iconList.push(checkCircleIcon); 
                            }
                            // if file not transferred show circle with arrow
                            else {
                                iconList.push(arrowCircleIcon);  
                            }
                        }
                    }
                }
                // hide spinner 
                setCalendarLoading(calendarLoading => calendarLoading = false); 
                // resolve on success
                resolve({
                    days: res['data']['DaysInMonthList'], 
                    icons: iconList, 
                }); 
            }).catch(err => {
                // hide spinner 
                setCalendarLoading(calendarLoading => calendarLoading = false); 
                // reject on error
                reject(err); 
            }); 
        })
        
    }

    // function to make initial get request 
    const getPayoutData = (date) => {
        return new Promise((resolve, reject) => {
            // show spinner while waiting for request response
            setShowSpinner(showSpinner => showSpinner = true); 
            // make GET request 
            GET('/data/payoutSchedulePage', {
                date: date
            }).then(res => {
                // set display type
                setHistorical(historical => historical = false); 
                // set table state
                setPayoutDataList(payoutDataList => payoutDataList = res['data']['PayoutFileData']); 
                setPayoutTableHeader(payoutTableHeader => payoutTableHeader = res['data']['PayoutFileCols']);
                // set calendar state
                setScheduleTypeList(scheduleTypeList => scheduleTypeList = res['data']['ScheduleTypeList']); 
                // set next payout date state
                if (res['data']['NextPayoutDate']){
                    setNextPayoutDate(nextPayoutDate => nextPayoutDate = new Date(res['data']['NextPayoutDate']['formatteddate'])); 
                    setNextPayoutData(nextPayoutData => nextPayoutData = res['data']['NextPayoutDate']); 
                } 
                // set schedule filter lists
                if (res['data']['PayoutScheduleFilters'][0]){
                    setPayoutTypeList(payoutTypeList => payoutTypeList = res['data']['PayoutScheduleFilters'][0]); 
                }
                if (res['data']['PayoutScheduleFilters'][1]){
                    setPortfolioList(portfolioList => portfolioList = res['data']['PayoutScheduleFilters'][1]); 
                }
                if (res['data']['PayoutScheduleFilters'][2]){
                    setPaymentMethodList(paymentMethodList => paymentMethodList = res['data']['PayoutScheduleFilters'][2]); 
                }
                // set state for selected day tab
                setSelectedDateData(selectedDateData => selectedDateData = res['data']['SelectedDateData'][0]); 
                setSelectedDateFilters(selectedDateFilters => selectedDateFilters = res['data']['SelectedDateFilters']); 
                // hide spinner for table 
                setShowSpinner(showSpinner => showSpinner = false);  
                // resolve on success
                resolve(res); 
            }).catch(err => {
                // hide spinner for table 
                setShowSpinner(showSpinner => showSpinner = false); 
                // reject on error
                reject(err); 
            })
        }); 
    }

    // get payment data for historical payments that have already been transferred
    const getHistoricalPayoutData = (date) => {
        return new Promise((resolve, reject) => {
            // show spinner while waiting for request response
            setShowSpinner(showSpinner => showSpinner = true); 
            // make GET request 
            GET('/data/payoutSchedulePage/historical', {
                date: date
            }).then(res => {
                // set display type 
                setHistorical(historical => historical = true); 
                // set table state
                setPayoutDataList(payoutDataList => payoutDataList = res['data']['PayoutFileData']); 
                setPayoutTableHeader(payoutTableHeader => payoutTableHeader = res['data']['PayoutFileCols']);
                // set next payout date state
                setNextPayoutDate(nextPayoutDate => nextPayoutDate = new Date(res['data']['FileTransferredDate']['formatteddate'])); 
                setNextPayoutData(nextPayoutData => nextPayoutData = res['data']['FileTransferredDate']); 
                // set state for selected day tab
                setSelectedDateData(selectedDateData => selectedDateData = res['data']['SelectedDateData'][0]); 
                setSelectedDateFilters(selectedDateFilters => selectedDateFilters = res['data']['SelectedDateFilters']); 
                // hide spinner for table 
                setShowSpinner(showSpinner => showSpinner = false);  
                // resolve on success
                resolve(res); 
            }).catch(err => {
                // hide spinner for table 
                setShowSpinner(showSpinner => showSpinner = false); 
                // reject on error
                reject(err); 
            }); 
        }); 
    }

    // useEffect to run on initial render
    useEffect(() => {
        // get todays date
        const today = new Date(); 
        // get data for payout table 
        getPayoutData(today).then(res => {
            console.log(res); 
        }).catch(err => {
            console.log(err); 
        }); 
    }, []); 

    // settings for PageContext
    const settings = {
        showScheduleModal, 
        setShowScheduleModal, 
        calendarDate, 
        setCalendarDate, 
        scheduleDays, 
        scheduleTypeList, 
        showSnackbar, 
        setShowSnackbar, 
        snackbarMessage, 
        setSnackbarMessage, 
        snackbarColor, 
        setSnackbarColor, 
        snackbarAlert, 
        getPayoutData, 
        getScheduleForMonth, 
        refreshCalendar, 
        setRefreshCalendar,
        scheduleDaysData, 
        setScheduleDaysData,
        scheduleLookup, 
        setScheduleLookup,
        showPayoutStatusModal, 
        setShowPayoutStatusModal,
        editPayoutFileRow, 
        setEditPayoutFileRow,
        payoutTypeList, 
        portfolioList, 
        paymentMethodList,
        selectedDateData, 
        setSelectedDateData, 
        selectedDateFilters, 
        setSelectedDateFilters, 
        highlightDays, 
        setHighlightDays, 
        nextPayoutData, 
        nextPayoutDate, 
        historical, 
        showSpinner, 
        payoutTableHeader, 
    }; 

    return(
        <div class='summary-page'>
            {/* wrap children so they have access to variables in PageContext */}
            <PageContext.Provider value={ settings }>
                {/* header banner and navslider present on every page */}
                <HeaderBanner indicator={1}/>
                <Navslider indicator={-1} backPath='/RebateWorkflowLanding'/>
                {/* modals */}
                <PayoutScheduleModal/>
                <PayoutFileEditModal/>
                {/* snackbar component for alerts */}
                <SnackbarAlert />
                {/* top label */} 
                <div class='top-label-div'>
                    <label class='top-label-text'><b>Payout Schedule </b></label>
                </div> 
                <br />
                {/* <div class='data-table-div' style={{marginBottom: '5px', display: 'block', alignContent: 'center'}}>
                    <PageCalendar initialDate={todayDate} onChange={onCalendarChange} getHighlightDays={getScheduleForMonth} refreshCalendar={refreshCalendar}/>
                    <CalendarTabs />               
                </div> */}
                <Grid container sx={{margin: 'auto', width: '80%'}} >
                    <Grid item xs={4} >
                        <PageCalendar initialDate={todayDate} onChange={onCalendarChange} getHighlightDays={getScheduleForMonth} refreshCalendar={refreshCalendar}/>
                    </Grid>
                    <Grid item xs={8} paddingRight={2} paddingLeft={2}>
                        <CalendarTabs />               
                    </Grid>
                </Grid>
                <br/><br/>
                <div class='data-table-div'>
                    <span class='label-text-span-left' style={{width: '500px'}}>
                        {historical ? 
                        <label class='table-label'><b>Payouts transferred {nextPayoutDate ? 'on ' + moment(nextPayoutDate).format('MM-DD-YYYY') + ' at 5pm': ''} </b></label>
                        :<label class='table-label'><b>Payouts elidgible for file transfer {nextPayoutDate ? 'on ' + moment(nextPayoutDate).format('MM-DD-YYYY') + ' at 5pm': ''} </b></label>}
                    </span>
                    <span style={{float: 'right'}}>
                        <ExportExcelButton tag={'Payout_File'} dataList={payoutDataList} tableHeader={payoutTableHeader} formatList={fmtList}/>
                    </span>
                    <br /><br />
                    <OrderedDataTable RowComponent={PayoutFileRowComponent} dataList={payoutDataList} tableHeader={payoutTableHeader} showSpinner={showSpinner} numToPage={25} skeletonHeight='28vh'/>
                </div>
            </PageContext.Provider>
        </div>
    ); 
}

export default PayoutSchedulePage;