import { useContext, useState, useEffect } from 'react';
import { Modal } from "react-bootstrap";
import PageContext from './PageContext';
import AutoCompleteInput from './AutoCompleteInput';
import PageInput from './PageInput';
// import Checkbox from '@mui/material/Checkbox';
// import FormControlLabel from '@mui/material/FormControlLabel';
import { HttpContext } from '../../api-services/HttpService';
import isNumeric from '../functions/isNumeric';
import ApiRequestButton from './ApiRequestButton';

const AddAdhocPaymentModal = ({ refreshFunction }) => {
    // initialize PageContext
    const {
        showAddAdhocModal,
        setShowAddAdhocModal,
        subCompanyList,
        masterCompanyId,
        snackbarAlert
    } = useContext(PageContext);
    // initialize HttpContext
    const {
        POST,
    } = useContext(HttpContext);
    // initialize state
    const [subCompany, setSubCompany] = useState(null);
    const [amount, setAmount] = useState(0);
    const [masterPayment, setMasterPayment] = useState(false);
    const [companyList, setCompanyList] = useState([]);

    // event handler for checkbox click
    const onCheckboxClick = (e) => {
        setMasterPayment(masterPayment => masterPayment = e.target.checked)
        // set sub company to place
        if (e.target.checked == true) {
            setSubCompany(subCompany => subCompany = process.env.REACT_APP_MASTER_PAYOUT_INFO_PLACEHOLDER);
        }
        else {
            setSubCompany(subCompany => subCompany = null);
        }
    }

    // event handler for submit click 
    const onSubmitClick = () => {
        return new Promise((resolve, reject) => {
            let response; 
            // validate amount is numeric 
            if (!isNumeric(amount.replaceAll(",", ""))) {
                snackbarAlert(true, 'Invalid Payment Amount.', 'error');
                reject(); 
                return;
            }
            // validate sub company
            if (!subCompanyList.includes(subCompany) && subCompany != process.env.REACT_APP_MASTER_PAYOUT_INFO_PLACEHOLDER) {
                snackbarAlert(true, 'Invalid Sub Company.', 'error');
                reject(); 
                return;
            }
            // make request 
            POST('/data/adhoc/adhocpage', {
                mastercompanyid: masterCompanyId,
                companyid: subCompany,
                amount: amount.replaceAll(",", ""),
            }).then(res => {
                console.log(res);
                // hide modal 
                setShowAddAdhocModal(showAddAdhocModal => showAddAdhocModal = false);
                // trigger success alert
                snackbarAlert(true, 'Successfully uploaded data.', 'success');
                // refresh data on page
                return refreshFunction();
            }).then(res => {
                console.log(res);
                // resolve on success
                resolve(response); 
            }).catch(err => {
                console.log(err);
                // show alert on error
                snackbarAlert(true, 'Error uploading data.', 'error');
                // reject on error
                reject(err); 
            });
        }); 
    }

    // useEffect to clear subCompany and amount when subCompanyList changes
    useEffect(() => {
        setSubCompany(subCompany => subCompany = null);
        setAmount(amount => amount = 0);
        // set sub company list while removing 'All' if present 
        let index = subCompanyList.indexOf('All');
        if (index != -1) {
            setCompanyList(companyList => companyList = [...subCompanyList.slice(0, index), ...subCompanyList.slice(index + 1, subCompanyList.length)]);
        }
        else {
            setCompanyList(companyList => companyList = subCompanyList);
        }
    }, [subCompanyList]);

    return (
        <>
            <Modal className="rebate-summary-modal"
                show={showAddAdhocModal}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                scrollable={true}
            >
                <Modal.Header closeButton onClick={() => setShowAddAdhocModal(showAddAdhocModal => showAddAdhocModal = false)}>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Add Ad-Hoc Payment
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    {/* <div class='modal-div'>
                        <FormControlLabel
                            value="end"
                            control={<Checkbox
                                sx={{
                                    color: '#000000',
                                    '&.Mui-checked': {
                                        color: '#000000',
                                    },
                                }} />}
                            label="Add Payment to Master Company"
                            labelPlacement="end"
                            sx={{
                                color: 'black',
                            }}
                            checked={masterPayment}
                            onChange={(e) => onCheckboxClick(e)}
                        />
                    </div>
                    <br /> */}
                    {!masterPayment ?
                        <>
                            <div class='modal-div'>
                                <AutoCompleteInput tag='Sub Company' value={subCompany} setValue={setSubCompany} list={companyList} />
                            </div>
                            <br />
                        </>
                        : <></>}
                    <div class='modal-div'>
                        <PageInput tag='Payment Amount: ' value={amount} setValue={setAmount} />
                    </div>
                    <br />
                    <ApiRequestButton onButtonClick={onSubmitClick} />
                    <br /><br />
                </Modal.Body>
            </Modal>
        </>
    );
}

export default AddAdhocPaymentModal;