import isEmptyString from './isEmptyString'; 

const subCompanyValidationChecksWithError = ( input, editBillingRate, snackbarAlert ) => {
    console.log(input); 
    // check that companyname is not empty
    if (input.companyname == null || isEmptyString(input.companyname)){
        snackbarAlert(true, 'Invalid Sub Company Name.', 'error'); 
        return 'companyname'; 
    }
    // check companyid is not empty
    if (input.companyid == null || isEmptyString(input.companyid)){
        snackbarAlert(true, 'Invalid Sub Company ID.', 'error'); 
        return 'companyid'; 
    }
    // check that billing rate description is not empty
    if (editBillingRate == true && input.billingratedescription == '--select--'){
        snackbarAlert(true, 'Invalid Billing Rate.', 'error'); 
        return 'billingratedescription'; 
    }
    // return null on no error
    return; 
}

export default subCompanyValidationChecksWithError; 