import { Link } from 'react-router-dom';
import HeaderBanner from '../sub-components/HeaderBanner';
import Navslider from '../sub-components/Navslider';
import historyIcon from '../../svgs/history-icon.svg';
import reportIcon from '../../svgs/report-icon.svg';
import RebateTrackingWidgets from '../sub-components/RebateTrackingWidgets'; 

const RebateWorkflowLanding = () =>{

    return(
        <div class='page'>
            <HeaderBanner indicator={1}/>
            <Navslider indicator={2} backPath='/MainLanding'/>

            { process.env.REACT_APP_REBATE_PAYOUT_SCHEDULER == '1' ?
            <>
                <div class='landing-three-div'>  
                    <Link
                        to={{
                            pathname: "/RebateSummaryPage",
                        }}
                        ><button class='landing-button'>
                            <img src={historyIcon} class="tile-icon"/>
                            <br/><br/>
                            Rebate <br/> Summary
                            <br/><br/>
                            <button class='landing-tile-button'>View</button>
                        </button>
                    </Link>
                    <Link
                        to={{
                            pathname: "/AdhocPaymentPage",
                        }}
                        ><button class='landing-button'>
                            <img src={reportIcon} class="tile-icon"/>
                            <br/><br/>
                            Payment <br/> History
                            <br/><br/>
                            <button class='landing-tile-button'>View</button>
                        </button>
                    </Link>
                    <Link
                        to={{
                            pathname: "/PayoutSchedulePage",
                        }}
                        ><button class='landing-button'>
                            <img src={reportIcon} class="tile-icon"/>
                            <br/><br/>
                            Payout <br/> Schedule
                            <br/><br/>
                            <button class='landing-tile-button'>View</button>
                        </button>
                    </Link>
                </div>
            </>
            :<>
                <div class='landing-two-div'>  
                    <Link
                        to={{
                            pathname: "/RebateSummaryPage",
                        }}
                        ><button class='landing-button'>
                            <img src={historyIcon} class="tile-icon"/>
                            <br/><br/>
                            Rebate <br/> Summary
                            <br/><br/>
                            <button class='landing-tile-button'>View</button>
                        </button>
                    </Link>
                    <Link
                        to={{
                            pathname: "/AdhocPaymentPage",
                        }}
                        ><button class='landing-button'>
                            <img src={reportIcon} class="tile-icon"/>
                            <br/><br/>
                            Payment <br/> History
                            <br/><br/>
                            <button class='landing-tile-button'>View</button>
                        </button>
                    </Link>
                </div>
            </>}
            <br />
            { process.env.REACT_APP_REBATE_TRACKING_WIDGETS == '1' ? 
            <div>
                <RebateTrackingWidgets />
                <br />
                <br />
                <br />
            </div>
            :<></>}
        </div>
    );
}

export default RebateWorkflowLanding;