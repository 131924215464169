import { useContext } from 'react';
import PageContext from './PageContext';
import greenCircleIcon from '../../svgs/green-circle-icon.svg';
import redCircleIcon from '../../svgs/red-circle-icon.svg';
import RowEditIcon from './RowEditIcon';

const PayoutInfoRow = ({ row, count, tableHeader, enableEdit }) => {
    // get context variables from parent
    const {
        editPayoutInfoModal, 
        setEditPayoutInfoModal, 
        editPayoutInfoRow, 
        setEditPayoutInfoRow, 
    } = useContext(PageContext);

    // event handler for edit click 
    const onEditClick = () => {
        setEditPayoutInfoRow(editPayoutInfoRow => editPayoutInfoRow = row);
        setEditPayoutInfoModal(editPayoutInfoModal => editPayoutInfoModal = true);
    }

    // show red icon for disabled (false) status or green icon for Active (true) status 
    const statusIcon = (status) =>{
        if ( status == true){
            return <img src={greenCircleIcon} class='data-table-icon-left'/>
        }
        else {
            return <img src={redCircleIcon} class='data-table-icon-left'/>
        }
    }

    return (
        <>
            {/* map logic over all elements of row */}
            {tableHeader.map(el => 
                // show circles for status 
                el['columnname'] == 'active' ?
                    <td>{statusIcon(row[el['columnname']])}</td>
                // if allowEdit flag is true show edit icon to activate EditRebateSetupModal 
                : el['columnname'] == 'blank' ?
                    <td>
                        {/* show edit icon to trigger EditSubCompanyRow modal */}                      
                        <RowEditIcon enable={enableEdit} onClick={onEditClick}/>
                    </td>
                : el['columnname'] == 'companyid' ?
                <>
                    {row[el['columnname']] == process.env.REACT_APP_MASTER_PAYOUT_INFO_PLACEHOLDER ? 
                    <td class='table-col'>MASTER</td>
                    :<td>{row[el['columnname']]}</td>}
                </>
                // show None if cell is null
                : row[el['columnname']] == null ?
                    <td class='table-col'>None</td>
                // standard display for all other columns 
                :<td>{row[el['columnname']]}</td>
            )}
            
        </>
    );
}

export default PayoutInfoRow; 