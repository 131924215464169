import moneyFormat from '../functions/moneyFormat';

// pass row object as prop 
const TransactionDataRow = ({ row, count, tableHeader }) =>{
    return(
        <>
            {/* map logic over all columns in object */}
            {tableHeader.map(el => 
                // apply money format to correct columns  
                el['columnname'] == 'transactionamount' || el['columnname'] == 'interchangeamount' ?
                    row[el['columnname']] == null ?
                    <td class='table-col'>None</td>
                    :<td>{moneyFormat(row[el['columnname']])}</td>
                // standard display for all other columns 
                : row[el['columnname']] == null ?
                    <td class='table-col'>None</td>
                // standard display for all other columns 
                :<td>{row[el['columnname']]}</td>          
            )}
        </>
        
    );
}

export default TransactionDataRow; 