import { useContext, useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from 'redux';
import { actionCreators } from "../../redux/index";
import { Link } from 'react-router-dom';
import PageContext from './PageContext';
import pencilIcon from '../../svgs/pencil-icon.svg';
import magnifyIcon from '../../svgs/magnifying-glass-icon.svg';
import commentIcon from '../../svgs/comment-icon.svg';
import moneyFormat from '../functions/moneyFormat';
import PageTooltip from './PageTooltip'; 

// pass row object 
const RebateSummaryRow = ({ row, count, tableHeader }) =>{
    // get redux persistant state from redux store 
    const company = useSelector((state) => state.company);
    const companyInfo = useSelector((state) => state.companyInfo);
    const dispatch = useDispatch();
    const { setCompany, setCompanyInfo } = bindActionCreators(actionCreators, dispatch);
    // get context variables from parent 
    const {
        editRowModal, 
        setEditRowModal, 
        commentModal, 
        setCommentModal, 
        trailModal, 
        setTrailModal,
        editRow, 
        setEditRow,
        checkAll, 
        setCheckAll, 
        workflowActionCheckedList, 
        setWorkflowActionCheckedList,
        checkBoxChange,
        showDataList, 
        workflowAction, 
        setWorkflowAction,
        workflowActionStatus, 
        userRole,
        setWorkflowActionStatus,
        workflowStatus, 
    } = useContext(PageContext);

    // initialize state 
    const [checked, setChecked] = useState(false);
    const [showCheckbox, setShowCheckbox] = useState(false); 

    // event handler for comment icon click 
    const onCommentClick = () =>{
        // set edit row to be used in modal component
        setEditRow(editRow => editRow = row);
        // make modal visible using state variable from context 
        setCommentModal(commentModal => commentModal = true);
    }

    // event handler for trail icon click 
    const onTrailClick = () =>{
        // set edit row to be used in modal component
        setEditRow(editRow => editRow = row);
        // make modal visible using state variable from context 
        setTrailModal(trailModal => trailModal = true);
    }

    // event handler for checkbox click 
    const onCheckboxClick = () =>{
        if (checked == false){
            // mark box with check using state variable 
            setChecked(checked => checked = true);
            // add from workflow action checked list
            setWorkflowActionCheckedList( workflowActionCheckedList => workflowActionCheckedList = [...workflowActionCheckedList, row]);   
        }
        else {
            // clear check from box using state variable 
            setChecked(checked => checked = false);
            // remove from workflow action checked list
            var index = workflowActionCheckedList.indexOf(row);
            setWorkflowActionCheckedList( workflowActionCheckedList => workflowActionCheckedList = 
                                [...workflowActionCheckedList.slice(0, index), ...workflowActionCheckedList.slice(index + 1)]);
            // if checkAll is true make false and block change to other sub components
            if (checkAll == true){
                setCheckAll(checkAll => checkAll = false);
            }
        }
       
    }

    // event handler when company link is clicked 
    const onCompanyClick = (e) =>{
        // set company state to bring to new route (CompanyPagoutViewPage)
        setCompany(e.target.innerText.toString());
        setCompanyInfo(row);
    }

    //  show status color
    const showStatus = (value) => {
        // set color based on value
        let color = '#4472C4'; 
        let textColor = 'white'
        if (value == 'Pending'){
            color = 'yellow'; 
            textColor = 'black'; 
        }
        else if (value == 'Rejected'){
            color = '#F63812'; 
            textColor = 'white'; 
        }
        else if (value == 'Initial Review'){
            color = '#00B0F0'; 
            textColor = 'white'; 
        }
        else if (value == 'Final Review'){
            color = '#4472C4'
            textColor = 'white'; 
        }
        else if (value == 'Accepted'){
            color = 'green';
            textColor = 'white'; 
        }
        // show text with color
        return <text style={{
            backgroundColor: color, 
            color: textColor, 
            borderRadius: '15px', 
            padding: '5px', 
        }}>
            {value}
        </text>;
    }

    // check box if status is that for the workflow action on check all
    useEffect(() => {
        if(row['status'] == workflowActionStatus){
            setChecked(checked => checked = checkAll);  
        }       
    }, [checkBoxChange]);

    // only have box checked if it is in workflowActionCheckedList
    useEffect(() => {
        if (workflowActionCheckedList.includes(row) && row['status'] == workflowActionStatus){
            setChecked(checked => checked = true);
        }
        else{
            setChecked(checked => checked = false);
        }
    }, [row, workflowAction]);

    // useEffect to determine visibility of checkbox
    useEffect(() => {
        if (workflowActionStatus == row['status'] && userRole == row['userroleid']) {
            setShowCheckbox(showCheckbox => showCheckbox = true); 
        }
        else {
            setShowCheckbox(showCheckbox => showCheckbox = false); 
        }
    }, [workflowActionStatus, userRole, row]); 

    return(
        <>
            {/* map logic over all columns in row object */}
            {tableHeader.map(el => 
                el['columnname'] == 'mastercompanyname' ?
                    <td>
                        <Link onClick={(e)=>onCompanyClick(e)}
                            to={{
                                pathname: "/CompanyPayoutViewPage",
                            }}>
                            {row[el['columnname']]}
                        </Link>
                    </td>                    
                : el['columnname'] == 'grossspend' || el['columnname'] == 'grossinterchange' || el['columnname'] == 'incentiveamount' ? 
                    <> 
                        {row[el['columnname']] == null ?
                            <td>None</td>
                        :<td>{moneyFormat(row[el['columnname']])}</td>}
                    </>
                : el['columnname'] == 'blank' ? 
                    <td> 
                        <PageTooltip title='comment'>
                            <img src={commentIcon} className='data-table-icon' onClick={(e)=>onCommentClick(e)}/> 
                        </PageTooltip>
                        <PageTooltip title='trail'>
                            <img src={magnifyIcon} className='data-table-icon' onClick={(e)=>onTrailClick(e)}/>
                        </PageTooltip>
                    </td>
                : el['columnname'] == 'checkbox' ?
                    <td>
                        { showCheckbox ?
                            <input type="checkbox" checked={checked} onChange={(e)=>onCheckboxClick(e)}/>
                            // else show nothing 
                            :<></>}
                    </td>
                : el['columnname'] == 'status' ? 
                <td>
                    {showStatus(row[el['columnname']])}
                </td>
                // standard display for other columns 
                : row[el['columnname']] == null ?
                    <td>None</td>
                : <td>{row[el['columnname']]}</td>
            )}
        </>
    );
}

export default RebateSummaryRow;