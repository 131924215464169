import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import FormControl from '@mui/material/FormControl';
import Skeleton from '@mui/material/Skeleton';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const MultiSelectAutocomplete = ({tag, values, setValues, list, width, font, disableEdit, isLoading}) => {
    // set width if not passed as prop
    if (width == null){
        width = '16.6vw';
    }
    // set font size if given as prop, else use default
    let style = {};
    if (font){ 
        style['fontSize'] = font; 
    }

    // handler for change event 
    const onChange = (e, value) => { 
        setValues(values => values = value); 
    }

    // function to check that two objects are equal
    const checkEqual = (value1, value2) => {
        // compare objects (keys must be in same order)        
        return  JSON.stringify(value1) === JSON.stringify(value2) ; 
    }

    return (
        <>
        { isLoading ? 
            <>
                <Skeleton variant="rectangular" width='100%' height={'40px'} sx={{ borderRadius: '5px' }}/>
            </>
            : 
            <FormControl fullWidth size='small' sx={{ width: width, minWidth: 210, maxWidth: 450 }}>
                <Autocomplete
                    readOnly={disableEdit}
                    size='small'
                    multiple
                    id="checkboxes-tags-demo"
                    value={values}
                    options={list}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.label}
                    renderOption={(props, option, { selected }) => (
                        <li {...props}>
                        <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                        />
                        {option.label}
                        </li>
                    )}
                    // style={{ width: width }}
                    renderInput={(params) => (
                        <TextField 
                            {...params} 
                            style={style} 
                            InputProps={{ ...params.InputProps, style: style }} 
                            InputLabelProps={{style: style}} 
                            label={tag}  
                        />
                    )}
                    onChange={onChange}
                    isOptionEqualToValue={checkEqual}
                />
            </FormControl>}
        </>
    );
}

export default MultiSelectAutocomplete