import { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { Modal } from "react-bootstrap";
import PageContext from "./PageContext";
import DatePicker from './DatePicker'
import PageInput from './PageInput';
import PageDropdown from './PageDropdown';
import { HttpContext } from '../../api-services/HttpService';
import removeStrCommas from '../functions/removeStrCommas';
import performanceInputValidationCheck from '../functions/performanceInputValidationCheck';
import ApiRequestButton from './ApiRequestButton';

const AddPerformanceBonusModal = () => {
    // get context from parent
    const {
        addPerformanceBonusModal, 
        setAddPerformanceBonusModal, 
        masterCompanyData,
        getCompanyDetailsData,  
        snackbarAlert, 
    } = useContext(PageContext);
    // initialize http context 
    const {
        POST, 
    } = useContext(HttpContext);
    // get persistant redux global state from store
    const company = useSelector((state) => state.company);
    // initialize state
    const [status, setStatus] = useState('Active');
    const [statusList, setStatusList] = useState(['Active', 'Inactive']);
    const [spendByDate, setSpendByDate] = useState(new Date());
    const [spendCommitment, setSpendCommitment] = useState('');
    const [bonusAmount, setBonusAmount] = useState('');
    // const [setupId, setSetupId] = useState('');

    // event handler for submit button click
    const onSubmitClick = () => {
        return new Promise((resolve, reject) => {
            let response; 
            const input = {
                // incentivesetupid: setupId, 
                spendbydate: spendByDate, 
                spendcommitment: spendCommitment, 
                bonusamount: bonusAmount, 
                mastercompanyid: masterCompanyData['mastercompanyid'],  
                incentiveid: 1, 
            }
            // make sure that inputs are valid
            if (!performanceInputValidationCheck(input, snackbarAlert)){
                reject(); 
                return;
            }
            // set active arg from status
            let active = false; 
            if (status == 'Active') {
                active = true;
            }
            // make POST request 
            POST('/data/performanceBonus', { 
                // incentivesetupid: setupId, 
                active: active, 
                spendbydate: spendByDate, 
                spendcommitment: removeStrCommas(spendCommitment), 
                bonusamount: removeStrCommas(bonusAmount), 
                mastercompanyid: masterCompanyData['mastercompanyid'],  
                incentiveid: 1, 
            }).then(res => {
                console.log(res);
                response(res); 
                // hide modal 
                setAddPerformanceBonusModal(addPerformanceBonusModal => addPerformanceBonusModal = false);
                // default all values on success
                setStatus(status => status = 'Active'); 
                setSpendByDate(spendByDate => spendByDate = new Date()); 
                setSpendCommitment(spendCommitment => spendCommitment = ''); 
                setBonusAmount(bonusAmount => bonusAmount = ''); 
                // setSetupId(setupId => setupId = ''); 
                // trigger success alert
                snackbarAlert(true, 'Successfully uploaded data.', 'success');
                // refresh CompanyDetailsPage using function from parent
                return getCompanyDetailsData(company);
            }).then(res => {
                console.log(res); 
                // resolve on success
                resolve(response); 
            }).catch(err => {
                console.log(err);
                // show alert on error
                snackbarAlert(true, 'Error uploading data.', 'error');
                // reject on error
                reject(err); 
            });
        }); 
    }

    return(
        <> 
            <Modal className="rebate-summary-modal"
                show={addPerformanceBonusModal}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                scrollable={true}
                >
                <Modal.Header closeButton onClick={()=>setAddPerformanceBonusModal(addPerformanceBonusModal => addPerformanceBonusModal = false)}>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Add Performance Bonus Tier
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <div class='modal-div'>
                        <PageDropdown tag='Status: ' value={status} setValue={setStatus} list={statusList}/>
                    </div>
                    <br/>
                    {/* <div class='modal-div'>
                        <PageInput tag='Setup ID: ' value={setupId} setValue={setSetupId} />
                    </div>
                    <br/> */}
                    <div class='modal-div'>
                        <DatePicker tag='Spend By Date: ' value={spendByDate} setValue={setSpendByDate} disable={null} indicator={0}/>
                    </div>
                    <br/>
                    <div class='modal-div'>
                        <PageInput tag='Spend Commitment: ' value={spendCommitment} setValue={setSpendCommitment} />
                    </div>
                    <br/>
                    <div class='modal-div'>
                        <PageInput tag='Bonus Amount: ' value={bonusAmount} setValue={setBonusAmount} />
                    </div>
                    {/* button to submit edits to rebate setup */}
                    <ApiRequestButton onButtonClick={onSubmitClick} />
                </Modal.Body>
            </Modal>
        </>
    );
}

export default AddPerformanceBonusModal; 