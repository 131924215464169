import moneyFormat from '../functions/moneyFormat';

// pass row object as prop
const AdditionalDetailsRow = ({ row, count, tableHeader }) =>{
    return(
        <>
            {/* map display over all columns of row component */}
            {tableHeader.map( el => 
                (el['columnname'] == 'bonusamount' || el['columnname'] == 'spendcommitment') && row[el['columnname']] != null ? 
                    <td>{moneyFormat(row[el['columnname']])}</td>
                // standard display for other columns 
                : row[el['columnname']] == null ?
                    <td>None</td>
                : <td>{row[el['columnname']]}</td>
            )}
        </>
    );
}

export default AdditionalDetailsRow; 