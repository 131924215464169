import PageTooltip from './PageTooltip';
import pencilIcon from '../../svgs/pencil-icon.svg';

const RowEditIcon = ({ enable, onClick }) => {
    return (
        <>
            { enable ? 
            <>
                {/* show edit icon to trigger edit modal */}
                <PageTooltip title='edit'>
                    <img src={pencilIcon} className='data-table-icon' onClick={(e)=>onClick(e)}/>
                </PageTooltip>
            </>
            // :<div style={{color: 'transparent', font: '1px'}}>-</div>}
            :<></>}
        </>
    );
}

export default RowEditIcon;