import { useEffect, useState } from 'react'; 
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

const PageTabs = ({ value, setValue, tabList }) => {
    // state for tab index
    const [tabIndex, setTabIndex] = useState(0); 
    // event handler for tab change
    const handleChange = (e, newVal) =>{; 
        setValue(value => value = tabList[newVal]['key']);
        setTabIndex(tabIndex => tabIndex = newVal)
    }

    useEffect(() => {
        console.log(value); 
    }, [value]); 

    return(
        // material ui tabs component
        <Tabs
        value={tabIndex}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs example"
        >
            {tabList.map( el => 
                <Tab label={el['tag']}/>
            )}
        </Tabs>
    );
}

export default PageTabs