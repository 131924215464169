import TextField from '@mui/material/TextField';
import Skeleton from '@mui/material/Skeleton';

const PageInput = ({ tag, value, width, font, isLoading }) =>{
    // set width if not given as prop
    if (width == null){
        width = '16.6vw';
    } 
    // set font size if given as prop, else use default
    let style = {};
    if (font){
        style['fontSize'] = font;
    }
    return (
        <>
            { isLoading ? 
            <>
                <Skeleton variant="rectangular" width='100%' height={'40px'} sx={{ borderRadius: '5px' }}/>
            </>
            : 
            /* material ui textfield for uneditable text display */
            <TextField 
                id="outlined-basic" 
                sx={{ width: width, maxWidth: 450 }}
                size='small' 
                label={tag} 
                variant="outlined" 
                value={value} 
                inputProps={{style: style, readOnly: true}} // font size of input text
                InputLabelProps={{style: style}} // font size of input label
            />} 
        </>
    );
}

export default PageInput;