import { useContext, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import PageContext from './PageContext';
import WorkflowCommentLog from '../sub-components/WorkflowCommentLog';
import PageReadOnlyInput from '../sub-components/PageReadOnlyInput';
import { HttpContext } from '../../api-services/HttpService';
//import { AuthContext } from '../../auth/AuthRoute';

const RebateCommentModal = () =>{
    // get context variables from parent
    const {
        commentModal, 
        setCommentModal, 
        editRow,   
        setEditRow,
    } = useContext(PageContext); 
    // initialize http context 
    const {
        GET, 
    } = useContext(HttpContext);

    //get tokens from auth context 
    /*const {
        getTokenSession
    } = useContext(AuthContext);*/


    // global redux state for company (passed in from other route)
    const company = useSelector((state) => state.company);
    const companyInfo = useSelector((state) => state.companyInfo);
    // initialize state
    const [commentText, setCommentText] = useState('');

    // create function for creating a new comment
    const createComment = (workflowid, commenttype, commenttext) => {
        return new Promise((resolve, reject) => {
        GET('/rebate/summary/comment/new', {workflowid: workflowid, commenttype: commenttype, commenttext: commenttext})
            .then(res => { 
                resolve(res);
            }).catch(err => {console.log(err);
                reject(err);
            });
        })
        //.then(getUserEntitlements(user))  
    }     

    const onSubmitClick = () =>{
        createComment(editRow.workflowid, editRow.userroleid, commentText)
        //close modal
        setCommentModal(commentModal => commentModal = false);
        //reset comment text to blank incase user returns to make another comment
        setCommentText(commentText => commentText = '');
    }

    return(
        <> 
            <Modal className='rebate-summary-modal'
                show={commentModal}
                size='lg'
                aria-labelledby='contained-modal-title-vcenter'
                centered
                scrollable={true}
                >
                <Modal.Header closeButton onClick={()=>setCommentModal(commentModal => commentModal = false)}>
                    <Modal.Title id='contained-modal-title-vcenter'>
                        Edit Comment
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    {/* read only input for info on rebate workflow that is being viewed */}
                    <div class='modal-div'>
                        <PageReadOnlyInput tag='Company:' value={editRow.mastercompanyname} />
                    </div>
                    <br/>
                    <div class='modal-div'>
                        <PageReadOnlyInput tag='Payout Cycle: ' value={editRow.periodcyclestart + '-' + editRow.periodcycleend + ' [' + editRow.incentivefreq + ']'}/>
                    </div>
                    <br/>
                    {/* comment log to show all comments attached to rebate workflow */}
                    <div class='modal-div'>
                        <WorkflowCommentLog workflowid={editRow.workflowid}/>
                    </div>
                    <br/>
                    {/* textarea input for new comment */}
                    <div class='modal-div'>
                        <label><b>New Comment:</b></label>
                        <br/>
                        <textarea class='modal-text-area' value={commentText} onChange={(e)=>setCommentText(commentText => commentText = e.target.value)}></textarea>
                    </div>
                    <button class='modal-button-full-blue' onClick={()=>onSubmitClick()}>Submit</button>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default RebateCommentModal;