import { Link } from "react-router-dom";
import '../../css/navsliderStyle.css';
import Offcanvas from 'react-bootstrap/Offcanvas';
import homeIcon from '../../svgs/home-icon.svg';
import portfolioIcon from '../../svgs/graph-icon.svg';
import rebateIcon from '../../svgs/card-icon.svg';
import clientIcon from '../../svgs/company-icon.svg';
import userAdminIcon from '../../svgs/user-admin-icon.svg';
import backIcon from '../../svgs/back-icon.svg'

const Navslider = ({ indicator, backPath, style }) =>{
    var navList = []
    if (backPath){
        // object to hold information for the navigation button on the navslider with back button 
        navList = [
            {path: '/MainLanding', tag: 'Home', icon: homeIcon, index: 0},
            {path: '/TableauDashboardEmbed', tag: 'Portfolio', icon: portfolioIcon, index: 1},
            {path: '/RebateWorkflowLanding', tag: 'Rebate Workflow', icon: rebateIcon, index: 2},
            {path: '/CompanyInfoLanding', tag: 'Client Profile', icon: clientIcon, index: 3},
            {path: '/UserAdminLanding', tag: 'User Admin', icon: userAdminIcon, index: 4},
            {path: backPath, tag: 'back', icon: backIcon, index: 5},
        ];
    }
    else{
        // object to hold information for the navigation button on the navslider without back button 
        navList = [
            {path: '/MainLanding', tag: 'Home', icon: homeIcon, index: 0},
            {path: '/TableauDashboardEmbed', tag: 'Portfolio', icon: portfolioIcon, index: 1},
            {path: '/RebateWorkflowLanding', tag: 'Rebate Workflow', icon: rebateIcon, index: 2},
            {path: '/CompanyInfoLanding', tag: 'Client Profile', icon: clientIcon, index: 3},
            {path: '/UserInventoryPage', tag: 'User Admin', icon: userAdminIcon, index: 4},
        ];
    }

    return(
        <div>
            {/* reactbootstrap Offcanvas component for navslider on side of page */}
            <Offcanvas className='offcanvas navslider' show={true} placement='start' scroll={true} backdrop={false} style={style}>
                <Offcanvas.Body className='navslider-body'>
                    {/* map links over each element of navList */}
                    {navList.map( i => 
                        <Link 
                            to={{
                                pathname: i['path'], 
                                }}
                            ><button className={ indicator == i['index'] ? "navslider-selected-button" : "navslider-button" }> 
                                <img src={i['icon']} className='navslider-icon'/>
                                <label className='navslider-button-label'>{i['tag']}</label>
                            </button></Link> 
                    )}
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    );
}

export default Navslider;