import { useEffect, useState } from 'react';
import * as FileSaver from 'file-saver';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';

const ExportS3ExcelButton = ({ tag, getData, removeDate }) => {
    // initialize state for date
    const [currentDate, setCurrentDate] = useState('');
    // set global variables to be used in handler function
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    // state to show loading and block button from being clicked while loading file
    const [block, setBlock] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState('');

    // event handler to export excel
    const exportExcel = () => {
        // block other export clicks until response of current call
        setBlock(block => block = true);
        // initialize objects for presigned url responses
        let presignedUrlDownloadObj;
        let presignedUrlDeleteObj; 
        // update loading message
        setLoadingMessage(loadingMessage => loadingMessage = 'Creating Excel File...');
        // use getData prop to upload xlsx to s3 bucket and get presigned urls
        getData().then(res => {
            console.log(res);
            // set presigned url objects
            presignedUrlDownloadObj = res['data']['PresignedUrlDownload'];
            presignedUrlDeleteObj = res['data']['PresignedUrlDelete'];
            // update loading message
            setLoadingMessage(loadingMessage => loadingMessage = 'Retrieving Excel File...');
            // use presigned url to get file from s3 bucket
            return axios({
                method: 'GET', 
                url: presignedUrlDownloadObj['downloadURL'], 
                responseType: 'blob'
            })
        }).then(res => {
            console.log(res);
            // update loading message
            setLoadingMessage(loadingMessage => loadingMessage = 'Downloading Excel File...');
            // create .xlsx file for download 
            const data = new Blob([res['data']], {type: fileType});
            // export file with date in name 
            if (removeDate) {
                FileSaver.saveAs(data, tag + fileExtension);
            }
            else {
                FileSaver.saveAs(data, tag + '-' + currentDate + fileExtension);
            }
            // delete file from bucket location after download
            return axios({
                method: 'DELETE', 
                url: presignedUrlDeleteObj['deleteURL'],
            }); 
        }).then(res => {
            console.log(res);
            // stop blocking export
            setBlock(block => block = false);
        }).catch(err => {
            console.log(err);
            // stop blocking export
            setBlock(block => block = false);
        });
        
    }

    // event handler to initiate export excel
    const onExport = () => {
        // check if block is false
        if (!block){
            // trigger export excel 
            exportExcel();
        }
        else {
            console.log('Blocked!');
        }
    }

    // get date using react built in function and set state
    useEffect(()=>{
        setCurrentDate(currentDate => currentDate = Date().toLocaleString().substring(0, 15).replace(/ /g, '-').toLowerCase());
    }, []);

    return (
        <div>
            <button class='summary-page-button' onClick={()=>onExport()}>Export Excel</button>
            { block ? 
            <>
                <CircularProgress style={{height: '25px', width: '25px', marginTop: '5px', marginLeft: '10px', marginBottom: '-5px'}}/>
                <span style={{marginLeft: '10px'}}>{loadingMessage}</span>
            </>
            :<></>}
        </div>
    );
}

export default ExportS3ExcelButton; 