// return true if element equals value
const eligibleFilter = (el, val, filterCol) => {
    // handle edge case where not looking at eligible and non eligible workflows 
    if (val == null){
        return true; 
    }
    // else check to see if rebate workflow is eligible
    else {
        // get end period from rebate cycle
        const endPeriod = el[filterCol].split(' to ')[1]; 
        // if end period = val then rebate workflow is eligible
        if (parseInt(endPeriod) == parseInt(val)){
            return true; 
        }
        // else not eligible
        else {
            return false; 
        }
    }
}

export default eligibleFilter;