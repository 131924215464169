import { useEffect, useState, useContext } from 'react';  
import PageContext from './PageContext'; 
import greenPlusIcon from '../../svgs/green-plus-icon.svg';
import redMinusIcon from '../../svgs/red-minus-icon.svg'; 
import PageInput from './PageInput';
import PageDropdown from './PageDropdown';

const AddNewAccountIdsRow = ({ row, count, tableHeader, index }) => {
    // get page context from parent
    const { 
        accountIdsDataList, 
        setAccountIdsDataList,
        accountIdsRow, 
        setAccountIdsRow, 
    } = useContext(PageContext); 
    // initialize state
    const [accountId, setAccountId] = useState(''); 
    const [status, setStatus] = useState('Active');
    const [statusList, setStatusList] = useState(['Active', 'Inactive']); 

    // event handler for add account ids row
    const addAccountId = () => {
        let temp2 = []; 
        for (let i = 0; i < tableHeader.length; i++){
            temp2.push(tableHeader[i]['columnname']);
        }
        let temp = {};
        temp2.map((el) =>{
            if (el == 'blank'){
                temp = {...temp, [el]: false};
            }
            else if (el == 'active'){
                temp = {...temp, [el]: 'Active'};
            }
            else{
                temp = {...temp, [el]: ''};
            }
        });
        setAccountIdsDataList(accountIdsDataList => accountIdsDataList = [
            // list of account ids for sub companies before index
            ...accountIdsDataList.slice(0, index), 
            // data list for selected sub company
            [
                ...accountIdsDataList[index].slice(0, accountIdsDataList[index].length-1), 
                temp, 
                ...accountIdsDataList[index].slice(accountIdsDataList[index].length-1, accountIdsDataList[index].length),
            ], 
            // list of account ids for sub companies after index
            ...accountIdsDataList.slice(index+1, accountIdsDataList.length), 
        ]); 
    }
    // event handler for removing account ids row
    const removeAccountId = ( ind ) => {
        setAccountIdsDataList(accountIdsDataList => accountIdsDataList = [
            // lists of account ids for sub companies before index
            ...accountIdsDataList.slice(0, index), 
            // data list for selected sub company
            [
                ...accountIdsDataList[index].slice(0, ind), 
                ...accountIdsDataList[index].slice(ind + 1, accountIdsDataList[index].length),
            ], 
            // lists of account ids for sub companies after index
            ...accountIdsDataList.slice(index+1, accountIdsDataList.length),
        ]); 
    }

    // function with logic to determine cell rendering 
    const showCell = ( el ) => {
        if (el == 'blank'){
            if (row[el] == true){
                return <img src={greenPlusIcon} class='data-table-icon-right' onClick={()=>addAccountId()}/>
            }
            else{
                return <img src={redMinusIcon} class='data-table-icon-right' onClick={()=>removeAccountId(count)}/>
            }
        }
        else if (row['blank'] != true && el == 'accountid') {
            return <PageInput tag='Account ID: ' value={accountId} setValue={setAccountId}/>
        }
        else if (row['blank'] != true && el == 'active') {
            return <PageDropdown tag='Status: ' value={status} setValue={setStatus} list={statusList} width='7.2vw'/>
        }
        else{
            return <>{row[el]}</>;
        } 
    }

    // useEffect to update data list row values 
    useEffect(() => {
        if ( count < accountIdsDataList[index].length - 1 ){
            setAccountIdsDataList(accountIdsDataList => accountIdsDataList = [
                // lists of account ids for sub companies before index
                ...accountIdsDataList.slice(0, index),
                // data list for selected sub company
                [
                    ...accountIdsDataList[index].slice(0, count), 
                    { active: status, accountid: accountId, blank: row['blank'] }, 
                    ...accountIdsDataList[index].slice(count + 1, accountIdsDataList[index].length),
                ],
                // lists of account ids for sub companies after index
                ...accountIdsDataList.slice(index+1, accountIdsDataList.length),
            ]); 
        }

    }, [status, accountId]); 

    // useEffect to change row values when size of row changes
    useEffect(() => { 
        if (accountIdsDataList[index][count]){
            setStatus(status => status = accountIdsDataList[index][count]['active']); 
            setAccountId(accountId => accountId = accountIdsDataList[index][count]['accountid']); 
        }
    }, [accountIdsDataList[index].length]); 

    return( 
        <>
            {/* map showCell function over ever column in the row */}
            {tableHeader.map(el => 
                <td>{showCell(el['columnname'])}</td>
            )}
        </>
    );
}

export default AddNewAccountIdsRow; 