import { useEffect, useState } from 'react';
import * as FileSaver from 'file-saver';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';

const ExportS3Icon = ({ tag, getData, removeDate, deleteFile, icon, css, fileType, fileExtension}) => {
    // initialize state for date
    const [currentDate, setCurrentDate] = useState('');
    // state to show loading and block button from being clicked while loading file
    const [block, setBlock] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState('');

    // event handler to export excel
    const exportExcel = () => {
        // block other export clicks until response of current call
        setBlock(block => block = true);
        // initialize objects for presigned url responses
        let presignedUrlDownload;
        let presignedUrlDelete; 
        // update loading message
        setLoadingMessage(loadingMessage => loadingMessage = 'Creating File...');
        // use getData prop to upload xlsx to s3 bucket and get presigned urls
        getData().then(res => {
            console.log(res);
            // set presigned url objects
            presignedUrlDownload = res['data']['PresignedUrlDownload'];
            presignedUrlDelete = res['data']['PresignedUrlDelete'];
            // update loading message
            setLoadingMessage(loadingMessage => loadingMessage = 'Retrieving File...');
            // use presigned url to get file from s3 bucket
            return axios({
                method: 'GET', 
                url: presignedUrlDownload, 
                responseType: 'blob'
            })
        }).then(res => {
            console.log(res);
            // update loading message
            setLoadingMessage(loadingMessage => loadingMessage = 'Downloading File...');
            // create .xlsx file for download 
            const data = new Blob([res['data']], {type: fileType});
            // export file with date in name 
            if (removeDate) {
                FileSaver.saveAs(data, tag );
            }
            else {
                FileSaver.saveAs(data, tag.replace('.pdf', '') + '-' + currentDate + fileExtension);
            }
            // delete file from bucket location after download
            if (deleteFile){
                return axios({
                    method: 'DELETE', 
                    url: presignedUrlDelete,
                }); 
            }
            else {
                return; 
            }
            
        }).then(res => {
            console.log(res);
            // stop blocking export
            setBlock(block => block = false);
        }).catch(err => {
            console.log(err);
            // stop blocking export
            setBlock(block => block = false);
        });
        
    }

    // event handler to initiate export excel
    const onExport = () => {
        // check if block is false
        if (!block){
            // trigger export excel 
            exportExcel();
        }
        else {
            console.log('Blocked!');
        }
    }

    // get date using react built in function and set state
    useEffect(()=>{
        setCurrentDate(currentDate => currentDate = Date().toLocaleString().substring(0, 15).replace(/ /g, '-').toLowerCase());
    }, []);

    return (
        <>
            <img src={icon} className={css} onClick={onExport}/> 
            { block ? 
            <>
                <CircularProgress style={{height: '7px', width: '7px'}}/>
            </>
            :<></>}
        </>
    );
}

export default ExportS3Icon; 