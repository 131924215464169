import { useContext, useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Modal } from "react-bootstrap";
import PageContext from "./PageContext";
import PageDropdown from './PageDropdown';
import PageInput from './PageInput';
import AutoComplete from './AutoCompleteInput';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { HttpContext } from '../../api-services/HttpService';
import removeStrCommas from '../functions/removeStrCommas';
import rebateInputValidationChecks from '../functions/rebateInputValidationChecks';
import percentToDecimal from '../functions/percentToDecimal'; 
import ApiRequestButton from './ApiRequestButton';

const AddRebateSetupModal = () => {
    // get context from parent
    const { 
        addRebateSetupModal, 
        setAddRebateSetupModal, 
        masterCompanyData,
        getCompanyDetailsData,  
        snackbarAlert, 
        productList, 
        billingRateList,
        daysToPayList, 
        feeCategoryList, 
        subCompanyList, 
        masterSetupIdsList, 
        // additionalDetailsValue, 
        rebateInputTypeId, 
    } = useContext(PageContext);
    // initialize http context 
    const {
        POST, 
    } = useContext(HttpContext);
    // get persistant redux global state from store
    const company = useSelector((state) => state.company);
    // set state
    const [statusTag, setStatusTag] = useState('Active');
    const [statusList, setStatusList] = useState(['Active', 'Inactive']);
    const [masterSetupId, setMasterSetupId] = useState(null); // initialize as null because must go into db as null if there is no input
    const [setupId, setSetupId] = useState('');
    const [subCompany, setSubCompany] = useState('');
    const [product, setProduct] = useState('--select--');
    const [billingRate, setBillingRate] = useState('--select--'); 
    const [daysToPay, setDaysToPay] = useState('--select--');
    const [feeCategory, setFeeCategory] = useState('--select--');
    const [tierMin, setTierMin] = useState('');
    const [tierMax, setTierMax] = useState('');
    const [rebateRate, setRebateRate] = useState(null); // initialize as null because must go into db as null if no input
    const [masterSetupBool, setMasterSetupBool] = useState(false);
    // const [minimumSpend, setMinimumSpend] = useState(null); 
    // const [paybackAmount, setPaybackAmount] = useState(null); 

    // event handler for submit click 
    const onSubmitClick = () =>{
        return new Promise((resolve, reject) => {
            let response;
            // set active arg from status
            let active = false; 
            if (statusTag == 'Active') {
                active = true;
            }
            // make daysToPay null if has value == 'None'
            let validDaysToPay = daysToPay; 
            if (daysToPay == 'None'){
                validDaysToPay = null; 
            }

            // set params for request
            let params = {
                active: active, 
                masterincentivesetupid: masterSetupId, 
                // incentivesetupid: setupId, 
                mastercompanyid: masterCompanyData['mastercompanyid'], 
                companyid: subCompany,
                producttypedescription: product, 
                billingratedescription: billingRate, 
                daystopay: validDaysToPay, 
                feecategorydescription: feeCategory, 
                minspend: removeStrCommas(tierMin), 
                maxspend: removeStrCommas(tierMax), 
                incentiverate: rebateRate, 
                incentivetype: masterCompanyData['incentivetype'],
            }

            // // add additional details if necessary
            // if (additionalDetailsValue == 1){
            //     params['spendcommitment'] = removeStrCommas(minimumSpend); 
            //     params['bonusamount'] = removeStrCommas(paybackAmount); 
            // }

            // -- if not a master setup run transformation on incentiverate depending on input type --
            // -- rebateInputTypeId of 0 is considered default and will have no transformation -- 
            if (!masterSetupBool && rebateInputTypeId == 1){
                // input type id of 1 for percent
                params['incentiverate'] = percentToDecimal(params['incentiverate']); 
            } 

            // validate input before making request
            if(!rebateInputValidationChecks(params, subCompanyList, masterSetupIdsList, snackbarAlert)){
                reject(); 
                return;
            }

            // use POST function to make post request 
            POST('/data/incentiveSetups', params).then(res => {
                console.log(res);
                response = res; 
                // hide modal 
                setAddRebateSetupModal(addRebateSetupModal => addRebateSetupModal = false);
                // trigger success alert
                snackbarAlert(true, 'Successfully uploaded data.', 'success');
                // reset inputs for tier info 
                setStatusTag(statusTag => statusTag = 'Active');
                setTierMin(tierMin => tierMin = '');
                setTierMax(tierMax => tierMax = '');
                setRebateRate(rebateRate => rebateRate = null); // set to null because must go into db as null if no input
                // refresh CompanyDetailsPage using function from parent
                return getCompanyDetailsData(company);
            }).then(res => {
                console.log(res);
                // resolve on success
                resolve(response); 
            }).catch(err => {
                console.log(err);
                // show alert on error
                snackbarAlert(true, 'Error uploading data.', 'error');
                // reject on error
                reject(err); 
            }); 
        }); 
    }

    // useEffect to change values on masterSetupBool change
    useEffect (() => {
        // if adding master setup bool make rate and master setup id null
        if (masterSetupBool){
            setRebateRate(rebateRate => rebateRate = null);
            setMasterSetupId(masterSetupId => masterSetupId = null);
        }   
    }, [masterSetupBool]);
    // on masterSetupId change make null if empty
    useEffect(() => {
        // set inputs to null if they are empty strings
        if (masterSetupId != null && masterSetupId.length == 0){
            setMasterSetupId(masterSetupId => masterSetupId = null);
        }
    }, [masterSetupId]);
    // on rebateRate change make null if empty
    useEffect(() => {
        if (rebateRate != null && rebateRate.length == 0){
            setRebateRate(rebateRate => rebateRate = null);
        }
    }, [rebateRate]);

    return(
        <> 
            <Modal className="rebate-summary-modal"
                show={addRebateSetupModal}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                scrollable={true}
                >
                <Modal.Header closeButton onClick={()=>setAddRebateSetupModal(addRebateSetupModal => addRebateSetupModal = false)}>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Add Rebate Setup
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    {/* page inputs/dropdowns for adding rebate setup */}
                    <div class='modal-div'>
                        <PageDropdown tag='Status: ' value={statusTag} setValue={setStatusTag} list={statusList}/>
                    </div>
                    <br/>
                    <div class='modal-div'>
                        <FormControlLabel
                            value="end"
                            control={<Checkbox
                                sx={{
                                    color: '#000000',
                                    '&.Mui-checked': {
                                    color: '#000000',
                                    },
                                }} />}
                            label="Add Master Setup Tier"
                            labelPlacement="end"
                            sx={{
                                color: 'black',
                            }}
                            checked={masterSetupBool}
                            onChange={(e)=>{setMasterSetupBool(masterSetupBool => masterSetupBool = e.target.checked)}}
                        />
                    </div>
                    <br/>
                    { !masterSetupBool ? 
                        <>
                            <div class='modal-div'>
                                <AutoComplete tag='Master Setup ID: ' value={masterSetupId} setValue={setMasterSetupId} list={masterSetupIdsList} width='16.5vw' />
                            </div>
                            <br/>
                        </>
                    :<></>}
                    <div class='modal-div'>
                        <AutoComplete tag='Sub Company ID: ' value={subCompany} setValue={setSubCompany} list={subCompanyList} width='16.5vw' />
                    </div>
                    <br/>
                    {/* <div class='modal-div'>
                        <PageInput tag='Setup ID: ' value={setupId} setValue={setSetupId} />
                    </div>
                    <br/> */}
                    <div class='modal-div'>
                        <PageDropdown tag='Product: ' value={product} setValue={setProduct} list={productList}/>
                    </div>
                    <br/>
                    <div class='modal-div'>
                        <PageDropdown tag='Billing Rate: ' value={billingRate} setValue={setBillingRate} list={billingRateList}/>
                    </div>
                    <br/>
                    <div class='modal-div'>
                        <PageDropdown tag='Days To Pay: ' value={daysToPay} setValue={setDaysToPay} list={daysToPayList}/>
                    </div>
                    <br/>
                    <div class='modal-div'>
                        <PageDropdown tag='Fee Category: ' value={feeCategory} setValue={setFeeCategory} list={feeCategoryList}/>
                    </div>
                    <br/>
                    <div class='modal-div'>
                        <PageInput tag='Tier Min: ' value={tierMin} setValue={setTierMin} placeholder='Enter Tier Min'/>
                    </div>
                    <br/>
                    <div class='modal-div'>
                        <PageInput tag='Tier Max: ' value={tierMax} setValue={setTierMax} placeholder='Enter Tier Max'/>
                    </div>
                    <br/>
                    {!masterSetupBool ? 
                    <>
                        <div class='modal-div'>
                            <PageInput tag='Rebate Rate: ' value={rebateRate} setValue={setRebateRate} placeholder='Enter Rebate Rate'/>
                        </div>
                        <br/>
                    </>
                    :<></>}
                    {/* if the additionalDetailsValue is 1 show hBB&T additional details editable fields */}
                    {/* { additionalDetailsValue == 1 ? 
                    <>
                        <div class='modal-div'>
                            <PageInput tag='Minimum Spend: ' value={minimumSpend} setValue={setMinimumSpend} />
                        </div>
                        <br/>
                        <div class='modal-div'>
                            <PageInput tag='Payback Amount' value={paybackAmount} setValue={setPaybackAmount} />
                        </div>
                        <br/>
                    </>
                    : <></>} */}
                    {/* submit button to add rebate setup */}
                    <ApiRequestButton onButtonClick={onSubmitClick} />
                    <br/><br/>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default AddRebateSetupModal; 