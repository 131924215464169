// -----------------------------------------------------------------
//  this function is a placeholder and is not currently in use
// -----------------------------------------------------------------


import { useContext, useState } from 'react';
import { Modal } from "react-bootstrap";
import PageContext from './PageContext';
import PageDropdown from './PageDropdown';
import AutoCompleteInput from './AutoCompleteInput';

const AddNewUserModal = () => {
    // get context from parent
    const {
        addNewUserModal, 
        setAddNewUserModal, 
        addRoleList, 
    } = useContext(PageContext);
    // initialize state
    const [role, setRole] = useState('--select--');
    const [searchUser, setSearchUser] = useState('');
    // array to hold userList for autocomplete
    const userList = [];
    // event handler for submit click 
    const onSubmit = () => {
        setAddNewUserModal(addNewUserModal => addNewUserModal = false);
    }

    return(
        <> 
            <Modal className="rebate-summary-modal"
                show={addNewUserModal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                scrollable={true}
                >
                <Modal.Header closeButton onClick={()=>setAddNewUserModal(addNewUserModal => addNewUserModal = false)}>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Add New User
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <div class='modal-div'>
                        <span className='span-left'>
                            {/* PageDropdown to select role for new user */}
                            <PageDropdown tag='Role:' value={role} setValue={setRole} list={addRoleList}/>
                        </span>
                        <span class='span-right'>
                            {/* button to submit new user addition */}
                            <button className='entitlement-modal-button' onClick={()=>onSubmit()}>Submit</button>
                        </span>
                        <span className='entitlement-input-span-right'>
                            {/* autocomplete to search from list of users with access to site */}
                            <AutoCompleteInput tag='Search User: ' value={searchUser} setValue={setSearchUser} list={userList} width='350px'/>
                        </span>
                        <br/><br/>
                    </div>
                </Modal.Body>
            </Modal> 
        </>
    );
}   

export default AddNewUserModal;