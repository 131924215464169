// function to make copy by value for state array of objects
const copyObjArray = (objArray) => {
    // initialize copy array 
    let copyArray = []; 
    // iterate through obj array destructing the objects to copy 
    for (let i = 0; i < objArray.length; i++){
        copyArray.push({
            ...objArray[i], 
        }); 
    }
    // return copy 
    return copyArray; 
}

export default copyObjArray; 