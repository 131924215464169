import moneyFormat from '../functions/moneyFormat'; 
import decimalToPercent from '../functions/decimalToPercent'; 

const AggregateTotalsFooter = ({tableHeader, footerObj, rebateInputTypeId}) => {
    return(
        <>
            {tableHeader.map( el => 
                el['columnname'] == 'cyclenetspend' || el['columnname'] == 'cyclenetinterchange' || el['columnname'] == 'cycleincentiveamount' ? 
                    <th>{moneyFormat(footerObj[el['columnname']])}</th>
                : el['columnname'] == 'incentiverate' && rebateInputTypeId == 1 && footerObj[el['columnname']] != null ? 
                    // apply transformation depending on rebate input type id
                    <th>{decimalToPercent(footerObj[el['columnname']])}</th>
                : <th>{footerObj[el['columnname']]}</th>
            )} 
        </>
    ); 
}

export default AggregateTotalsFooter; 

