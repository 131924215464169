import validateNumericInput from './validateNumericInput';
import isAlphaNumeric from './isAlphaNumeric';

const performanceInputValidationCheck = (input, snackbarAlert) => {
    // // validate setupId is alpha numeric
    // if (!isAlphaNumeric(input.incentivesetupid)){
    //     // show alert on invalid setup id
    //     snackbarAlert(true, 'Invalid Setup ID.', 'error');
    //     return false;
    // }
    // validate spend commitment
    if (!validateNumericInput(input.spendcommitment)){
        // show alert on invalid spend commitment
        snackbarAlert(true, 'Invalid Spend Commitment.', 'error');
        return 'spendcommitment';
    } 
    // validate bonus amount
    if(!validateNumericInput(input.bonusamount)){
        // show alert on invalid bonus amount
        snackbarAlert(true, 'Invalid Bonus Amount.', 'error');
        return 'bonusamount';
    }
    // if input passes checks return null
    return; 
}

export default performanceInputValidationCheck;