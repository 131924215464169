import { useState, useEffect, useContext, useRef } from 'react';
import PageContext from '../sub-components/PageContext';
import { useSelector } from 'react-redux';
import HeaderBanner from '../sub-components/HeaderBanner';
import Navslider from '../sub-components/Navslider';
import EditCompanyDetailsModal from '../sub-components/EditCompanyDetailsModal';
import AddSubCompanyModal from '../sub-components/AddSubCompanyModal';
import EditSubCompanyModal from '../sub-components/EditSubCompanyModal';
import OrderedDataTable from '../sub-components/OrderedDataTable';
import SubCompanyRow from '../sub-components/SubCompanyRow';
import RebateSetupRow from '../sub-components/RebateSetupRow';
import CompanyEntitlementsRow from '../sub-components/CompanyEntitlementsRow';
import EditRebateSetupModal from '../sub-components/EditRebateSetupModal'; 
import AddRebateSetupModal from '../sub-components/AddRebateSetupModal';
import EditCompanyEntitlementsModal from '../sub-components/EditCompanyEntitlementsModal';
import AddCompanyEntitlementsModal from '../sub-components/AddCompanyEntitlementsModal';
import PageReadOnlyInput from '../sub-components/PageReadOnlyInput';
import PageTabs from '../sub-components/PageTabs';
import PerformanceBonusRow from '../sub-components/PerformanceBonusRow';
import EditPerformanceBonusModal from '../sub-components/EditPerformanceBonusModal';
import AddPerformanceBonusModal from '../sub-components/AddPerformanceBonusModal';
import { HttpContext } from '../../api-services/HttpService';
import SnackbarAlert from '../sub-components/SnackbarAlert';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import statusFilter from '../functions/statusFilter';
import standardFilter from '../functions/standardFilter'; 
import applyFilters from '../functions/applyFilters';
import PayoutInfoRow from '../sub-components/PayoutInfoRow';
import AddPayoutInfoModal from '../sub-components/AddPayoutInfoModal';
import EditPayoutInfoModal from '../sub-components/EditPayoutInfoModal';
import EntitledButton from '../sub-components/EntitledButton';
import PageDropdownWithIDs from '../sub-components/PageDropdownWithIDs'; 
import PageDropdown from '../sub-components/PageDropdown';
import EditAccountIdsModal from '../sub-components/EditAccountIdsModal'; 
import { AuthContext } from '../../auth/AuthRoute';
import AdditionalDetailsModal from '../sub-components/AdditionalDetailsModal';  
import standardWithNoneFilter from '../functions/standardWithNoneFilter'; 
import ViewSubCompaniesModal from '../sub-components/ViewSubCompaniesModal';
import ConsortiumViewRow from '../sub-components/ConsortiumViewClientInfoRow';
import ExportS3ExcelButton from '../sub-components/ExportS3ExcelButton'; 
import UploadS3ExcelButton from '../sub-components/UploadS3ExcelButton';
import ProcessingLogTableRow from '../sub-components/ProcessingLogTableRow'; 
import BannerAlert from '../sub-components/BannerAlert';
import ProcessingCommentModal from '../sub-components/ProcessingCommentModal'; 
import UploadContractRow from '../sub-components/UploadContractRow'; 
import AddClientContractModal from '../sub-components/AddClientContractModal'; 
import ContractUploadCommentModal from '../sub-components/ContractUploadCommentModal'; 
import EditUploadClientContractModal from '../sub-components/EditUploadClientContractModal'; 
import getPageTabs from '../functions/getPageTabs';

const CompanyDetailsPage = () => {
    // initialize http context 
    const {
        GET, 
        PUT, 
    } = useContext(HttpContext);
    // initialize auth context
    const { 
        getAdmin, 
        getDataTech, 
    } = useContext(AuthContext); 
    // options for page tabs 
    const pageTabs = [
        {tag: 'Sub Company List', key: 0, active: true }, 
        {tag: 'Rebate Setup', key: 1, active: true }, 
        {tag: 'Bonuses', key: 2, active: true }, 
        {tag: 'User Entitlements', key: 3, active: true }, 
        {tag: 'Payout Info', key: 4, active: true }, 
        {tag: 'Upload Excel', key: 5, active: process.env.REACT_APP_UPLOAD_EXCEL == '1'}, 
        {tag: 'Upload Contract', key: 6, active: process.env.REACT_APP_UPLOAD_CLIENT_CONTRACTS == '1'}, 
        // {tag: 'Consortium View', key: 7, active: false}, 
    ]; 
    // get global state
    const company = useSelector((state) => state.company);
    const companyInfo = useSelector((state) => state.companyInfo);
    const backPath = useSelector((state) => state.backPath); 
    // initialize modal state
    const [editCompanyDetailsModal, setEditCompanyDetailsModal] = useState(false);
    const [editSubCompanyModal, setEditSubCompanyModal] = useState(false);
    const [editSubCompanyRow, setEditSubCompanyRow] = useState({});
    const [addSubCompanyModal, setAddSubCompanyModal] = useState(false);
    const [editRebateSetupModal, setEditRebateSetupModal] = useState(false);
    const [editRebateSetupRow, setEditRebateSetupRow] = useState({});
    const [addRebateSetupModal, setAddRebateSetupModal] = useState(false);
    const [editEntitlementsModal, setEditEntitlementsModal] = useState(false);
    const [editEntitlementsRow, setEditEntitlementsRow] = useState({});
    const [addEntitlementsModal, setAddEntitlementsModal] = useState(false);
    const [editPayoutTypeModal, setEditPayoutTypeModal] = useState(false);
    const [editPerformanceBonusModal, setEditPerformanceBonusModal] = useState(false);
    const [editPerformanceBonusRow, setEditPerformanceBonusRow] = useState({});
    const [addPerformanceBonusModal, setAddPerformanceBonusModal] = useState(false);
    const [editPayoutInfoModal, setEditPayoutInfoModal] = useState(false);
    const [editPayoutInfoRow, setEditPayoutInfoRow] = useState({});
    const [addPayoutInfoModal, setAddPayoutInfoModal] = useState(false);
    const [editAccountIdsModal, setEditAccountIdsModal] = useState(false); 
    // initialize list states for entitlements modal 
    const [roleList, setRoleList] = useState([]);
    const [userList, setUserList] = useState([]);
    // initialize table state
    const [subCompanyDataList, setSubCompanyDataList] = useState([]);
    const [showSubCompanyDataList, setShowSubCompanyDataList] = useState([]);
    const [subCompanyTableHeader, setSubCompanyTableHeader] = useState([]);
    const [rebateSetupDataList, setRebateSetupDataList] = useState([]);
    const [showRebateSetupDataList, setShowRebateSetupDataList] = useState([]);
    const [rebateSetupTableHeader, setRebateSetupTableHeader] = useState([]);
    const [entitlementsDataList, setEntitlementsDataList] = useState([]);
    const [showEntitlementsDataList, setShowEntitlementsDataList] = useState([]); 
    const [entitlementsTableHeader, setEntitlementsTableHeader] = useState([]);
    const [performanceBonusDataList, setPerformanceBonusDataList] = useState([]);
    const [showPerformanceBonusDataList, setShowPerformanceBonusDataList] = useState([]);
    const [performanceBonusTableHeader, setPerformanceBonusTableHeader] = useState([]);
    const [payoutInfoDataList, setPayoutInfoDataList] = useState([]);
    const [payoutInfoTableHeader, setPayoutInfoTableHeader] = useState([]);
    const [uploadContractsDataList, setUploadContractsDataList] = useState([]); 
    const [uploadContractsTableHeader, setUploadContractsTableHeader] = useState([]); 
    const [showUploadContractsDataList, setShowUploadContractsDataList] = useState([]);
    const [showSpinner, setShowSpinner] = useState(false);
    // initialize tab state
    const [tabValue, setTabValue] = useState(0);
    const [tabList, setTabList] = useState([]); 
    // initialize state for top label
    const [rebateFrequency, setRebateFrequency] = useState('');
    const [primaryCCRM, setPrimaryCCRM] = useState('');
    const [portfolio, setPortfolio] = useState('');
    const [masterCompanyId, setMasterCompanyId] = useState('');
    const [masterCompanyData, setMasterCompanyData] = useState({});
    const [rebateFrequencyList, setRebateFrequencyList] = useState([]);
    const [portfolioList, setPortfolioList] = useState([]);
    const [anniversaryMonth, setAnniversaryMonth] = useState('')
    const [monthList, setMonthList] = useState(['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']); 
    // initialize snackbar state
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarColor, setSnackbarColor] = useState('info');
    // initialize rebate setup modal state and refs
    const [productList, setProductList] = useState(['--select--']);
    const [billingRateList, setBillingRateList] = useState(['--select--']);
    const [daysToPayList, setDaysToPayList] = useState(['--select--']);
    const [feeCategoryList, setFeeCategoryList] = useState(['--select--']);
    const [subCompanyList, setSubCompanyList] = useState([]);
    const [masterSetupIdsList, setMasterSetupIdsList] = useState([]);
    const [rebateTypeList, setRebateTypeList] = useState(['--select--']);
    const [payoutMethodList, setPayoutMethodList] = useState(['--select--']);
    const [payoutMethodData, setPayoutMethodData] = useState([]); 
    // initialize check box state
    const [showInactiveSubcompanies, setShowInactiveSubcompanies] = useState(false);
    const [showInactiveSetups, setShowInactiveSetups] = useState(false);
    const [showInactivePerformance, setShowInactivePerformance] = useState(false);
    const [showInactiveEntitlements, setShowInactiveEntitlements] = useState(false); 
    const [showInactiveContractUploads, setShowInactiveContractUploads] = useState(false); 
    // initialize state for triggering filter on data load
    const [triggerFilter, setTriggerFilter] = useState(false);
    // initialize state for boolean to render edit buttons
    const [editButtonBool, setEditButtonBool] = useState(false);
    const [userEntitlementId, setUserEntitlementId] = useState('-1'); 
    // initialize state to filter rebate setup page 
    const [subCompanyFilter, setSubCompanyFilter] = useState('All');
    const [subCompanyFilterList, setSubCompanyFilterList] = useState(['All']);
    const [productFilter, setProductFilter] = useState('All');
    const [productFilterList, setProductFilterList] = useState(['All']);
    const [feeCategoryFilter, setFeeCategoryFilter] = useState('All');
    const [feeCategoryFilterList, setFeeCategoryFilterList] = useState(['All']);
    const [billingRateFilter, setBillingRateFilter] = useState('All');
    const [billingRateFilterList, setBillingRateFilterList] = useState(['All']);
    const [daysToPayFilter, setDaysToPayFilter] = useState('All');
    const [daysToPayFilterList, setDaysToPayFilterList] = useState(['All']);
    // initialize state for additional details 
    const [additionalDetailsValue, setAdditionalDetailsValue] = useState(0); 
    const [additionalDetailsTableHeader, setAdditionalDetailsTableHeader] = useState([]); 
    const [showAdditionalDetailsModal, setShowAdditionalDetailsModal] = useState(false); 
    const [additionalDetailsRow, setAdditionalDetailsRow] = useState({}); 
    // intialize state for rebate input type 
    const [rebateInputTypeId, setRebateInputTypeId] = useState(); 
    // initialize state for sub company processor list and default data
    const [processorList, setProcessorList] = useState([]); 
    const [processorDefaultData, setProcessorDefaultData] = useState([]); 
    // initialize sub company modal state
    const [editableBillingRate, setEditableBillingRate] = useState(false); 
    const [editableProcessor, setEditableProcessor] = useState(false); 
    // intialize consortium state 
    const [consortiumFlag, setConsortiumFlag] = useState(false); 
    const [consortiumDataList, setConsortiumDataList] = useState([]); 
    const [consortiumTableHeader, setConsortiumTableHeader] = useState([]); 
    const [consortiumId, setConsortiumId] = useState(''); 
    // initialize state for view sub company modal 
    const [viewModal, setViewModal] = useState(false);
    const [viewCompany, setViewCompany] = useState({}); 
    // initialize state for upload excel tab
    const [templateType, setTemplateType] = useState(''); 
    const [templateTypeData, setTemplateTypeData] = useState([]); 
    const [exportFilename, setExportFilename] = useState(''); 
    const [processingLogDataList, setProcessingLogDataList] = useState([]); 
    const [processingLogTableHeader, setProcessingLogTableHeader] = useState([]); 
    const [readyForUpload, setReadyForUpload] = useState(false); 
    const [refreshSpinner, setRefreshSpinner] = useState(false); 
    // initialize state for processing log modal 
    const [showProcessingCommentModal, setShowProcessingCommentModal] = useState(false); 
    const [processingRow, setProcessingRow] = useState({}); 
    // initialize state for client contract modal 
    const [uploadClientContractModal, setUploadClientContractModal] = useState(false);
    const [contractSpinner, setContractSpinner] = useState(false); 
    const [showContractCommentModal, setShowContractCommentModal] = useState(false); 
    const [showEditContractModal, setShowEditContractModal] = useState(false); 
    const [contractUploadRow, setContractUploadRow] = useState({}); 
    // initialize file reference
    const file = useRef(); 
    const fileInput = useRef();
    // initialize useRef to determine initial render
    const initialRender1 = useRef(true);
    const initialRender2 = useRef(true);
    const initialRender3 = useRef(true);
    const initialRender4 = useRef(true); 
    const initialRender5 = useRef(true); 

    // function wrapper to pass row component to datatable
    const SubCompanyRowComponent = (row, count, tableHeader ) =>{
        return(
            <>
                <SubCompanyRow row={row} count={count} tableHeader={tableHeader} enableEdit={editButtonBool} />
            </>
        );  
    }  

    // function wrapper to pass row component to datatable
    const RebateSetupRowComponent = (row, count, tableHeader) =>{
        return(
            <>
                <RebateSetupRow row={row} count={count} tableHeader={tableHeader} enableEdit={editButtonBool} />
            </>
        ); 
    } 

    // function wrapper to pass row component to datatable
    const CompanyEntitlementsRowComponent = (row, count, tableHeader) => {
        return(
            <>
                <CompanyEntitlementsRow row={row} count={count} tableHeader={tableHeader} enableEdit={getAdmin()} />
            </>
        );
    } 

    // function wrapper to pass row component to datatable
    const PerformanceBonusRowComponent = (row, count, tableHeader) => {
        return (
            <>
                <PerformanceBonusRow row={row} count={count} tableHeader={tableHeader} enableEdit={editButtonBool} />
            </>
        );
    }

    // function wrapper to pass row component to datatable
    const PayoutInfoRowComponent = (row, count, tableHeader) => {
        return (
            <>
                <PayoutInfoRow row={row} count={count} tableHeader={tableHeader} enableEdit={editButtonBool} />
            </>
        );
    }

    // function wrapper to pass row component to datatable
    const ConsortiumRowComponent = (row, count, tableHeader) =>{
        return(
            <>
                <ConsortiumViewRow row={row} count={count} tableHeader={tableHeader}/>
            </>
        ); 
    } 

    // function wrapper to pass row component to datatable
    const ProcessingRowComponent = (row, count, tableHeader) => {
        return (
            <>
                <ProcessingLogTableRow row={row} count={count} tableHeader={tableHeader} />
            </>
        );
    }

    // function wrapper to pass row component to datatable 
    const UploadContractRowComponent = (row, count, tableHeader) => {
        return (
            <>
                <UploadContractRow row={row} count={count} tableHeader={tableHeader} enableEdit={editButtonBool} />
            </>
        );
    }

    // handle file change
    const onFileChange = (e) => {
        console.log(e);
        file.current = e.target.files[0];
    }

    // handle file upload on click event 
    const putUploadMetadata = () => {
        return new Promise ((resolve, reject) => {
            PUT('/data/uploadMetadata', {
                filename: file.current.name, 
                mastercompanyid: companyInfo['mastercompanyid'], 
            }).then(res => {
                resolve(res); 
            }).catch(err => {
                reject(err); 
            }); 
        }); 
    }
    
    // export template event handler 
    const onTemplateExport = () => {
        return new Promise ((resolve, reject) => {
            // make get request to get presigned url
            GET('/data/companyDetailsData/getPresignedUrl', {
                mastercompanyid: companyInfo['mastercompanyid'], 
                filename: exportFilename, 
                templatetype: templateType, 
            }).then(res => {
                console.log(res); 
                // show alert on success
                resolve(res); 
            }).catch(err => {
                console.log(err); 
                // show alert on error
                snackbarAlert(true, 'Error Retrieving Data.', 'error'); 
                // reject on error
                reject(err); 
            }); 
        }); 
    }

    // function to set snackbar state
    const snackbarAlert = (show, message, color) => {
        setSnackbarMessage(snackbarMessage => snackbarMessage = message);
        setSnackbarColor(snackbarColor => snackbarColor = color);
        setShowSnackbar(showSnackbar => showSnackbar = show);
    }

    // refresh contract upload table
    const refreshContractUpload = () => {
        setContractSpinner(contractSpinner => contractSpinner = true); 
        return new Promise((resolve, reject) => {
            // hide modal for upload client contracts
            setUploadClientContractModal(uploadClientContractModal => uploadClientContractModal = false); 
            // fetch refresh data
            GET( '/data/uploadClientContract', {
                mastercompanyid: companyInfo['mastercompanyid'], 
            }).then(res => {
                // set state for upload contract table 
                setUploadContractsDataList(uploadContractsDataList => uploadContractsDataList = res['data']['UploadContractsData']); 
                setUploadContractsTableHeader(uploadContractsTableHeader => uploadContractsTableHeader = res['data']['UploadContractsCols']); 
                setShowUploadContractsDataList(showUploadContractsDataList => showUploadContractsDataList = res['data']['UploadContractsData']);               
                // stop showing spinner
                setContractSpinner(contractSpinner => contractSpinner = false); 
                // trigger filter
                setTriggerFilter(triggerFilter => triggerFilter = !triggerFilter); 
                resolve(res);
            }).catch(err => {
                // stop showing spinner
                setContractSpinner(contractSpinner => contractSpinner = false); 
                reject(err);
            });
        }); 
    }

    // wrap get funciton for refresh data 
    const refreshData = () => {
        return new Promise ((resolve, reject) => {
            setRefreshSpinner(refreshSpinner => refreshSpinner = true); 
            GET('/data/processingLogData', {
                mastercompanyid: companyInfo['mastercompanyid'], 
            }).then(res => {
                // set state for processing log table 
                setProcessingLogDataList(processingLogDataList => processingLogDataList = res['data']['ProcessingLogData']); 
                setProcessingLogTableHeader(processingLogTableHeader => processingLogTableHeader = res['data']['ProcessingLogCols']);
                // set state for additiion details 
                // setAdditionalDetailsValue(additionalDetailsValue => additionalDetailsValue = res['data']['AdditionalDetails']); 
                setAdditionalDetailsTableHeader(additionalDetailsTableHeader => additionalDetailsTableHeader = res['data']['AdditionalDetailsCols']); 
                // set state for rebate setups table
                setRebateSetupDataList(rebateSetupDataList => rebateSetupDataList = res['data']['RebateSetupData']);
                setRebateSetupTableHeader(rebateSetupTableHeader => rebateSetupTableHeader = res['data']['RebateSetupCols']);
                // trigger filter
                setTriggerFilter(triggerFilter => triggerFilter = !triggerFilter); 
                // hide spinner
                setRefreshSpinner(refreshSpinner => refreshSpinner = false); 
                // resolve
                resolve(res); 
            }).catch(err => {
                // hide spinner
                setRefreshSpinner(refreshSpinner => refreshSpinner = false); 
                // reject
                reject(err); 
            })
        }); 
    }

    // get all data to render on page from data endpoint
    const getCompanyDetailsData = (masterCompanyName) => {
        // show spinner while getting data
        setShowSpinner(showSpinner => showSpinner = true);
        return new Promise ((resolve, reject) => {
            GET('/data/companyDetailsData', {mastercompany: masterCompanyName, mastercompanyid: companyInfo['mastercompanyid']}).then(res => {
                // set state for top label
                setRebateFrequency(rebateFrequency => rebateFrequency = res['data']['MasterCompanyData'][0]['incentivefreq']);
                if (res['data']['MasterCompanyData'][0]['primaryccrm']){
                    setPrimaryCCRM(primaryCCRM => primaryCCRM = res['data']['MasterCompanyData'][0]['primaryccrm']);
                } 
                else {
                    setPrimaryCCRM(primaryCCRM => primaryCCRM = ''); 
                }
                setPortfolio(portfolio => portfolio = res['data']['MasterCompanyData'][0]['incentivetype']);
                setMasterCompanyId(masterCompanyId => masterCompanyId = res['data']['MasterCompanyData'][0]['mastercompanyid']);
                setMasterCompanyData(masterCompanyData => masterCompanyData = res['data']['MasterCompanyData'][0]);
                setAnniversaryMonth(anniversaryMonth => anniversaryMonth = res['data']['AnniversaryMonth']); 
                // set state for EditCompanyDetailsModal
                setPortfolioList(portfolioList => portfolioList = res['data']['RebateTypeList']);
                setRebateFrequencyList(rebateFrequencyList => rebateFrequencyList = res['data']['RebateFrequencyData']);
                // set state for sub companies table 
                setSubCompanyDataList(subCompanyDataList => subCompanyDataList = res['data']['SubCompanyTableData']);
                setShowSubCompanyDataList(showSubCompanyDataList => showSubCompanyDataList = res['data']['SubCompanyTableData']);
                setSubCompanyTableHeader(subCompanyTableHeader => subCompanyTableHeader = res['data']['SubCompanyTableCols']);
                // set state for rebate setups table
                setRebateSetupDataList(rebateSetupDataList => rebateSetupDataList = res['data']['RebateSetupData']);
                setShowRebateSetupDataList(showRebateSetupDataList => showRebateSetupDataList = res['data']['RebateSetupData']);
                setRebateSetupTableHeader(rebateSetupTableHeader => rebateSetupTableHeader = res['data']['RebateSetupCols']);
                // set performance bonus table state
                setPerformanceBonusDataList( performanceBonusDataList => performanceBonusDataList = res['data']['PerformanceBonusTableData']);
                setShowPerformanceBonusDataList ( performanceBonusDataList => performanceBonusDataList = res['data']['PerformanceBonusTableData'] );
                setPerformanceBonusTableHeader( performanceBonusTableHeader => performanceBonusTableHeader = res['data']['PerformanceBonusTableCols']);
                // set state for entitlements table
                setEntitlementsDataList(entitlementsDataList => entitlementsDataList = res['data']['CompanyEntitlementsData']);
                setEntitlementsTableHeader(entitlementsTableHeader => entitlementsTableHeader = res['data']['CompanyEntitlementsCols']);
                // set state for entitlements modal 
                setRoleList(roleList => roleList = ['--select--', ...res['data']['UserRoleData']]);
                // set state for rebate setups modal 
                setProductList(productList => productList = ['--select--', ...res['data']['ProductTypeData']]);
                setBillingRateList(billingRateList => billingRateList = ['--select--', ...res['data']['BillingRateData']]);
                setFeeCategoryList(feeCategoryList => feeCategoryList = ['--select--', ...res['data']['FeeCategoryData']]);
                setSubCompanyList(subCompanyList => subCompanyList = res['data']['SubCompanyList']);
                setMasterSetupIdsList(masterSetupIdsList => masterSetupIdsList = res['data']['MasterSetupIdList']);
                setRebateTypeList(rebateTypeList => rebateTypeList = ['--select--', ...res['data']['RebateTypeList']]);
                setPayoutMethodList(payoutMethodList => payoutMethodList = ['--select--', ...res['data']['PayoutMethodList']]);
                // set state for payout method data for validations 
                setPayoutMethodData(payoutMethodData => payoutMethodData = res['data']['PayoutMethodData']); 
                // change null to None for daysToPayList
                let index = res['data']['DaysToPayData'].indexOf(null); 
                if (index !== -1){
                    res['data']['DaysToPayData'][index] = 'None';
                }
                setDaysToPayList(daysToPayList => daysToPayList = ['--select--', ...res['data']['DaysToPayData']]);
                // set state for rebate setups filter dropdown
                setSubCompanyFilterList(subCompanyFilterList => subCompanyFilterList = ['All', ...res['data']['SubCompanyList']]);
                setProductFilterList(productFilterList => productFilterList = ['All', ...res['data']['ProductTypeData']]);
                setFeeCategoryFilterList(feeCategoryFilterList => feeCategoryFilterList = ['All', ...res['data']['FeeCategoryData']]);
                setBillingRateFilterList(billingRateFilterList => billingRateFilterList = ['All', ...res['data']['BillingRateData']]);
                setDaysToPayFilterList(daysToPayFilterList => daysToPayFilterList = ['All', ...res['data']['DaysToPayData']]);
                // set state for payout info table
                setPayoutInfoDataList(payoutInfoDataList => payoutInfoDataList = res['data']['PayoutInfoTableData']);
                setPayoutInfoTableHeader(payoutInfoTableHeader => payoutInfoTableHeader = res['data']['PayoutInfoTableCols']);
                // set edit button bool based on user entitlement 
                setEditButtonBool(editButtonBool => editButtonBool = res['data']['UserEntitlement']);
                setUserEntitlementId(userEntitlementId => userEntitlementId = res['data']['UserEntitlementId']);
                // set state for additional details option
                setAdditionalDetailsValue(additionalDetailsValue => additionalDetailsValue = res['data']['AdditionalDetails']); 
                setAdditionalDetailsTableHeader(additionalDetailsTableHeader => additionalDetailsTableHeader = res['data']['AdditionalDetailsCols']); 
                // set state for rebate rate input type id
                setRebateInputTypeId(rebateInputTypeId => rebateInputTypeId = res['data']['RebateInputTypeId']); 
                // set state for processors
                setProcessorList(processorList => processorList = res['data']['ProcessorIdsList']); 
                setProcessorDefaultData(processorDefaultData => processorDefaultData = res['data']['RebateIncentiveData']); 
                setEditableProcessor(editableProcessor => editableProcessor = res['data']['EditableProcessor']); 
                // set editable billing rate
                setEditableBillingRate(editableBillingRate => editableBillingRate = res['data']['EditableBillingRate']); 
                // set consortium id 
                setConsortiumId(consortiumId => consortiumId = res['data']['ConsortiumId']); 
                setConsortiumFlag(consortiumFlag => consortiumFlag = res['data']['ConsortiumFlag']); 
                setConsortiumDataList(consortiumDataList => consortiumDataList = res['data']['ConsortiumDataList']); 
                setConsortiumTableHeader(consortiumTableHeader => consortiumTableHeader = res['data']['ConsortiumCols']); 
                // set tabs depending on consortiumFlag
                if (res['data']['ConsortiumFlag'] == true){ 
                    setTabList(tabList => tabList = getPageTabs(pageTabs, [{tag: 'Consortium View', key: 7, active: true}]));
                }
                else {
                    setTabList(tabList => tabList = getPageTabs(pageTabs));
                    // change tabValue if it is greater than equal to 5 (tabList.length)
                    if(tabValue == 7){
                        setTabValue(tabValue => tabValue = 0); 
                    }
                }
                // set state for excel upload 
                if (res['data']['TemplateTypeData'].length > 0){
                    setTemplateType(templateType => templateType = res['data']['TemplateTypeData'][0]['templatetypedescription']); 
                    setExportFilename(exportFilename => exportFilename = res['data']['TemplateTypeData'][0]['filename']);
                } 
                setTemplateTypeData(templateTypeData => templateTypeData = res['data']['TemplateTypeData']);
                // set state for processing log table 
                setProcessingLogDataList(processingLogDataList => processingLogDataList = res['data']['ProcessingLogData']); 
                setProcessingLogTableHeader(processingLogTableHeader => processingLogTableHeader = res['data']['ProcessingLogCols']);
                // set state for upload contract table 
                setUploadContractsDataList(uploadContractsDataList => uploadContractsDataList = res['data']['UploadContractsData']); 
                setUploadContractsTableHeader(uploadContractsTableHeader => uploadContractsTableHeader = res['data']['UploadContractsCols']);  
                setShowUploadContractsDataList(showUploadContractsDataList => showUploadContractsDataList = res['data']['UploadContractsData']);               
                // trigger filter
                setTriggerFilter(triggerFilter => triggerFilter = !triggerFilter);
                // stop showing spinner
                setShowSpinner(showSpinner => showSpinner = false);
                resolve(res);
            }).catch(err => {
                // stop showing spinner
                setShowSpinner(showSpinner => showSpinner = false);
                reject(err);
            });
        });
    }

    // get user info from users endpoint
    const getUserInfoData = () => {
        return new Promise((resolve, reject) => {
            GET('/user/inv/headers', {}).then(res => {
                let temp = ['None'];
                // if user is active add to list
                for (let i = 0; i < res['data']['UserInventory']['Users'].length; i++){
                    if (res['data']['UserInventory']['Users'][i]['Enabled'] == true){
                        temp.push(res['data']['UserInventory']['Users'][i]['Username']);
                    }
                }
                // set userlist to render on screen
                setUserList(userList => userList = temp);
                resolve(res);
            }).catch(err => {
                reject(err);
            })
        });
    }

    useEffect(() => {
        // make initial get request on render
        getCompanyDetailsData(company).then(res => {
            console.log(res);
        }).catch( err => {
            console.log(err);
        });
        // make get request to get user list on render
        getUserInfoData().then(res=> {
            console.log(res);
        }).catch(err => {
            console.log(err);
        });
    }, [companyInfo]);

    // useEffect to filter sub company table for active/inactive
    useEffect(() => {
        // do not run on initial render
        if (!initialRender1.current){
            // set status var from checkbox 
            let status = 'Active';
            if (showInactiveSubcompanies){
                status = 'All';
            }
            // declare list of functions with parameters to filter/search list
            const functionList = [
                {function: statusFilter, value: status, flag: 1, cols: ['active']}, 
            ];
            // use applyFilters function to search by running all functions declared above
            let filteredList = applyFilters(subCompanyDataList, functionList);
            // update showDataList
            setShowSubCompanyDataList(showSubCompanyDataList => showSubCompanyDataList = filteredList);
        }
        else{
            initialRender1.current = false;
        }
    }, [showInactiveSubcompanies, triggerFilter]);

    // useEffect to filter rebate setup table 
    useEffect(() => {
        // do not run on initial render
        if (!initialRender2.current){
            // set status var from checkbox 
            let status = 'Active';
            if (showInactiveSetups){
                status = 'All';
            }
            // declare list of functions with parameters to filter/search list
            const functionList = [
                {function: statusFilter, value: status, flag: 1, cols: ['active']}, 
                {function: standardFilter, value: subCompanyFilter, flag: 1, cols: ['companyid']}, 
                {function: standardFilter, value: productFilter, flag: 1, cols: ['producttypedescription']}, 
                {function: standardFilter, value: feeCategoryFilter, flag: 1, cols: ['feecategorydescription']}, 
                {function: standardFilter, value: billingRateFilter, flag: 1, cols: ['billingratedescription']},
                {function: standardWithNoneFilter, value: daysToPayFilter, flag: 1, cols: ['daystopay']},
            ];
            // use applyFilters function to search by running all functions declared above
            let filteredList = applyFilters(rebateSetupDataList, functionList);
            // update showDataList
            setShowRebateSetupDataList(showRebateSetupDataList => showRebateSetupDataList = filteredList);
        }
        else{
            initialRender2.current = false;
        }
    }, [showInactiveSetups, subCompanyFilter, productFilter, feeCategoryFilter, billingRateFilter, daysToPayFilter, triggerFilter]);

    // useEffect to filter performance bonus table for active/inactive
    useEffect(() => {
        // do not run on initial render
        if (!initialRender3.current){
            // set status var from checkbox 
            let status = 'Active';
            if (showInactivePerformance){
                status = 'All';
            }
            // declare list of functions with parameters to filter/search list
            const functionList = [
                {function: statusFilter, value: status, flag: 1, cols: ['active']}, 
            ];
            // use applyFilters function to search by running all functions declared above
            let filteredList = applyFilters(performanceBonusDataList, functionList);
            // update showDataList
            setShowPerformanceBonusDataList(showPerformanceBonusDataList => showPerformanceBonusDataList = filteredList);
        }
        else {
            initialRender3.current = false;
        }
    }, [showInactivePerformance, triggerFilter]);

    // useEffect to filter entitlements data table
    useEffect(() => {
        if (!initialRender4.current){
            // set status var from checkbox
            let status = 'Active'; 
            if (showInactiveEntitlements){
                status = 'All'
            }
            // declare list of functions with parameters to filter/search list
            const functionList = [
                {function: statusFilter, value: status, flag: 1, cols: ['active']}, 
            ];
            // use applyFilters function to search by running all functions declared above
            let filteredList = applyFilters(entitlementsDataList, functionList);
            // update showDataList
            setShowEntitlementsDataList(showEntitlementsDataList => showEntitlementsDataList = filteredList);
        }
        else {
            initialRender4.current = false; 
        }
    }, [showInactiveEntitlements, triggerFilter]); 

    // useEffect to filter contracts table 
    useEffect(() => {
        if (!initialRender5.current){
            // set status var from checkbox
            let status = 'Active'; 
            if (showInactiveContractUploads){
                status = 'All'
            }
            // declare list of functions with parameters to filter/search list
            const functionList = [
                {function: statusFilter, value: status, flag: 1, cols: ['active']}, 
            ];
            // use applyFilters function to search by running all functions declared above
            let filteredList = applyFilters(uploadContractsDataList, functionList);
            // update showDataList
            setShowUploadContractsDataList(showUploadContractsDataList => showUploadContractsDataList = filteredList);
        }
        else {
            initialRender5.current = false; 
        }
    }, [showInactiveContractUploads, triggerFilter]); 

    // use Effect to run on initial render to set tabs
    useEffect(() => {
        setTabList(tabList => tabList = getPageTabs(pageTabs)); 
    }, []); 

    // object with variables to pass to context
    const settings = {
        company, 
        companyInfo, 
        editCompanyDetailsModal, 
        setEditCompanyDetailsModal,
        editSubCompanyModal, 
        setEditSubCompanyModal,
        editSubCompanyRow,
        setEditSubCompanyRow,
        addSubCompanyModal, 
        setAddSubCompanyModal,
        rebateSetupDataList, 
        setRebateSetupDataList, 
        rebateSetupTableHeader, 
        setRebateSetupTableHeader, 
        editRebateSetupModal, 
        setEditRebateSetupModal, 
        editRebateSetupRow, 
        setEditRebateSetupRow,
        addRebateSetupModal, 
        setAddRebateSetupModal,
        editEntitlementsModal, 
        setEditEntitlementsModal, 
        editEntitlementsRow, 
        setEditEntitlementsRow, 
        addEntitlementsModal, 
        setAddEntitlementsModal, 
        roleList, 
        userList, 
        editPayoutTypeModal,
        setEditPayoutTypeModal, 
        editPerformanceBonusModal, 
        setEditPerformanceBonusModal, 
        editPerformanceBonusRow, 
        setEditPerformanceBonusRow, 
        addPerformanceBonusModal, 
        setAddPerformanceBonusModal, 
        masterCompanyData, 
        setMasterCompanyData, 
        rebateFrequencyList,  
        portfolioList,
        getCompanyDetailsData,  
        showSnackbar, 
        setShowSnackbar,
        snackbarMessage, 
        snackbarColor,   
        snackbarAlert,
        productList, 
        billingRateList,
        daysToPayList, 
        feeCategoryList, 
        subCompanyList, 
        masterSetupIdsList, 
        rebateTypeList, 
        editPayoutInfoModal, 
        setEditPayoutInfoModal, 
        editPayoutInfoRow, 
        setEditPayoutInfoRow, 
        payoutMethodList,
        addPayoutInfoModal, 
        setAddPayoutInfoModal, 
        editAccountIdsModal, 
        setEditAccountIdsModal, 
        editButtonBool, 
        payoutMethodData, 
        showAdditionalDetailsModal, 
        setShowAdditionalDetailsModal, 
        additionalDetailsTableHeader, 
        additionalDetailsValue, 
        additionalDetailsRow, 
        setAdditionalDetailsRow,
        rebateInputTypeId, 
        processorList, 
        processorDefaultData, 
        portfolio, 
        editableBillingRate, 
        editableProcessor, 
        viewModal, 
        setViewModal, 
        viewCompany, 
        setViewCompany, 
        consortiumFlag, 
        showProcessingCommentModal, 
        setShowProcessingCommentModal,
        processingRow,
        setProcessingRow, 
        anniversaryMonth,
        setAnniversaryMonth, 
        monthList, 
        setMonthList,
        uploadClientContractModal, 
        setUploadClientContractModal,
        refreshContractUpload, 
        showContractCommentModal, 
        setShowContractCommentModal,
        showEditContractModal, 
        setShowEditContractModal,  
        contractUploadRow, 
        setContractUploadRow, 
        uploadContractsDataList, 
    };

    return(
        <div class='summary-page'>
            {/* wrap children so they have access to variables in PageContext */}
            <PageContext.Provider value={ settings }>
                {/* header and navslider present on every page */}
                <HeaderBanner indicator={1}/>
                {/* <Navslider indicator={-1} backPath={'/CompanyProfilePage'}/> */}
                <Navslider indicator={-1} backPath={backPath}/>
                {/* modal for viewing sub companies. Default is hidden state */}
                <ViewSubCompaniesModal />
                {/* all modals that can be activated. By default they are initialized in hidden state */}
                <EditCompanyDetailsModal/>
                <AddSubCompanyModal/>
                <EditSubCompanyModal/> 
                <AddRebateSetupModal/>  
                <EditRebateSetupModal/> 
                <AddCompanyEntitlementsModal/>
                <EditCompanyEntitlementsModal/> 
                <EditPerformanceBonusModal/>
                <AddPerformanceBonusModal/>
                <AddPayoutInfoModal/>
                <EditPayoutInfoModal/>
                <EditAccountIdsModal/>
                <AdditionalDetailsModal/>
                <ProcessingCommentModal/>
                <AddClientContractModal />
                <ContractUploadCommentModal />
                <EditUploadClientContractModal />
                {/* snackbar component for alerts */}
                <SnackbarAlert />
                {/* top label to view company data */}
                <div class='top-label-div'>
                    <label class='top-label-text'><b>Company Details</b></label>
                    <br/><br/>
                    <div class='label-text-div'>
                        <span class='label-text-span-left'>
                            <PageReadOnlyInput tag='Company: ' value={company} />
                        </span>
                        <span class='label-text-span-mid'>
                            <PageReadOnlyInput tag='Rebate ID: ' value={masterCompanyId} />
                        </span>
                        <span class='label-text-span-right'>
                            <PageReadOnlyInput tag='Portfolio: ' value={portfolio} />
                        </span>
                    </div>
                    <br/>
                    <div class='label-text-div'>
                        <span class='label-text-span-left'>
                            <PageReadOnlyInput tag='Primary CCRM: ' value={primaryCCRM} />
                        </span>
                        <span>
                            <PageReadOnlyInput tag='Rebate Frequency: ' value={rebateFrequency} />
                        </span>
                        <span class='label-text-span-right'>
                            <PageReadOnlyInput tag='Anniversary Month: ' value={anniversaryMonth} />
                        </span>
                    </div>
                    <br/>
                    <div class='label-text-div'>
                        <span class='label-text-span-right'>
                            <EntitledButton entitledBool={editButtonBool} tag='Edit' cssClass='summary-page-button' onClick={()=>setEditCompanyDetailsModal(editCompanyDetailsModal => editCompanyDetailsModal = true)}/>
                        </span>                    
                    </div>
                    <br /><br/>
                </div>
                <br/>
                {/* tabs component for navigation between sections without leaving page */}
                <div class='data-table-div'>
                    <PageTabs value={tabValue} setValue={setTabValue} tabList={tabList}/>
                </div>
                {/* logic to show different items based on the tab that has been selected */}
                { tabValue == 0 ?
                <div class='data-table-sm-div'>
                    <span class='label-text-span-left'>
                        <label class='table-label'><b>Sub Company List: </b></label>
                    </span>
                    <span> 
                        <FormControlLabel
                            value="end"
                            control={<Checkbox
                                sx={{
                                    color: '#000000',
                                    '&.Mui-checked': {
                                    color: '#000000',
                                    },
                                }} />}
                            label="Show inactive sub companies"
                            labelPlacement="end"
                            sx={{
                                color: 'black',
                            }}
                            checked={showInactiveSubcompanies}
                            onChange={(e)=>{setShowInactiveSubcompanies(showInactiveSubcompanies => showInactiveSubcompanies = e.target.checked)}}
                        />
                    </span>
                    <span class='label-text-span-right'>
                        {/* <button class='table-add-button' onClick={()=>setAddSubCompanyModal(addSubCompanyModal => addSubCompanyModal = true)}>Add Sub Company</button> */}
                        <EntitledButton entitledBool={editButtonBool} tag='Add Sub Company' cssClass='table-add-button' onClick={()=>setAddSubCompanyModal(addSubCompanyModal => addSubCompanyModal = true)} />
                    </span>
                    <OrderedDataTable RowComponent={SubCompanyRowComponent} tableHeader={subCompanyTableHeader} dataList={showSubCompanyDataList} showSpinner={showSpinner} numToPage={25} skeletonHeight='28vh'/>
                </div>
                : tabValue == 1 ? 
                <>
                    <div class='rebate-filter-label-div'>
                        <span>
                            <span class='label-text-span-left'>
                                <label class='rebate-filter-label'><b>Rebate Setup Filters: </b></label>
                            </span>
                            <span class='label-text-span-mid'>
                                <PageDropdown tag='Sub Company: ' value={subCompanyFilter} setValue={setSubCompanyFilter} list={subCompanyFilterList}/> 
                            </span>
                            <span class='label-text-span-right'>
                                <PageDropdown tag='Product Type: ' value={productFilter} setValue={setProductFilter} list={productFilterList}/>
                            </span>
                        </span>                     
                        <br/><br/>
                        <span>
                            <span class='label-text-span-left'>
                                <PageDropdown tag='Fee Category: ' value={feeCategoryFilter} setValue={setFeeCategoryFilter} list={feeCategoryFilterList} />    
                            </span>
                            <span class='label-text-span-mid'>
                                <PageDropdown tag='Billing Rate: ' value={billingRateFilter} setValue={setBillingRateFilter} list={billingRateFilterList}/>
                            </span>
                            <span class='label-text-span-right'>
                                <PageDropdown tag='Days To Pay: ' value={daysToPayFilter} setValue={setDaysToPayFilter} list={daysToPayFilterList} />
                            </span>
                        </span>
                    </div>

                    <div class='data-table-div'>
                        <span class='label-text-span-left'>
                            <label class='table-label'><b>Rebate Setup: </b></label>
                        </span>
                        <span>
                            <FormControlLabel
                                value="end"
                                control={<Checkbox
                                    sx={{
                                        color: '#000000',
                                        '&.Mui-checked': {
                                        color: '#000000',
                                        },
                                    }} />}
                                label="Show inactive rebate setups"
                                labelPlacement="end"
                                sx={{
                                    color: 'black',
                                }}
                                checked={showInactiveSetups}
                                onChange={(e)=>{setShowInactiveSetups(showInactiveSetups => showInactiveSetups = e.target.checked)}}
                            />
                        </span>
                        <span class='label-text-span-right'>
                            <EntitledButton entitledBool={editButtonBool} tag='Add Rebate Setup' cssClass='table-add-button' onClick={()=>setAddRebateSetupModal(addRebateSetupModal => addRebateSetupModal = true)} /> 
                        </span>
                        <OrderedDataTable RowComponent={RebateSetupRowComponent} tableHeader={rebateSetupTableHeader} dataList={showRebateSetupDataList} showSpinner={showSpinner} numToPage={25} skeletonHeight='28vh'/>
                    </div>
                </>
                : tabValue == 2 ? 
                <div class='data-table-sm-div'>
                    <span class='label-text-span-left'>
                        <label class='table-label'><b>Performance Bonus:</b></label>
                    </span>
                    <span>
                        <FormControlLabel
                            value="end"
                            control={<Checkbox
                                sx={{
                                    color: '#000000',
                                    '&.Mui-checked': {
                                    color: '#000000',
                                    },
                                }} />}
                            label="Show inactive performance tiers"
                            labelPlacement="end"
                            sx={{
                                color: 'black',
                            }}
                            checked={showInactivePerformance}
                            onChange={(e)=>{setShowInactivePerformance(showInactivePerformance => showInactivePerformance = e.target.checked)}}
                        />
                    </span>
                    <span class='label-text-span-right'>
                        <EntitledButton entitledBool={editButtonBool} tag='Add Bonus Tier' cssClass='table-add-button' onClick={()=>setAddPerformanceBonusModal(addPerformanceBonusModal => addPerformanceBonusModal = true)} /> 
                    </span>
                    <OrderedDataTable RowComponent={PerformanceBonusRowComponent} tableHeader={performanceBonusTableHeader} dataList={showPerformanceBonusDataList} showSpinner={showSpinner} numToPage={25} skeletonHeight='28vh'/>
                </div>
                : tabValue == 3 ? 
                <div class='data-table-sm-div'>
                    <span class='label-text-span-left'>
                        <label class='table-label'><b>User Entitlements: </b></label>
                    </span>
                    <span>
                        <FormControlLabel
                            value="end"
                            control={<Checkbox
                                sx={{
                                    color: '#000000',
                                    '&.Mui-checked': {
                                    color: '#000000',
                                    },
                                }} />}
                            label="Show inactive user entitlements"
                            labelPlacement="end"
                            sx={{
                                color: 'black',
                            }}
                            checked={showInactiveEntitlements}
                            onChange={(e)=>{setShowInactiveEntitlements(showInactiveEntitlements => showInactiveEntitlements = e.target.checked)}}
                        />
                    </span>
                    <span class='label-text-span-right'>
                        <EntitledButton entitledBool={getAdmin()} tag='Add Entitlement' cssClass='table-add-button' onClick={()=>setAddEntitlementsModal(addEntitlementsModal => addEntitlementsModal = true)}/>
                    </span>
                    <OrderedDataTable RowComponent={CompanyEntitlementsRowComponent} tableHeader={entitlementsTableHeader} dataList={showEntitlementsDataList} showSpinner={showSpinner} numToPage={25} skeletonHeight='28vh'/>
                </div>
                : tabValue == 4 ?
                <>
                    <div class='data-table-sm-div'>
                        <label class='mid-page-label-text'><b>Payout Info: </b></label>
                        <EntitledButton entitledBool={editButtonBool} tag='Add Payout Info' cssClass='table-add-button' onClick={()=>setAddPayoutInfoModal(addPayoutInfoModal => addPayoutInfoModal = true)} />
                        <OrderedDataTable RowComponent={PayoutInfoRowComponent} tableHeader={payoutInfoTableHeader} dataList={payoutInfoDataList} showSpinner={showSpinner} numToPage={25} skeletonHeight='28vh'/>
                    </div>
                </>
                : tabValue == 5 ? 
                <>
                    <div class='upload-label-div'>
                        <span class='label-dropdown-left'>
                            {/* get template from s3 bucket */}
                            <ExportS3ExcelButton tag={exportFilename.replace('.xlsx', '') + '-' + companyInfo['mastercompanyid']} getData={onTemplateExport} removeDate={true}/>
                        </span>
                        <span class='label-dropdown-right'>
                            {/* choose type of template to export */}
                            <PageDropdownWithIDs tag='Template Type: ' value={templateType} setValue={setTemplateType} list={templateTypeData} id={exportFilename} setId={setExportFilename} idKey='filename' valueKey='templatetypedescription' />
                        </span>
                    </div>
                    <div style={{marginTop: '15px', marginBottom: '15px'}}>
                    <BannerAlert show={true} type='warning' message={'Warning: Always download new template file after each successfully processed upload file. Do not change template file name.' } width='80%' />
                    </div>
                    {/* Only show upload if user is entitled */}
                    { editButtonBool ?
                        <>
                            <div class='upload-label-div'>
                                <span class='label-dropdown-left'>
                                    {/* upload excel file to s3 */}
                                    <UploadS3ExcelButton tag='Upload Excel' folder='uploads/' file={file} fileInput={fileInput} putMetadata={putUploadMetadata} refreshData={refreshData} snackbarAlert={snackbarAlert} validateData={()=>{return true}} showSpinner={true}/>                    
                                </span>                      
                                <span class='label-dropdown-right'>
                                    <input style={{float: 'right'}} class='page-file-input' type='file' accept='.xls,.xlsx' onChange={(e)=>onFileChange(e)} ref={ ref => fileInput.current = ref}/>
                                </span>
                            </div>
                            <div style={{marginTop: '15px', marginBottom: '15px'}}>
                                <BannerAlert show={true} type='info' message={'When you upload a new ' + templateType + ' template, it overwrites all existing ' + templateType + ' for this master company.' } width='80%' />                               
                            </div>
                        </>
                        :<></>
                    }
                    <div class='data-table-sm-div'>
                        <div>
                            <label class='mid-page-label-text' style={{float: 'left'}}><b>Excel Upload Log: </b></label>
                            <button class='table-add-button' style={{float: 'right'}} onClick={()=>{refreshData()}}>Refresh</button>
                        </div>
                        <OrderedDataTable RowComponent={ProcessingRowComponent} tableHeader={processingLogTableHeader} dataList={processingLogDataList} showSpinner={showSpinner || refreshSpinner} numToPage={25} skeletonHeight='28vh'/>
                    </div>
                </>
                : tabValue == 6 ? 
                <>  
                    <div class='data-table-div'>
                        <span class='label-text-span-left'>
                            <label class='table-label'><b>Upload Client Contracts: </b></label>
                        </span>
                        <span>
                            <FormControlLabel
                                value="end"
                                control={<Checkbox
                                    sx={{
                                        color: '#000000',
                                        '&.Mui-checked': {
                                        color: '#000000',
                                        },
                                    }} />}
                                label="Show inactive contracts"
                                labelPlacement="end"
                                sx={{
                                    color: 'black',
                                }}
                                checked={showInactiveContractUploads}
                                onChange={(e)=>{setShowInactiveContractUploads(showInactiveContractUploads => showInactiveContractUploads = e.target.checked)}}
                            />
                        </span>
                        <span class='label-text-span-right'>
                            <EntitledButton entitledBool={editButtonBool} tag='Add Client Contract' cssClass='table-add-button' onClick={() => {setUploadClientContractModal(uploadClientContractModal => uploadClientContractModal = true)}} />
                        </span>
                        <OrderedDataTable RowComponent={UploadContractRowComponent} tableHeader={uploadContractsTableHeader} dataList={showUploadContractsDataList} showSpinner={showSpinner || contractSpinner} numToPage={25} skeletonHeight='28vh' />
                    </div>
                </>
                : tabValue == 7 ? 
                <>
                    <div class='data-table-sm-div'>
                        <div class='consortium-label-div'>
                            <label><b>Consortium ID: {consortiumId}</b></label>
                        </div>
                        <label class='mid-page-label-text'><b>Consortium View: </b></label>
                        <OrderedDataTable RowComponent={ConsortiumRowComponent} tableHeader={consortiumTableHeader} dataList={consortiumDataList} showSpinner={showSpinner} numToPage={25} skeletonHeight='28vh'/>
                    </div>
                </>
                :<></>} 
                <br/>
            </PageContext.Provider>
        </div> 
    );
}

export default CompanyDetailsPage;