import { useState, useEffect, useRef, useContext } from 'react';
import { useSelector } from 'react-redux';
import PageContext from '../sub-components/PageContext';
import HeaderBanner from '../sub-components/HeaderBanner';
import Navslider from '../sub-components/Navslider';
import OrderedDataTable from '../sub-components/OrderedDataTable';
import OrderedDataTableWithFooter from '../sub-components/OrderedDataTableWithFooter'; 
import RebateAggregateRow from '../sub-components/RebateAggregateRow';
import RebateBreakupRow from '../sub-components/RebateBreakupRow';
import WorkflowButton from '../sub-components/WorkflowButton';
import OverrideModal from '../sub-components/OverrideModal';
import PageReadOnlyInput from '../sub-components/PageReadOnlyInput';
import PageDropdown from '../sub-components/PageDropdown';
import WorkflowModal from '../sub-components/WorkflowModal';
import PageTabs from '../sub-components/PageTabs'; 
import RebateSetupRow from '../sub-components/RebateSetupRow';
import EditPayoutTypeModal from '../sub-components/EditPayoutTypeModal';
import PayoutInfoRow from '../sub-components/PayoutInfoRow';
import { HttpContext } from '../../api-services/HttpService';
import standardFilter from '../functions/standardFilter';
import lessThanOrEqualFilter from '../functions/lessThanOrEqualFilter';
import applyFilters from '../functions/applyFilters';
import SnackbarAlert from '../sub-components/SnackbarAlert';
import formatDate from '../functions/formatDate'; 
import AdhocDataRow from '../sub-components/AdhocDataRow';
import EntitledButton from '../sub-components/EntitledButton'; 
import AddAdhocPaymentModal from '../sub-components/AddAdhocPaymentModal';  
import EditAdhocPaymentModal from '../sub-components/EditAdhocPaymentModal'; 
import AggregateTotalsFooter from '../sub-components/AggregateTotalsFooter'; 
import AdditionalDetailsModal from '../sub-components/AdditionalDetailsModal';  
import standardWithNoneFilter from '../functions/standardWithNoneFilter'; 
import ConsortiumViewRow from '../sub-components/ConsortiumViewRow'; 
import ConsortiumViewFooter from '../sub-components/ConsortiumViewFooter'; 
import UploadContractRow from '../sub-components/UploadContractRow'; 
import ContractUploadCommentModal from '../sub-components/ContractUploadCommentModal';
import getPageTabs from '../functions/getPageTabs';

const CompanyPayoutView = () =>{
    // initialize http context 
    const {
        GET,  
    } = useContext(HttpContext);
    // intialize default page tabs 
    const pageTabs = [
        {tag: 'Current Cycle', key: 0, active: true}, 
        {tag: 'Historical Cycles', key: 1, active: true}, 
        {tag: 'Rebate Setups', key: 2, active: true}, 
        {tag: 'Payout Info', key: 3, active: true}, 
        {tag: 'Payment History', key: 4, active: true}, 
        {tag: 'Client Contracts', key: 5, active: process.env.REACT_APP_UPLOAD_CLIENT_CONTRACTS == '1'}, 
        // {tag: 'Consortium View', key: 6, active: false}, 
    ]; 
    // global redux state for company (passed in from other route)
    const company = useSelector((state) => state.company);
    const companyInfo = useSelector((state) => state.companyInfo);
    // initailize state for period id dropdowns
    const [periodId, setPeriodId] = useState('');
    const [periodList, setPeriodList] = useState(['']);
    const [periodIdHistorical, setPeriodIdHistorical] = useState('');
    const [periodListHistorical, setPeriodListHistorical] = useState(['']);
    // initialize state for tables 
    const [aggregateDataList, setAggregateDataList] = useState([]);
    const [showAggregateDataList, setShowAggregateDataList] = useState([]);
    const [aggregateTableHeader, setAggregateTableHeader] = useState([]);
    const [breakupDataList, setBreakupDataList] = useState([]);
    const [showBreakupDataList, setShowBreakupDataList] = useState([]);
    const [breakupTableHeader, setBreakupTableHeader] = useState([]);
    const [rebateSetupDataList, setRebateSetupDataList] = useState([]);
    const [showRebateSetupDataList, setShowRebateSetupDataList] = useState([]);
    const [rebateSetupTableHeader, setRebateSetupTableHeader] = useState([]);
    const [payoutInfoDataList, setPayoutInfoDataList] = useState([]);
    const [payoutInfoTableHeader, setPayoutInfoTableHeader] = useState([]);
    const [historicalAggregateDataList, setHistoricalAggregateDataList] = useState([]);
    const [showHistoricalAggregateDataList, setShowHistoricalAggregateDataList] = useState([]);
    const [historicalAggregateTableHeader, setHistoricalAggregateTableHeader] = useState([]);
    const [historicalBreakupDataList, setHistoricalBreakupDataList] = useState([]);
    const [showHistoricalBreakupDataList, setShowHistoricalBreakupDataList] = useState([]);
    const [historicalBreakupTableHeader, setHistoricalBreakupTableHeader] = useState([]);
    const [workflowAggregateDataList, setWorkflowAggregateDataList] = useState([]);
    const [workflowAggregateTableHeader, setWorkflowAggregateTableHeader] = useState([]);
    const [adhocDataList, setAdhocDataList] = useState([]); 
    const [adhocTableHeader, setAdhocTableHeader] = useState([]); 
    const [aggregateTotalsDataList, setAggregateTotalsDataList] = useState({}); 
    const [historicalTotalsDataList, setHistoricalTotalsDataList] = useState({});
    const [uploadContractsDataList, setUploadContractsDataList] = useState([]); 
    const [uploadContractsTableHeader, setUploadContractsTableHeader] = useState([]); 
    // initialize state for sub company dropdown
    const [subCompany, setSubCompany] = useState('All');
    const [subCompanyList, setSubCompanyList] = useState(['All']);
    // initialize state for override modal
    const [editRow, setEditRow] = useState({});
    const [overrideModal, setOverrideModal] = useState(false);
    const [overrideTypeList, setOverrideTypeList] = useState(['']);
    const [overrideType, setOverrideType] = useState('');
    const [showOverride, setShowOverride] = useState(true);
    // initialize state for workflow modal 
    const [workflowModal, setWorkflowModal] = useState(false);
    const [workflowAction, setWorkflowAction] = useState('');
    const [workflowNewStatus, setWorkflowNewStatus] = useState('');
    // initialize reference for workflow modal
    const workflowButtonCss = useRef('modal-button-full-blue');
    const workflowOptOut = useRef(false);
    const workflowReviewAction = useRef(false);
    // initialize state for page tabs 
    const [tabValue, setTabValue] = useState(0);
    const [tabList, setTabList] = useState([]);
    // initialize state for edit payout info modal 
    const [editPayoutTypeModal, setEditPayoutTypeModal] = useState(false);
    // initialize spinner while data is loading
    const [showSpinner, setShowSpinner] = useState(false);
    const [showSpinnerHistorical, setShowSpinnerHistorical] = useState(false);
    // initialize state to trigger filter
    const [triggerFilter, setTriggerFilter] = useState(false);
    const [triggerFilterHistorical, setTriggerFilterHistorical] = useState(false);
    // initialize state for snackbar 
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarColor, setSnackbarColor] = useState('info');
    // initialize state for user entitlement id
    const [userEntitlementId, setUserEntitlementId] = useState('');
    // initialize state for historical cycle
    const [historicalCycleStart, setHistoricalCycleStart] = useState('None');
    const [historicalCycleEnd, setHistoricalCycleEnd] = useState('None');
    // initialize state for master company status
    const [status, setStatus] = useState(companyInfo['status']);
    // initialize state to filter rebate setup page 
    const [productFilter, setProductFilter] = useState('All');
    const [productFilterList, setProductFilterList] = useState(['All']);
    const [feeCategoryFilter, setFeeCategoryFilter] = useState('All');
    const [feeCategoryFilterList, setFeeCategoryFilterList] = useState(['All']);
    const [billingRateFilter, setBillingRateFilter] = useState('All');
    const [billingRateFilterList, setBillingRateFilterList] = useState(['All']);
    const [daysToPayFilter, setDaysToPayFilter] = useState('All');
    const [daysToPayFilterList, setDaysToPayFilterList] = useState(['All']);
    // initialize state to disable workflow button 
    const [disableWorkflowButton, setDisableWorkflowButton] = useState(false);
    // initialize state for add adhoc payment modal 
    const [showAddAdhocModal, setShowAddAdhocModal] = useState(false); 
    const [masterCompanyId, setMasterCompanyId] = useState(); 
    const [showEditAdhocModal, setShowEditAdhocModal] = useState(false); 
    const [editAdhocRow, setEditAdhocRow] = useState([]); 
    // bool to set edit rights for adhoc data table 
    const [adhocEditBool, setAdhocEditBool] = useState(false); 
    // initialize state for enable reopen button 
    const [enableReopen, setEnableReopen] = useState(false); 
    // initialize state for additional details 
    const [additionalDetailsValue, setAdditionalDetailsValue] = useState(0); 
    const [additionalDetailsTableHeader, setAdditionalDetailsTableHeader] = useState([]); 
    const [showAdditionalDetailsModal, setShowAdditionalDetailsModal] = useState(false); 
    const [additionalDetailsRow, setAdditionalDetailsRow] = useState({}); 
    // intialize state for rebate input type 
    const [rebateInputTypeId, setRebateInputTypeId] = useState(); 
    // intialize state for consortium tab
    const [consortiumDataList, setConsortiumDataList] = useState([]); 
    const [showConsortiumDataList, setShowConsortiumDataList] = useState([]); 
    const [consortiumTableHeader, setConsortiumTableHeader] = useState([]); 
    const [consortiumFlag, setConsortiumFlag] = useState(false); 
    const [consortiumId, setConsortiumId] = useState(''); 
    const [consortiumTotalsDataList, setConsortiumTotalsDataList] = useState({});
    // initialize state for contract tab
    const [showContractCommentModal, setShowContractCommentModal] = useState(false); 
    const [contractUploadRow, setContractUploadRow] = useState({}); 
    // initailize state to alert page refresh
    const [pageRefresh, setPageRefresh] = useState(false); 
    // useRef for initial render 
    const initialRender1 = useRef(true);
    const initialRender2 = useRef(true);
    const initialRender3 = useRef(true);
    const initialRender4 = useRef(true);
    const initialRender5 = useRef(true);

    // function wrapper to pass row component to datatable
    const CurrentAggregateRowComponent = (row, count, tableHeader) =>{
        return( 
            <>
                <RebateAggregateRow row={row} count={count} tableHeader={tableHeader} showOverride={showOverride}/>
            </> 
        );   
    }  

    // function wrapper to pass row component to datatable
    const HistoricalAggregateRowComponent = (row, count, tableHeader) => {
        return (
            <>
                <RebateAggregateRow row={row} count={count} tableHeader={tableHeader} showOverride={false}/>
            </>
        );
    }

    // function wrapper to pass row component to datatable
    const BreakupRowComponent = (row, count, tableHeader) =>{
        return( 
            <>
                <RebateBreakupRow row={row} count={count} tableHeader={tableHeader}/>
            </>
        );
    } 

    // function wrapper to pass row component to datatable
    const RebateSetupRowComponent = (row, count, tableHeader) => {
        return (
            <>
                <RebateSetupRow row={row} count={count} tableHeader={tableHeader} enableEdit={false} />
            </>
        )
    }

    // function wrapper to pass row component to datatable
    const PayoutInfoRowComponent = (row, count, tableHeader) => {
        return (
            <>
                <PayoutInfoRow row={row} count={count} tableHeader={tableHeader} enableEdit={false} />
            </>
        );
    }

    // function wrapper to pass row component to datatable
    const AdhocRowComponent = (row, count, tableHeader) =>{
        return( 
            <>
                <AdhocDataRow row={row} count={count} tableHeader={tableHeader} editBool={adhocEditBool}/>
            </>
        );
    } 

    // function wrapper to pass footer to datatable
    const FooterComponent = (tableHeader, footerObj) => {
        return (
            <>
                <AggregateTotalsFooter tableHeader={tableHeader} footerObj={footerObj} rebateInputTypeId={rebateInputTypeId} />
            </>
        ); 
    }

    // function wrapper to pass footer to datatable
    const ConsortiumFooterComponent = (tableHeader, footerObj) => {
        return (
            <>
                <ConsortiumViewFooter tableHeader={tableHeader} footerObj={footerObj} />
            </>
        )
    }

    // funciton wrapper to pass row component to datatable
    const ConsortiumViewRowComponent = (row, count, tableHeader) => {
        return (
            <ConsortiumViewRow row={row} count={count} tableHeader={tableHeader} />
        ); 
    }

    // function wrapper to pass row component to datatable 
    const UploadContractRowComponent = (row, count, tableHeader) => {
        return (
            <>
                <UploadContractRow row={row} count={count} tableHeader={tableHeader} enableEdit={false} />
            </>
        );
    }
    
    // function to set snackbar state
    const snackbarAlert = (show, message, color) => {
        setSnackbarMessage(snackbarMessage => snackbarMessage = message);
        setSnackbarColor(snackbarColor => snackbarColor = color);
        setShowSnackbar(showSnackbar => showSnackbar = show);
    }

    // calculate totals from the show aggregate list 
    const calculateAggregateTotals = (object, setObject, showDataList) => {
        return new Promise((resolve, reject) => {
            // initialize obj
            let totals = {
                companyid: 'Totals: ', 
                cyclenetspend: 0, 
                cyclenetinterchange: 0, 
                cycleincentiveamount: 0, 
                
            }; 
            // add all vals in showDataList elements to totals
            for (let i = 0; i < showDataList.length; i++){
                if (showDataList[i]['cyclenetspend']){
                    totals['cyclenetspend'] = totals['cyclenetspend'] + parseFloat(showDataList[i]['cyclenetspend']); 
                }
                if (showDataList[i]['cyclenetinterchange']){
                    totals['cyclenetinterchange'] = totals['cyclenetinterchange'] + parseFloat(showDataList[i]['cyclenetinterchange']); 
                }
                if (showDataList[i]['cycleincentiveamount']){
                    totals['cycleincentiveamount'] = totals['cycleincentiveamount'] + parseFloat(showDataList[i]['cycleincentiveamount']); 
                } 
            }
            // set estimated rebate amount ( cycleincentiveamount / cyclenetspend )
            if (totals['cycleincentiveamount'] != 0){
                totals['incentiverate'] = (totals['cycleincentiveamount'] / totals['cyclenetspend']).toFixed(4); 
            }
            
            // set state
            setObject(object => object = totals); 
            // resolve
            resolve(totals); 
        })
    }

    // calculate totals for consortium show data list
    const consortiumCalculateAggregateTotals = (object, setObject, showDataList) => {
        return new Promise((resolve, reject) => {
            // initialize obj
            let totals = {
                mastercompanyname: 'Totals: ', 
                cyclegrossspend: 0, 
                cyclegrossinterchange: 0,  
                ytdgrossspend: 0, 
                ytdgrossinterchange: 0,                
            }; 
            // add all vals in showDataList elements to totals
            for (let i = 0; i < showDataList.length; i++){
                Object.keys(totals).map( el => {
                    if (showDataList[i][el] && el != 'mastercompanyname'){
                        totals[el] = totals[el] + parseFloat(showDataList[i][el]); 
                    }
                });
            }
            console.log(totals); 
            // set state
            setObject(object => object = totals); 
            // resolve
            resolve(totals); 
        })
    }

    // get all data to render on page from data endpoint
    const getCompanyPayoutViewPageData = () => {
        // show spinner while getting data
        setShowSpinner(showSpinner => showSpinner = true);
        return new Promise((resolve, reject) => {
            // use GET function to make request
            GET('/data/companyPayoutViewPage', {
                mastercompanyid: companyInfo['mastercompanyid'], 
                mastercompanyname: company, 
                cyclestart: companyInfo['cyclestart'], 
                cycleend: companyInfo['cycleend'], 
                workflowid: companyInfo['workflowid'], 
            }).then(res => {
                console.log(res); 
                // set state for rebate setup table
                setRebateSetupDataList(rebateSetupDataList => rebateSetupDataList = res['data']['RebateSetupData']);
                setShowRebateSetupDataList(showRebateSetupDataList => showRebateSetupDataList = res['data']['RebateSetupData']);
                setRebateSetupTableHeader(rebateSetupTableHeader => rebateSetupTableHeader = res['data']['RebateSetupCols']);
                // set state for payout info table 
                setPayoutInfoDataList(payoutInfoDataList => payoutInfoDataList = res['data']['PayoutInfoData']);
                setPayoutInfoTableHeader(payoutInfoTableHeader => payoutInfoTableHeader = res['data']['PayoutInfoCols']);
                // set state for adhoc table 
                setAdhocDataList(adhocDataList => adhocDataList = res['data']['AdhocTableData']); 
                setAdhocTableHeader(adhocTableHeader => adhocTableHeader = res['data']['AdhocTableCols']); 
                // set state for rebate aggregate
                setAggregateDataList(aggregateDataList => aggregateDataList = res['data']['RebateCycleAggregateData']);
                setShowAggregateDataList(showAggregateDataList => showAggregateDataList = res['data']['RebateCycleAggregateData']);
                setAggregateTableHeader(aggregateTableHeader => aggregateTableHeader = res['data']['RebateCycleAggregateCols'])
                // set state for monthly breakup
                setBreakupDataList(breakupDataList => breakupDataList = res['data']['MonthlyBreakupData']);
                setShowBreakupDataList(showBreakupDataList => showBreakupDataList = res['data']['MonthlyBreakupData']);
                setBreakupTableHeader(breakupTableHeader => breakupTableHeader = res['data']['MonthlyBreakupCols']);
                // set period dropdown state
                setPeriodId(periodId => periodId = res['data']['PeriodIdList'][0]);
                setPeriodList(periodList => periodList = res['data']['PeriodIdList']);
                // set historical period dropdown state
                setPeriodIdHistorical(periodIdHistorical => periodIdHistorical = res['data']['HistoricalPeriodIdList'][0]);
                setPeriodListHistorical(periodListHistorical => periodListHistorical = res['data']['HistoricalPeriodIdList']);
                // set sub company dropdown state
                setSubCompany(subCompany => subCompany = 'All'); 
                setSubCompanyList(subCompanyList => subCompanyList = ['All', ...res['data']['SubCompanyList']]);
                // set override type list 
                setOverrideType(overrideType => overrideType = res['data']['OverrideTypeList'][0]);
                setOverrideTypeList(overrideTypeList => overrideTypeList = res['data']['OverrideTypeList']);
                // set user entitlement id 
                setUserEntitlementId(userEntitlementId => userEntitlementId = res['data']['UserEntitlementId']);
                // set workflow modal state
                setWorkflowAggregateTableHeader(workflowAggregateTableHeader => workflowAggregateTableHeader = res['data']['WorkflowAggregateCols']);
                // take largest period id for each company for workflowAggregateDataList
                let temp = [];
                for (let i = 0; i < res['data']['RebateCycleAggregateData'].length; i++){
                    if (res['data']['RebateCycleAggregateData'][i]['periodid'] == res['data']['PeriodIdList'][0]){
                        temp.push(res['data']['RebateCycleAggregateData'][i]);
                    }
                }
                setWorkflowAggregateDataList(workflowAggregateDataList => workflowAggregateDataList = temp);
                // set state for status
                setStatus(status => status = res['data']['WorkflowStatus']);
                // set state for rebate setup filters
                setProductFilterList(productFilterList => productFilterList = ['All', ...res['data']['ProductTypeList']]);
                setFeeCategoryFilterList(feeCategoryFilterList => feeCategoryFilterList = ['All', ...res['data']['FeeCategoryList']]);
                setBillingRateFilterList(billingRateFilterList => billingRateFilterList = ['All', ...res['data']['BillingRateList']]);
                setDaysToPayFilterList(daysToPayFilterList => daysToPayFilterList = ['All', ...res['data']['DaysToPayList']]);
                // set entitlement bool for adhoc data table add/edit 
                setAdhocEditBool(adhocEditBool => adhocEditBool = res['data']['AdhocEntitlement']); 
                // set state for enable reopen 
                setEnableReopen(enableReopen => enableReopen = res['data']['AllowReopen']); 
                // set state for additional details option
                setAdditionalDetailsValue(additionalDetailsValue => additionalDetailsValue = res['data']['AdditionalDetails']); 
                setAdditionalDetailsTableHeader(additionalDetailsTableHeader => additionalDetailsTableHeader = res['data']['AdditionalDetailsCols']); 
                // set state for rebate rate input type id
                setRebateInputTypeId(rebateInputTypeId => rebateInputTypeId = res['data']['RebateInputTypeId']); 
                // set state for consortium view 
                setConsortiumDataList(consortiumDataList => consortiumDataList = res['data']['ConsortiumDataList']); 
                setShowConsortiumDataList(showConsortiumDataList => showConsortiumDataList = res['data']['ConsortiumDataList']); 
                setConsortiumTableHeader(consortiumTableHeader => consortiumTableHeader = res['data']['ConsortiumCols']); 
                setConsortiumId(consortiumId => consortiumId = res['data']['ConsortiumId']); 
                setConsortiumFlag(consortiumFlag => consortiumFlag = res['data']['ConsortiumFlag']); 
                // set tabs depending on consortiumFlag
                if (res['data']['ConsortiumFlag'] == true){ 
                    setTabList(tabList => tabList = getPageTabs(pageTabs, [{tag: 'Consortium View', key: 6, active: true}]));
                }
                else {
                    setTabList(tabList => tabList = getPageTabs(pageTabs));
                    // change tabValue if it is greater than equal to 5 (tabList.length)
                    if(tabValue == 6){
                        setTabValue(tabValue => tabValue = 0); 
                    }
                }
                // set state for upload contract table 
                setUploadContractsDataList(uploadContractsDataList => uploadContractsDataList = res['data']['UploadContractsData']); 
                setUploadContractsTableHeader(uploadContractsTableHeader => uploadContractsTableHeader = res['data']['UploadContractsCols']); 
                // trigger filter
                setTriggerFilter(triggerFilter => triggerFilter = !triggerFilter);
                // change page refresh to alert useEffects
                setPageRefresh(pageRefresh => pageRefresh = !pageRefresh); 
                // make show spinner false 
                setShowSpinner(showSpinner => showSpinner = false);
                resolve(res); 
            }).catch(err => {
                // make show spinner false 
                setShowSpinner(showSpinner => showSpinner = false);
                reject(err);
            });
        });
    }

    const onWebsocketRefresh = () => {
        return new Promise((resolve, reject) => {
            // use GET function to make request
            GET('/data/companyPayoutViewPage', {
                mastercompanyid: companyInfo['mastercompanyid'], 
                mastercompanyname: company, 
                cyclestart: companyInfo['cyclestart'], 
                cycleend: companyInfo['cycleend'], 
                workflowid: companyInfo['workflowid'], 
            }).then(res => {
                // set state for rebate setup table
                setRebateSetupDataList(rebateSetupDataList => rebateSetupDataList = res['data']['RebateSetupData']);
                setRebateSetupTableHeader(rebateSetupTableHeader => rebateSetupTableHeader = res['data']['RebateSetupCols']);
                // set state for payout info table 
                setPayoutInfoDataList(payoutInfoDataList => payoutInfoDataList = res['data']['PayoutInfoData']);
                setPayoutInfoTableHeader(payoutInfoTableHeader => payoutInfoTableHeader = res['data']['PayoutInfoCols']);
                // set state for adhoc table 
                setAdhocDataList(adhocDataList => adhocDataList = res['data']['AdhocTableData']); 
                setAdhocTableHeader(adhocTableHeader => adhocTableHeader = res['data']['AdhocTableCols']); 
                // set state for rebate aggregate
                setAggregateDataList(aggregateDataList => aggregateDataList = res['data']['RebateCycleAggregateData']);
                setShowAggregateDataList(showAggregateDataList => showAggregateDataList = res['data']['RebateCycleAggregateData']);
                setAggregateTableHeader(aggregateTableHeader => aggregateTableHeader = res['data']['RebateCycleAggregateCols'])
                // set state for monthly breakup
                setBreakupDataList(breakupDataList => breakupDataList = res['data']['MonthlyBreakupData']);
                setShowBreakupDataList(showBreakupDataList => showBreakupDataList = res['data']['MonthlyBreakupData']);
                setBreakupTableHeader(breakupTableHeader => breakupTableHeader = res['data']['MonthlyBreakupCols']);
                // set state for status
                setStatus(status => status = res['data']['WorkflowStatus']);
                // trigger filter
                setTriggerFilter(triggerFilter => triggerFilter = !triggerFilter);
                resolve(res);
            }).catch(err => {
                reject(err);
            });
        });
    }

    // function to make get request to historical cycle endpoint
    const getHistoricalCycleData = () => {
        // show spinner while waiting for response
        setShowSpinnerHistorical(showSpinnerHistorical => showSpinnerHistorical = true);
        return new Promise((resolve, reject) => {
            // use GET function to make request
            GET('/data/historicalCycle', {
                mastercompanyid: companyInfo['mastercompanyid'],
                periodid: periodIdHistorical, 
            }).then(res => {
                // set historical rebate aggregate state 
                setHistoricalAggregateDataList(historicalAggregateDataList => historicalAggregateDataList = res['data']['HistoricalRebateCycleAggregateData']);
                setShowHistoricalAggregateDataList(showHistoricalAggregateDataList => showHistoricalAggregateDataList = res['data']['HistoricalRebateCycleAggregateData']);
                setHistoricalAggregateTableHeader(historicalAggregateTableHeader => historicalAggregateTableHeader = res['data']['HistoricalRebateCycleAggregateCols']);
                // set historical monthly breakup state
                setHistoricalBreakupDataList(historicalBreakupDataList => historicalBreakupDataList = res['data']['HistoricalMonthlyBreakupData']);
                setShowHistoricalBreakupDataList(showHistoricalBreakupDataList => showHistoricalBreakupDataList = res['data']['HistoricalMonthlyBreakupData']);
                setHistoricalBreakupTableHeader(historicalBreakupTableHeader => historicalBreakupTableHeader = res['data']['HistoricalMonthlyBreakupCols']);
                // set historical cycle start and end state
                setHistoricalCycleStart(historicalCycleStart => historicalCycleStart = res['data']['HistoricalCycleStart']);
                setHistoricalCycleEnd(historicalCycleEnd => historicalCycleEnd = res['data']['HistoricalCycleEnd']);  
                // hide spinner
                setShowSpinnerHistorical(showSpinnerHistorical => showSpinnerHistorical = false);
                // resolve on success
                setTriggerFilterHistorical(triggerFilterHistorical => triggerFilterHistorical = !triggerFilterHistorical);
                resolve(res);
            }).catch(err => {
                // hide spinner
                setShowSpinnerHistorical(showSpinnerHistorical => showSpinnerHistorical = false);
                // reject on error
                reject(err);
            });
        });
    }

    // useEffect to run on initial render
    useEffect(()=>{
        // set masterCompanyId for Adhoc modals 
        setMasterCompanyId(masterCompanyId => masterCompanyId = companyInfo['mastercompanyid']); 
        // get data to display
        getCompanyPayoutViewPageData('/data/companyPayoutViewPage').then(res => {
            // console.log(res);
        }).catch(err => {
            console.log(err);
        })
    }, [companyInfo]); 

    // useEffect to get historical data on period dropdown change
    useEffect(() => {
        if (periodIdHistorical != ''){
            getHistoricalCycleData().then(res => {
                console.log(res);
                // calculate totals 
                return calculateAggregateTotals(); 
            }).then(res => {
                // console.log(res); 
            }).catch(err => {
                console.log(err);
            });
        }
    }, [periodIdHistorical]);

    // useEffect to determine show override based on period
    useEffect(() => {
        // disable override when period is not last in cycle and user is a submitter
        if (userEntitlementId == process.env.REACT_APP_SUBMITTER_ROLE_ID &&
        periodId == companyInfo['cycleend'].slice(0, 4) + '' + companyInfo['cycleend'].slice(5, 7)){
            setShowOverride(showOverride => showOverride = true);
        }
        else{
            setShowOverride(showOverride => showOverride = false);
        }
    }, [periodId, userEntitlementId, pageRefresh]);

    // useEffect to determine where to disable workflow button based on period 
    useEffect(() => {
        // disable workflow actio when period is not last in the cycle
        if (periodId == companyInfo['cycleend'].slice(0, 4) + '' + companyInfo['cycleend'].slice(5, 7)){
            setDisableWorkflowButton(disableWorkflowButton => disableWorkflowButton = false); 
        }
        else {
            setDisableWorkflowButton(disableWorkflowButton => disableWorkflowButton = true);
        }
    }, [periodId, userEntitlementId, pageRefresh]); 

    // useEffect for sorting rebate cycle aggregate table 
    useEffect(() => {
        if (!initialRender1.current){
            // declare list of functions with parameters to filter/search list
            const functionList = [
                {function: standardFilter, value: periodId, flag: 1, cols: ['periodid']}, 
                {function: standardFilter, value: subCompany, flag: 1, cols: ['companyid']},
            ];
            // use applyFilters function to search by running all functions declared above
            let filteredList = applyFilters(aggregateDataList, functionList);
            // update showDataList
            setShowAggregateDataList(showAggregateDataList => showAggregateDataList = filteredList);
            
        }
        else {
            initialRender1.current = false;
        }
    }, [periodId, subCompany, triggerFilter]);

    // useEffect for sorting monthly breakup table 
    useEffect(() => {
        if (!initialRender2.current){
            // declare list of functions with parameters to filter/search list
            const functionList = [
                {function: lessThanOrEqualFilter, value: periodId, flag: 1, cols: ['periodid']}, 
                {function: standardFilter, value: subCompany, flag: 1, cols: ['companyid']},
            ];
            // use applyFilters function to search by running all functions declared above
            let filteredList = applyFilters(breakupDataList, functionList);
            // update showDataList
            setShowBreakupDataList(showBreakupDataList => showBreakupDataList = filteredList);
        }
        else {
            initialRender2.current = false;
        }
    }, [periodId, subCompany, triggerFilter]);

    // useEffect for filtering historical cycle aggregate and historical rebate breakup table 
    useEffect(() => {
        if (!initialRender3.current){
            // declare list of functions with parameters to filter/search list
            const functionList = [
                {function: standardFilter, value: subCompany, flag: 1, cols: ['companyid']},
            ];
            // use applyFilters function to search by running all functions declared above
            let filteredListAggregate = applyFilters(historicalAggregateDataList, functionList);
            let filteredListBreakup = applyFilters(historicalBreakupDataList, functionList);
            // update showDataList
            setShowHistoricalAggregateDataList(showHistoricalAggregateDataList => showHistoricalAggregateDataList = filteredListAggregate);
            setShowHistoricalBreakupDataList(showHistoricalBreakupDataList => showHistoricalBreakupDataList = filteredListBreakup);
        }
        else {
            initialRender3.current = false; 
        }
    }, [subCompany, triggerFilterHistorical]);

    // filters for rebate setups table 
    useEffect(() => {
        // do not run on initial render
        if (!initialRender4.current){
            // declare list of functions with parameters to filter/search list
            const functionList = [
                {function: standardFilter, value: subCompany, flag: 1, cols: ['companyid']}, 
                {function: standardFilter, value: productFilter, flag: 1, cols: ['producttypedescription']}, 
                {function: standardFilter, value: feeCategoryFilter, flag: 1, cols: ['feecategorydescription']}, 
                {function: standardFilter, value: billingRateFilter, flag: 1, cols: ['billingratedescription']},
                {function: standardWithNoneFilter, value: daysToPayFilter, flag: 1, cols: ['daystopay']},
            ];
            // use applyFilters function to search by running all functions declared above
            let filteredList = applyFilters(rebateSetupDataList, functionList);
            // update showDataList
            setShowRebateSetupDataList(showRebateSetupDataList => showRebateSetupDataList = filteredList);
        }
        else{
            initialRender4.current = false;
        }
    }, [subCompany, productFilter, feeCategoryFilter, billingRateFilter, billingRateFilter, daysToPayFilter, triggerFilter]);

    // useEffect for sorting consortium view table
    useEffect(() => {
        if (!initialRender5.current){
            // declare list of functions with parameters to filter/search list
            const functionList = [
                {function: standardFilter, value: periodId, flag: 1, cols: ['periodid']}, 
            ];
            // use applyFilters function to search by running all functions declared above
            let filteredList = applyFilters(consortiumDataList, functionList);
            // update showDataList
            setShowConsortiumDataList(showConsortiumDataList => showConsortiumDataList = filteredList);
            
        }
        else {
            initialRender5.current = false;
        }
    }, [periodId, triggerFilter]);
 
    // useEffect for calculating aggregate totals on showAggregateDataList change
    useEffect(() => {
        // calculate totals for aggregate table
        calculateAggregateTotals(aggregateTotalsDataList, setAggregateTotalsDataList, showAggregateDataList).then(res => {
            // console.log(res);
        }).catch(err => {
            console.log(err); 
        }); 
    }, [showAggregateDataList]); 

    // useEffect for calculating historical aggregate totals on showHistoricalAggregateDataList change
    useEffect(() => {
        calculateAggregateTotals(historicalTotalsDataList, setHistoricalTotalsDataList, showHistoricalAggregateDataList).then(res => {
            // console.log(res); 
        }).catch(err => {
            console.log(err); 
        }); 
    }, [showHistoricalAggregateDataList]); 

    // useEffect for calculating aggregate totals on showConsortiumDataList change
    useEffect(() => {
        consortiumCalculateAggregateTotals(consortiumTotalsDataList, setConsortiumTotalsDataList, showConsortiumDataList).then(res => {
            // console.log(res); 
        }).catch(err => {
            console.log(err); 
        }); 
    }, [showConsortiumDataList]); 

    // use Effect to run on initial render to set tabs
    useEffect(() => {
        setTabList(tabList => tabList = getPageTabs(pageTabs)); 
    }, []); 

    // object to pass variables to context
    const settings = {
        editRow, 
        setEditRow, 
        overrideModal, 
        setOverrideModal,
        workflowModal, 
        setWorkflowModal, 
        workflowAction, 
        setWorkflowAction, 
        workflowNewStatus, 
        setWorkflowNewStatus, 
        aggregateDataList, 
        aggregateTableHeader, 
        workflowButtonCss, 
        workflowOptOut, 
        workflowReviewAction, 
        editPayoutTypeModal, 
        setEditPayoutTypeModal, 
        aggregateTableHeader, 
        overrideTypeList, 
        subCompany, 
        overrideType, 
        setOverrideType, 
        getCompanyPayoutViewPageData, 
        snackbarAlert, 
        showSnackbar, 
        setShowSnackbar,
        snackbarMessage, 
        snackbarColor, 
        userEntitlementId,
        showAggregateDataList,  
        workflowAggregateTableHeader, 
        workflowAggregateDataList, 
        status, 
        getCompanyPayoutViewPageData,
        snackbarAlert, 
        showAddAdhocModal, 
        setShowAddAdhocModal, 
        subCompanyList, 
        masterCompanyId, 
        showEditAdhocModal, 
        setShowEditAdhocModal, 
        editAdhocRow, 
        setEditAdhocRow, 
        enableReopen, 
        showAdditionalDetailsModal, 
        setShowAdditionalDetailsModal, 
        additionalDetailsTableHeader, 
        additionalDetailsValue, 
        additionalDetailsRow, 
        setAdditionalDetailsRow,
        rebateInputTypeId, 
        showContractCommentModal, 
        setShowContractCommentModal,
        contractUploadRow, 
        setContractUploadRow,
    };

    return(
        <div class='summary-page'>
            {/* wrap children so they have access to variables in PageContext */}
            <PageContext.Provider value={ settings }>
                {/* header banner and navslider present on every page */}
                <HeaderBanner indicator={1}/>
                <Navslider indicator={-1} backPath='/RebateSummaryPage'/>
                {/* all modals that can be activated. By default they are initialized in hidden state */}
                <OverrideModal/> 
                <WorkflowModal/>
                <EditPayoutTypeModal defaultFlag={0}/>
                <AddAdhocPaymentModal refreshFunction={onWebsocketRefresh}/>
                <EditAdhocPaymentModal refreshFunction={onWebsocketRefresh}/>
                <AdditionalDetailsModal/>
                <ContractUploadCommentModal />
                {/* snackbar component for alerts */}
                <SnackbarAlert />
                {/* top label to view company info */}
                <div class='top-label-div'>
                    <label class='top-label-text'><b>Rebate Payout Details</b></label>
                    <br/><br/>
                    <div class='label-text-div'>
                        <span class='label-text-span-left'>
                            <PageReadOnlyInput tag='Company: ' value={company} />
                        </span>
                        <span>
                            <PageReadOnlyInput tag='Payout Cycle: ' value={formatDate(companyInfo['cyclestart']) + ' to ' + formatDate(companyInfo['cycleend']) + ' [' + companyInfo['incentivefreq'] + ']'} />
                        </span>
                        <span class='label-text-span-right'>
                            <PageDropdown tag='Period: ' value={periodId} setValue={setPeriodId} list={periodList} />
                        </span>
                    </div>
                    <br/>
                    <div class='label-text-div'> 
                        <span class='label-text-span-left'>
                            <PageReadOnlyInput tag='Payout Status: ' value={status} />
                        </span>    
                        <span>
                            <PageDropdown tag='Sub Company ID: ' value={subCompany} setValue={setSubCompany} list={subCompanyList} />
                        </span>
                        <span class='label-text-span-right'>
                            {/* workflow button component that changes dynamically based on status and user entitlement */}
                            <WorkflowButton disable={disableWorkflowButton} />
                        </span>          
                        <br/>
                    </div>
                    <br/>
                </div>
                <br/>
                {/* tabs component for navigation between sections without leaving page */}
                <div class='data-table-div'>
                    <PageTabs value={tabValue} setValue={setTabValue} tabList={tabList}/>
                </div>
                <br/>
                {/* logic to show different sections based on the tab that has been chosen */}
                { tabValue == 0 ?
                    <>
                        <div class='data-table-div'>
                            <label><b>Rebate Cycle Aggregate: </b></label>
                            <OrderedDataTableWithFooter RowComponent={CurrentAggregateRowComponent} dataList={showAggregateDataList} tableHeader={aggregateTableHeader} showSpinner={showSpinner} numToPage={25} skeletonHeight='7vh' FooterComponent={FooterComponent} footerObj={aggregateTotalsDataList} />
                        </div>
                        <div class='data-table-div'>
                            <label><b>Monthly Breakup: </b></label>
                            <OrderedDataTable RowComponent={BreakupRowComponent} dataList={showBreakupDataList} tableHeader={breakupTableHeader} showSpinner={showSpinner} numToPage={25} skeletonHeight='28vh'/>
                        </div>
                    </>
                : tabValue == 1 ?
                    <>
                        <div class='mid-label-div'>
                            { periodListHistorical.length == 0 ? 
                            <>
                                <b>No Historical Rebate Cycles Found</b>
                            </>
                            :<>
                                <span class='label-text-span-left'>
                                    <PageReadOnlyInput tag='Historical Cycle: ' value={formatDate(historicalCycleStart) + ' to ' + formatDate(historicalCycleEnd)} width='17vw'/>
                                </span>
                                <span >
                                    {/* <PageReadOnlyInput tag='Status: ' value={'Accepted'}/> */} 
                                </span>
                                <span class='label-text-span-right'>
                                    <PageDropdown tag='Historical Period: ' value={periodIdHistorical} setValue={setPeriodIdHistorical} list={periodListHistorical} width='17vw'/>
                                </span>
                                <br/>
                            </>}                            
                        </div>
                        <br/>
                        <div class='data-table-div'>
                            <label><b>Rebate Cycle Aggregate: </b></label>
                            <OrderedDataTableWithFooter RowComponent={HistoricalAggregateRowComponent} dataList={showHistoricalAggregateDataList} tableHeader={historicalAggregateTableHeader} showSpinner={showSpinnerHistorical} numToPage={25} skeletonHeight='7vh' FooterComponent={FooterComponent} footerObj={historicalTotalsDataList}/>
                        </div>
                        <div class='data-table-div'>
                            <label><b>Monthly Breakup: </b></label>
                            <OrderedDataTable RowComponent={BreakupRowComponent} dataList={showHistoricalBreakupDataList} tableHeader={historicalBreakupTableHeader} showSpinner={showSpinnerHistorical} numToPage={25} skeletonHeight='28vh'/>
                        </div>
                    </>
                : tabValue == 2 ? 
                    <>
                        <div class='rebate-filter-label-div'>
                            <span>
                                <span class='label-text-span-left'>
                                    <label class='rebate-filter-label'><b>Rebate Setup Filters: </b></label>
                                </span>
                                <span class='label-text-span-mid'>
                                    <PageDropdown tag='Product Type: ' value={productFilter} setValue={setProductFilter} list={productFilterList}/>
                                </span>
                                <span class='label-text-span-right'>
                                    <PageDropdown tag='Fee Category: ' value={feeCategoryFilter} setValue={setFeeCategoryFilter} list={feeCategoryFilterList} />    
                                </span>
                            </span>                     
                            <br/><br/>
                            <span>
                                <span class='label-text-span-left'>
                                    <PageDropdown tag='Billing Rate: ' value={billingRateFilter} setValue={setBillingRateFilter} list={billingRateFilterList}/>
                                </span>
                                <span class='label-text-span-mid'>
                                    <PageDropdown tag='Days To Pay: ' value={daysToPayFilter} setValue={setDaysToPayFilter} list={daysToPayFilterList} />
                                </span>
                                <span class='label-text-span-right'>
                                    <span style={{color: 'transparent'}}>-</span>
                                </span>
                            </span>
                        </div>
                        <div class='data-table-div'>
                            <label><b>Rebate Setups: </b></label>
                            <OrderedDataTable RowComponent={RebateSetupRowComponent} dataList={showRebateSetupDataList} tableHeader={rebateSetupTableHeader} showSpinner={showSpinner} numToPage={25} skeletonHeight='28vh'/>
                        </div>
                    </>
                : tabValue == 3 ?
                    <div class='data-table-sm-div'>
                        <label class='mid-page-label-text'><b>Cycle Payout Info: </b></label>
                        <OrderedDataTable RowComponent={PayoutInfoRowComponent} dataList={payoutInfoDataList} tableHeader={payoutInfoTableHeader} showSpinner={showSpinner} numToPage={25} skeletonHeight='28vh'/> 
                    </div>
                : tabValue == 4 ? 
                    <div class='data-table-div'> 
                        <label class='mid-page-label-text'><b>Payment History:</b> </label>
                        <EntitledButton entitledBool={adhocEditBool} tag='Add Adhoc-Payment' cssClass='table-add-button' onClick={()=>setShowAddAdhocModal(showAddAdhocModal => showAddAdhocModal = true)}/>
                        <OrderedDataTable RowComponent={AdhocRowComponent} dataList={adhocDataList} tableHeader={adhocTableHeader} showSpinner={showSpinner} numToPage={25} skeletonHeight='28vh'/> 
                    </div>
                : tabValue == 5 ? 
                    <div class='data-table-div'>
                        <label class='mid-page-label-text'><b>Upload Client Contracts:</b> </label>
                        <OrderedDataTable RowComponent={UploadContractRowComponent} tableHeader={uploadContractsTableHeader} dataList={uploadContractsDataList} showSpinner={showSpinner} numToPage={25} skeletonHeight='28vh' />
                    </div>
                : tabValue == 6 ? 
                    <div class='data-table-div'>
                         <div class='consortium-label-div'>
                            <label><b>Consortium ID: {consortiumId}</b></label>
                        </div>
                        <label class='mid-page-label-text'><b>Consortium View:</b> </label>
                        <OrderedDataTableWithFooter RowComponent={ConsortiumViewRowComponent} dataList={showConsortiumDataList} tableHeader={consortiumTableHeader} showSpinner={showSpinner} numToPage={25} skeletonHeight='7vh' FooterComponent={ConsortiumFooterComponent} footerObj={consortiumTotalsDataList}/>
                    </div>
                : <></> }        
            </PageContext.Provider>
        </div>
    );
}

export default CompanyPayoutView;