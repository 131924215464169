const removeStrCommas = ( value ) => {
    // remove commas from string 
    let temp = '';
    for (let i = 0; i < value.length; i++){
        if (value[i] == ' '){
            // throw error if a space char is found because numbers cannot have spaces
            throw new Error('Invalid input for numeric');
        }
        else if (value[i] != ','){
            temp = temp + value[i];
        }
    }
    return temp; 
}

export default removeStrCommas; 