import TextField from '@mui/material/TextField';

const ReadOnlyInputAutoSize = ({ tag, value }) =>{

    return (
        <>
            {/* material ui textfield autosized to 80% of container */}
            <TextField id="outlined-basic" sx={{ width: '80%' }} size='small' multiline label={tag} variant="outlined" value={value} inputProps={{readOnly: true}}/>
        </>
    );
}

export default ReadOnlyInputAutoSize;