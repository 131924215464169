import { useContext } from 'react';
import PageContext from './PageContext';
import pencilIcon from '../../svgs/pencil-icon.svg';
import PageTooltip from './PageTooltip'; 

const UserEntitlementRow = ({ row, count, tableHeader }) => {
    //set page context
    const {
        editModal,  
        setEditModal, 
        editUser, 
        setEditUser, 
        getUserEntitlements,
    } = useContext(PageContext);

    // event handler for edit icon click 
    const onEditClick = () =>{
        setEditUser(editUser => editUser = row['userid']);
        setEditModal(editModal => editModal = true);
    }

    const settings = {
    //passing this through settings for websocket use on EditUserEntitlementRow
        getUserEntitlements
    }

    return(
            <>
                {tableHeader.map(el =>
                el['columnname'] == 'blank' ?
                    <td>
                        <PageTooltip title='edit'>
                            <img src={pencilIcon} className='data-table-icon' onClick={(e)=>onEditClick(e)}/>
                        </PageTooltip>
                    </td>                
                : <td>{row[el['columnname']]}</td>
                )}
            </>
    );
}

export default UserEntitlementRow;