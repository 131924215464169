import { useState, useEffect, useContext, useRef } from 'react'; 
import PageContext from './PageContext'; 
import PageDropdown from './PageDropdown';
import PageInput from './PageInput';
import PageReadOnlyInput from './PageReadOnlyInput'; 
import { HttpContext } from '../../api-services/HttpService';
import DatePicker from './DatePicker'; 
import toDate from '../functions/toDate';
import MultiSelectAutocomplete from "./MultiSelectAutocomplete"; 
import Grid from '@mui/material/Grid';
import ApiRequestButton from './ApiRequestButton';
import { AuthContext } from '../../auth/AuthRoute'

// add function to Date class to compare if date greater than or equal to another date 
Date.prototype.greater = function(compDate) {
    // specify date1
    let date1 = new Date(this.valueOf()); 
    // set time to 4pm (1 hour before payout file would go out)
    date1.setHours(16,0,0,0); 
    // use getTime() function to compare dates
    if (date1.getTime() > compDate.getTime()){ 
        return true
    }
    return false; 
}

// add function to data class to compare if dates are equal
Date.prototype.equals = function(compDate) { 
    // use getTime() function to compare dates
    if (this.getTime() == compDate.getTime()){
        return true; 
    }
    return false; 
}

const CalendarTabs = () => {
    // get context from parent
    const {
        calendarDate, 
        snackbarAlert, 
        getPayoutData, 
        scheduleTypeList, 
        setRefreshCalendar, 
        payoutTypeList, 
        portfolioList, 
        paymentMethodList,
        selectedDateData, 
        selectedDateFilters,
        nextPayoutData, 
        historical, 
        showSpinner, 
    } = useContext(PageContext); 
    // get context for http service
    const { 
        PUT, 
    } = useContext(HttpContext);
    // get context for auth service 
    const {
        getAdmin, 
    } = useContext(AuthContext); 
    // intialize state
    const [scheduleType, setScheduleType] = useState(''); 
    const [scheduleName, setScheduleName] = useState(''); 
    const [noEndDate, setNoEndDate] = useState(['One-Time']); 
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date()); 
    const [status, setStatus] = useState('Active'); 
    const [updateType, setUpdateType] = useState('Series'); 
    const [updateTypeList, setUpdateTypeList] = useState(['Series', 'Occurance']); 
    const [today, setToday] = useState(new Date()); 
    const [occuranceDate, setOccuranceDate] = useState(calendarDate); 
    const [payoutTypeFilters, setPayoutTypeFilters] = useState([]); 
    const [portfolioFilters, setPortfolioFilters] = useState([]); 
    const [paymentMethodFilters, setPaymentMethodFilters] = useState([]); 
    const [editStartDate, setEditStartDate] = useState(true); 
    const [isAdmin, setIsAdmin] = useState(false); 
    // initialize refs for scheduleIndex and daysIndex
    const occuranceData = useRef({}); 

    // event handler for update to series
    const onSeriesUpdateClick = () => {
        return new Promise((resolve, reject) => {
            let response; 
            // validate data 
            if (!scheduleTypeList.includes(scheduleType)){
                snackbarAlert(true, 'Error: Invalid Schedule Type', 'error'); 
                reject();
                return; 
            }
            if (scheduleName.length == 0){
                snackbarAlert(true, 'Error: Please provide Schedule Name', 'error'); 
                reject();
                return; 
            }
            if (startDate == null || startDate.toString() == 'Invalid Date'){
                snackbarAlert(true, 'Error: Please provide valid Start Date', 'error'); 
                reject();
                return; 
            }
            // if (!startDate.greater(today)){
            //     snackbarAlert(true, 'Error: Start date has already passed', 'error'); 
            //     reject();
            //     return; 
            // }
            if (!noEndDate.includes(scheduleType) && endDate == null){
                snackbarAlert(true, 'Error: Please provide End Date', 'error'); 
                reject();
                return; 
            } 
            if (payoutTypeFilters.length < 1){
                snackbarAlert(true, 'Error: Please add at least one payout type filter', 'error'); 
                reject();
                return; 
            }
            if (portfolioFilters.length < 1){
                snackbarAlert(true, 'Error: Please add at least one portfolio filter', 'error'); 
                reject();
                return; 
            }
            if (paymentMethodFilters.length < 1){
                snackbarAlert(true, 'Error: Please add at least one payment method filter', 'error'); 
                reject();
                return; 
            }
            // set active 
            let active = false; 
            if (status == 'Active'){
                active = true; 
            }
            // use httpContext function to make PUT request 
            PUT('/data/payoutSchedulePage/series', {
                scheduleid: selectedDateData['scheduleid'], 
                scheduletypedescription: scheduleType, 
                schedulename: scheduleName, 
                startdate: startDate, 
                enddate: endDate,
                active: active, 
                filters: {
                    0: payoutTypeFilters, 
                    1: portfolioFilters, 
                    2: paymentMethodFilters, 
                },
            }).then(res => {
                console.log(res);
                response = res; 
                // show snackbar on error
                snackbarAlert(true, 'Successfully uploaded data', 'success'); 
                // refresh calendar data by toggling refresh state. This will cause calendar to make get request
                setRefreshCalendar(refreshCalendar => refreshCalendar = !refreshCalendar);  
                // refrest payout file data
                return getPayoutData(calendarDate);         
            }).then(res => {
                // resolve on success
                resolve(response); 
            }).catch(err => {
                console.log(err); 
                // show snackbar on error
                snackbarAlert(true, 'Error uploading data', 'error'); 
                // reject on error
                reject(); 
            }); 
        }); 
    }

    // event handler for update occurance 
    const onOccuranceUpdateClick = () => {
        return new Promise((resolve, reject) => {
            let response; 
            // set active 
            let active = false; 
            if (status == 'Active'){
                active = true; 
            }
            // valid filter lists
            if (payoutTypeFilters.length < 1){
                snackbarAlert(true, 'Error: Please add at least one payout type filter', 'error'); 
                reject();
                return; 
            }
            if (portfolioFilters.length < 1){
                snackbarAlert(true, 'Error: Please add at least one portfolio filter', 'error'); 
                reject();
                return; 
            }
            if (paymentMethodFilters.length < 1){
                snackbarAlert(true, 'Error: Please add at least one payment method filter', 'error'); 
                reject();
                return; 
            }
            // use httpContext function to make PUT request 
            PUT('/data/payoutSchedulePage/occurance', {
                scheduleid: occuranceData.current['scheduleid'], 
                dateid: occuranceData.current['dateid'], 
                date: occuranceDate, 
                active: active, 
                filters: {
                    0: payoutTypeFilters, 
                    1: portfolioFilters, 
                    2: paymentMethodFilters, 
                },
            }).then(res => {
                console.log(res); 
                response = res; 
                // show snackbar on error
                snackbarAlert(true, 'Successfully uploaded data', 'success'); 
                // refresh calendar data by toggling refresh state. This will cause calendar to make get request
                setRefreshCalendar(refreshCalendar => refreshCalendar = !refreshCalendar);  
                // refrest payout file data
                return getPayoutData(occuranceDate);         
            }).then(res => {
                // resolve on success
                resolve(response); 
            }).catch(err => { 
                // show snackbar on error
                snackbarAlert(true, 'Error uploading data', 'error'); 
                // reject on error
                reject(err); 
            }); 
        }); 
    }

    // useEffect to run when selected date changes
    useEffect(() => {
        if (selectedDateData){
            setUpdateType(updateType => updateType = 'Series'); 
            // set status to true by default
            setStatus(status => status = 'Active');  
            // set schedule type 
            setScheduleType(scheduleType => scheduleType = selectedDateData['scheduletypedescription']);
            // set schedule name
            setScheduleName(scheduleName => scheduleName = selectedDateData['schedulename']);
            // set start date
            setStartDate(startDate => startDate = toDate(selectedDateData['startdate'])); 
            // set editStartDate boolean
            let tempStartDate = toDate(selectedDateData['startdate']); 
            if (tempStartDate.greater(today)) {
                setEditStartDate(editStartDate => editStartDate = true); 
            }
            else {
                setEditStartDate(editStartDate => editStartDate = false); 
            }
            // set end date 
            if (selectedDateData['enddate']){
                setEndDate(endDate => endDate = toDate(selectedDateData['enddate'])); 
            }
            else {
                setEndDate(endDate => endDate = null); 
            }
        }         
    }, [selectedDateData])

    // useEffect for schedule's filter lists
    useEffect(() => {
        if (selectedDateFilters){
            // set filters 
            if (selectedDateFilters[0]){
                setPayoutTypeFilters(payoutTypeFilters => payoutTypeFilters = selectedDateFilters[0]);   
            }
            if (selectedDateFilters[1]){
                setPortfolioFilters(portfolioFilters => portfolioFilters = selectedDateFilters[1]); 
            }
            if (selectedDateFilters[2]){
                setPaymentMethodFilters(paymentMethodFilters => paymentMethodFilters = selectedDateFilters[2]); 
            }
        }
    }, [selectedDateFilters]); 

    // useEffect to update occuranceDate on calendarDate change
    useEffect(() => {
        // by default use date data for next payout date 
        if(nextPayoutData) {
            // set to nextPayoutDate 
            setOccuranceDate(occuranceDate => occuranceDate = new Date(nextPayoutData['formatteddate'])); 
            // set data needed in request to update occurance
            occuranceData.current = {
                scheduleid: nextPayoutData['scheduleid'], 
                dateid: nextPayoutData['dateid'], 
            }
        }
        // use selected date info for date date if calendar date matches date data
        if (calendarDate && selectedDateData) { 
            let tempDate = new Date(calendarDate.valueOf());
            tempDate.setHours(0, 0, 0, 0); 
            let selectedDate = new Date(selectedDateData['transferdate']); 
            console.log(selectedDateData);  
            if (tempDate.equals(selectedDate)){
                // set to nextPayoutDate 
                setOccuranceDate(occuranceDate => occuranceDate = new Date(selectedDateData['transferdate'])); 
                // set data needed in request to update occurance
                occuranceData.current = {
                    scheduleid: selectedDateData['scheduleid'], 
                    dateid: selectedDateData['dateid'], 
                }
            }
        }
    }, [calendarDate, selectedDateData, nextPayoutData]);   
    
    // useEffect to run on initial render
    useEffect(() => {
        // use Auth context 
        let admin = getAdmin(); 
        // set state 
        setIsAdmin(isAdmin => isAdmin = admin);
    }, []); 

    return (
        <span>            
            { updateType == 'Series' ? 
            <Grid container >
                <Grid item xs={4} padding={1}>
                    { !noEndDate.includes(scheduleType) ?
                    <div class='modal-div'>
                        <PageDropdown tag='Edit:' value={updateType} setValue={setUpdateType} list={updateTypeList} width='90%' disableEdit={historical || !isAdmin} isLoading={showSpinner}/>
                    </div>
                    : <div class='modal-div'>
                        <PageReadOnlyInput tag='Edit:' value={'Occurance'} width='90%' isLoading={showSpinner} />
                    </div> }
                </Grid>
                <Grid item xs={4} padding={1}>
                    <div class='modal-div'>
                        <PageDropdown tag='Status:' value={status} setValue={setStatus} list={['Active', 'Inactive']} width='90%' disableEdit={historical || !isAdmin} isLoading={showSpinner}/>
                    </div>
                </Grid>
                <Grid item xs={4} padding={1}> 
                    <div class='modal-div'>
                        <PageDropdown tag='Schedule Type:' value={scheduleType} setValue={setScheduleType} list={scheduleTypeList} width='90%' disableEdit={historical || !isAdmin} isLoading={showSpinner}/>
                    </div>
                </Grid>
                <Grid item xs={4} padding={1}> 
                    <div class='modal-div'>
                        <PageInput tag={'Schedule Name:'} value={scheduleName} setValue={setScheduleName} width='90%' disableEdit={historical || !isAdmin} isLoading={showSpinner}/>
                    </div> 
                </Grid>
                <Grid item xs={4} padding={1}> 
                    {/* only allow edit on start date if it is after current date */}
                    { editStartDate ?
                    <div class='modal-div'>
                        <DatePicker tag='Start Date:' value={startDate} setValue={setStartDate} disable={endDate} indicator={3} width='90%' disableEdit={historical || !isAdmin} isLoading={showSpinner}/>
                    </div> 
                    : <div class='modal-div'>
                        <PageReadOnlyInput tag='Start Date:' value={startDate.getMonth()+1 + '/' + startDate.getDate() + '/' + startDate.getFullYear()} width='90%' isLoading={showSpinner} />
                    </div> }
                </Grid>
                <Grid item xs={4} padding={1}> 
                    { !noEndDate.includes(scheduleType) && endDate.greater(today) ? 
                        <div class='modal-div'>
                            <DatePicker tag='End Date:' value={endDate} setValue={setEndDate} disable={calendarDate} indicator={4} width='90%' disableEdit={historical || !isAdmin} isLoading={showSpinner}/>
                        </div>
                    : !noEndDate.includes(scheduleType) && !endDate.greater(today) ? 
                        <div class='modal-div'>
                            <PageReadOnlyInput tag='End Date:' value={endDate.getMonth()+1 + '/' + endDate.getDate() + '/' + endDate.getFullYear()} width='90%' isLoading={showSpinner}/>
                        </div> 
                    : <div class='modal-div'>
                        <PageReadOnlyInput tag='End Date:' value={'None'} width='90%' isLoading={showSpinner} />
                    </div> }
                </Grid>
                <Grid item xs={4} padding={1}> 
                    <div class='modal-div'>
                        <MultiSelectAutocomplete tag='Payout Types Included' values={payoutTypeFilters} setValues={setPayoutTypeFilters} list={payoutTypeList} width='90%' disableEdit={historical || !isAdmin} isLoading={showSpinner}/>
                    </div> 
                </Grid>
                <Grid item xs={4} padding={1}>
                    <div class='modal-div'>
                        <MultiSelectAutocomplete tag='Portfolios Included' values={portfolioFilters} setValues={setPortfolioFilters} list={portfolioList} width='90%' disableEdit={historical || !isAdmin} isLoading={showSpinner}/>
                    </div>
                </Grid>
                <Grid item xs={4} padding={1}>
                    <div class='modal-div'>
                        <MultiSelectAutocomplete tag='Payment Methods Included' values={paymentMethodFilters} setValues={setPaymentMethodFilters} list={paymentMethodList} width='90%' disableEdit={historical || !isAdmin} isLoading={showSpinner}/>
                    </div> 
                </Grid>
                <Grid item xs={12} padding={1}>
                    { historical || !isAdmin ? 
                    <></>
                    // :<button class='modal-button-full-blue' onClick={()=>onSeriesUpdateClick()}>Submit</button>}
                    : <ApiRequestButton onButtonClick={onSeriesUpdateClick} isLoading={showSpinner} /> }
                </Grid>
            </Grid>
            : <Grid container>
                <Grid item xs={4} padding={1}>
                    { !noEndDate.includes(scheduleType) ?
                    <div class='modal-div'>
                        <PageDropdown tag='Edit:' value={updateType} setValue={setUpdateType} list={updateTypeList} width='90%' disableEdit={historical || !isAdmin} isLoading={showSpinner}/>
                    </div>
                    : <div class='modal-div'>
                        <PageReadOnlyInput tag='Edit:' value={'Occurance'} width='90%' isLoading={showSpinner} />
                    </div> }
                </Grid>
                <Grid item xs={4} padding={1}>
                    <div class='modal-div'>
                        <PageDropdown tag='Status:' value={status} setValue={setStatus} list={['Active', 'Inactive']} width='90%' disableEdit={historical || !isAdmin} isLoading={showSpinner}/>
                    </div>
                </Grid>
                <Grid item xs={4} padding={1}>
                    { occuranceDate ?
                        occuranceDate.greater(today) ? 
                        <>  
                            <div class='modal-div'>
                                <DatePicker tag='Date:' value={occuranceDate} setValue={setOccuranceDate} disable={today} indicator={4} width='90%' disableEdit={historical || !isAdmin} isLoading={showSpinner}/>
                            </div>
                            <br/>
                        </>
                        :<>
                            <div class='modal-div'>
                                <PageReadOnlyInput tag='Date:' value={occuranceDate.getMonth()+1 + '/' + occuranceDate.getDate() + '/' + occuranceDate.getFullYear()} width='90%' isLoading={showSpinner} />
                            </div> 
                            <br/>
                        </>
                    :<></>}
                </Grid>
                <Grid item xs={4} padding={1}>
                    <div class='modal-div'>
                        <MultiSelectAutocomplete tag='Payout Types Included' values={payoutTypeFilters} setValues={setPayoutTypeFilters} list={payoutTypeList} width='90%' disableEdit={historical || !isAdmin} isLoading={showSpinner} />
                    </div>  
                </Grid>
                <Grid item xs={4} padding={1}>
                    <div class='modal-div'>
                        <MultiSelectAutocomplete tag='Portfolios Included' values={portfolioFilters} setValues={setPortfolioFilters} list={portfolioList} width='90%' disableEdit={historical || !isAdmin} isLoading={showSpinner}/>
                    </div>
                </Grid>
                <Grid item xs={4} padding={1}>
                    <div class='modal-div'>
                        <MultiSelectAutocomplete tag='Payment Methods Included' values={paymentMethodFilters} setValues={setPaymentMethodFilters} list={paymentMethodList} width='90%' disableEdit={historical || !isAdmin} isLoading={showSpinner}/>
                    </div>
                </Grid>
                <Grid item xs={12} padding={1}>
                    { historical || !isAdmin ? 
                    <></>
                    // : <button class='modal-button-full-blue' onClick={()=>onOccuranceUpdateClick()}>Submit</button>}
                    : <ApiRequestButton onButtonClick={onOccuranceUpdateClick} isLoading={showSpinner} /> }
                </Grid>
            </Grid>}
            
        </span>
        
    ); 
}

export default CalendarTabs; 