import { useContext, useState, useEffect } from 'react';
import { Modal } from "react-bootstrap";
import PageContext from "./PageContext";
import ReadOnlyInputAutoSize from './ReadOnlyInputAutoSize'

const ProcessingCommentModal = () =>{
    // get context from parent
    const {
        showProcessingCommentModal, 
        setShowProcessingCommentModal,
        processingRow,
        setProcessingRow, 
    } = useContext(PageContext);


    return(
        <> 
            <Modal className="rebate-summary-modal"
                show={showProcessingCommentModal}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                scrollable={true}
                >
                <Modal.Header closeButton onClick={()=>setShowProcessingCommentModal(showProcessingCommentModal => showProcessingCommentModal = false)}>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Processing Comment for {processingRow['filename']}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <ReadOnlyInputAutoSize tag='comment' value={processingRow['processingcomment']}/>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default ProcessingCommentModal;