import { useState, useEffect, useContext, useRef  } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../redux/index';
import PageContext from '../sub-components/PageContext';
import HeaderBanner from '../sub-components/HeaderBanner';
import Navslider from '../sub-components/Navslider';
import PageDropdown from '../sub-components/PageDropdown'; 
import PageInput from '../sub-components/PageInput';
import OrderedDataTable from '../sub-components/OrderedDataTable';
import AddNewSubCompanyRow from '../sub-components/AddNewSubCompanyRow';
import AddNewRebateSetupRow from '../sub-components/AddNewRebateSetupRow'; 
import AddNewCompanyEntitlementsRow from '../sub-components/AddNewCompanyEntitlementsRow';
import AddNewPayoutInfoRow from '../sub-components/AddNewPayoutInfoRow';
import AddNewPerformanceBonusRow from '../sub-components/AddNewPerformanceBonusRow';
import { HttpContext } from '../../api-services/HttpService';
import EntitledButton from '../sub-components/EntitledButton';
import SnackbarAlert from '../sub-components/SnackbarAlert';
// import rebateInputValidationChecks from '../functions/rebateInputValidationChecks';
// import performanceBonusValidationCheck from '../functions/performanceInputValidationCheck';
// import payoutInputValidationChecks from '../functions/payoutInputValidationChecksMasterCompany';
import removeStrCommas from '../functions/removeStrCommas';
import isAlphaNumeric from '../functions/isAlphaNumeric';
import AddNewAccountIdsModal from '../sub-components/AddNewAccountIdsModal'; 
import { AuthContext } from '../../auth/AuthRoute'; 
import isNumeric from '../functions/isNumeric'; 
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import isEmptyString from '../functions/isEmptyString'; 
import percentToDecimal from '../functions/percentToDecimal'; 
import rebateInputValidationChecksWithError from '../functions/rebateInputValidationChecksWithError';
import subCompanyValidationChecksWithError from '../functions/subCompanyValidationChecksWithError'; 
import payoutValidationChecksWithError from '../functions/payoutValidationChecksWithError'; 
import copyObjArray from '../functions/copyObjArray'; 
import performanceBonusValidationChecksWithError from '../functions/performanceInputValidationChecksWithError'; 
import CircularProgress from '@mui/material/CircularProgress';

const AddNewCompanyProfilePage = () =>{
    // initialize http context 
    const {
        GET, 
        POST, 
    } = useContext(HttpContext);
    // get auth context  
    const { 
        getDataTech,  
    } = useContext(AuthContext); 
    // redux state for use of persistant state in different route (CompanyDetailsPage)
    const company = useSelector((state) => state.company);
    const companyInfo = useSelector((state) => state.companyInfo);
    const dispatch = useDispatch();
    const { setCompany, setCompanyInfo } = bindActionCreators(actionCreators, dispatch);
    // initialize states for company info 
    const [masterCompanyName, setMasterCompanyName] = useState('');
    const [rebateFrequency, setRebateFrequency] = useState('');
    const [primaryCCRM, setPrimaryCCRM] = useState('');
    const [portfolio, setPortfolio] = useState('');
    const [masterCompanyId, setMasterCompanyId] = useState('');
    const [rebateFrequencyList, setRebateFrequencyList] = useState([]);
    const [portfolioList, setPortfolioList] = useState([]);
    const [userList, setUserList] = useState([]);
    const [editButtonBool, setEditButtonBool] = useState(false);
    const [masterPayoutInfoBool, setMasterPayoutInfoBool] = useState(false); 
    const [setupToAllSubs, setSetupToAllSubs] = useState(false); 
    // initialize state for table dropdowns
    const [roleDescriptionList, setRoleDescriptionList] = useState([]);
    const [feeCategoryDescriptionList, setFeeCategoryDescriptionList] = useState([]);
    const [productTypeList, setProductTypeList] = useState([]);
    const [billingRateList, setBillingRateList] = useState([]);
    const [daysToPayList, setDaysToPayList] = useState([]);
    const [payoutMethodList, setPayoutMethodList] = useState([]);
    const [payoutMethodData, setPayoutMethodData] = useState([]); 
    const [processorList, setProcessorList] = useState([]); 
    const [processorDefaultData, setProcessorDefaultData] = useState([]); 
    // initialize state for sub company ids autocomplete
    const [subCompanyIdsList, setSubCompanyIdsList] = useState([]); 
    const [masterTierIdsList, setMasterTierIdsList] = useState([]); 
    // initialize states for tables
    const [subCompanyDataList, setSubCompanyDataList] = useState([]);
    const [subCompanyTableHeader, setSubCompanyTableHeader] = useState([]);
    const [rebateSetupDataList, setRebateSetupDataList] = useState([]);
    const [rebateSetupTableHeader, setRebateSetupTableHeader] = useState([]);
    const [entitlementsDataList, setEntitlementsDataList] = useState([]     );
    const [entitlementsTableHeader, setEntitlementsTableHeader] = useState([]);
    const [payoutInfoDataList, setPayoutInfoDataList] = useState([]);
    const [payoutInfoTableHeader, setPayoutInfoTableHeader] = useState([]);
    const [performanceBonusDataList, setPerformanceBonusDataList] = useState([]);
    const [performanceBonusTableHeader, setPerformanceBonusTableHeader] = useState([]);
    // initialize state for adding account ids to sub companies 
    const [showAddAccountIdsModal, setShowAddAccountIdsModal] = useState(false);
    const [accountIdsDataList, setAccountIdsDataList] = useState([]);
    const [accountIdsTableHeader, setAccountIdsTableHeader] = useState([]); 
    const [accountIdsRow, setAccountIdsRow] = useState(); 
    // initialize state for loading progress 
    const [showProgress, setShowProgress] = useState(false);
    // initialize state for rebate input type to determine incentiverate transformations
    const [rebateInputTypeId, setRebateInputTypeId] = useState(0);
    // initialize snackbar state
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarColor, setSnackbarColor] = useState('info');
    // initailize state lists for errors 
    const [subCompanyErrorList, setSubCompanyErrorList] = useState([{}]); 
    const [rebateSetupErrorList, setRebateSetupErrorList] = useState([{}]); 
    const [performanceBonusErrorList, setPerformanceBonusErrorList] = useState([{}]); 
    const [payoutInfoErrorList, setPayoutInfoErrorList] = useState([{}]); 
    // initialize state for errors
    const [masterCompanyNameError, setMasterCompanyNameError] = useState(false); 
    const [masterCompanyIdError, setMasterCompanyIdError] = useState(false); 
    const [portfolioError, setPortfolioError] = useState(false); 
    const [primaryCcrmError, setPrimaryCcrmError] = useState(false); 
    const [rebateFrequencyError, setRebateFrequencyError] = useState(false); 
    const [editBillingRate, setEditBillingRate] = useState(true); 
    // initialize state for request loading 
    const [isLoading, setIsLoading] = useState(false); 

    // function wrapper to pass row component to datatable
    const AddNewSubCompanyRowComponent = (row, count, tableHeader) =>{
        return(
            <>
                <AddNewSubCompanyRow row={row} count={count} tableHeader={tableHeader}/>
            </>
        ); 
    } 

    // function wrapper to pass row component to datatable
    const AddNewRebateSetupRowComponent = (row, count, tableHeader) =>{
        return(
            <>
                <AddNewRebateSetupRow row={row} count={count} tableHeader={tableHeader}/>
            </>
        );
    } 

    // function wrapper to pass row component to datatable 
    const AddNewEntitlementsRowComponent = (row, count, tableHeader) => {
        return (
            <>
                <AddNewCompanyEntitlementsRow row={row} count={count} tableHeader={tableHeader} />
            </>
        );
    }

    // function wrapper to pass row component to datatable
    const AddNewPayoutInfoRowComponent = (row, count, tableHeader) => {
        return (
            <>
                <AddNewPayoutInfoRow row={row} count={count} tableHeader={tableHeader}/>
            </>
        );
    }

    // function wrapper to pass row component to datatable
    const AddNewPerformanceBonusRowComponent = (row, count, tableHeader) => {
        return (
            <>
                <AddNewPerformanceBonusRow row={row} count={count} tableHeader={tableHeader} />
            </>
        );
    }

    // function to set snackbar state
    const snackbarAlert = (show, message, color) => {
        setSnackbarMessage(snackbarMessage => snackbarMessage = message);
        setSnackbarColor(snackbarColor => snackbarColor = color);
        setShowSnackbar(showSnackbar => showSnackbar = show);
    }

    // event handler for submit click
    const onSubmitClick = () => {
        // validate company name and id 
        if (masterCompanyName.length == 0){
            snackbarAlert(true, 'Error: Invalid company name.', 'error');
            setMasterCompanyNameError(masterCompanyNameError => masterCompanyNameError = true); 
            return; 
        }
        else {
            setMasterCompanyNameError(masterCompanyNameError => masterCompanyNameError = false); 
        }
        // check master company id 
        if (!isAlphaNumeric(masterCompanyId)){
            snackbarAlert(true, 'Error: Invalid company ID.', 'error');
            setMasterCompanyIdError(masterCompanyIdError => masterCompanyIdError = true); 
            return; 
        }
        else {
            setMasterCompanyIdError(masterCompanyIdError => masterCompanyIdError = false); 
        }
        // check for valid portfolio
        if (!portfolioList.includes(portfolio)){
            snackbarAlert(true, 'Error: Invalid portfolio choice.', 'error');
            setPortfolioError(portfolioError => portfolioError = true); 
            return; 
        }
        else {
            setPortfolioError(portfolioError => portfolioError = false); 
        }
        // check that primary ccrm is in userList
        if (!userList.includes(primaryCCRM) && primaryCCRM != ''){
            snackbarAlert(true, 'Error: Invalid primary ccrm userid.', 'error'); 
            setPrimaryCcrmError(primaryCcrmError => primaryCcrmError = true); 
            return; 
        }
        else {
            setPrimaryCcrmError(primaryCcrmError => primaryCcrmError = false); 
        }
        // check that rebate frequency is valid
        if (rebateFrequency == ''){
            snackbarAlert(true, 'Error: Invalid Rebate Frequency.', 'error'); 
            setRebateFrequencyError(rebateFrequencyError => rebateFrequencyError = true); 
            return; 
        }
        else {
            setRebateFrequencyError(rebateFrequencyError => rebateFrequencyError = false); 
        }
        // list of sub company ids to use for validation 
        let companyIdsList = []; 
        // validate sub company input array
        for (let i = 0; i < subCompanyDataList.length; i++) {
            if (subCompanyDataList[i]['blank'] == false){
                // check for errors in row 
                let checkError = subCompanyValidationChecksWithError(subCompanyDataList[i], editBillingRate, snackbarAlert); 
                if (checkError){
                    setSubCompanyErrorList(subCompanyErrorList => subCompanyErrorList = [
                        ...subCompanyErrorList.slice(0, i),
                        {
                            ...subCompanyErrorList[0], 
                            [checkError]: true,
                        }, 
                        ...subCompanyErrorList.slice(i+1, subCompanyErrorList.length),                         
                    ]); 
                    // return to stop request
                    return; 
                }
                else {
                    // set error list 
                    let errorObj = {}
                    Object.keys(subCompanyErrorList[i]).map((el) => {
                        errorObj[el] = false; 
                    }); 
                    setSubCompanyErrorList(subCompanyErrorList => subCompanyErrorList = [
                        ...subCompanyErrorList.slice(0, i),
                        errorObj, 
                        ...subCompanyErrorList.slice(i+1, subCompanyErrorList.length),                         
                    ]); 
                }
                // if no error add sub company id to list for other validations
                companyIdsList.push(subCompanyDataList[i]['companyid']); 
            }
        }

        // add all masterIncentiveSetupIds from dataList 
        let masterIncentiveSetupIdsList = []; 
        for (let i = 0; i < rebateSetupDataList.length; i++){
            if (rebateSetupDataList[i]['blank'] == false && rebateSetupDataList[i]['mastersetupbool'] == true){
                masterIncentiveSetupIdsList.push(rebateSetupDataList[i]['incentivesetupid']); 
            }
        }
        // create temp array for transformations before request 
        let rebateSetupDataListRequest = copyObjArray(rebateSetupDataList); 
        // validate rebate setup input array
        for(let i = 0; i < rebateSetupDataListRequest.length; i++) {
            if(rebateSetupDataListRequest[i]['blank'] == false){
                // run transformation on incentive rates depending on rebate input type id (id of 0 is default and does not require transformation)
                if (rebateInputTypeId == 1 && rebateSetupDataListRequest[i]['incentiverate'] != null) { 
                    // id == 1 for input as percent transformed to decimal 
                    rebateSetupDataListRequest[i]['incentiverate'] = percentToDecimal(rebateSetupDataListRequest[i]['incentiverate']); 
                }
                // return on invalid input
                let checkError = rebateInputValidationChecksWithError(rebateSetupDataListRequest[i], companyIdsList, setupToAllSubs, masterIncentiveSetupIdsList, snackbarAlert); 
                if (checkError){
                    // set error list 
                    setRebateSetupErrorList(rebateSetupErrorList => rebateSetupErrorList = [
                        ...rebateSetupErrorList.slice(0, i),
                        {
                            ...rebateSetupErrorList[0], 
                            [checkError]: true,
                        }, 
                        ...rebateSetupErrorList.slice(i+1, rebateSetupErrorList.length),                         
                    ]); 
                    // return to stop request
                    return; 
                }
                else {
                    // set error list 
                    let errorObj = {}
                    Object.keys(rebateSetupErrorList[i]).map((el) => {
                        errorObj[el] = false; 
                    }); 
                    setRebateSetupErrorList(rebateSetupErrorList => rebateSetupErrorList = [
                        ...rebateSetupErrorList.slice(0, i),
                        errorObj, 
                        ...rebateSetupErrorList.slice(i+1, rebateSetupErrorList.length),                         
                    ]); 
                    // else remove commas from numeric min spend and max spend 
                    rebateSetupDataListRequest[i]['minspend'] = removeStrCommas(rebateSetupDataListRequest[i]['minspend']);
                    rebateSetupDataListRequest[i]['maxspend'] = removeStrCommas(rebateSetupDataListRequest[i]['maxspend']);
                    // make days to pay null value if == 'None'
                    if (rebateSetupDataListRequest[i]['daystopay'] == 'None'){
                        rebateSetupDataListRequest[i]['daystopay'] = null;
                    }
                }
            }         
        }
        // create temp array for transformations before request 
        let performanceBonusDataListRequest = copyObjArray(performanceBonusDataList); 
        // validate performance bonus input array
        for (let i = 0; i < performanceBonusDataListRequest.length; i++){
            if(performanceBonusDataListRequest[i]['blank'] == false){
                // return on invalid input
                let checkError = performanceBonusValidationChecksWithError(performanceBonusDataListRequest[i], snackbarAlert);
                if (checkError){
                    // set error list 
                    setPerformanceBonusErrorList(performanceBonusErrorList => performanceBonusErrorList = [
                        ...performanceBonusErrorList.slice(0, i),
                        {
                            ...performanceBonusErrorList[0], 
                            [checkError]: true,
                        }, 
                        ...performanceBonusErrorList.slice(i+1, performanceBonusErrorList.length),                         
                    ]); 
                    return; 
                }
                // else remove commas from spend commitment and bonus amount
                else {
                    // set error list 
                    let errorObj = {}
                    Object.keys(performanceBonusErrorList[i]).map((el) => {
                        errorObj[el] = false; 
                    }); 
                    setPerformanceBonusErrorList(performanceBonusErrorList => performanceBonusErrorList = [
                        ...performanceBonusErrorList.slice(0, i),
                        errorObj, 
                        ...performanceBonusErrorList.slice(i+1, performanceBonusErrorList.length),                         
                    ]); 
                    // run transformations 
                    performanceBonusDataListRequest[i]['spendcommitment'] = removeStrCommas(performanceBonusDataListRequest[i]['spendcommitment']);
                    performanceBonusDataListRequest[i]['bonusamount'] = removeStrCommas(performanceBonusDataListRequest[i]['bonusamount']);
                }
            }
        } 
        // create temp array for transformations before request 
        let payoutInfoDataListRequest = copyObjArray(payoutInfoDataList); 
        // validate payout info input arry
        for(let i = 0; i < payoutInfoDataListRequest.length; i++) {
            if(payoutInfoDataListRequest[i]['blank'] == false){
                // return on invalid input
                let checkError = payoutValidationChecksWithError(payoutInfoDataListRequest[i], companyIdsList, masterPayoutInfoBool, snackbarAlert, payoutMethodData); 
                if (checkError){
                    // set error list 
                    setPayoutInfoErrorList(payoutInfoErrorList => payoutInfoErrorList = [
                        ...payoutInfoErrorList.slice(0, i),
                        {
                            ...payoutInfoErrorList[0], 
                            [checkError]: true,
                        }, 
                        ...payoutInfoErrorList.slice(i+1, payoutInfoErrorList.length),                         
                    ]); 
                    // return to block request
                    return; 
                }
                else {
                    // set error list 
                    let errorObj = {}
                    Object.keys(payoutInfoErrorList[i]).map((el) => {
                        errorObj[el] = false; 
                    }); 
                    setPayoutInfoErrorList(payoutInfoErrorList => payoutInfoErrorList = [
                        ...payoutInfoErrorList.slice(0, i),
                        errorObj, 
                        ...payoutInfoErrorList.slice(i+1, payoutInfoErrorList.length),                         
                    ]); 
                }
                // if ach info does not need to be added and is blank make null 
                if (isEmptyString(payoutInfoDataListRequest[i]['achaccountnumber'])){
                    payoutInfoDataListRequest[i]['achaccountnumber'] = null; 
                }
                if (isEmptyString(payoutInfoDataListRequest[i]['achbankabanumber'])){
                    payoutInfoDataListRequest[i]['achbankabanumber'] = null; 
                }
                if (payoutInfoDataListRequest[i]['achaccounttype'] == '--select--'){
                    payoutInfoDataListRequest[i]['achaccounttype'] = null; 
                } 
                // // delete masterSetupBool key because it does not get input into database
                // delete payoutInfoDataListRequest[i]['mastersetupbool']; 
            } 
        }
        // if payout info for sub companies slice off last element because it is the add row (if it is master payout info there will be no add row)
        if (!masterPayoutInfoBool){
            payoutInfoDataListRequest = payoutInfoDataListRequest.slice(0, payoutInfoDataListRequest.length-1); 
        }
        // validate accountid input 
        for(let i = 0; i < accountIdsDataList.length; i++){
            for(let k = 0; k < accountIdsDataList[i].length; k++){
                // check that account id is numeric 
                if (accountIdsDataList[i][k]['blank'] == false && !isNumeric(accountIdsDataList[i][k]['accountid'])){
                    console.log(accountIdsDataList[i][k]['accountid']); 
                    snackbarAlert(true, 'Error: Invalid account id for sub company ' + subCompanyDataList[i]['companyid'], 'error'); 
                    return; 
                }
                // remove blank key form account list
                else if (accountIdsDataList[i][k]['blank'] == true) {
                    accountIdsDataList[i] = [
                        ...accountIdsDataList[i].slice(0, k), 
                        ...accountIdsDataList[i].slice(k+1, accountIdsDataList[i].length), 
                    ]; 
                }
            }
        }
        // remove index from sub company array objects 
        for (let i = 0; i < subCompanyDataList.length; i++){
            if(subCompanyDataList[i]['blank'] == false){
                delete subCompanyDataList[i]['index']; 
            }
        }
        // set masterCompanyData to insert master company info
        let masterCompanyData =  {
            mastercompanyname: masterCompanyName, 
            mastercompanyid: masterCompanyId, 
            incentivetype: portfolio, 
            incentivefreq: rebateFrequency, 
            primaryccrm: primaryCCRM, 
            active: true, 
            versionnumber: 1,  
        }
        // show spinner on request button 
        setIsLoading(isLoading => isLoading = true); 
        // make post request to insert data into aurora db
        POST('/data/addNewCompanyData', {
            MasterCompanyData: masterCompanyData, 
            // slice of last element of array because it is a placeholder for add icon
            SubCompanyData: subCompanyDataList.slice(0, subCompanyDataList.length-1), 
            RebateSetupData: rebateSetupDataListRequest.slice(0, rebateSetupDataListRequest.length-1), 
            PerformanceBonusData: performanceBonusDataListRequest.slice(0, performanceBonusDataListRequest.length-1), 
            // CompanyEntitlementsData: entitlementsDataList.slice(0, entitlementsDataList.length-1), 
            CompanyEntitlementsData: entitlementsDataList, 
            PayoutInfoData: payoutInfoDataListRequest, 
            AccountIdsData: accountIdsDataList, 
            AddRebateSetupToAllSubCompanies: setupToAllSubs, 
        }).then(res => {
            console.log(res);
            // trigger success alert
            snackbarAlert(true, 'Successfully uploaded data.', 'success');
            // hide spinner
            setIsLoading(isLoading => isLoading = false); 
            // update redux persistant state and redirect to /CompanyDetailsPage
            setCompany(masterCompanyName);
            setCompanyInfo(masterCompanyData);
            window.location = window.location.origin + '/CompanyDetailsPage'
        }).catch(err => {
            console.log(err);
            // show alert on error
            snackbarAlert(true, 'Error uploading data.', 'error');
            // hide spinner
            setIsLoading(isLoading => isLoading = false); 
        });
    }

    // get all info from database to render
    const getAddNewCompanyData = () => {
        setShowProgress(showProgress => showProgress = true);
        return new Promise ((resolve, reject) => {
            GET('/data/addNewCompanyData', {}).then(res => {
                // set add new company state
                let temp = []; 
                for (let i = 0; i < res['data']['AddNewSubCompanyCols'].length; i++){
                    temp.push(res['data']['AddNewSubCompanyCols'][i]['columnname']);
                }
                setSubCompanyTableHeader(subCompanyTableHeader => subCompanyTableHeader = res['data']['AddNewSubCompanyCols']);
                // first element to add to data list 
                let temp1;
                [...temp].map((el) => {
                    if (el == 'blank'){
                        temp1 = {...temp1, [el]: true};
                    }
                    else{
                        temp1 = {...temp1, [el]: ''};
                    }
                });
                setSubCompanyDataList(subCompanyDataList => subCompanyDataList = [temp1]);
                // set add new rebate setup state
                temp = [];
                for (let i = 0; i < res['data']['AddNewRebateSetupCols'].length; i++){
                    temp.push(res['data']['AddNewRebateSetupCols'][i]['columnname']);
                }
                setRebateSetupTableHeader(rebateSetupTableHeader => rebateSetupTableHeader = res['data']['AddNewRebateSetupCols']);
                // first element to add to list
                let temp2; 
                [...temp].map((el) => {
                    if (el == 'blank'){
                        temp2 = {...temp2, [el]: true};
                    }
                    else{
                        temp2 = {...temp2, [el]: ''};
                    }
                });
                setRebateSetupDataList(rebateSetupDataList => rebateSetupDataList = [temp2]);
                // set add new entitlements state
                temp = [];
                for (let i = 0; i < res['data']['AddNewCompanyEntitlementsCols'].length; i++){
                    temp.push(res['data']['AddNewCompanyEntitlementsCols'][i]['columnname']);
                }
                setEntitlementsTableHeader(entitlementsTableHeader => entitlementsTableHeader = res['data']['AddNewCompanyEntitlementsCols']);
                // // first element to add to data list
                // let temp3; 
                // temp.map((el) => {
                //     if (el == 'blank'){
                //         temp3 = {...temp3, [el]: true};
                //     }
                //     else{
                //         temp3 = {...temp3, [el]: ''};
                //     }
                // });
                // setEntitlementsDataList(entitlementsDataList => entitlementsDataList = [temp3]);
                // set add new payout info state
                temp = [];
                for (let i = 0; i < res['data']['AddNewPayoutInfoCols'].length; i++){
                    temp.push(res['data']['AddNewPayoutInfoCols'][i]['columnname']);
                }
                setPayoutInfoTableHeader(payoutInfoTableHeader => payoutInfoTableHeader = res['data']['AddNewPayoutInfoCols']);
                //first element to add to data list
                let temp4; 
                temp.map((el) => {
                    if (el == 'blank'){
                        temp4 = {...temp4, [el]: true};
                    }
                    else {
                        temp4 = {...temp4, [el]: ''};
                    }
                });
                setPayoutInfoDataList(payoutInfoDataList => payoutInfoDataList = [temp4]);
                // set add new performance bonus table state
                temp = [];
                for (let i = 0; i < res['data']['AddNewPerformanceBonusCols'].length; i++){
                    temp.push(res['data']['AddNewPerformanceBonusCols'][i]['columnname']);
                }
                setPerformanceBonusTableHeader(performanceBonusTableHeader => performanceBonusTableHeader = res['data']['AddNewPerformanceBonusCols']);
                // first element to add to data list 
                let temp5;  
                temp.map((el) => {
                    if (el == 'blank'){
                        temp5 = {...temp5, [el]: true};
                    }
                    else{
                        temp5 = {...temp5, [el]: ''};
                    }
                });
                setPerformanceBonusDataList(performanceBonusDataList => performanceBonusDataList = [temp5]);
                // set state for top label dropdown 
                setRebateFrequencyList(rebateFrequencyList => rebateFrequencyList = res['data']['RebateFrequencyList']);
                setPortfolioList(portfolioList => portfolioList = res['data']['RebateTypeList']);
                // set edit button bool from user entitlement (only admin)
                setEditButtonBool(editButtonBool => editButtonBool = res['data']['UserEntitlement']);
                // set state for rebate setup row dropdowns
                setProductTypeList(productTypeList => productTypeList = ['--select--', ...res['data']['ProductTypeList']]);
                setFeeCategoryDescriptionList(feeCategoryDescriptionList => feeCategoryDescriptionList = ['--select--', ...res['data']['FeeCategoryData']]);
                setBillingRateList(billingRateList => billingRateList = ['--select--', ...res['data']['BillingRateList']]);
                // change null to None for daysToPayList
                let index = res['data']['DaysToPayList'].indexOf(null); 
                if (index !== -1){
                    res['data']['DaysToPayList'][index] = 'None';
                }
                setDaysToPayList(daysToPayList => daysToPayList = ['--select--', ...res['data']['DaysToPayList']]);
                // set state for user entitlements row dropdowns
                setRoleDescriptionList(roleDescriptionList => roleDescriptionList = ['--select--', ...res['data']['UserRoleData']]);
                // set state for payout info row dropdown 
                setPayoutMethodList(payoutMethodList => payoutMethodList = ['--select--', ...res['data']['PayoutMethodList']]);
                // set state for payout method data for validations 
                setPayoutMethodData(payoutMethodData => payoutMethodData = res['data']['PayoutMethodData']); 
                // set state for addNewAccountIdsModal
                setAccountIdsTableHeader(accountIdsTableHeader => accountIdsTableHeader = res['data']['AddNewAccountIdsCols']); 
                setAccountIdsDataList(accountIdsDataList => accountIdsDataList = []); 
                // set state for rebate input type id to determine incentiverate transformations
                setRebateInputTypeId(rebateInputTypeId => rebateInputTypeId = res['data']['RebateInputTypeId']); 
                // set state for processor list and defaults
                setProcessorList(processorList => processorList = res['data']['ProcessorIdsList']); 
                setProcessorDefaultData(processorDefaultData => processorDefaultData = res['data']['RebateIncentiveData']); 
                // hide loading skeletons and show table
                setShowProgress(showProgress => showProgress = false);
                resolve(res);
            }).catch(err => {
                setShowProgress(showProgress => showProgress = false);
                reject(err);
            });
        });
    }

    // get user info from users endpoint
    const getUserInfoData = () => {
        return new Promise((resolve, reject) => {
            GET('/user/inv/headers', {}).then(res => {
                let temp = [];
                // if user is active add to list
                for (let i = 0; i < res['data']['UserInventory']['Users'].length; i++){
                    if (res['data']['UserInventory']['Users'][i]['Enabled'] == true){
                        temp.push(res['data']['UserInventory']['Users'][i]['Username']);
                    }
                }
                // set userlist to render on screen
                setUserList(userList => userList = temp);
                resolve(res);
            }).catch(err => {
                reject(err);
            })
        });
    }
    // useEffect for initial render
    useEffect(() => {
        // make initial get request to get display information from database
        getAddNewCompanyData().then(res => {
            console.log(res);
        }).catch(err => {
            console.log(err);
        });      
        // make get request to get user list from users endpoint
        getUserInfoData().then(res => {
            console.log(res);
        }).catch(err => {
            console.log(err);
        });
    }, []);

    // when primaryCCRM changes add to entitlements list as ccrm
    useEffect(() => {
        // set CCRM entitlement for primary ccrm 
        if (primaryCCRM != null && primaryCCRM.length > 0){
            setEntitlementsDataList(entitlementsDataList => entitlementsDataList = [
                {
                    active: process.env.REACT_APP_ACTIVE_DESCRIPTION, 
                    userid: primaryCCRM, 
                    userroledescription: process.env.REACT_APP_CCRM_DESCRIPTION, 
                    blank: false, 
                }, 
            ]); 
        }
        else {
            setEntitlementsDataList(entitlementsDataList => entitlementsDataList = []); 
        }
        
    }, [primaryCCRM]); 

    // useEffect for masterPayoutInfoBool toggle
    useEffect(() => {
        // if masterPayoutInfoBool is true make one input row for master company 
        if (masterPayoutInfoBool){
            let temp2 = []; 
            for (let i = 0; i < payoutInfoTableHeader.length; i++){
                temp2.push(payoutInfoTableHeader[i]['columnname']);
            }
            let temp;
            temp2.map((el) => {
                if (el == 'blank'){
                    temp = {...temp, [el]: false};
                }
                else if (el == 'active'){
                    temp = {...temp, [el]: 'Active'};
                }
                else if (el == 'payoutmethoddescription' || el == 'achaccounttype'){
                    temp = {...temp, [el]: '--select--'};
                }
                else if (el == 'companyid'){
                    temp = {...temp, [el]: process.env.REACT_APP_MASTER_PAYOUT_INFO_PLACEHOLDER}; 
                }
                else{
                    temp = {...temp, [el]: ''};
                }
            });
            setPayoutInfoDataList(payoutInfoDataList => payoutInfoDataList = [ temp ]);
        }
        // if changing back to sub company payout info reset payoutInfoDataList
        else {
            // set add new payout info state
            let temp = [];
            for (let i = 0; i < payoutInfoTableHeader.length; i++){
                temp.push(payoutInfoTableHeader[i]['columnname']);
            }
            //first element to add to data list
            let temp4; 
            temp.map((el) => {
                if (el == 'blank'){
                    temp4 = {...temp4, [el]: true};
                }
                else {
                    temp4 = {...temp4, [el]: ''};
                }
            });
            setPayoutInfoDataList(payoutInfoDataList => payoutInfoDataList = [ temp4 ]);
        }
    }, [masterPayoutInfoBool]); 

    // useEffect for setupToAllSubsChange
    useEffect(() => {
        // remove companyid from all elements of rebateSetupDataList
        let temp = rebateSetupDataList; 
        if (setupToAllSubs){
            for (let i = 0; i < temp.length; i++){
               temp[i]['companyid'] = null;  
            }
        }
        setRebateSetupDataList(rebateSetupDataList => rebateSetupDataList = temp); 
    }, [setupToAllSubs]); 

    // object with variables to pass to context
    const settings = { 
        subCompanyDataList, 
        setSubCompanyDataList, 
        subCompanyTableHeader, 
        setSubCompanyTableHeader, 
        rebateSetupDataList, 
        setRebateSetupDataList, 
        rebateSetupTableHeader, 
        setRebateSetupTableHeader, 
        entitlementsDataList, 
        setEntitlementsDataList, 
        entitlementsTableHeader, 
        setEntitlementsTableHeader,
        payoutInfoDataList, 
        setPayoutInfoDataList, 
        payoutInfoTableHeader, 
        setPayoutInfoTableHeader,  
        performanceBonusDataList, 
        setPerformanceBonusDataList,
        performanceBonusTableHeader, 
        setPerformanceBonusTableHeader,  
        feeCategoryDescriptionList,  
        productTypeList, 
        billingRateList, 
        daysToPayList,
        roleDescriptionList,  
        userList, 
        payoutMethodList, 
        showSnackbar, 
        setShowSnackbar, 
        snackbarMessage, 
        snackbarColor, 
        showAddAccountIdsModal, 
        setShowAddAccountIdsModal, 
        accountIdsDataList, 
        setAccountIdsDataList, 
        accountIdsTableHeader, 
        setAccountIdsTableHeader, 
        accountIdsRow, 
        setAccountIdsRow, 
        showProgress, 
        subCompanyIdsList, 
        setSubCompanyIdsList, 
        masterTierIdsList, 
        setMasterTierIdsList, 
        masterPayoutInfoBool, 
        setupToAllSubs,
        payoutMethodData,   
        subCompanyErrorList, 
        setSubCompanyErrorList, 
        rebateSetupErrorList, 
        setRebateSetupErrorList,
        payoutInfoErrorList, 
        setPayoutInfoErrorList,
        performanceBonusErrorList, 
        setPerformanceBonusErrorList, 
        processorList, 
        processorDefaultData, 
        portfolio, 
        editBillingRate,
        setEditBillingRate, 
        rebateInputTypeId, 
    };

    return(
        <div class='summary-page'>
            {/* Wrap all children so they can access PageContext variables */}
            <PageContext.Provider value={ settings }>
                {/* header and navslider present on every page */}
                <HeaderBanner indicator={1}/>
                <Navslider indicator={-1} backPath='/CompanyInfoLanding'/>
                {/* show modal for adding accountids */}
                <AddNewAccountIdsModal/>
                {/* snackbar component for alerts */}
                <SnackbarAlert />
                {/* top label to input company info */}
                <div class='top-label-div'>
                    <label class='top-label-text'><b>Add New Company Profile</b></label>
                    <br/><br/>
                    <div class='label-text-div'>
                        <span class='label-text-span-left'>
                            <PageInput tag='Company: ' value={masterCompanyName} setValue={setMasterCompanyName} showError={masterCompanyNameError}/>
                        </span>
                        <span class='label-text-span-mid'>
                            <PageInput tag='Rebate ID: ' value={masterCompanyId} setValue={setMasterCompanyId} showError={masterCompanyIdError}/>
                        </span>
                        <span class='label-text-span-right'>
                            <PageDropdown tag='Portfolio: ' value={portfolio} setValue={setPortfolio} list={portfolioList} showError={portfolioError}/>
                        </span>
                    </div>
                    <br/>
                    <div class='label-text-div'>
                        <span class='label-text-span-left'>
                            <PageDropdown tag='Primary CCRM: ' value={primaryCCRM} setValue={setPrimaryCCRM} list={userList} showError={primaryCcrmError}/>
                        </span>
                        <span>
                            <PageDropdown tag='Rebate Frequency: ' value={rebateFrequency} setValue={setRebateFrequency} list={rebateFrequencyList} showError={rebateFrequencyError}/>
                        </span>
                        <span class='label-text-span-right'>
                            { isLoading ? 
                            <button class={'summary-page-button'} style={{backgroundColor: 'gray', borderColor: 'gray'}}>
                                <CircularProgress size='25px' sx={{ color: 'white', margin: '2.5px' }}/>
                            </button>
                            : <EntitledButton entitledBool={editButtonBool} tag='Submit' cssClass='summary-page-button' onClick={()=>onSubmitClick()}/>}
                        </span> 
                    </div> 
                    <br/>  
                </div>
                <br/>
                {/* table to add sub companies */}
                <div class='data-table-div'>
                    <label><b>Sub Companies: </b></label>
                    <OrderedDataTable RowComponent={AddNewSubCompanyRowComponent} tableHeader={subCompanyTableHeader} dataList={subCompanyDataList} showSpinner={showProgress} skeletonHeight='7vh'/>
                </div> 
                <br/>
                {/* table to add rebate setups */}
                <div class='data-table-div'>
                    <span class='label-text-span-left'>
                        <label class='table-label'><b>Rebate Setup: </b></label>
                    </span>
                    <span>
                        <FormControlLabel
                            value="end"
                            control={<Checkbox
                                sx={{
                                    color: '#000000',
                                    '&.Mui-checked': {
                                    color: '#000000',
                                    },
                                }} />}
                            label="Add Setup to all Sub Companies"
                            labelPlacement="end"
                            sx={{
                                color: 'black',
                                // margin: 'auto', 
                            }}
                            checked={setupToAllSubs}
                            onChange={(e)=>{setSetupToAllSubs(setupToAllSubs => setupToAllSubs = e.target.checked)}}
                        />
                    </span>
                    <span class='label-text-span-right'>
                        <text style={{color: 'transparent'}}>---</text>
                    </span>
                    <OrderedDataTable RowComponent={AddNewRebateSetupRowComponent} tableHeader={rebateSetupTableHeader} dataList={rebateSetupDataList} showSpinner={showProgress} skeletonHeight='7vh'/>
                </div>
                <br/>
                {/* table to add performance bonus setups  */}
                <div class='data-table-div'>
                    <label><b>Performance Bonus: </b></label>
                    <OrderedDataTable RowComponent={AddNewPerformanceBonusRowComponent} tableHeader={performanceBonusTableHeader} dataList={performanceBonusDataList} showSpinner={showProgress} skeletonHeight='7vh'/>
                </div>
                <br/>
                {/* table to add payout info for sub companies */}
                    <div class='data-table-div'>
                        <span class='label-text-span-left'>
                            <label class='table-label'><b>Payout Info: </b></label>
                        </span>
                        <span>
                            <FormControlLabel
                                value="end"
                                control={<Checkbox
                                    sx={{
                                        color: '#000000',
                                        '&.Mui-checked': {
                                        color: '#000000',
                                        },
                                    }} />}
                                label="Master Company Payout Info"
                                labelPlacement="end"
                                sx={{
                                    color: 'black',
                                    // margin: 'auto', 
                                }}
                                checked={masterPayoutInfoBool}
                                onChange={(e)=>{setMasterPayoutInfoBool(masterPayoutInfoBool => masterPayoutInfoBool = e.target.checked)}}
                            />
                        </span>
                        <span class='label-text-span-right'>
                                
                        </span>
                        <OrderedDataTable RowComponent={AddNewPayoutInfoRowComponent} tableHeader={payoutInfoTableHeader} dataList={payoutInfoDataList} showSpinner={showProgress} skeletonHeight='7vh'/>
                    </div>
            </PageContext.Provider>
        </div> 
    );
}

export default AddNewCompanyProfilePage;