import { useContext, useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Modal } from "react-bootstrap";
import PageContext from "./PageContext";
import PageDropdown from './PageDropdown';
import PageInput from './PageInput';
import AutoComplete from './AutoCompleteInput';
import { HttpContext } from '../../api-services/HttpService';
import rebateInputValidationChecks from '../functions/rebateInputValidationChecks';
import removeStrCommas from '../functions/removeStrCommas';
import percentToDecimal from '../functions/percentToDecimal'; 
import decimalToPercent from '../functions/decimalToPercent'; 
import ApiRequestButton from './ApiRequestButton';

const EditRebateSetupModal = () =>{
    // get context from parent
    const {
        editRebateSetupModal, 
        setEditRebateSetupModal, 
        editRebateSetupRow, 
        setEditRebateSetupRow,
        masterCompanyData,
        getCompanyDetailsData,  
        snackbarAlert, 
        productList, 
        billingRateList,
        daysToPayList, 
        feeCategoryList, 
        masterSetupIdsList, 
        subCompanyList, 
        additionalDetailsValue, 
        rebateInputTypeId, 
    } = useContext(PageContext);
    // initialize http context 
    const {
        POST, 
        PUT, 
    } = useContext(HttpContext);
    // get persistant redux global state from store
    const company = useSelector((state) => state.company);
    // initialize state variables
    const [statusTag, setStatusTag] = useState('Active');
    const [statusList, setStatusList] = useState(['Active', 'Inactive']);
    const [product, setProduct] = useState('--select--');
    const [billingRate, setBillingRate] = useState('--select'); 
    const [daysToPay, setDaysToPay] = useState('--select--');
    const [feeCategory, setFeeCategory] = useState('--select--');
    const [tierMin, setTierMin] = useState(0);
    const [tierMax, setTierMax] = useState(0);
    const [rebateRate, setRebateRate] = useState(null); // initialize as null because must go into db as null if there is no input
    const [masterSetupId, setMasterSetupId] = useState(null); // initialize as null because must go into db as null if there is no input
    const [subCompany, setSubCompany] = useState('');
    const [isMasterSetup, setIsMasterSetup] = useState(false);
    const [statusChange, setStatusChange] = useState(false);
    const [minimumSpend, setMinimumSpend] = useState(''); 
    const [paybackAmount, setPaybackAmount] = useState(''); 
    // useRef to determine initial render
    const initialRender = useRef(true);
    // useRef for initialValues oby
    const initialValues = useRef({}); 

    const onSubmitClick = () =>{       
        return new Promise((resolve, reject) => {
            let response; 
            // set active arg from statusTag
            let active = false; 
            if (statusTag == 'Active') {
                active = true;
            }
            // make daysToPay null if has value == 'None'
            let validDaysToPay = daysToPay; 
            if (daysToPay == 'None'){
                validDaysToPay = null; 
            }

            // make post request if no status change
            if (!statusChange){
                let params = {
                    active: active, 
                    masterincentivesetupid: masterSetupId, 
                    incentivesetupid: editRebateSetupRow['incentivesetupid'], 
                    mastercompanyid: masterCompanyData['mastercompanyid'], 
                    companyid: subCompany,
                    producttypedescription: product, 
                    billingratedescription: billingRate, 
                    daystopay: validDaysToPay, 
                    feecategorydescription: feeCategory, 
                    minspend: removeStrCommas(tierMin), 
                    maxspend: removeStrCommas(tierMax), 
                    incentiverate: rebateRate, 
                    incentivetype: masterCompanyData['incentivetype'],
                    versionnumber: editRebateSetupRow['versionnumber'], 
                }

                // add additional details if necessary
                if (additionalDetailsValue == 1){
                    if (minimumSpend && minimumSpend.length > 0){
                        params['spendcommitment'] = removeStrCommas(minimumSpend); 
                    }
                    else {
                        params['spendcommitment'] = null
                    }
                    if (paybackAmount && paybackAmount.length > 0){
                        params['bonusamount'] = removeStrCommas(paybackAmount); 
                    }
                    else {
                        params['bonusamount'] = null; 
                    }
                }

                // -- if not a master setup run transformation on incentiverate depending on input type --
                // -- rebateInputTypeId of 0 is considered default and will have no transformation -- 
                if (!isMasterSetup && rebateInputTypeId == 1){
                    // input type id of 1 for percent 
                    params['incentiverate'] = percentToDecimal(params['incentiverate']); 
                } 

                console.log(params); 
                // validate input before making request
                if(!rebateInputValidationChecks(params, subCompanyList, masterSetupIdsList, snackbarAlert)){
                    reject(); 
                    return;
                }

                // add oldValues 
                params['oldValues'] = {
                    ...editRebateSetupRow, 
                    mastercompanyid: masterCompanyData['mastercompanyid'], 
                    incentivetype: masterCompanyData['incentivetype']
                }; 
                // make request 
                PUT('/data/rebateSetups', params).then(res => {
                    console.log(res);
                    response = res; 
                    // hide modal 
                    setEditRebateSetupModal(editRebateSetupModal => editRebateSetupModal = false);
                    // trigger success alert
                    snackbarAlert(true, 'Successfully uploaded data.', 'success');
                    // refresh CompanyDetailsPage using function from parent
                    return getCompanyDetailsData(company);
                }).then(res => {
                    console.log(res);
                    // resolve on success
                    resolve(response); 
                }).catch(err => {
                    console.log(err);
                    // show alert on error
                    snackbarAlert(true, 'Error uploading data.', 'error');
                    // reject on error
                    reject(err);
                });
            }
            // make put request if there is a status change
            else {
                // add old values to params
                let oldValues = {
                    ...editRebateSetupRow, 
                    mastercompanyid: masterCompanyData['mastercompanyid'], 
                    incentivetype: masterCompanyData['incentivetype']
                }; 
                // make request 
                PUT('/data/incentiveSetups',  {
                    masterincentivesetupid: editRebateSetupRow['masterincentivesetupid'], 
                    incentivesetupid: editRebateSetupRow['incentivesetupid'], 
                    mastercompanyid: masterCompanyData['mastercompanyid'], 
                    companyid: subCompany, 
                    versionnumber: editRebateSetupRow['versionnumber'], 
                    active: active, 
                    oldValues: oldValues, 
                }).then(res => {
                    console.log(res);
                    response = res; 
                    // hide modal 
                    setEditRebateSetupModal(editRebateSetupModal => editRebateSetupModal = false);
                    // trigger success alert
                    snackbarAlert(true, 'Successfully uploaded data.', 'success');
                    // refresh CompanyDetailsPage using function from parent
                    return getCompanyDetailsData(company);
                }).then(res => {
                    console.log(res);
                    // resolve on success
                    resolve(response); 
                }).catch(err => {
                    console.log(err);
                    // show alert on error
                    snackbarAlert(true, 'Error uploading data.', 'error');
                    // reject on error
                    reject(err);
                });
            }
        });    
    }

    // useEffect to run when editRebateSetupRow is changed
    useEffect(() => {
        console.log(editRebateSetupRow);
        // do not assign to state variables on initial render
        if (initialRender.current){
            initialRender.current = false;
        }
        // assign to state variables from editRebateSetupRow object
        else{
            // set status state
            if (editRebateSetupRow['active'] == true){
                setStatusTag(statusTag => statusTag = 'Active');
            }
            else{
                setStatusTag(statusTag => statusTag = 'Inactive');
            }
            // set fee Category state
            setFeeCategory(feeCategory => feeCategory = editRebateSetupRow['feecategorydescription']);
            // set rebate details
            setTierMin(tierMin => tierMin = editRebateSetupRow['minspend']);
            setTierMax(tierMax => tierMax = editRebateSetupRow['maxspend']); 
            // set product
            setProduct(product => product = editRebateSetupRow['producttypedescription']);
            // set billing rate and days to pay
            setBillingRate(billingRate => billingRate = editRebateSetupRow['billingratedescription']);
            if (editRebateSetupRow['daystopay']){
                setDaysToPay(daysToPay => daysToPay = editRebateSetupRow['daystopay']);
            }
            else {
                setDaysToPay(daysToPay => daysToPay = 'None');
            }
            // set sub company
            setSubCompany(subCompany => subCompany = editRebateSetupRow['companyid']);
            // set setup id and masterSetupId
            setMasterSetupId(masterSetupId => masterSetupId = editRebateSetupRow['masterincentivesetupid']);
            // set state for hBB&T additional details 
            if (editRebateSetupRow['spendcommitment']){
                setMinimumSpend(minimumSpend => minimumSpend = editRebateSetupRow['spendcommitment']); 
            }   
            else {
                setMinimumSpend(minimumSpend => minimumSpend = ''); 
            }
            if (editRebateSetupRow['bonusamount']){
                setPaybackAmount(paybackAmount => paybackAmount = editRebateSetupRow['bonusamount']); 
            }
            else {
                setPaybackAmount(paybackAmount => paybackAmount = ''); 
            }
            // if masterSetupId and rebateRate are null treat as master setup
            if (editRebateSetupRow['incentiverate'] == null && editRebateSetupRow['masterincentivesetupid'] == null){
                setIsMasterSetup(isMasterSetup => isMasterSetup = true);
            }
            else {
                setIsMasterSetup(isMasterSetup => isMasterSetup = false);
            }
            // -- transform value depending on rebateInputTypeId if rebate rate != null -- 
            // -- null rate means master setup and there is no rate --
            if (editRebateSetupRow['incentiverate'] != null && rebateInputTypeId == 1) {
                setRebateRate(rebateRate => rebateRate = decimalToPercent(editRebateSetupRow['incentiverate'])); 
            }      
            // -- rebateInputTypeId of 0 treated as default and has no transformation -- 
            else {
                setRebateRate(rebateRate => rebateRate = editRebateSetupRow['incentiverate']);
            }

        }
    }, [editRebateSetupRow]);


    // useEffect to change values on isMasterSetup change
    useEffect (() => {
        // if adding master setup bool make rate and master setup id null
        if (isMasterSetup){
            setRebateRate(rebateRate => rebateRate = null);
            setMasterSetupId(masterSetupId => masterSetupId = null);
        }   
    }, [isMasterSetup]);
    // on masterSetupId change make null if empty
    useEffect(() => {
        // set inputs to null if they are empty strings
        if (masterSetupId != null && masterSetupId.length == 0){
            setMasterSetupId(masterSetupId => masterSetupId = null);
        }
    }, [masterSetupId]);
    // on rebateRate change make null if empty
    useEffect(() => {
        if (rebateRate != null && rebateRate.length == 0){
            setRebateRate(rebateRate => rebateRate = null);
        }
    }, [rebateRate]);

    // if status tag is changed check for status change
    useEffect(() => {
        // set active var to compare to row active col
        let active = false;
        if (statusTag == 'Active'){
            active = true;
        }
        // compare col to active
        if (active == editRebateSetupRow['active']){
            // if they are the same no status change
            setStatusChange(statusChange => statusChange = false);
        }
        else {
            // if they are different flag status change
            setStatusChange(statusChange => statusChange = true);
        }
    }, [statusTag, editRebateSetupRow]);

    return(
        <> 
            <Modal className="rebate-summary-modal"
                show={editRebateSetupModal}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                scrollable={true}
                >
                <Modal.Header closeButton onClick={()=>setEditRebateSetupModal(editRebateSetupModal => editRebateSetupModal = false)}>
                    <Modal.Title id="contained-modal-title-vcenter">
                        { isMasterSetup ?
                        <> Edit Master Setup ID: {editRebateSetupRow['incentivesetupid']}</>
                        :<>Edit Setup ID: {editRebateSetupRow['incentivesetupid']}</>}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    {/* dropdowns and inputs to edit rebate setups */}
                    <div class='modal-div'>
                        <PageDropdown tag='Status: ' value={statusTag} setValue={setStatusTag} list={statusList}/>
                    </div>
                    <br/>
                    {/* do not show other inputs if status is changing because a different request will be made */}
                    { !statusChange ? 
                    <>
                        {/* do not show if master setup because master setups have null masterincentivesetupid */}
                        { !isMasterSetup ? 
                            <>
                                <div class='modal-div'>
                                    <AutoComplete tag='Master Setup ID: ' value={masterSetupId} setValue={setMasterSetupId} list={masterSetupIdsList} width='16.5vw' />
                                </div>
                                <br/>
                            </>
                        :<></>}
                        <div class='modal-div'>
                            <AutoComplete tag='Sub Company ID: ' value={subCompany} setValue={setSubCompany} list={subCompanyList} width='16.5vw' />
                        </div>
                        <br/>
                        <div class='modal-div'>
                            <PageDropdown tag='Product: ' value={product} setValue={setProduct} list={productList}/>
                        </div>
                        <br/>
                        <div class='modal-div'>
                            <PageDropdown tag='Billing Rate: ' value={billingRate} setValue={setBillingRate} list={billingRateList}/>
                        </div>
                        <br/>
                        <div class='modal-div'>
                            <PageDropdown tag='Days To Pay: ' value={daysToPay} setValue={setDaysToPay} list={daysToPayList}/>
                        </div>
                        <br/>
                        <div class='modal-div'>
                            <PageDropdown tag='Fee Category: ' value={feeCategory} setValue={setFeeCategory} list={feeCategoryList}/>
                        </div>
                        <br/>
                        <div class='modal-div'>
                            <PageInput tag='Tier Min: ' value={tierMin} setValue={setTierMin} placeholder='Enter Tier Min'/>
                        </div>
                        <br/>
                        <div class='modal-div'>
                            <PageInput tag='Tier Max: ' value={tierMax} setValue={setTierMax} placeholder='Enter Tier Max'/>
                        </div>
                        <br/>
                        {/* do not show if master setup because master setups have null rate */}
                        { !isMasterSetup ? 
                        <>
                            <div class='modal-div'>
                                <PageInput tag='Rebate Rate: ' value={rebateRate} setValue={setRebateRate} placeholder='Enter Rebate Rate'/>
                            </div>
                            <br/>
                        </>    
                        :<><div></div></>}
                        {/* if the additionalDetailsValue is 1 show hBB&T additional details editable fields */}
                        { additionalDetailsValue == 1 ? 
                        <>
                            <div class='modal-div'>
                                <PageInput tag='Minimum Spend: ' value={minimumSpend} setValue={setMinimumSpend} />
                            </div>
                            <br/>
                            <div class='modal-div'>
                                <PageInput tag='Payback Amount' value={paybackAmount} setValue={setPaybackAmount} />
                            </div>
                            <br/>
                        </>
                        : <></>}
                    </>
                    :<></>}
                    {/* button to submit edits to rebate setup */}
                    <ApiRequestButton onButtonClick={onSubmitClick} />
                    <br/><br/>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default EditRebateSetupModal;