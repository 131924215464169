import { Fragment } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';

const AutoCompleteInput = ({tag, value, setValue, obj, setObj,  list, width, font}) => {
    // set width if not passed as prop
    if (width == null){
        width = '16.6vw';
    }
    // set font size if given as prop, else use default
    let style = {};
    if (font){
        style['fontSize'] = font; 
    }

    // event hander to change state variable when a dropdown value is click
    const onInputChange = (e, values) => {
        setObj(obj => obj = values);
    }

    // event handler to change state variable when textfield changes
    const onTextFieldChange = (e) =>{
        setValue(value => value = e.target.value);
    } 

    const filterOptions = createFilterOptions({
        stringify: ({ label, id }) => `${label}${id}`
    });

    return (
        // material ui autocomplete component
        <FormControl fullWidth size='small' sx={{ width: width, minWidth: 210 }} >
            <Autocomplete
                value={obj}
                options={list}
                renderOption={(props, option) => (
                    <li {...props} key={option.id}>
                       {option.label + '\n' + option.id}
                    </li>
                  )}
                getOptionsLabel={option => option.label}
                renderInput={params => {
                    return (
                        <TextField 
                            {...params} 
                            label={tag} 
                            variant="outlined" 
                            fullWidth
                            size='small'
                            value={value}
                            onChange={(e)=>onTextFieldChange(e)}
                        />
                    )
                }}
                onChange={(e, values)=>onInputChange(e, values)}
                disableClearable
                filterOptions={filterOptions}
            />
        </FormControl>
    );
}

export default AutoCompleteInput;