import { useContext, useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Modal } from "react-bootstrap";
import PageContext from "./PageContext";
import PageDropdown from './PageDropdown';
import PageInput from './PageInput';
import DatePicker from './DatePicker';
import { HttpContext } from '../../api-services/HttpService';
// import { AuthContext } from '../../auth/AuthRoute';
import toDate from '../functions/toDate'; 
import checkChange from '../functions/checkChange'; 
import ApiRequestButton from './ApiRequestButton';

const EditSubCompanyModal = () =>{  
    // get context from parent
    const {
        editSubCompanyModal, 
        setEditSubCompanyModal,
        editSubCompanyRow,
        setEditSubCompanyRow,
        masterCompanyData,
        getCompanyDetailsData,  
        snackbarAlert, 
        processorList, 
        editableProcessor,
        editableBillingRate, 
        billingRateList, 
    } = useContext(PageContext);
    // initialize http context 
    const { 
        PUT, 
    } = useContext(HttpContext);
    // get persistant redux global state from store
    const company = useSelector((state) => state.company);
    // set state
    const [companyName, setCompanyName] = useState('');
    const [companyId, setCompanyId] = useState('');
    const [status, setStatus] = useState('');
    const [statusList, setStatusList] = useState(['Active', 'Inactive']);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [processor, setProcessor] = useState(''); 
    const [billingRate, setBillingRate] = useState(''); 
    // useRef to determine initial render
    const initialRender = useRef(true);
    // useRef to set initial values to check for changes 
    const initialValues = useRef({}); 

    // event handler for submit click
    const onSubmitClick = () =>{
        return new Promise((resolve, reject) => {
            let response; 
            // make sure company name and id are valid
            if (companyName == '' || companyId == ''){
                // show alert on invalid input
                snackbarAlert(true, 'Invalid company name or ID.', 'error');
                reject(); 
                return;
            }
            // require value for billing rate 
            if (billingRate == '--select--'){
                // show alert on invalid input 
                snackbarAlert(true, 'Invalid billing rate.', 'error'); 
                reject(); 
                return; 
            }
            // set active bool
            let active = false; 
            if (status == 'Active'){
                active = true;
            }
            // remove elements that have not changed from params 
            let params = checkChange(initialValues, {
                mastercompanyid: masterCompanyData['mastercompanyid'],
                companyid: companyId, 
                companyname: companyName,
                processorid: processor,  
                billingratedescription: billingRate, 
                active: active, 
                startdate: startDate, 
                enddate: endDate, 
            });
            // add oldValues to params 
            params['oldValues'] = initialValues.current; 
            // make PUT request 
            PUT('/data/subCompanyData', params).then(res => {
                console.log(res);
                response = res; 
                // hide modal
                setEditSubCompanyModal(editSubCompanyModal => editSubCompanyModal = false);
                // trigger success alert
                snackbarAlert(true, 'Successfully uploaded data.', 'success');
                // refresh company details page using function from parent
                return getCompanyDetailsData(company);
            }).then(res => {
                console.log(res);
                // resolve on success
                resolve(response); 
            }).catch(err => {
                console.log(err);
                // show alert on error
                snackbarAlert(true, 'Error uploading data.', 'error');
                // reject on error
                reject(err); 
            });
        }); 
    }

    // useEffect to run on change to editSubCompanyRow state variable
    useEffect(() => {
        // do not assign to state variables on initial render
        if (initialRender.current){
            initialRender.current = false;
        }
        // assign to state variables from editSubCompanyRow object
        else{
            if (editSubCompanyRow['active'] == true){
                setStatus(status => status = 'Active');
            }
            else{
                setStatus(status => status = 'Inactive')
            }
            setCompanyName(companyName => companyName = editSubCompanyRow['companyname']);
            setCompanyId(companyId => companyId = editSubCompanyRow['companyid']);
            setProcessor(processor => processor = editSubCompanyRow['processorid']); 
            // if startdate is not null make datepicker value
            if (editSubCompanyRow['startdate']){
                setStartDate(startDate => startDate = toDate(editSubCompanyRow['startdate'] ));
            }
            else {
                setStartDate(startDate => startDate = null); 
            }
            // if enddate is not null make datepicker value
            if (editSubCompanyRow['enddate']){
                setEndDate(endDate => endDate = toDate(editSubCompanyRow['enddate'] ));
            }
            else {
                setEndDate(endDate => endDate = null); 
            }
            // set billing rate description 
            if (editSubCompanyRow['billingratedescription']) {
                setBillingRate(billingRate => billingRate = editSubCompanyRow['billingratedescription']);
            }
            // use --select-- as default value if no billing rate is picked 
            else {
                setBillingRate(billingRate => billingRate = '--select--');
            }
            // set initialValues object 
            initialValues.current = {
                active: editSubCompanyRow['active'], 
                companyname: editSubCompanyRow['companyname'] ,
                startdate: editSubCompanyRow['startdate'], 
                enddate: editSubCompanyRow['enddate'], 
                processorid: editSubCompanyRow['processorid'], 
                billingratedescription: editSubCompanyRow['billingratedescription'], 
            }
        }
    }, [editSubCompanyRow]);

    return(
        <> 
            <Modal className="rebate-summary-modal"
                show={editSubCompanyModal}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                scrollable={true}
                >
                <Modal.Header closeButton onClick={()=>setEditSubCompanyModal(editSubCompanyModal => editSubCompanyModal = false)}>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Edit Sub Company
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    {/* inputs and dropdowns for sub company info */}
                    <div class='modal-div'>
                        <PageInput tag='Company Name: ' value={companyName} setValue={setCompanyName} />
                    </div>
                    <br/>
                    <div class='modal-div'>
                        <PageDropdown tag='Status: ' value={status} setValue={setStatus} list={statusList}/>
                    </div>
                    <br/>
                    {/* only show editable processor if flag is true */}
                    { editableProcessor ? 
                    <>
                        <div class='modal-div'>
                            <PageDropdown tag='Processor: ' value={processor} setValue={setProcessor} list={processorList}/>
                        </div>
                        <br/>
                    </>
                    :<></>}
                    {/* only show editable billing rate if flag is true */}
                    { editableBillingRate ? 
                    <>
                        <div class='modal-div'>
                            <PageDropdown tag='Billing Rate: ' value={billingRate} setValue={setBillingRate} list={billingRateList}/>
                        </div>
                        <br/>
                    </>
                    :<></>}
                    <div class='modal-div'>
                        <DatePicker tag='Start Date: ' value={startDate} setValue={setStartDate} disable={endDate} indicator={3} /> 
                    </div>
                    <br/>
                    <div class='modal-div'>
                        <DatePicker tag='End Date: ' value={endDate} setValue={setEndDate} disable={startDate} indicator={4}/>
                    </div>
                    {/* button to submit edits to sub company information */}
                    <ApiRequestButton onButtonClick={onSubmitClick} />
                </Modal.Body>
            </Modal>
        </>
    );
}

export default EditSubCompanyModal;