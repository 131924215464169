import { useContext } from 'react';
import PageContext from './PageContext';
import greenCircleIcon from '../../svgs/green-circle-icon.svg';
import redCircleIcon from '../../svgs/red-circle-icon.svg';
import RowEditIcon from './RowEditIcon';
import magnifyIcon from '../../svgs/magnifying-glass-icon.svg';
import PageTooltip from './PageTooltip';

// pass row object as prop
const SubCompanyRow = ({ row, count, tableHeader, enableEdit }) =>{
    // get context variables from parent 
    const {
        editSubCompanyModal, 
        setEditSubCompanyModal,
        editSubCompanyRow,
        setEditSubCompanyRow,
        editAccountIdsModal, 
        setEditAccountIdsModal, 
    } = useContext(PageContext);  

    // event handler for edit icon click 
    const onEditClick = () =>{
        // make EditSubCompanyModal visible using state variable from context 
        setEditSubCompanyModal(editSubCompanyModal => editSubCompanyModal = true);
        // set row for use in EditSubCompanyModal 
        setEditSubCompanyRow(editSubCompanyRow => editSubCompanyRow = row);
    }

    // event handler for view accout ids click
    const onViewClick = () => {
        // set row for use in EditAccountIdsModal 
        setEditSubCompanyRow(editSubCompanyRow => editSubCompanyRow = row);
        // make edit account ids modal visible
        setEditAccountIdsModal(editAccountIdsModal => editAccountIdsModal = true); 
    }

    // display red circle for disabled (0) status or green circle for active (1) status
    const statusIcon = (status) =>{
        if ( status == true){
            return <img src={greenCircleIcon} class='data-table-icon-left'/>
        }
        else {
            return <img src={redCircleIcon} class='data-table-icon-left'/>
        }
    }

    return(
        <>
            {/* map logic over ever column in row object */}
            {tableHeader.map(el =>
                // if status column apply statusIcon function 
                el['columnname'] == 'active' ?
                    <td>{statusIcon(row[el['columnname']])}</td>
                : el['columnname'] == 'blank' ?
                    <td>
                        {/* show edit icon to trigger EditSubCompanyRow modal */}
                        <RowEditIcon enable={enableEdit} onClick={onEditClick}/>
                    </td>
                : el['columnname'] == 'viewaccountids' ? 
                    <td>
                        <PageTooltip title='Account IDs'>
                            <img src={magnifyIcon} className='data-table-icon-left' onClick={(e)=>onViewClick(e)}/>
                        </PageTooltip>
                    </td>
                : row[el['columnname']] == null ?
                    <td class='table-col'>None</td>
                // standard display for all other columns 
                :<td>{row[el['columnname']]}</td>
            )}
            
        </>
    );
}

export default SubCompanyRow; 