import { useContext, useState, useEffect, useRef } from 'react';
import { Modal } from "react-bootstrap";
import PageContext from './PageContext';
import AutoCompleteInput from './AutoCompleteInput';
import PageInput from './PageInput';
import PageDropdown from './PageDropdown';
// import Checkbox from '@mui/material/Checkbox';
// import FormControlLabel from '@mui/material/FormControlLabel';
import { HttpContext } from '../../api-services/HttpService';
import isNumeric from '../functions/isNumeric';
import ApiRequestButton from './ApiRequestButton';


const EditAdhocPaymentModal = ({ refreshFunction }) => {
    // initialize PageContext
    const {
        showEditAdhocModal,
        setShowEditAdhocModal,
        subCompanyList,
        editAdhocRow,
        // setEditAdhocRow,
        masterCompanyId,
        snackbarAlert
    } = useContext(PageContext);
    // initialize HttpContext
    const {
        PUT,
    } = useContext(HttpContext);
    // initialize state
    const [subCompany, setSubCompany] = useState(null);
    const [amount, setAmount] = useState(0);
    const [masterPayment, setMasterPayment] = useState(false);
    const [status, setStatus] = useState('Active');
    const [statusList, setStatusList] = useState(['Active', 'Inactive']);
    const [companyList, setCompanyList] = useState([]);
    // initialize ref to determine initialRender
    const initialRender = useRef(true);

    // event handler for checkbox click
    const onCheckboxClick = (e) => {
        setMasterPayment(masterPayment => masterPayment = e.target.checked)
        // set sub company to place
        if (e.target.checked == true) {
            setSubCompany(subCompany => subCompany = process.env.REACT_APP_MASTER_PAYOUT_INFO_PLACEHOLDER);
        }
        else if (editAdhocRow['companyid'] != process.env.REACT_APP_MASTER_PAYOUT_INFO_PLACEHOLDER) {
            setSubCompany(subCompany => subCompany = editAdhocRow['companyid']);
        }
        else {
            setSubCompany(subCompany => subCompany = null);
        }
    }

    // event handler for submit click 
    const onSubmitClick = () => {
        return new Promise((resolve, reject) => {
            let response; 
            // validate amount is numeric 
            if (!isNumeric(amount.replaceAll(",", ""))) {
                snackbarAlert(true, 'Invalid Payment Amount.', 'error');
                reject()
                return;
            }
            // validate sub company
            if (!subCompanyList.includes(subCompany) && subCompany != process.env.REACT_APP_MASTER_PAYOUT_INFO_PLACEHOLDER) {
                console.log(subCompany)
                snackbarAlert(true, 'Invalid Sub Company.', 'error');
                reject(); 
                return;
            }
            // set active based on status
            let active = false;
            if (status == 'Active') {
                active = true;
            }
            // make request 
            PUT('/data/adhoc/adhocpage', {
                paymentid: editAdhocRow['paymentid'],
                mastercompanyid: masterCompanyId,
                companyid: subCompany,
                amount: amount.replaceAll(",", ""),
                active: active,
            }).then(res => {
                console.log(res);
                response = res;
                // hide modal 
                setShowEditAdhocModal(showEditAdhocModal => showEditAdhocModal = false);
                // trigger success alert
                snackbarAlert(true, 'Successfully uploaded data.', 'success');
                // refresh data on page
                return refreshFunction();
            }).then(res => {
                console.log(res);
                // resolve on success
                resolve(response); 
            }).catch(err => {
                console.log(err);
                // show alert on error
                snackbarAlert(true, 'Error uploading data.', 'error');
                // reject on error
                reject(err); 
            });
        }); 
    }

    // useEffect to set state on row change 
    useEffect(() => {
        if (initialRender.current == false) {
            // determine master payment or sub company payment
            if (editAdhocRow['companyid'] == process.env.REACT_APP_MASTER_PAYOUT_INFO_PLACEHOLDER) {
                setMasterPayment(masterPayment => masterPayment = true);
                // sub company on master payment (will be -999999999)
                setSubCompany(subCompany => subCompany = editAdhocRow['companyid']);
            }
            else {
                setMasterPayment(masterPayment => masterPayment = false);
                // if not master payment set sub company
                setSubCompany(subCompany => subCompany = editAdhocRow['companyid']);
            }
            // set amount 
            setAmount(amount => amount = editAdhocRow['amount']);
            // set status 
            if (editAdhocRow['active'] == true) {
                setStatus(status => status = 'Active');
            }
            else {
                setStatus(status => status = 'Inactive');
            }
            // set sub company list while removing 'All' if present 
            let index = subCompanyList.indexOf('All');
            if (index != -1) {
                setCompanyList(companyList => companyList = [...subCompanyList.slice(0, index), ...subCompanyList.slice(index + 1, subCompanyList.length)]);
            }
            else {
                setCompanyList(companyList => companyList = subCompanyList);
            }
        }
        else {
            initialRender.current = false;
        }
    }, [editAdhocRow]);

    return (
        <>
            <Modal className="rebate-summary-modal"
                show={showEditAdhocModal}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                scrollable={true}
            >
                <Modal.Header closeButton onClick={() => setShowEditAdhocModal(showEditAdhocModal => showEditAdhocModal = false)}>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Edit Ad-Hoc Payment
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <div class='modal-div'>
                        <PageDropdown tag='Status' value={status} setValue={setStatus} list={statusList} />
                    </div>
                    <br />
                    {/* <div class='modal-div'>
                        <FormControlLabel
                            value="end"
                            control={<Checkbox
                                sx={{
                                    color: '#000000',
                                    '&.Mui-checked': {
                                        color: '#000000',
                                    },
                                }} />}
                            label="Add Payment to Master Company"
                            labelPlacement="end"
                            sx={{
                                color: 'black',
                            }}
                            checked={masterPayment}
                            onChange={(e) => onCheckboxClick(e)}
                        />
                    </div>
                    <br /> */}
                    {!masterPayment ?
                        <>
                            <div class='modal-div'>
                                <AutoCompleteInput tag='Sub Company: ' value={subCompany} setValue={setSubCompany} list={companyList} />
                            </div>
                            <br />
                        </>
                        : <></>}
                    <div class='modal-div'>
                        <PageInput tag='Payment Amount: ' value={amount} setValue={setAmount} />
                    </div>
                    <br />
                    <ApiRequestButton onButtonClick={onSubmitClick} />
                    <br /><br />
                </Modal.Body>
            </Modal>
        </>
    );
}

export default EditAdhocPaymentModal;