import { useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../redux/index';
import { Link } from 'react-router-dom';

const SubCompanySearchRow = ({row, count, tableHeader}) => {
    // redux state for use of persistant state in different route (CompanyDetailsPage)
    const company = useSelector((state) => state.company);
    const companyInfo = useSelector((state) => state.companyInfo);
    const backPath = useSelector((state) => state.backPath); 
    const dispatch = useDispatch();
    const { setCompany, setCompanyInfo, setBackPath } = bindActionCreators(actionCreators, dispatch);

    // event handler when link is clicked
    const onCompanyClick = (e) =>{
        // set back path to let navbar on next page to return to /SubCompanySearchPage
        setBackPath('/SubCompanySearchPage'); 
        // set company state to bring to new route
        setCompany(row['mastercompanyname']);
        setCompanyInfo(row);
    }

    return(
        <>
            {tableHeader.map(el => {
                // logic to determine how to display each column based on name
                if ( el['columnname'] == 'companyname'){
                    return(
                        <td class='table-col'>
                            <Link onClick={(e)=>onCompanyClick(e)}
                                to={{
                                    pathname: "/CompanyDetailsPage",
                                }}>
                                {row[el['columnname']]}
                            </Link>
                        </td>
                    );
                }
                else if (row[el['columnname']] == null){
                    return(
                        <td class='table-col'>None</td>
                    );
                }
                else{
                    return(
                        <td class='table-col'>{row[el['columnname']]}</td>
                    );
                }
            })}
        </>
    );
}

export default SubCompanySearchRow; 