const amplifyConfigure = {
    Auth: {
      region: 'us-east-1',
      userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
      userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
    },
    oauth: {
      domain: process.env.REACT_APP_COGNITO_LOGIN_URL,
      scope: [
        "aws.cognito.signin.user.admin",
        "email",
        "openid",
        "profile",
      ],
      redirectSignIn: `${window.location.origin}/callback`,
      redirectSignOut: `${window.location.origin}/`,
      responseType: "code",
    },
};
  
export default amplifyConfigure;