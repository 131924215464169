import * as React from 'react';
import TextField from '@mui/material/TextField';
import PageTooltip from './PageTooltip'; 

const PageInputReduxTooltip = ({ tag, tooltip, value, setValue, width, font }) =>{
    // set width if not given as prop
    if (width == null){
        width = '16.6vw';
    }
    // set font size if given as prop, else use default
    let style = {}; 
    if (font){
        style['fontSize'] = font;
    }

    return (
        <>
            <PageTooltip title={tooltip}>
                {/* material ui textfield for text input */}
                <TextField 
                        sx={{width: width, minWidth: width }} 
                        id="outlined-basic" 
                        size='small' 
                        label={tag} 
                        variant="outlined" 
                        value={value} 
                        onChange={(e)=>setValue(e.target.value)}
                        inputProps={{style: style}} // font size of input text
                        InputLabelProps={{style: style}} // font size of input label
                    />
            </PageTooltip>
        </> 
    );
} 

export default PageInputReduxTooltip;