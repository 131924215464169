import { useContext, useEffect } from 'react';
import { AuthContext } from './AuthRoute';

const LoginRedirect = () => {
    const {
        redirectToLogin,
    } = useContext(AuthContext);

    useEffect(() => {
        redirectToLogin();
    }, []); 

    return(
        <></>
    );

}

export default LoginRedirect;