import { useContext } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import PageContext from '../sub-components/PageContext';

const SnackbarAlert = () => {
    // get context variables from parent 
    const {
        showSnackbar, 
        setShowSnackbar, 
        snackbarMessage, 
        snackbarColor, 
    } = useContext(PageContext);

    // event handler for close snackbar click 
    const handleClose = () => {
        setShowSnackbar(showSnackbar => showSnackbar = false);
    }

    return (
        // material ui snackbar component
        <Snackbar open={showSnackbar} autoHideDuration={12000} onClose={handleClose}>
            <Alert onClose={handleClose} severity={snackbarColor} sx={{ width: '500px' }} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                {snackbarMessage}
            </Alert>
        </Snackbar>
    );
}

export default SnackbarAlert;