import { useState, useEffect, useRef, useContext } from 'react';
import HeaderBanner from '../sub-components/HeaderBanner';
import Navslider from '../sub-components/Navslider';
import OrderedDataTable from '../sub-components/OrderedDataTable';
import PageContext from '../sub-components/PageContext';
import ViewSubCompaniesModal from '../sub-components/ViewSubCompaniesModal';
import standardSearch from '../functions/standardSearch';
import applyFilters from '../functions/applyFilters';
import { HttpContext } from '../../api-services/HttpService';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from 'redux';
import { actionCreators } from "../../redux/index";
import PageInputRedux from '../sub-components/PageInputRedux';
import ConsortiumSearchRow from '../sub-components/ConsortiumSearchRow'; 

const ConsortiumSearchPage = () => {
    // initialize http context 
    const {
        GET, 
    } = useContext(HttpContext);
    // initialize redux persisent state for search 
    const consortiumSearch = useSelector((state) => state.consortiumSearch);
    const dispatch = useDispatch();
    const { 
        setConsortiumSearch, 
    } = bindActionCreators(actionCreators, dispatch);
    // initialize state for table
    const [showDataList, setShowDataList] = useState([]);
    const [dataList, setDataList] = useState([]);
    const [tableHeader, setTableHeader] = useState([]);
    const [showSpinner, setShowSpinner] = useState(false);
    // initialize state for company numbers modal 
    const [viewModal, setViewModal] = useState(false);
    const [viewCompany, setViewCompany] = useState({});
    // initailize ref to determine initial render
    const initialRender = useRef(true);

    // function wrapper to pass row component to datatable
    const RowComponent = (row, count, tableHeader) =>{
        return(
            <>
                <ConsortiumSearchRow row={row} count={count} tableHeader={tableHeader}/>
            </>
        ); 
    }  

    // make get request to get consortium data
    const getConsortiumData = () => {
        setShowSpinner(showSpinner => showSpinner = true);
        return new Promise ((resolve, reject) => {
            GET('/data/consortiumSearchPage', {}).then(res => {
                // set state
                setDataList(dataList => dataList = res['data']['ConsortiumSearchData']); 
                setShowDataList(showDataList => showDataList = res['data']['ConsortiumSearchData']); 
                setTableHeader(tableHeader => tableHeader = res['data']['ConsortiumSearchCols']); 
                // stop showing spinner
                setShowSpinner(showSpinner => showSpinner = false);
                // resolve response
                resolve(res);
            }).catch(err => {
                setShowSpinner(showSpinner => showSpinner = false);
                // reject on error
                reject(err); 
            }); 
        }); 
    }

    // useEffect to run on initial render
    useEffect(() => {
        getConsortiumData().then(res => {
            console.log(res); 
        }).catch(err => {
            console.log(err); 
        }); 
    }, []); 

    // useEffect to run list of search/filter functions on table data
    useEffect(() => { 
        // do not run on initial render
        if (!initialRender.current){
            // declare list of functions with parameters to filter/search list
            const functionList = [
                {function: standardSearch, value: consortiumSearch, flag: 0, cols: ['consortiumid', 'mastercompanyname', 'mastercompanyid', 'primaryccrm']}
            ];
            // use applyFilters function to search by running all functions declared above
            let filteredList = applyFilters(dataList, functionList);
            // update showDataList
            setShowDataList(showDataList => showDataList = filteredList);
        }
        else{
            initialRender.current = false;
        }
        
    }, [dataList, consortiumSearch]);

    // object to pass variables to context 
    const settings = {
        viewModal, 
        setViewModal, 
        viewCompany, 
        setViewCompany, 
    };

    return (
        <div class='summary-page'>
            {/* wrap children so they can access variables in PageContext */}
            <PageContext.Provider value={ settings }>
                {/* header and navslider present on every page */}
                <HeaderBanner indicator={1}/>
                <Navslider indicator={-1} backPath='/CompanyInfoLanding'/>
                {/* modal for viewing sub companies. Default is hidden state */}
                <ViewSubCompaniesModal />
                {/* label for search column dropdown and search bar */}
                <div class='top-label-div'>
                    <label class='top-label-text'><b>Consortium Search</b></label>
                    <br/>
                    <div class='label-dropdown-div'>
                        <span class='label-dropdown-left'>
                            {/* link button to navigate to master company search page */}
                            <Link
                                to={{
                                    pathname: "/CompanyProfilePage",
                                }}
                                ><button class='summary-page-button'>Master Company Search</button>
                            </Link>
                            {/* link button to navigate to sub company search page */}
                            <Link
                                to={{
                                    pathname: "/SubCompanySearchPage",
                                }}
                                ><button class='summary-page-button'>Sub Company Search</button>
                            </Link>
                        </span> 
                        <span class='label-dropdown-right'>
                            {/* search input to change value for search functionality*/} 
                            <PageInputRedux tag='Search: ' value={consortiumSearch} setValue={setConsortiumSearch}/>
                        </span>
                        <br/>
                    </div>
                    <br/>
                </div> 
                <br/>
                {/* table to display company data with links for drilldown */}
                <div class='data-table-div'>
                    <OrderedDataTable RowComponent={RowComponent} tableHeader={tableHeader} dataList={showDataList} showSpinner={showSpinner} numToPage={50} skeletonHeight='28vh'/>
                    <br/><br/>
                </div>
            </PageContext.Provider>
        </div>
    ); 
}

export default ConsortiumSearchPage; 