import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const PageDropdownRedux = ({ tag, list, value, setValue, width, font}) =>{
    // set width if not given as prop
    if (width == null){
        width = '16.6vw';
    }
    // set font size if given as prop, else use default
    let style = {};
    if (font != null){
        style['fontSize'] = font;
    }
    // event handler to set value on dropdown change
    const onDropdownChange = (e) =>{
        setValue(e.target.value);
    }
    return(
        <>
            {/* material ui dropdown component */}
            <FormControl fullWidth size='small' sx={{ minWidth: width, maxWidth: width }}>
                <InputLabel style={style} id="demo-simple-select-label">{tag}</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={value}
                    label={tag}
                    style={style}                 
                    onChange={(e)=>onDropdownChange(e)}
                >
                    {list.map( el =>    
                        <MenuItem style={style} id={el} value={el}>{el}</MenuItem>
                    )}
                </Select>
            </FormControl>
        </>
    );
}

export default PageDropdownRedux;