import TextField from '@mui/material/TextField';

const PageInputRedux = ({ tag, value, setValue, width, font }) =>{
    // set width if not given as prop
    if (width == null){
        width = '16.6vw';
    }
    // set font size if given as prop, else use default
    let style = {};
    if (font){
        style['fontSize'] = font;
    }

    return (
        <>
            {/* material ui textfield for text input */}
            <TextField 
                sx={{width: width, minWidth: width }} 
                id="outlined-basic" 
                size='small' 
                label={tag} 
                variant="outlined" 
                value={value} 
                onChange={(e)=>setValue(e.target.value)}
                inputProps={{style: style}} // font size of input text
                InputLabelProps={{style: style}} // font size of input label
            />
        </> 
    );
} 

export default PageInputRedux;