import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

const WorkflowOptOut = ({ value, setValue }) => {
    return (
        <div class='modal-div'>
            <FormControlLabel
                value="end"
                control={<Checkbox
                    sx={{
                        color: '#ff0f0f',
                        '&.Mui-checked': {
                        color: '#ff0f0f',
                        },
                    }} />}
                label="Opt out of CCRM review"
                labelPlacement="end"
                sx={{
                    color: 'red',
                }}
                checked={value}
                onChange={(e)=>{setValue(value => value = e.target.checked)}}
            />
        </div> 
    ); 
}

export default WorkflowOptOut; 