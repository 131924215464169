import '../../css/headerStyle.css';
import { useContext, useState, useEffect } from 'react'
import profileIcon from '../../svgs/profile-icon.svg';
import powerIcon from '../../svgs/power-icon.svg';
import { AuthContext } from '../../auth/AuthRoute';
import logo from '../../svgs/logo-truist.png'
import PageTooltip from './PageTooltip';

// header banner for the top of every page
const HeaderBanner = ({ indicator }) =>{
    // get logout function from auth context
    const {
        sessionLogout, 
        getAdmin, 
        getUsername, 
        getEmail, 
    } = useContext(AuthContext);
    // set state for profile tooltip
    const [profileText, setProfileText] = useState(''); 
    // useEffect to populate state
    useEffect(() => {
        // get id token using function from auth context
        let username = getUsername();
        let email = getEmail(); 
        // look for Administrator group in cognito groups
        let admin = getAdmin(); 
        // set profile text 
        let text = 'Username: ' + username + '\nEmail: ' + email; 
        if (admin){
            text = text + '\nAdministrator'; 
        }
        setProfileText(profileText => profileText = text);       
        
    }, []);
    // indicator 0 for login header 
    if (indicator == 0){
        return(
            <header class='header-banner-login'>
                <label class='header-banner-text'>Commercial Cards Rebate Platform</label>
            </header>
        );
    }
    // indicator 1 for other page headers
    else{
        return( 
            <header class='header-banner-other'>
                {/* <label class='header-banner-text'>Commercial Cards Rebate Platform</label> */}
                <img src={logo} class='logo-icon'/>
                <div class='header-icon-div'>
                    <PageTooltip title={profileText}>
                        <img src={profileIcon} className="profile-icon"/>
                    </PageTooltip>
                    <PageTooltip title='Log Out'>
                        <img onClick={()=>sessionLogout()} src={powerIcon} className="power-icon"/>
                    </PageTooltip>
                </div>
            </header>
        );
    }
}

export default HeaderBanner;