// apply a list of functions to an array and return the resulting array
const applyFilters = (dataList, functionList) => {
    let filteredList = dataList;
    for (let i = 0; i < functionList.length; i++){
        // flag == 1 means filter function (only filters on one column)
        if (functionList[i]['flag'] == 1){
            if (functionList[i]['value'] != 'All'){
                filteredList = filteredList.filter((el) => {
                    return functionList[i]['function'](el, functionList[i]['value'], functionList[i]['cols'][0]);
                });
            }
        }
        // flag == 0 means search function (can search across multiple columns)
        else {
            if (functionList[i]['value'] != ''){
                filteredList = functionList[i]['function'](filteredList, functionList[i]['cols'], functionList[i]['value']);
            }
        }
    }
    return filteredList;
}

export default applyFilters;