import { Link } from 'react-router-dom';
import HeaderBanner from '../sub-components/HeaderBanner';
import Navslider from '../sub-components/Navslider';
import buildingIcon from '../../svgs/building-icon.svg';
import plusIcon from '../../svgs/plus-icon.svg';
import uploadIcon from '../../svgs/upload-icon.svg'

// landing a page with navigation buttons for client info setup module
const CompanyInfoLanding = () =>{
    return(
        <div class='page'>
            {/* header and navslider present on every page */}
            <HeaderBanner indicator={1}/>
            <Navslider indicator={3} backPath='/MainLanding'/>
            {/* div for navigation buttons */}
            { process.env.REACT_APP_UPLOAD_CLIENT_CONTRACTS == '1' ? 
            <>
                <div class='landing-three-div'>
                    <Link
                        to={{
                            pathname: "/CompanyProfilePage",
                        }}
                        ><button class='landing-button'>
                            <img src={buildingIcon} class="tile-icon"/>
                            <br/><br/>
                            Client Profile Overview
                            <br/><br/>
                            <button class='landing-tile-button'>View</button>
                        </button>
                    </Link>
                    <Link
                        to={{
                            pathname: "/AddNewCompanyProfilePage",
                        }}
                        ><button class='landing-button'>
                            <img src={plusIcon} class="tile-icon"/>
                            <br/><br/>
                            Add New <br/> Profile
                            <br/><br/>
                            <button class='landing-tile-button'>View</button>
                        </button>
                    </Link>
                    <Link
                        to={{
                            pathname: "/SearchClientContractPage",
                        }}
                        ><button class='landing-button'>
                            <img src={uploadIcon} class="tile-icon"/>
                            <br/><br/>
                            Search Client <br/> Contract
                            <br/><br/>
                            <button class='landing-tile-button'>View</button>
                        </button>
                    </Link> 
                </div>
            </>
            :<>
                <div class='landing-two-div'>
                    <Link
                        to={{
                            pathname: "/CompanyProfilePage",
                        }}
                        ><button class='landing-button'>
                            <img src={buildingIcon} class="tile-icon"/>
                            <br/><br/>
                            Client Profile Overview
                            <br/><br/>
                            <button class='landing-tile-button'>View</button>
                        </button>
                    </Link>
                    <Link
                        to={{
                            pathname: "/AddNewCompanyProfilePage",
                        }}
                        ><button class='landing-button'>
                            <img src={plusIcon} class="tile-icon"/>
                            <br/><br/>
                            Add New <br/> Profile
                            <br/><br/>
                            <button class='landing-tile-button'>View</button>
                        </button>
                    </Link> 
                </div>
            </>}
        </div>
    );
}

export default CompanyInfoLanding;