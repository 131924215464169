import { useContext, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Modal } from "react-bootstrap";
import PageContext from "./PageContext";
import PageDropdown from './PageDropdown';
import { HttpContext } from '../../api-services/HttpService';
import MonthPicker from './MonthPicker';
import ApiRequestButton from './ApiRequestButton';

// prototype to get periodid (YYYYMM) date string
Date.prototype.getPeriodId = function() {
    let mm = this.getMonth() + 1; // getMonth() is zero-based
  
    return [
        this.getFullYear(),
        (mm > 9 ? '' : '0') + mm
    ].join('');
};

// get string of timestamp for filename
Date.prototype.getFileTimestamp = function(){
    let MM = this.getMonth() + 1; // getMonth() is zero-based
    let dd = this.getDate();
    let hh = this.getHours(); 
    let mm = this.getMinutes(); 
    let ss = this.getSeconds(); 

    return [
        this.getFullYear(),
        (MM > 9 ? '' : '0') + MM,
        (dd > 9 ? '' : '0') + dd, 
        (hh > 9 ? '' : '0') + hh, 
        (mm > 9 ? '' : '0') + mm, 
        (ss > 9 ? '' : '0') + ss, 
    ].join('');
}

const EditUploadClientContractModal = () => {
    // get context from parent
    const {
        showEditContractModal, 
        setShowEditContractModal, 
        contractUploadRow, 
        snackbarAlert, 
        refreshContractUpload, 
    } = useContext(PageContext);
    // initialize http context 
    const {
        PUT, 
    } = useContext(HttpContext);
    // intialize state
    const [startPeriodId, setStartPeriodId] = useState(new Date()); 
    const [endPeriodId, setEndPeriodId] = useState(new Date()); 
    const [contractComment, setContractComment] = useState(''); 
    const [status, setStatus] = useState('Active'); 
    const [statusList, setStatusList] = useState(['Active', 'Inactive']); 

    // event handler for value change in commment textarea
    const onTextAreaChange = (e) =>{
        // set state to value passed in event object
        setContractComment(contractComment => contractComment = e.target.value);
    }

    // event handler for submit click 
    const onSubmitClick = () => {
        return new Promise((resolve, reject) => {
            let response
            // check that startPeriodId and endPeriodId are in correct order
            if (startPeriodId.greater(endPeriodId)){
                snackbarAlert(true, 'Invalid start and end periods.', 'error'); 
                reject();
                return;
            }
            // check for valid comment
            if (contractComment.length <= 0){
                snackbarAlert(true, 'Please input valid comment.', 'error'); 
                reject(); 
                return;
            }
            // assign boolean to status
            let active = false; 
            if (status == 'Active'){
                active = true; 
            }
            // use function to make PUT request
            PUT('/data/uploadClientContract', {
                active: active, 
                mastercompanyid: contractUploadRow['mastercompanyid'], 
                companyid: contractUploadRow['companyid'], 
                startperiodid: startPeriodId.getPeriodId(), 
                endperiodid: endPeriodId.getPeriodId(), 
                contractcomment: contractComment, 
                uploadfilename: contractUploadRow['uploadfilename'], 
                displayfilename: contractUploadRow['displayfilename'], 
                filepath: contractUploadRow['filepath'], 
            }).then(res => { 
                console.log(res); 
                response = res; 
                // hide modal 
                setShowEditContractModal(showEditContractModal => showEditContractModal = false); 
                // show alert on error
                snackbarAlert(true, 'Successfully uploaded data.', 'success');
                // refresh data 
                return refreshContractUpload(); 
            }).then(res => {
                console.log(res); 
                // resolve on success
                resolve(response); 
            }).catch(err => {
                console.log(err); 
                // show alert on error
                snackbarAlert(true, 'Error uploading data.', 'error');
                // reject on error
                reject(err); 
            });
        }); 
    }

    // useEffect to run on contractUploadRow change
    useEffect(() => {
        if (Object.keys(contractUploadRow).length > 0){
            // set state
            setStartPeriodId(startPeriodId => startPeriodId = new Date(
                contractUploadRow['startperiodid'].toString().substring(0, 4), 
                contractUploadRow['startperiodid'].toString().substring(4, 6) - 1, 
                1
            )); 
            setEndPeriodId(endPeriodId => endPeriodId = new Date(
                contractUploadRow['endperiodid'].toString().substring(0, 4), 
                contractUploadRow['endperiodid'].toString().substring(4, 6) - 1, 
                1
            )); 
            setContractComment(contractComment => contractComment = contractUploadRow['contractcomment']);
            if (contractUploadRow['active'] == true){
                setStatus(status => status = 'Active'); 
            }
            else {
                setStatus(status => status = 'Inactive'); 
            }
        }
    }, [contractUploadRow]); 

    return(
        <> 
            <Modal className="rebate-summary-modal"
                show={showEditContractModal}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                scrollable={true}
                >
                <Modal.Header closeButton onClick={()=>setShowEditContractModal(showEditContractModal => showEditContractModal = false)}>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Edit Contract: {contractUploadRow['displayfilename']}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <div class='modal-div'>
                        <PageDropdown tag='Status' value={status} setValue={setStatus} list={statusList} />
                    </div>
                    <br />
                    <div class='modal-div'>
                        <MonthPicker tag='Start Period:' value={startPeriodId} setValue={setStartPeriodId} />
                    </div>
                    <br />
                    <div class='modal-div'>
                        <MonthPicker tag='End Period:' value={endPeriodId} setValue={setEndPeriodId} />
                    </div>
                    <br />
                    <div class='modal-div'>
                        <label><b>Contract Description: </b></label>
                        <br/>
                        <textarea class='modal-text-area' value={contractComment} onChange={(e)=>onTextAreaChange(e)}></textarea>
                    </div>
                    <br />
                    <ApiRequestButton onButtonClick={onSubmitClick} />
                </Modal.Body>
            </Modal>
        </>
    );
}

export default EditUploadClientContractModal; 