import { useState, useEffect, useRef, useContext } from 'react';
import HeaderBanner from '../sub-components/HeaderBanner';
import Navslider from '../sub-components/Navslider';
// import PageInput from '../sub-components/PageInput';
import OrderedDataTable from '../sub-components/OrderedDataTable';
import CompanyProfileRow from '../sub-components/CompanyProfileRow';
import PageContext from '../sub-components/PageContext';
import ViewSubCompaniesModal from '../sub-components/ViewSubCompaniesModal';
import standardSearch from '../functions/standardSearch';
import applyFilters from '../functions/applyFilters';
import { HttpContext } from '../../api-services/HttpService';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from 'redux';
import { actionCreators } from "../../redux/index";
import PageInputRedux from '../sub-components/PageInputRedux';

const CompanyProfilePage = () =>{
    // initialize http context 
    const {
        GET, 
    } = useContext(HttpContext);
    // initialize redux persisent state for search 
    const companyProfileSearch = useSelector((state) => state.companyProfileSearch);
    const dispatch = useDispatch();
    const { 
        setCompanyProfileSearch, 
    } = bindActionCreators(actionCreators, dispatch);
    // initialize state for table
    const [showDataList, setShowDataList] = useState([]);
    const [showDataListChange, setShowDataListChange] = useState(true);
    const [dataList, setDataList] = useState([]);
    const [tableHeader, setTableHeader] = useState([]);
    const [showSpinner, setShowSpinner] = useState(false);
    // initialize state for company numbers modal 
    const [viewModal, setViewModal] = useState(false);
    const [viewCompany, setViewCompany] = useState({});
    // initialize state for search
    // const [searchValue, setSearchValue] = useState('');
    // initialize ref for initial render
    const initialRender = useRef(true);

    // function wrapper to pass row component to datatable
    const RowComponent = (row, count, tableHeader) =>{
        return(
            <>
                <CompanyProfileRow row={row} count={count} tableHeader={tableHeader}/>
            </>
        ); 
    }  

    //get and set all info from database to render
    const getCompanyData = () => {
        setShowSpinner(showSpinner => showSpinner = true);
        return new Promise ((resolve, reject) => {
            GET('/data/masterCompanyData', {}).then(res => {
                // set table state
                setShowDataList(showDataList => showDataList = res['data']['MasterCompanyData']);
                setDataList(dataList => dataList = res['data']['MasterCompanyData']);
                setTableHeader(tableHeader => tableHeader = res['data']['CompanyProfilePageCols']);
                // stop showing spinner
                setShowSpinner(showSpinner => showSpinner = false);
                // resolve response
                resolve(res);
            }).catch(err => {
                setShowSpinner(showSpinner => showSpinner = false);
                reject(err);
            });
        });
    }

    // useEffect that runs on initial render
    useEffect(()=>{
        // function to do initial get and set of 
        getCompanyData().then(res => {
            console.log(res);
        }).catch(err => {
            console.log(err);
        });
    }, []);

     // useEffect to run list of search/filter functions on table data
     useEffect(() => { 
        // do not run on initial render
        if (!initialRender.current){
            // declare list of functions with parameters to filter/search list
            const functionList = [
                {function: standardSearch, value: companyProfileSearch, flag: 0, cols: ['mastercompanyname', 'mastercompanyid', 'primaryccrm']}
            ];
            // use applyFilters function to search by running all functions declared above
            let filteredList = applyFilters(dataList, functionList);
            // update showDataList
            setShowDataList(showDataList => showDataList = filteredList);
        }
        else{
            initialRender.current = false;
        }
        
    }, [dataList, companyProfileSearch]);

    // object to pass variables to context 
    const settings = {
        viewModal, 
        setViewModal, 
        viewCompany, 
        setViewCompany, 
    };
 
    return(
        <div class='summary-page'>
            {/* wrap children so they can access variables in PageContext */}
            <PageContext.Provider value={ settings }>
                {/* header and navslider present on every page */}
                <HeaderBanner indicator={1}/>
                <Navslider indicator={-1} backPath='/CompanyInfoLanding'/>
                {/* modal for viewing sub companies. Default is hidden state */}
                <ViewSubCompaniesModal />
                {/* label for search column dropdown and search bar */}
                <div class='top-label-div'>
                    <label class='top-label-text'><b>Company Search</b></label>
                    <br/>
                    <div class='label-dropdown-div'>
                        <span class='label-dropdown-left'>
                            {/* link button to navigate to master company search page */}
                            <Link
                                to={{
                                    pathname: "/SubCompanySearchPage",
                                }}
                                ><button class='summary-page-button'>Sub Company Search</button>
                            </Link>
                            {/* link button to navigate to consortium search page */}
                            <Link
                                to={{
                                    pathname: "/ConsortiumSearchPage",
                                }}
                                ><button class='summary-page-button'>Consortium Search</button>
                            </Link>
                        </span> 
                        <span class='label-dropdown-right'>
                            {/* search input to change value for search functionality*/} 
                            <PageInputRedux tag='Search: ' value={companyProfileSearch} setValue={setCompanyProfileSearch}/>
                        </span>
                        <br/>
                    </div>
                    <br/>
                </div> 
                <br/>
                {/* table to display company data with links for drilldown */}
                <div class='data-table-div'>
                    <OrderedDataTable RowComponent={RowComponent} tableHeader={tableHeader} dataList={showDataList} showSpinner={showSpinner} numToPage={50} skeletonHeight='28vh'/>
                    <br/><br/>
                </div>
            </PageContext.Provider>
        </div>
    );
} 

export default CompanyProfilePage;