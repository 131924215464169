import { useContext, useState, useEffect, useRef } from 'react';
import PageContext from './PageContext';
import greenPlusIcon from '../../svgs/green-plus-icon.svg';
import redMinusIcon from '../../svgs/red-minus-icon.svg';
import PageInput from './PageInput';
import PageDropdown from './PageDropdown';
import DatePicker from './DatePicker';
import AutoComplete from './AutoCompleteInput';

const AddNewPerformanceBonusRow = ({ row, count, tableHeader }) => {
    // get context from parent 
    const {
        performanceBonusDataList, 
        setPerformanceBonusDataList, 
        performanceBonusErrorList, 
        setPerformanceBonusErrorList, 
    } = useContext(PageContext);

    // initialize state
    const [status, setStatus] = useState('Active');
    const [statusList, setStatusList] = useState(['Active', 'Inactive']);
    const [spendByDate, setSpendByDate] = useState(new Date());
    const [spendCommitment, setSpendCommitment] = useState('');
    const [bonusAmount, setBonusAmount] = useState('');
    // const [setupId, setSetupId] = useState('');

    // add performance bonus row
    const addPerformanceBonus = () => {
        let temp2 = []; 
        for (let i = 0; i < tableHeader.length; i++){
            temp2.push(tableHeader[i]['columnname']);
        }
        let temp;
        temp2.map((el) => {
            if (el == 'blank'){
                temp = {...temp, [el]: false};
            }
            else if (el == 'active'){
                temp = {...temp, [el]: 'Active'};
            }
            else if (el == 'spendbydate'){
                temp = {...temp, [el]: new Date()};
            }
            else{
                temp = {...temp, [el]: ''};
            }
        });
        setPerformanceBonusDataList(performanceBonusDataList => performanceBonusDataList = [...performanceBonusDataList.slice(0, performanceBonusDataList.length-1), temp, ...performanceBonusDataList.slice(performanceBonusDataList.length-1, performanceBonusDataList.length)]);
        // set error object 
        let temp5 = {}
        Object.keys(temp2).map((el) => {
            temp5 = {
                ...temp5, 
                el: false, 
            }
        }); 
        // add error object to sub company error list 
        setPerformanceBonusErrorList(performanceBonusErrorList => performanceBonusErrorList = [
            ...performanceBonusErrorList.slice(0, performanceBonusErrorList.length-1), 
            temp5, 
            ...performanceBonusErrorList.slice(performanceBonusErrorList.length-1, performanceBonusErrorList.length)
        ]); 
    }

    // remove row from data list 
    const removePerformanceBonus = ( index ) => {
        setPerformanceBonusDataList(performanceBonusDataList => performanceBonusDataList = [...performanceBonusDataList.slice(0, index), ...performanceBonusDataList.slice(index+1, performanceBonusDataList.length)]);
        // remove error object from error list
        setPerformanceBonusErrorList(performanceBonusErrorList => performanceBonusErrorList = [...performanceBonusErrorList.slice(0, index), ...performanceBonusErrorList.slice(index + 1, performanceBonusErrorList.length)]); 
    }

    // showCell to determine the display of cell
    const showCell = (el) => {
        if (el == 'blank'){
            if (row[el] == true){
                return <img src={greenPlusIcon} class='data-table-icon-right' onClick={()=>addPerformanceBonus()}/>
            }
            else{
                return <img src={redMinusIcon} class='data-table-icon-right' onClick={()=>removePerformanceBonus(count)}/>
            }
        }
        else if (row['blank'] != true && el == 'active'){
            return <PageDropdown tag='Status:' value={status} setValue={setStatus} list={statusList} width='7.2vw' font={10}/>
        }
        // else if (row['blank'] != true && el == 'incentivesetupid'){
        //     return <PageInput tag='Setup ID:' value={setupId} setValue={setSetupId} width='15vw' font={10} />
        // }
        else if (row['blank'] != true && el == 'spendbydate'){
            return <DatePicker tag='Spend By Date:' value={spendByDate} setValue={setSpendByDate} disable={null} indicator={0} width='15vw' font={10} />
        }
        else if (row['blank'] != true && el == 'spendcommitment'){
            return <PageInput tag='Spend Commitment:' value={spendCommitment} setValue={setSpendCommitment} width='15vw' font={10} showError={performanceBonusErrorList[count]['spendcommitment']}/> 
        }
        else if (row['blank'] != true && el == 'bonusamount'){
            return <PageInput tag='Bonus Amount:' value={bonusAmount} setValue={setBonusAmount} width='15vw' font={10} showError={performanceBonusErrorList[count]['bonusamount']}/>
        }
        else{
            return <>{row[el]}</>;
        }
    }

    // useEffect to update row values 
    useEffect(() => {
        if (row['blank'] == false){
            setPerformanceBonusDataList(performanceBonusDataList => performanceBonusDataList = 
                [...performanceBonusDataList.slice(0, count), 
                {
                    active: status, 
                    // incentivesetupid: setupId, 
                    spendbydate: spendByDate, 
                    spendcommitment: spendCommitment, 
                    bonusamount: bonusAmount, 
                    blank: false},
                ...performanceBonusDataList.slice(count+1, performanceBonusDataList.length)]);
        }
    }, [status, spendByDate, spendCommitment, bonusAmount]);

    // useEffect to change row values when size of list changes
    useEffect(() => {
        setStatus(status => status = performanceBonusDataList[count]['active']);
        // setSetupId(setupId => setupId = performanceBonusDataList[count]['incentivesetupid']);
        setSpendByDate(spendByDate => spendByDate = performanceBonusDataList[count]['spendbydate']);
        setSpendCommitment(spendCommitment => spendCommitment = performanceBonusDataList[count]['spendcommitment']);
        setBonusAmount(bonusAmount => bonusAmount = performanceBonusDataList[count]['bonusamount']);
    }, [performanceBonusDataList.length]);

    return( 
        <>
            {/* map showCell function over ever column in the row */}
            {tableHeader.map(el => 
                <td>{showCell(el['columnname'])}</td>
            )}
        </>
    );
}

export default AddNewPerformanceBonusRow; 