import { useContext, useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { HttpContext } from '../../api-services/HttpService';
import { Modal } from "react-bootstrap";
import AutoCompleteInput from './AutoCompleteInput';
import UploadS3ExcelButton from './UploadS3ExcelButton';
import PageContext from './PageContext'; 
import MonthPicker from './MonthPicker';

// prototype to get periodid (YYYYMM) date string
Date.prototype.getPeriodId = function() {
    let mm = this.getMonth() + 1; // getMonth() is zero-based
  
    return [
        this.getFullYear(),
        (mm > 9 ? '' : '0') + mm
    ].join('');
};

// get string of timestamp for filename
Date.prototype.getFileTimestamp = function(){
    let MM = this.getMonth() + 1; // getMonth() is zero-based
    let dd = this.getDate();
    let hh = this.getHours(); 
    let mm = this.getMinutes(); 
    let ss = this.getSeconds(); 

    return [
        this.getFullYear(),
        (MM > 9 ? '' : '0') + MM,
        (dd > 9 ? '' : '0') + dd, 
        (hh > 9 ? '' : '0') + hh, 
        (mm > 9 ? '' : '0') + mm, 
        (ss > 9 ? '' : '0') + ss, 
    ].join('');
}

const AddClienttContractModal = () => {
    // get context from parent
    const {
        uploadClientContractModal, 
        setUploadClientContractModal,
        subCompanyList, 
        snackbarAlert, 
        refreshContractUpload, 
        uploadContractsDataList, 
    } = useContext(PageContext);
    // get http context
    const {
        PUT, 
    } = useContext(HttpContext); 
    // get global state
    const companyInfo = useSelector((state) => state.companyInfo);
    // intiailize state
    const [subCompanyId, setSubCompanyId] = useState(''); 
    const [startPeriodId, setStartPeriodId] = useState(new Date()); 
    const [endPeriodId, setEndPeriodId] = useState(new Date()); 
    const [comment, setComment] = useState(''); 
    // initialize refs for file
    const contractFile = useRef(); 
    const contractFileInput = useRef();    
    // const uploadFileName = useRef(''); 
    const [uploadFileName, setUploadFileName] = useState(''); 
    // initialze ref for today
    const today = useRef(new Date());   
    // initialize ref for initial render
    const initialRender = useRef(true); 

    // handle contract file change
    const onContractFileChange = (e) => {
        console.log(e); 
        // set file 
        contractFile.current = e.target.files[0];
        // // set upload file name 
        // let today = new Date(); 
        // uploadFileName.current = e.target.files[0].name + '_' + today.getFileTimestamp(); 
    }

    // event handler for value change in commment textarea
    const onTextAreaChange = (e) =>{
        // set state to value passed in event object
        setComment(comment => comment = e.target.value);
    }

    // validations before uploading contract
    const validateData = () => {
        // check that sub company ID is valid
        if (!subCompanyList.includes(subCompanyId)){
            snackbarAlert(true, 'Invalid company ID.', 'error'); 
            return false; 
        }
        // check that startPeriodId and endPeriodId are in correct order
        if (startPeriodId.greater(endPeriodId)){
            snackbarAlert(true, 'Invalid start and end periods.', 'error'); 
            return false;
        }
        // check for valid comment
        if (comment.length <= 0){
            snackbarAlert(true, 'Please input valid comment.', 'error'); 
            return false;
        }
        // return true
        return true; 
    }
    
    // handle contract pdf click event 
    const putContractMetadata = () => {
        return new Promise((resolve, reject) => {
            PUT('/data/uploadClientContract', {
                mastercompanyid: companyInfo['mastercompanyid'], 
                companyid: subCompanyId, 
                startperiodid: startPeriodId.getPeriodId(), 
                endperiodid: endPeriodId.getPeriodId(), 
                contractcomment: comment, 
                uploadfilename: uploadFileName, 
                displayfilename: contractFile.current.name, 
            }).then(res => { 
                resolve(res);  
            }).catch(err => {
                reject(err); 
            });
        });  
    }

    // useEffect to determine uploadFileName
    useEffect(() => {
        // uploadFileName.current = 'Contract_' + companyInfo['mastercompanyid'] + '_'+ subCompanyId + '_' + today.current.getFileTimestamp() + '.pdf' ; 
        // console.log(uploadFileName.current); 
        setUploadFileName(uploadFileName => uploadFileName = 'Contract_' + companyInfo['mastercompanyid'] + '_'+ subCompanyId + '_' + today.current.getFileTimestamp() + '.pdf'); 
    }, [companyInfo['mastercompanyid'], subCompanyId]); 

    // useEffect default all values on data refresh
    useEffect(() => {
        if (initialRender.current == false){
            // reset metadata inputs 
            setSubCompanyId(subCompanyId => subCompanyId = ''); 
            setStartPeriodId(startPeriodId => startPeriodId = new Date()); 
            setEndPeriodId(endPeriodId => endPeriodId = new Date()); 
            setComment(comment => comment = ''); 
            // reset file inputs 
            if (contractFileInput.current != null && contractFileInput.current.value != null){
                contractFileInput.current.value = '';
            }
            if (contractFile.current != null){
                contractFile.current = null; 
            }
        }
        else {
            initialRender.current = false; 
        }
    }, [uploadContractsDataList]); 

    return (
        <>
            <Modal className="rebate-summary-modal"
                show={uploadClientContractModal}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                scrollable={true}
                >
                <Modal.Header closeButton onClick={()=>setUploadClientContractModal(uploadClientContractModal => uploadClientContractModal = false)}>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Add Client Contract
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <div class='modal-div'>
                        <AutoCompleteInput tag='Company ID:' value={subCompanyId} setValue={setSubCompanyId} list={subCompanyList} />
                    </div>
                    <br />
                    <div class='modal-div'>
                        <MonthPicker tag='Start Period:' value={startPeriodId} setValue={setStartPeriodId} />
                    </div>
                    <br />
                    <div class='modal-div'>
                        <MonthPicker tag='End Period:' value={endPeriodId} setValue={setEndPeriodId} />
                    </div>
                    <br /> 
                    <div class='modal-div'>
                        <label><b>Contract Description: </b></label>
                        <br/>
                        <textarea class='modal-text-area' value={comment} onChange={(e)=>onTextAreaChange(e)}></textarea>
                    </div>
                    <br />
                    <div class='modal-div'>
                        <input style={{}} class='page-file-input' type='file' accept='.pdf' onChange={onContractFileChange} ref={ ref => contractFileInput.current = ref}/>
                    </div>
                    <br />
                    <UploadS3ExcelButton tag='Upload PDF' folder='contracts/initial/' filename={uploadFileName} file={contractFile} fileInput={contractFileInput} putMetadata={putContractMetadata} refreshData={refreshContractUpload} validateData={validateData} snackbarAlert={snackbarAlert} css='modal-button-full-blue-upload' showSpinner={false}/>                                       
                    <br />
                </Modal.Body>
            </Modal>
        </>
    ); 

}

export default AddClienttContractModal; 