import { useState, useEffect, useContext, useRef } from 'react';
import PageContext from '../sub-components/PageContext';
import HeaderBanner from '../sub-components/HeaderBanner';
import Navslider from '../sub-components/Navslider';
import AutoCompleteInputWithOptions from '../sub-components/AutoCompleteInputWithOptions';
import { HttpContext } from '../../api-services/HttpService';
import OrderedDataTable from '../sub-components/OrderedDataTable';
import AdhocDataRow from '../sub-components/AdhocDataRow';
import PageReadOnlyInput from '../sub-components/PageReadOnlyInput';
// import PageInput from '../sub-components/PageInput';  
import AddAdhocPaymentModal from '../sub-components/AddAdhocPaymentModal';
import EditAdhocPaymentModal from '../sub-components/EditAdhocPaymentModal';
// import AdhocCompanySearchRow from '../sub-components/AdhocCompanySearchRow'; 
import ViewSubCompaniesModal from '../sub-components/ViewSubCompaniesModal';
// import standardSearch from '../functions/standardSearch';
// import applyFilters from '../functions/applyFilters';
import SnackbarAlert from '../sub-components/SnackbarAlert';
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from 'redux';
import { actionCreators } from "../../redux/index";
import AutoCompleteInputWithOptionsRedux from '../sub-components/AutoCompleteInputWithOptionsRedux'; 

const AdhocPaymentPage = () => {
    // initialize http context 
    const {
        GET,
    } = useContext(HttpContext);
    // initialize redux persistant state
    const adhocPaymentSearch = useSelector((state) => state.adhocPaymentSearch); 
    const dispatch = useDispatch();
    const { 
        setAdhocPaymentSearch, 
    } = bindActionCreators(actionCreators, dispatch);
    // initialize state for master company autocomplete
    const [masterCompany, setMasterCompany] = useState('--select--');
    const [masterCompanyId, setMasterCompanyId] = useState('--select--');
    const [masterCompanyObj, setMasterCompanyObj] = useState({ label: '--select--', id: '--select--' });
    const [masterCompanyList, setMasterCompanyList] = useState([{ label: '--select--', id: '--select--' }]);
    // initialize table state 
    const [dataList, setDataList] = useState([]);
    const [tableHeader, setTableHeader] = useState([]);
    // initialize state for spinner
    const [showSpinner, setShowSpinner] = useState(false);
    // initialize state for modal 
    const [showAddAdhocModal, setShowAddAdhocModal] = useState(false);
    const [showEditAdhocModal, setShowEditAdhocModal] = useState(false);
    const [editAdhocRow, setEditAdhocRow] = useState([]);
    const [subCompanyList, setSubCompanyList] = useState([]);
    // initialize state for sub company modal
    const [viewModal, setViewModal] = useState(false);
    const [viewCompany, setViewCompany] = useState({});
    // initialize state for entitlement boolean
    const [entitledBool, setEntitledBool] = useState(false);
    // // useRef to determine initial render
    // const initialRender = useRef(true); 
    // initialize snackbar state
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarColor, setSnackbarColor] = useState('info');

    // function wrapper to pass row component to datatable
    const RowComponent = (row, count, tableHeader) => {
        return (
            <>
                <AdhocDataRow row={row} count={count} tableHeader={tableHeader} editBool={entitledBool} />
            </>
        );
    }

    // function to set snackbar state
    const snackbarAlert = (show, message, color) => {
        setSnackbarMessage(snackbarMessage => snackbarMessage = message);
        setSnackbarColor(snackbarColor => snackbarColor = color);
        setShowSnackbar(showSnackbar => showSnackbar = show);
    }

    // initial get request for masterCompanyList 
    const getMasterCompanyData = () => {
        // show spinner while getting data 
        setShowSpinner(showSpinner => showSpinner = true);
        return new Promise((resolve, reject) => {
            GET('/data/adhoc/adhocpage', {}).then(res => {
                // set state for autocomplete
                setMasterCompanyList(masterCompanyList => masterCompanyList = res['data']['MasterCompanyData1']);
                // set state for table header for adhoc data table
                setTableHeader(tableHeader => tableHeader = res['data']['AdhocTableCols']);
                // resolve on success and hide spinner
                setShowSpinner(showSpinner => showSpinner = false);
                resolve(res);
            }).catch(err => {
                // reject on error and hide spinner
                setShowSpinner(showSpinner => showSpinner = false);
                reject(err);
            });
        });
    }

    // get payment history once master company has been selected
    const getPaymentHistoryData = () => {
        // show spinner while waiting for response 
        setShowSpinner(showSpinner => showSpinner = true);
        return new Promise((resolve, reject) => {
            GET('/data/adhoc/paymenthistory', {
                mastercompanyid: adhocPaymentSearch['id'],
            }).then(res => {
                // set state for datatable
                setDataList(dataList => dataList = res['data']['AdhocTableData']);
                setTableHeader(tableHeader => tableHeader = res['data']['AdhocTableCols']);
                // set state for modal 
                setSubCompanyList(subCompanyList => subCompanyList = res['data']['SubCompanyList']);
                // set state for entitled bool
                setEntitledBool(entitledBool => entitledBool = res['data']['UserEntitlement']);
                // resolve and hide spinner on success
                setShowSpinner(showSpinner => showSpinner = false);
                // resolve on success
                resolve(res);
            }).catch(err => {
                // reject and hide spinner on error
                setShowSpinner(showSpinner => showSpinner = false);
                reject(err);
            });
        });
    }

    // useEffect for initial render
    useEffect(() => {
        // make initial get request 
        getMasterCompanyData().then(res => {
            console.log(res);
        }).catch(err => {
            console.log(err);
        });
    }, []);

    // useEffect when master company changes
    useEffect(() => {
        // only make call if there is a selected company
        if (adhocPaymentSearch['id'] != '--select--') {
            // set masterCompanyId
            setMasterCompanyId(masterCompanyId => masterCompanyId = adhocPaymentSearch['id']);
            setMasterCompanyObj(masterCompanyObj => masterCompanyObj = adhocPaymentSearch); 
            // make request to get payment history
            getPaymentHistoryData().then(res => {
                console.log(res);
            }).catch(err => {
                console.log(err);
            });
        }
    }, [adhocPaymentSearch]);

    // variables to pass in page context
    const settings = {
        showAddAdhocModal,
        setShowAddAdhocModal,
        masterCompanyObj,
        setMasterCompanyObj,
        subCompanyList,
        showEditAdhocModal,
        setShowEditAdhocModal,
        editAdhocRow,
        setEditAdhocRow,
        masterCompanyId,
        getPaymentHistoryData,
        viewModal,
        setViewModal,
        viewCompany,
        setViewCompany,
        snackbarAlert,
        showSnackbar,
        snackbarMessage,
        snackbarColor,
        setSnackbarMessage,
        setSnackbarColor,
        setShowSnackbar
    }

    return (
        <div class='summary-page'>
            <PageContext.Provider value={settings}>
                {/* header and navslider present on every page */}
                <HeaderBanner indicator={1} />
                <Navslider indicator={-1} backPath='/RebateWorkflowLanding' />
                {/* modal to add adhoc payment */}
                <AddAdhocPaymentModal refreshFunction={getPaymentHistoryData} />
                <EditAdhocPaymentModal refreshFunction={getPaymentHistoryData} />
                <ViewSubCompaniesModal />
                {/* snackbar component for alerts */}
                <SnackbarAlert />
                {/* top label with master company autocomplete */}
                <div class='top-label-div'>
                    <label class='top-label-text'><b>Payment History</b></label>
                    <div class='label-text-div'>
                        <span class='label-text-span-left'>
                            {masterCompanyObj['label'] != '--select--' ?
                                <PageReadOnlyInput tag={'Master Company:'} value={masterCompanyObj['label']} />
                                : <PageReadOnlyInput tag={'Master Company:'} value={''} />}
                        </span>
                        <span class='label-text-span-mid'>
                            {masterCompanyObj['id'] != '--select--' ?
                                <PageReadOnlyInput tag={'Rebate ID:'} value={masterCompanyObj['id']} />
                                : <PageReadOnlyInput tag={'Rebate ID:'} value={''} />}
                        </span>
                        <span class='label-text-span-right'>
                            <AutoCompleteInputWithOptionsRedux tag='Master Company:' value={masterCompany} setValue={setMasterCompany} obj={adhocPaymentSearch} setObj={setAdhocPaymentSearch} list={masterCompanyList} />
                        </span>
                    </div>
                    <br />
                </div>
                <br />
                {/* data table for payment table */}
                <div class='data-table-div'>
                    <label class='mid-page-label-text'><b>Payment History:</b> </label>
                    {entitledBool ?
                        <button class='summary-page-button' style={{ float: 'right' }} onClick={() => setShowAddAdhocModal(showAddAdhocModal => showAddAdhocModal = true)}>Add Adhoc-Payment</button>
                        : <></>}
                    <OrderedDataTable RowComponent={RowComponent} tableHeader={tableHeader} dataList={dataList} showSpinner={showSpinner} numToPage={25} skeletonHeight='28vh' />
                </div>

            </PageContext.Provider>
        </div>
    );
}

export default AdhocPaymentPage; 