export const setCompany = (company) => {
    return (dispatch) => {
        dispatch({
            type: "setCompany",
            payload: company
        });
    }
}

export const setCompanyInfo = (companyInfo) => {
    return (dispatch) => {
        dispatch({
            type: "setCompanyInfo",
            payload: companyInfo
        });
    } 
}

export const setTransactionDetails = (transactionDetails) => {
    return (dispatch) => {
        dispatch({
            type: "setTransactionDetails",
            payload: transactionDetails
        });
    } 
}

export const setUserSession = (userSession) => {
    return (dispatch) => {
        dispatch({
            type: "setUserSession",
            payload: userSession
        });
    } 
}

export const setBackPath = (backPath) => {
    return (dispatch) => {
        dispatch({
            type: "setBackPath", 
            payload: backPath
        });  
    }
}

export const setRebateSummarySearch = (rebateSummarySearch) => {
    return (dispatch) => {
        dispatch({
            type: "setRebateSummarySearch", 
            payload: rebateSummarySearch
        });  
    }
}

export const setRebateSummaryPeriod = (rebateSummaryPeriod) => {
    return (dispatch) => {
        dispatch({
            type: "setRebateSummaryPeriod", 
            payload: rebateSummaryPeriod
        });  
    }
}

export const setRebateFrequency = (rebateFrequency) => {
    return (dispatch) => {
        dispatch({
            type: "setRebateFrequency", 
            payload: rebateFrequency
        });  
    }
}

export const setWorkflowStatus = (workflowStatus) => {
    return (dispatch) => {
        dispatch({
            type: "setWorkflowStatus", 
            payload: workflowStatus
        });  
    }
}

export const setCompanyProfileSearch = (companyProfileSearch) => {
    return (dispatch) => {
        dispatch({
            type: "setCompanyProfileSearch", 
            payload: companyProfileSearch
        });  
    }
}

export const setSubCompanySearch = (subCompanySearch) => {
    return (dispatch) => {
        dispatch({
            type: "setSubCompanySearch", 
            payload: subCompanySearch
        });  
    }
}

export const setAdhocPaymentSearch = (adhocPaymentSearch) => {
    return (dispatch) => {
        dispatch({
            type: "setAdhocPaymentSearch", 
            payload: adhocPaymentSearch
        });  
    }
}

export const setConsortiumSearch = (consortiumSearch) => {
    return (dispatch) => {
        dispatch({
            type: "setConsortiumSearch", 
            payload: consortiumSearch
        });  
    }
}

export const setContractSearch = (contractSearch) => {
    return (dispatch) => {
        dispatch({
            type: "setContractSearch", 
            payload: contractSearch
        });  
    }
}

