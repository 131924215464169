import { useContext } from 'react';
import { useSelector } from 'react-redux';
import PageContext from './PageContext';
import moneyFormat from '../functions/moneyFormat';
import decimalToPercent from '../functions/decimalToPercent'; 
import PageTooltip from './PageTooltip';

// pass row object and showOverride flag as props
const RebateAggregateRow = ({ row, count, tableHeader, showOverride }) =>{
    // get context variables from parent 
    const {
        editRow, 
        setEditRow, 
        overrideModal, 
        setOverrideModal,
        status,
        rebateInputTypeId,
    } = useContext(PageContext);

    // global redux state for company (passed in from other route)
    const companyInfo = useSelector((state) => state.companyInfo);

    // event handler for override click
    const onOverrideClick = () =>{
        // make OverrideModal visible using state from parent 
        setOverrideModal(overrideModal => overrideModal = true);
        // pass correct row to override modal using context variable
        setEditRow(editRow => editRow = row);
    }

    return (
        <>
            {/* map logic over all columns in row object */}
            {tableHeader.map(el =>
                // logic to apply money format to correct rows
                el['columnname'] == 'cyclenetspend' || el['columnname'] == 'cyclenetinterchange' || el['columnname'] == 'cycleincentiveamount' ?
                    <> 
                        {row[el['columnname']] == null ?
                            <td>None</td>
                        :<td>{moneyFormat(row[el['columnname']])}</td>}
                    </>
                : el['columnname'] == 'blank' ?
                <>
                    {/* Only allow override if user is a Submitter and Company is in the PENDING status */}
                    { ((status == 'Pending' || status == 'Rejected') && showOverride) ?
                    <td>
                        <button class='payout-table-button' onClick={(e)=>onOverrideClick(e)}>Override</button>
                    </td>
                    :<td>
                        <PageTooltip title='disabled'>
                            <button className='payout-table-button-disabled'>Override</button>   
                        </PageTooltip>
                    </td>}    
                </>
                // convert to percent if rebateInputTypeId is 1
                : rebateInputTypeId == 1 && el['columnname'] == 'incentiverate' && row[el['columnname']] != null ? 
                    <td>{decimalToPercent(row[el['columnname']])}</td>
                // standard display for other cols
                : <> {row[el['columnname']] == null ?
                        <td>None</td>
                    :<td>{row[el['columnname']]}</td>}
                </>
            )}
        </>
    );
}

export default RebateAggregateRow;