import { useContext } from 'react';
import moneyFormat from '../functions/moneyFormat';
import decimalToPercent from '../functions/decimalToPercent'; 
import PageContext from './PageContext'; 

// pass row in props
const OverrideRow = ({ row, count, tableHeader }) => {
    // context from parent
    const {
        rebateInputTypeId, 
    } = useContext(PageContext);

    return(
        <>
            {/* map logic over all columns in row object */}
            {tableHeader.map(el =>
                // logic to apply money format to correct rows
                el['columnname'] == 'cyclenetspend' || el['columnname'] == 'cyclenetinterchange' || el['columnname'] == 'cycleincentiveamount' ?
                    <> 
                        {row[el['columnname']] == null ?
                            <td>None</td>
                        :<td>{moneyFormat(row[el['columnname']])}</td>}
                    </>
                // convert to percent if rebateInputTypeId is 1
                : rebateInputTypeId == 1 && el['columnname'] == 'incentiverate' && row[el['columnname']] != null ? 
                    <td>{decimalToPercent(row[el['columnname']])}</td>
                // standard display for other cols
                : <> {row[el['columnname']] == null ?
                        <td>None</td>
                    :<td>{row[el['columnname']]}</td>}
                </>
            )}
        </>
    );
}   

export default OverrideRow;