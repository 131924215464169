// parse JWT token
const parseJwt = (token) => {
    try {
        // Get Token Header
        const base64HeaderUrl = token.split('.')[0];
        const base64Header = base64HeaderUrl.replace('-', '+').replace('_', '/');
        const headerData = JSON.parse(window.atob(base64Header));
        // Get Token payload and date's
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace('-', '+').replace('_', '/');
        const dataJWT = JSON.parse(window.atob(base64));
        // Add header to decoded token object
        dataJWT.header = headerData;   
        // Add encoded token to decoded token object
        dataJWT.token = token;
        return dataJWT;
    } catch (err) {
        return token;
    }
}

export default parseJwt; 