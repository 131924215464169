import { useContext, useState, useEffect, useRef } from 'react';
import PageContext from './PageContext';
import greenPlusIcon from '../../svgs/green-plus-icon.svg';
import redMinusIcon from '../../svgs/red-minus-icon.svg';
import PageInput from './PageInput';
import PageDropdown from './PageDropdown';
import AutoComplete from './AutoCompleteInput'; 
// import Checkbox from '@mui/material/Checkbox';
// import FormControlLabel from '@mui/material/FormControlLabel';

const AddNewPayoutInfoRow = ({ row, count, tableHeader })=> {
    // get context variables from parent
    const {
        payoutInfoDataList, 
        setPayoutInfoDataList,   
        payoutMethodList, 
        subCompanyIdsList, 
        masterPayoutInfoBool, 
        payoutMethodData,
        payoutInfoErrorList, 
        setPayoutInfoErrorList, 
    } = useContext(PageContext);

    // initialize state 
    const [status, setStatus] = useState('Active');
    const [statusList, setStatusList] = useState(['Active', 'Inactive']);
    const [companyId, setCompanyId] = useState('');
    const [accountId, setAccountId] = useState('');
    const [payoutType, setPayoutType] = useState('--select--');
    const [achAccount, setAchAccount] = useState('');
    const [achAba, setAchAba] = useState('');
    const [achType, setAchType] = useState('--select--');
    const [achTypeList, setAchTypeList] = useState(['--select--', 'checking', 'savings']);
    const [masterSetupBool, setMasterSetupBool] = useState(false); 
    const [showAchInfo, setShowAchInfo] = useState(true); 

    // check whether ach type will clear ach info. If clears info return false so ach info does not show on modal
    const checkShowAchInfo = (method) => {
        // find method in payoutMethodData
        for (let i = 0; i < payoutMethodData.length; i++){
            if (payoutMethodData[i]['payoutmethoddescription'] == method){
                // return !clearachinfo to determine whether it should be shown in modal 
                return !payoutMethodData[i]['clearachinfo']; 
            }
        }
        // default return is true 
        return true; 
    }

    // add payout info 
    const addPayoutInfo = () => {
        let temp2 = []; 
        for (let i = 0; i < tableHeader.length; i++){
            temp2.push(tableHeader[i]['columnname']);
        }
        let temp;
        temp2.map((el) => {
            if (el == 'blank'){
                temp = {...temp, [el]: false};
            }
            else if (el == 'active'){
                temp = {...temp, [el]: 'Active'};
            }
            else if (el == 'payoutmethoddescription' || el == 'achaccounttype'){
                temp = {...temp, [el]: '--select--'};
            }
            else{
                temp = {...temp, [el]: ''};
            }
        });
        setPayoutInfoDataList(payoutInfoDataList => payoutInfoDataList = [...payoutInfoDataList.slice(0, payoutInfoDataList.length-1), temp, ...payoutInfoDataList.slice(payoutInfoDataList.length-1, payoutInfoDataList.length)]);
        // set error object 
        let temp5 = {}
        Object.keys(temp2).map((el) => {
            temp5 = {
                ...temp5, 
                el: false, 
            }
        }); 
        // add error object to sub company error list 
        setPayoutInfoErrorList(payoutInfoErrorList => payoutInfoErrorList = [
            ...payoutInfoErrorList.slice(0, payoutInfoErrorList.length-1), 
            temp5, 
            ...payoutInfoErrorList.slice(payoutInfoErrorList.length-1, payoutInfoErrorList.length)
        ]); 
    }
    // remove row from data list 
    const removePayoutInfo = (index) => {
        setPayoutInfoDataList(payoutInfoDataList => payoutInfoDataList = [...payoutInfoDataList.slice(0, index), ...payoutInfoDataList.slice(index+1, payoutInfoDataList.length)]);
        // remove error object from error list
        setPayoutInfoErrorList(payoutInfoErrorList => payoutInfoErrorList = [...payoutInfoErrorList.slice(0, index), ...payoutInfoErrorList.slice(index + 1, payoutInfoErrorList.length)]); 
    }

    // show cell to determine display of cell
    const showCell = ( el ) => {
        if (el == 'blank'){
            if (row[el] == true){
                return <img src={greenPlusIcon} class='data-table-icon-right' onClick={()=>addPayoutInfo()}/>
            }
            else if (row[el] == false && !masterPayoutInfoBool){
                return <img src={redMinusIcon} class='data-table-icon-right' onClick={()=>removePayoutInfo(count)}/>
            }
            else {
                return <></>
            }
        }
        else if (row['blank'] != true && el == 'active'){
            return <PageDropdown tag='Status:' value={status} setValue={setStatus} list={statusList} width='5.6vw' font={10} showError={payoutInfoErrorList[count]['active']}/>
        }
        // else if (row['blank'] != true && el == 'mastersetupbool'){
        //     return <FormControlLabel
        //         value="end"
        //         control={<Checkbox
        //             sx={{
        //                 color: '#000000',
        //                 '&.Mui-checked': {
        //                 color: '#000000',
        //                 },
        //             }} />}
        //         label=""
        //         labelPlacement="end"
        //         sx={{
        //             color: 'black',
        //             margin: 'auto', 
        //             marginTop: '-5px', 
        //         }}
        //         checked={masterSetupBool}
        //         onChange={(e)=>{setMasterSetupBool(masterSetupBool => masterSetupBool = e.target.checked)}}
        //     />
        // }
        else if (row['blank'] != true && el == 'companyid'){
            // if (!masterSetupBool){
            if (!masterPayoutInfoBool) { 
                return <AutoComplete value={companyId} setValue={setCompanyId} tag='Sub Company ID:' list={subCompanyIdsList} width='9vw' font={10} showError={payoutInfoErrorList[count]['companyid']}/>
            }
            else {
                return <></>
            }
        }
        else if (row['blank'] != true && el == 'accountid'){
            return <PageInput value={accountId} setValue={setAccountId} tag='Account ID:' width='9vw' font={10} showError={payoutInfoErrorList[count]['accountid']}/>
        }
        else if (row['blank'] != true && el == 'payoutmethoddescription'){
            return <PageDropdown value={payoutType} setValue={setPayoutType} list={payoutMethodList} tag='Payout Type:' width='9vw' font={10} showError={payoutInfoErrorList[count]['payoutmethoddescription']}/>
        }
        else if (row['blank'] != true && el == 'achaccountnumber' && showAchInfo){
            return <PageInput value={achAccount} setValue={setAchAccount} tag='ACH Account #:' width='9vw' font={10} showError={payoutInfoErrorList[count]['achaccountnumber']}/>
        }
        else if (row['blank'] != true && el == 'achbankabanumber' && showAchInfo){
            return <PageInput value={achAba} setValue={setAchAba} tag='ACH ABA #:' width='9vw' font={10} showError={payoutInfoErrorList[count]['achbankabanumber']}/>
        }
        else if (row['blank'] != true && el == 'achaccounttype' && showAchInfo){
            return <PageDropdown value={achType} setValue={setAchType} list={achTypeList} tag='ACH Account Type:' width='9vw' font={10} showError={payoutInfoErrorList[count]['achaccounttype']} />
        }
        // else{
        //     return <>{row[el]}</>;
        // }
    }

    // useEffect to update list row values
    useEffect(() => {
        if (row['blank'] == false && count < payoutInfoDataList.length){
            setPayoutInfoDataList(payoutInfoDataList => payoutInfoDataList = 
                [...payoutInfoDataList.slice(0, count), 
                {active: status, companyid: companyId, accountid: accountId, payoutmethoddescription: payoutType, achaccountnumber: achAccount, achbankabanumber: achAba, achaccounttype: achType, /* mastersetupbool: masterSetupBool, */ blank: payoutInfoDataList[count]['blank']}, 
                ...payoutInfoDataList.slice(count + 1, payoutInfoDataList.length)]);
        }
    }, [status, companyId, accountId, payoutType, achAccount, achAba, achType, masterSetupBool]);

    // useEffect to change row values when size of list changes
    useEffect(() => {
        if (row['blank'] == false && count < payoutInfoDataList.length){
            setStatus(status => status = payoutInfoDataList[count]['active']);
            setCompanyId(companyId => companyId = payoutInfoDataList[count]['companyid']);
            setAccountId(accountId => accountId = payoutInfoDataList[count]['accountid']);
            setPayoutType(payoutType => payoutType = payoutInfoDataList[count]['payoutmethoddescription']);
            setAchAccount(achAccount => achAccount = payoutInfoDataList[count]['achaccountnumber']);
            setAchAba(achAba => achAba = payoutInfoDataList[count]['achbankabanumber']);
            setAchType(achType => achType = payoutInfoDataList[count]['achaccounttype']);
            // setMasterSetupBool(masterSetupBool => payoutInfoDataList[count]['mastersetupbool']); 
        }
    }, [payoutInfoDataList.length]);

    // useEffect for masterPayoutInfoBool toggle
    useEffect(() => {
        // if masterPayoutInfoBool is true make companyid master company placeholder 
        if (masterPayoutInfoBool){
            setCompanyId(companyId => companyId = process.env.REACT_APP_MASTER_PAYOUT_INFO_PLACEHOLDER); 
        }
        // if changing back to sub company payout info clear sub company id 
        else {
            setCompanyId(companyId => companyId = ''); 
        }
    }, [masterPayoutInfoBool]); 

    // useEffect to determine whether to allow input info ach info 
    useEffect(() => {
        if (checkShowAchInfo(payoutType)) {
            setShowAchInfo(showAchInfo => showAchInfo = true); 
        }
        else {
            setShowAchInfo(showAchInfo => showAchInfo = false); 
        }
    }, [payoutType]); 

    // // useEffect for masterSetupBool toggle
    // useEffect(() => {
    //     // if master setup bool is true set sub company id to specified placeholder for master company payout info
    //     if (masterSetupBool){
    //         setCompanyId(companyId => companyId = process.env.REACT_APP_MASTER_PAYOUT_INFO_PLACEHOLDER); 
    //     }
    //     // if changing back to sub company payout info clear sub company id 
    //     else {
    //         setCompanyId(companyId => companyId = ''); 
    //     }
    // }, [masterSetupBool]); 

    return( 
        <>
            {/* map showCell function over ever column in the row */}
            {tableHeader.map(el => 
                <td>{showCell(el['columnname'])}</td>
            )}
        </>
    );
}

export default AddNewPayoutInfoRow; 