import isAlphaNumeric from "./isAlphaNumeric";
import isNumeric from './isNumeric';
import isAlphaNumericDash from './isAlphaNumericDash';

const payoutInputValidationChecksMasterCompany = ( input, companyIdsList, masterPayoutInfoBool, snackbarAlert, payoutMethodData) => {
    // validate payout info inputs. Return false and show snackbar if invalid. 
    if (!masterPayoutInfoBool && (!isAlphaNumericDash(input.companyid) || !companyIdsList.includes(input.companyid))){
        snackbarAlert(true, 'Invalid Sub Company Id.', 'error');
        return 'companyid';
    }
    if (input.payoutmethoddescription == '--select--'){
        snackbarAlert(true, 'Invalid Payout Type.', 'error');
        return 'payoutmethoddescription'; 
    }

    // check payoutMethodData to determine if ach info is required
    for (let i = 0; i < payoutMethodData.length; i++){
        // find if ach info is required for that payoutmethoddescription
        if (payoutMethodData[i]['payoutmethoddescription'] == input['payoutmethoddescription'] && payoutMethodData[i]['requireachinfo'] == true){
            // check that account numbers are valid numeric input
            if (!isNumeric(input.achaccountnumber)){
                snackbarAlert(true, 'Invalid ACH Account #.', 'error');
                return 'achaccountnumber';
            }
            if (!isNumeric(input.achbankabanumber)){
                snackbarAlert(true, 'Invalid ACH ABA #.', 'error');
                return 'achbankabanumber';
            }
            if (input.achaccounttype == '--select--'){
                snackbarAlert(true, 'Invalid ACH Account Type.', 'error');
                return 'achaccounttype'; 
            }
            // check that account numbers are correct lengths
            if (input.achaccountnumber.toString().length > parseInt(process.env.REACT_APP_ACH_ACCOUNT_NUMBER_LENGTH)){
                snackbarAlert(true, 'ACH Account Number longer than configuration allows.', 'error');
                return 'achaccountnumber'; 
            }
            if (input.achbankabanumber.toString().length > parseInt(process.env.REACT_APP_ACH_BANK_ABA_NUMBER_LENGTH)){
                snackbarAlert(true, 'ACH Bank ABA Number longer than configuration allows.', 'error'); 
                return 'achbankabanumber'; 
            }
        }
    }

    // if validation checks are passed return null; 
    return; 

}

export default payoutInputValidationChecksMasterCompany;