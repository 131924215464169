import { useContext, useState, useRef, useEffect } from 'react';
import PageContext from './PageContext';
import greenPlusIcon from '../../svgs/green-plus-icon.svg';
import redMinusIcon from '../../svgs/red-minus-icon.svg';
import DatePicker from './DatePicker';
import PageInput from './PageInput';
import PageDropdown from './PageDropdown';
import pencilIcon from '../../svgs/pencil-icon.svg';
import PageReadOnlyInput from './PageReadOnlyInput'; 
import PageTooltip from './PageTooltip'; 

const AddNewSubCompanyRow = ({ row, count, tableHeader }) =>{
    // get context from parent
    const {
        subCompanyDataList, 
        setSubCompanyDataList,  
        showAddAccountIdsModal, 
        setShowAddAccountIdsModal, 
        accountIdsRow, 
        setAccountIdsRow, 
        accountIdsDataList, 
        setAccountIdsDataList, 
        accountIdsTableHeader, 
        subCompanyIdsList, 
        setSubCompanyIdsList, 
        subCompanyErrorList, 
        setSubCompanyErrorList,
        processorList, 
        processorDefaultData, 
        portfolio,  
        billingRateList, 
        editBillingRate, 
        setEditBillingRate
    } = useContext(PageContext);

    // set state
    const [status, setStatus] = useState('Active');
    const [statusList, setStatusList] = useState(['Active', 'Inactive']); 
    const [company, setCompany] = useState('');
    const [companyId, setCompanyId] = useState('');
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [processor, setProcessor] = useState(''); 
    const [billingRate, setBillingRate] = useState('--select--'); 
    const [editProcessor, setEditProcessor] = useState(false); 

    // event handler for add account ids click
    const onClick = () => {
        // set sub company row to add to for modal
        setAccountIdsRow(accountIdsRow => accountIdsRow = subCompanyDataList[count]); 
        // show modal to add account ids
        setShowAddAccountIdsModal(showAddAccountIdsModal => showAddAccountIdsModal = true); 
    }

    // function to make processor default value
    const defaultProcessor = () => {
        // find default processor in default processor data
        // check only if portfolio is picked
        if (portfolio != ''){
            for (let i = 0; i < processorDefaultData.length; i++ ){
                if (portfolio == processorDefaultData[i]['incentivetype']){
                    setProcessor(processor => processor = processorDefaultData[i]['processorid']); 
                    break; 
                }
            }
        }
    }

    // return default processor
    const getDefaultProcessor = () => {
        // find default processor in default processor data
        let processorId; 
        // check only if portfolio is picked
        if (portfolio != ''){
            for (let i = 0; i < processorDefaultData.length; i++ ){
                if (portfolio == processorDefaultData[i]['incentivetype']){
                    processorId = processorDefaultData[i]['processorid']; 
                    break; 
                }
            }
            return processorId; 
        }
        // return empty string if no portfolio
        else {
            return ''; 
        }
    }

    // add row to data list
    const addSubCompany = () =>{ 
        // add to subCompanyDataList
        let temp2 = []; 
        for (let i = 0; i < tableHeader.length; i++){
            temp2.push(tableHeader[i]['columnname']);
        }
        let temp = {
            index: count, 
        };
        temp2.map((el) =>{
            if (el == 'blank'){
                temp = {...temp, [el]: false};
            }
            else if (el == 'active'){
                temp = {...temp, [el]: 'Active'};
            }
            else if (el == 'startdate'){
                temp = {...temp, [el]: new Date()};
            }
            else if (el == 'enddate'){
                temp = {...temp, [el]: null}; 
            }
            else if (el == 'processorid'){
                temp = {...temp, [el]: getDefaultProcessor()}; 
            }
            else if (el == 'billingratedescription') {
                temp = {...temp, [el]: '--select--'}; 
            }
            else{
                temp = {...temp, [el]: ''};
            }
        });
        console.log(temp); 
        setSubCompanyDataList(subCompanyDataList => subCompanyDataList = [
            ...subCompanyDataList.slice(0, subCompanyDataList.length-1), 
            temp, 
            ...subCompanyDataList.slice(subCompanyDataList.length-1,  subCompanyDataList.length)
        ]);
        // set error object 
        let temp5 = {}
        Object.keys(temp2).map((el) => {
            temp5 = {
                ...temp5, 
                el: false, 
            }
        }); 
        // add error object to sub company error list 
        setSubCompanyErrorList(subCompanyErrorList => subCompanyErrorList = [
            ...subCompanyErrorList.slice(0, subCompanyErrorList.length-1), 
            temp5, 
            ...subCompanyErrorList.slice(subCompanyErrorList.length-1, subCompanyErrorList.length)
        ])
        // add list of account ids to accountIdsDataList
        let temp3 = []; 
        for (let i = 0; i < accountIdsTableHeader.length; i++){
            temp3.push(accountIdsTableHeader[i]['columnname']);
        }
        let temp4 = {};
        temp3.map((el) =>{
            if (el == 'blank'){
                temp4 = {...temp4, [el]: true};
            }
            else{
                temp4 = {...temp4, [el]: ''};
            }
        });
        console.log(temp4); 
        setAccountIdsDataList(accountIdsDataList => accountIdsDataList = [
            ...accountIdsDataList, 
            [temp4], 
        ]); 
    }

    // remove row from data list
    const removeSubCompany = (index) =>{
        // remove from sub company data list 
        setSubCompanyDataList(subCompanyDataList => subCompanyDataList = [...subCompanyDataList.slice(0, index), ...subCompanyDataList.slice(index + 1, subCompanyDataList.length)]);
        // remove account ids from account ids data list
        setAccountIdsDataList(accountIdsDataList => accountIdsDataList = [...accountIdsDataList.slice(0, index), ...accountIdsDataList.slice(index + 1, accountIdsDataList.length)]); 
        // remove sub company id from list 
        setSubCompanyIdsList(subCompanyIdsList => subCompanyIdsList = [...subCompanyIdsList.slice(0, index), ...subCompanyIdsList.slice(index + 1, subCompanyIdsList.length)]); 
        // remove error object from error list
        setSubCompanyErrorList(subCompanyErrorList => subCompanyErrorList = [...subCompanyErrorList.slice(0, index), ...subCompanyErrorList.slice(index + 1, subCompanyErrorList.length)]); 
    }


    // showCell to determine how to display each column
    const showCell = ( el ) =>{
        if (el == 'blank'){
            if (row[el] == true){
                return <img src={greenPlusIcon} class='data-table-icon-right' onClick={()=>addSubCompany()}/>
            }
            else{
                return <img src={redMinusIcon} class='data-table-icon-right' onClick={()=>removeSubCompany(count)}/>
            }
        }
        else if (row['blank'] != true && el == 'active'){
            return <PageDropdown tag='Status:' value={status} setValue={setStatus} list={statusList} width='7.2vw' font={10}/>
        }
        else if (row['blank'] != true && el == 'companyname'){
            return <PageInput value={company} setValue={setCompany} tag='Sub Company Name:' width='14.3vw' font={'10px'} showError={subCompanyErrorList[count]['companyname']}/>
        }
        else if (row['blank'] != true && el == 'companyid'){
            return <PageInput value={companyId} setValue={setCompanyId} tag='Sub Company ID:' width='14.3vw' font={'10px'} showError={subCompanyErrorList[count]['companyid']}/>
        }
        else if (row['blank'] != true && el == 'startdate'){
            return <DatePicker tag='Start Date:' value={startDate} setValue={setStartDate} disable={endDate} indicator={3} width='9vw' font={'10px'}/> 
        }
        else if (row['blank'] != true && el == 'enddate'){
            return <DatePicker tag='End Date:' value={endDate} setValue={setEndDate} disable={startDate} indicator={4} width='9vw' font={'10px'}/>
        }
        else if (row['blank'] != true && el == 'addaccountids'){
            return  <>
                {/* show edit icon to trigger addAccountIdsModal */}
                <PageTooltip title='Add Account IDs'>
                    <img src={pencilIcon} className='data-table-icon' style={{ marginTop: '6px', marginLeft: '23px'}} onClick={(e)=>onClick(e)}/>
                </PageTooltip>
            </>
        }
        else if (row['blank'] != true && el == 'processorid' && editProcessor == true){
            return <PageDropdown tag='Processor:' value={processor} setValue={setProcessor} list={processorList} width='8vw' font={10} showError={subCompanyErrorList[count]['processorid']}/>
        }
        else if (row['blank'] != true && el == 'processorid' && editProcessor == false){
            return <PageReadOnlyInput tag='Processor:' value={processor} width='8vw' font={10} />
        }
        else if (row['blank'] != true && el == 'billingratedescription' && editBillingRate == true){
            return <PageDropdown tag='Billing Rate:' value={billingRate} setValue={setBillingRate} list={billingRateList} width='8vw' font={10} showError={subCompanyErrorList[count]['billingratedescription']}/>
        }
        // else {
        //     return <>{row[el]}</>;
        // } 
    } 

    // useEffect to update processor on portfolio change 
    useEffect(() => {
        if (row['blank'] == false){
            // set processor to default 
            defaultProcessor(); 
            // enable/disable edit based on processor
            if (portfolio != ''){
                for (let i = 0; i < processorDefaultData.length; i++ ){
                    if (portfolio == processorDefaultData[i]['incentivetype']){
                        // set edit processor
                        setEditProcessor(editProcessor => editProcessor = processorDefaultData[i]['editprocessor']); 
                        // set edit billing rate 
                        setEditBillingRate(editBillingRate => editBillingRate = processorDefaultData[i]['editbillingrate']); 
                        break; 
                    }
                }
            }
        }
    }, [portfolio]); 

    // useEffect to update list row values 
    useEffect(() => { 
        if (row['blank'] == false){
            console.log('Here:', count);
            setSubCompanyDataList(subCompanyDataList => subCompanyDataList =
                [...subCompanyDataList.slice(0, count), 
                {active: status, companyname: company, companyid: companyId, startdate: startDate, enddate: endDate, processorid: processor, billingratedescription: billingRate, blank: false, index: count}, 
                ...subCompanyDataList.slice(count + 1, subCompanyDataList.length)]);
        }
    }, [status, company, companyId, startDate, endDate, processor, billingRate]);

    // useEffect to add to sub company ids list
    useEffect(() => {
        if (row['blank'] == false && companyId.length > 0 && companyId.length != '') {
            setSubCompanyIdsList(subCompanyIdsList => subCompanyIdsList = [
                ...subCompanyIdsList.slice(0, count),
                companyId, 
                ...subCompanyIdsList.slice(count + 1, subCompanyIdsList.length),  
            ]);
        }
    }, [companyId]); 

    // useEffect on to change row values when size of list changes
    useEffect(() => {
        setStatus(status => status = subCompanyDataList[count]['active']);
        setCompany(company => company = subCompanyDataList[count]['companyname']);
        setCompanyId(companyId => companyId = subCompanyDataList[count]['companyid']);
        setStartDate(startDate => startDate = subCompanyDataList[count]['startdate']);
        setEndDate(endDate => endDate = subCompanyDataList[count]['enddate']);
        setProcessor(processor => processor = subCompanyDataList[count]['processorid']); 
        setBillingRate(billingRate => billingRate = subCompanyDataList[count]['billingratedescription']); 
    }, [subCompanyDataList.length]);

    return( 
        <>
            {/* map showCell function over ever column in the row */}
            {tableHeader.map(el => 
                <td>{showCell(el['columnname'])}</td>
            )}
        </>
    );
}

export default AddNewSubCompanyRow;