import { useContext } from 'react';
import PageContext from './PageContext';
import PageDropdown from './PageDropdown';
import PageInput from '../sub-components/PageInput';

const OverrideInput = () =>{
    // get context from parent 
    const {
        overrideType, 
        overrideRate, 
        setOverrideRate,
        overrideSignAmt, 
        setOverrideSignAmt, 
    } = useContext(PageContext);

    // logic to determine whether to so rate input or amount input
    if (overrideType == 'Rate'){
        return(
            <div class='modal-div'>
                <PageInput tag='Override Rate: ' value={overrideRate} setValue={setOverrideRate}/>
            </div>
        );
    }
    else if (overrideType == 'Amount'){
        return(
            <div class='modal-div'>
                <span>
                    <PageInput tag='Override Amount: ' value={overrideSignAmt} setValue={setOverrideSignAmt} />
                </span>
            </div>
        );
    }
    else {
        return(
            <></>
        );
    }
}

export default OverrideInput;