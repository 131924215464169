import { useContext, useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import PageContext from './PageContext';
import PageDropdown from './PageDropdown';
import PageInput from './PageInput';
import PageReadOnlyInput from './PageReadOnlyInput';
import { HttpContext } from '../../api-services/HttpService';

const RebateSummaryEditRowModal = () =>{
    // get context variables from parent
    const {
        editRowModal, 
        setEditRowModal,
        editRow, 
        setEditRow,
    } = useContext(PageContext);
    // global redux state for company (passed in from other route)
    //const company = useSelector((state) => state.company);
    //const companyInfo = useSelector((state) => state.companyInfo);
    // initialize http context 
    const {
        GET, 
    } = useContext(HttpContext);
    // set state
    //const payoutTypeList = ['None', 'Check', 'ACH', 'Statement Credit'];
    const [payoutTypeList, setPayoutTypeList] = useState([]);
    const [payoutType, setPayoutType] = useState('')
    const [glrc, setGlrc] = useState('');
    const [workflowid, setWorkflowid] = useState('');

    // useRef to determine initial render
    const initialRender = useRef(true);

    const getPayoutMethodsLookup = () => {
        return new Promise((resolve, reject) => {
            GET('/rebate/summary/payoutmethod').then(res => {
                setPayoutTypeList(payoutTypeList => ['None', ...res.data.PayoutMethods.map(meth => meth.payoutmethoddescription)]);
                resolve(res);
            }).catch(err => {
                reject(err);
            });
        });
    }

    // create function for editing a payoutmethod type and glrc
    const editPayoutInfo = (workflowid, payoutmethoddescription, glrc) => {
        console.log(workflowid, payoutmethoddescription, glrc);
        return new Promise((resolve, reject) => {
            GET('/rebate/summary/payoutmethod/edit', { workflowid: workflowid, paymentmethoddescription: payoutmethoddescription, glrc: glrc}).then(res => {
                resolve(res);
            }).catch(err => {
                console.log(err);
                reject(err);
            });
            });
        //.then(getUsers())
        //.then(setEditUserModal(editUserModal => editUserModal = false))    
    }  

    const onSubmitClick = () =>{
        editPayoutInfo(workflowid, payoutType, glrc);
        setEditRowModal(editRowModal => editRowModal = false);
    }

    // useEffect to run when editRow state variable is changed
    useEffect(() =>{

        if (initialRender.current){
            initialRender.current = false;
        }
        // assign to state variables from editRow object
        else{
            getPayoutMethodsLookup();

            setPayoutType(payoutType => payoutType = editRow.paymenttype);
            setGlrc(glrc => glrc = editRow.glrc);
            setWorkflowid(workflowid => editRow.workflowid);
        }
    }, [editRow])    

    return(
        <> 
            <Modal className='rebate-summary-modal'
                show={editRowModal}
                size='md'
                aria-labelledby='contained-modal-title-vcenter'
                centered
                scrollable={true}
                >
                <Modal.Header closeButton onClick={()=>setEditRowModal(editRowModal => editRowModal = false)}>
                    <Modal.Title id='contained-modal-title-vcenter'>
                        Edit Payout Info
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    {/* read only inputs for information on rebate workflow payout info being edited */}
                    <div class='modal-div'>
                        <PageReadOnlyInput tag='Company:' value={editRow.mastercompanyname} />
                    </div>
                    <br/>
                    <div class='modal-div'>
                        <PageReadOnlyInput tag='Payout Cycle: ' value={editRow.cyclestart + ' to ' + editRow.cycleend + ' [' + editRow.incentivefreq + ']'}/>
                    </div>
                    <br/>
                    {/* dropdown and input to edit payout info */}
                    <div class='modal-div'>
                        <PageDropdown tag='Payout Type:' value={payoutType} setValue={setPayoutType} list={payoutTypeList}/>
                    </div>
                    <br/>
                    <div class='modal-div'>
                        <PageInput tag={'GLRC:'} value={glrc} setValue={setGlrc} />
                    </div> 
                    <button class='modal-button-full-blue' onClick={()=>onSubmitClick()}>Submit</button>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default RebateSummaryEditRowModal;