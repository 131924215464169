import validateNumericInput from '../functions/validateNumericInput';
import isAlphaNumeric from '../functions/isAlphaNumeric';
import isNumericOrNull from '../functions/isNumericOrNull';
import removeStrCommas from '../functions/removeStrCommas'; 
import isNumeric from '../functions/isNumeric';

const rebateInputValidationChecksWithError = ( input, companyIdsList, setupToAllSubs, masterSetupIdsList, snackbarAlert ) => {
    console.log(input);
    // validate companyid is alpha numeric and is in sub company list
    if (!setupToAllSubs && (!isAlphaNumeric(input.companyid) || !companyIdsList.includes(input.companyid))){ 
        // show alert on invalid company id
        snackbarAlert(true, 'Invalid Sub Company ID.', 'error');
        return 'companyid';
    }
    // validate mastersetupid is in masterSetupIdsList
    if (input.masterincentivesetupid && !masterSetupIdsList.includes(parseInt(input.masterincentivesetupid)) ){
        // show alert on invalid master setup id 
        snackbarAlert(true, 'Invalid Master Setup ID.', 'error'); 
        return 'masterincentivesetupid'; 
    }
    // // validate setupId is alpha numeric
    // if (!isAlphaNumeric(input.incentivesetupid)){
    //     // show alert on invalid setup id
    //     snackbarAlert(true, 'Invalid Setup ID.', 'error');
    //     return false;
    // }
    // validate numeric inputs 
    if (!validateNumericInput(input.minspend) ) {
        // show alert on invalid numeric input
        snackbarAlert(true, 'Invalid Tier Min Input.', 'error');
        return 'minspend'; 
    }  
    if (!validateNumericInput(input.maxspend) ){
        // show alert on invalid numeric input
        snackbarAlert(true, 'Invalid Tier Max Input.', 'error');
        return 'maxspend';
    }
    if (!input.mastersetupbool && !isNumeric(input.incentiverate)){
        // show alert on invalid numeric input
        snackbarAlert(true, 'Invalid Rebate Rate Input.', 'error');
        return 'incentiverate';
    }
    // validate dropdown selections
    if (input.producttypedescription == '--select--'){
        // show alert on invalid selection
        snackbarAlert(true, 'Invalid Product Type Selection.', 'error');
        return 'producttypedescription';
    }
    // validate dropdown selections
    if (input.billingratedescription == '--select--' ){
        // show alert on invalid selection
        snackbarAlert(true, 'Invalid Billing Rate Selection.', 'error');
        return 'billingratedescription';
    }
    // validate dropdown selections
    if ( input.daystopay == '--select--'){
        // show alert on invalid selection
        snackbarAlert(true, 'Invalid Days To Pay Selection.', 'error');
        return 'daystopay';
    }
    // validate dropdown selections
    if (input.feecategorydescription == '--select--'){
        // show alert on invalid selection
        snackbarAlert(true, 'Invalid Fee Category Selection.', 'error');
        return 'feecategorydescription';
    }
    // if passes all checks return null
    return null; 
}

export default rebateInputValidationChecksWithError;