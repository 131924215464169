import { useState, useEffect, useRef } from 'react';
import * as ReactBootStrap from 'react-bootstrap';
// import Pagination from 'react-bootstrap/Pagination'
import Skeleton from '@mui/material/Skeleton';
// import CircularProgress from '@mui/material/CircularProgress';
// import LinearProgress from '@mui/material/LinearProgress';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';


const OrderedDataTableWithFooter = ({ RowComponent, tableHeader, dataList, numToPage, overflowFlag, showSpinner, skeletonHeight, FooterComponent, footerObj }) =>{
    // initialize state
    const [page, setPage] = useState(1);
    const [pageList, setPageList] = useState([]);
    const [showDataList, setShowDataList] = useState([]);
    const [tableCss, setTableCss] = useState('table');
    const [numOfPages, setNumOfPages] = useState(0);
    // initialize ref
    const count = useRef(0);

    // function to make count equal to 0
    const zeroCount = () =>{
        count.current = 0;
    } 

    // increment count by 1
    const incrementCount = () =>{
        count.current = count.current + 1;
    }

    // event handler when page changes due to pagination component click
    const onPageChange = (event, value) => {
        setPage(page => page = parseInt(value));
    }

    // useEffect to run on change of dataList state variable
    useEffect(() => {
        setPage(page => page = 1);
        // determine number of pages and the page list that will 
        if(numToPage){
            let temp = parseInt(dataList.length / numToPage);
            if (dataList.length % numToPage > 0){
                temp = temp + 1;
            }
            setNumOfPages(numOfPages => numOfPages = temp);
            let tempList = [];
            for (let i = 0; i < temp; i++){
                tempList.push(i+1);
            }   
            setPageList(pageList => pageList = tempList);
        }
        // if overflow flag is true allow horizontal scroll
        if (overflowFlag){
            setTableCss(tableCss => tableCss = 'table-overflow');
        }
    }, [dataList]);

    // useEffect to update data to display on page of table when dataList or page state variables change
    useEffect(() => {
        if (numToPage){
            let startIndex = (page - 1) * numToPage;
            setShowDataList(showDataList => showDataList = [...dataList.slice(startIndex, startIndex + numToPage)]);
        }
        else{
            setShowDataList(showDataList => showDataList = dataList);
        }
    }, [page, dataList]); 

    return(
        <>
            {!showSpinner ?
                <>
                    {/* if specified number of rows to page and there is more than one page show pagination component */}
                    { numToPage && numOfPages > 1 ?
                    <>
                        <Stack spacing={2}>
                            <Pagination page={page} count={numOfPages} variant="outlined" shape="rounded" onChange={onPageChange}/>
                        </Stack>
                        <br/>
                    </>
                    :<></>}
                    <div class={tableCss}>
                        <ReactBootStrap.Table className='data-table' striped>
                            {/* display headers */}
                            <thead className='data-table-header'>
                                <tr>
                                    {tableHeader.map( el => 
                                        <th>{el['columntag']}</th>
                                    )} 
                                </tr>
                            </thead>
                            {/* display body of table */}
                            <tbody className='data-table-body'>
                                {/* zero count at the start of each row */}
                                {zeroCount()}
                                {showDataList.map( row =>
                                    <tr >
                                        {/* apply row component function to each row object */}
                                        {/* pass table headers to row component so order can be held */}
                                        {RowComponent(row, count.current, tableHeader)}  
                                        {/* increment count for each element in row */}
                                        {incrementCount()}                
                                    </tr>    
                                )}
                            </tbody>
                            {/* footer for table */}
                            <thead className='data-table-header'>
                                <tr>
                                    {FooterComponent(tableHeader, footerObj)}
                                </tr>
                            </thead>
                        </ReactBootStrap.Table>
                    </div>
                    
                </>
            :<>
                {/* show skeleton while showSpinner prop is true */}
                {/* generally used for waiting for api response */}
                <br/>
                {/* <CircularProgress /> */}
                {/* <LinearProgress sx={{ height: '10px', width: '90%', margin: 'auto', borderRadius:'5px'}} /> */}
                <Skeleton variant="rectangular" width='100%' height='7vh' sx={{ borderRadius: '10px', 'margin-bottom': '3px'}}/>
                <Skeleton variant="rectangular" width='100%' height={skeletonHeight} sx={{ borderRadius: '10px'}}/>
                <br/>
            </>}
            { !showSpinner && dataList.length == 0 && tableHeader.length > 0 ? 
            <div style={{textAlign: 'center'}}>
                No Data Available.
            </div>
            :<></>}
        </>
    );
}

export default OrderedDataTableWithFooter;