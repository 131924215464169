// filter to check for active or inactive status
const statusFilter = (el, val, filterCol) => {
    if (val == 'Active'){
        return el[filterCol] == true;
    }
    else {
        return el[filterCol] == false;
    }
}

export default statusFilter;