const EntitledButton = ({ entitledBool, tag, cssClass, onClick }) => {
    return (
        <>
            { entitledBool ? 
                <button class={cssClass} onClick={()=>onClick()}>{tag}</button>
            :<div style={{color: 'transparent'}}>-</div>}
        </>
    );
}

export default EntitledButton;