import { useEffect, useRef, useContext } from 'react';
import HeaderBanner from '../sub-components/HeaderBanner';
import Navslider from '../sub-components/Navslider';
import { HttpContext } from '../../api-services/HttpService';
import tableau from 'tableau-api';
import useWindowDimensions from '../functions/UseWindowDimensions'

const TableauDashboardEmbed = () => {
    // initialize http context 
    const {
        GET, 
    } = useContext(HttpContext);
    // initialize custom hook for height and width
    const { height, width } = useWindowDimensions();
    // initialize reference for tableau dashboard
    const vizContainer = useRef();
    const viz = useRef();
    // function to calculate px from vh value
    const convertVhToPx = (vh) => {
        const oneVhInPx = window.innerHeight / 100;
        return oneVhInPx * vh;
    };
    // function to calculate px from vw value
    const convertVwToPx = (vw) => {
        const oneVwInPx = window.innerWidth / 100; 
        return oneVwInPx * vw; 
    }
    // event handler for export to pdf
    const onExportToPdf = () => {
        viz.current.showExportPDFDialog();  
    }
    // event handler for export to excel
    const onExportToExcel = () => {
        // viz.current.exportCrossTabToExcel();
        viz.current.showExportCrossTabDialog();
    }
    // intitialize tableau to embed into site
    const initViz = (vizUrl) => {
        const options = {
            // hide navigation tabs
            hideTabs: true,
            // hide bottom toolbar
            hideToolbar: false, 
            // set height and width
            height: (convertVhToPx(100) - 50).toString() + 'px',
            width: (convertVwToPx(100) - 80).toString() + 'px', 
            // width: '92vw',
            // height: height.toString() + 'px',
            // width: (width - 84).toString() + 'px', 
        };
        viz.current = new window.tableau.Viz(vizContainer.current, vizUrl, options); 
    }

    // get tableau trusted ticket to embed tableau dashboard
    const getTicket = () => {
        return new Promise((resolve, reject) => {
            GET('/tableau/ticket', {}).then(res => {
                resolve(res);
            }).catch(err => {
                reject(err);
            })
        });
    }

    // useEffect for initial render
    useEffect(() => { 
        // get trusted ticket url and initialize viz
        getTicket().then(res  => {
            console.log(res);
            // initialize tableau viz      
            initViz(res['data']);
        }).catch(err => {
            console.log(err);
        });

        // function to run on unrender
        return (() => {
            // on unrender remove viz from div so that there is no error when the page is navigated to again
            let tempViz = window.tableau.VizManager.getVizs()[0];
            if (tempViz) {
                tempViz.dispose();
            }
        })
    }, []);

    return (
        <div className='summary-page'>
            {/* header and navslider present on every page */}

            <HeaderBanner indicator={1}/>
            <div ref={vizContainer} style={{ float: 'right', zIndex: '2', position: 'relative' }}></div>   

            <Navslider indicator={1} backPath='/MainLanding' style={{zIndex: '1', position: 'sticky', marginTop: '0px', height: '90vh'}}/> 

            {/* div containing tableau dashboard */}
            {/* <div className='tableau-button-div'>
                <button className='summary-page-button' onClick={()=>onExportToPdf()}>Export to PDF</button>
                <button className='summary-page-button' onClick={()=>onExportToExcel()}>Export to Excel</button>
            </div> */}

        </div>
    )
}

export default TableauDashboardEmbed; 