import { useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../redux/index';
import { Link } from 'react-router-dom';
import PageContext from './PageContext';
import magnifyIcon from '../../svgs/magnifying-glass-icon.svg';
import PageTooltip from './PageTooltip'; 

const ConsortiumSearchRow = ({ row, count, tableHeader }) => {
    // get context variables from parent
    const {
        viewModal, 
        setViewModal, 
        viewCompany,  
        setViewCompany, 
    } = useContext(PageContext);
    // redux state for use of persistant state in different route (CompanyDetailsPage)
    const company = useSelector((state) => state.company);
    const companyInfo = useSelector((state) => state.companyInfo);
    const backPath = useSelector((state) => state.backPath); 
    const dispatch = useDispatch();
    const { setCompany, setCompanyInfo, setBackPath } = bindActionCreators(actionCreators, dispatch);

    // event handler when link is clicked
    const onCompanyClick = (e) =>{
        // set back path to let navbar on next page to return to /CompanyProfilePage
        setBackPath('/ConsortiumSearchPage'); 
        // set company state to bring to new route
        setCompany(e.target.innerText.toString());
        setCompanyInfo(row);
    }

    // event handler for view icon click to show modal that lists sub companies
    const onViewClick = (e) => {
        setViewCompany(viewCompany => viewCompany = row);
        setViewModal(viewModal => viewModal = true);
    }

    return(
        <>
            {tableHeader.map(el => {
                // logic to determine how to display each column based on name
                if ( el['columnname'] == 'mastercompanyname'){
                    return(
                        <td class='table-col'>
                            <Link onClick={(e)=>onCompanyClick(e)}
                                to={{
                                    pathname: "/CompanyDetailsPage",
                                }}>
                                {row[el['columnname']]}
                            </Link>
                        </td>
                    );
                }
                else if (el['columnname'] == 'subcompanies'){
                    return (
                        <td class='table-col'>
                            <PageTooltip title='view'>
                                <img src={magnifyIcon} className='data-table-icon-left' onClick={(e)=>onViewClick(e)}/>
                            </PageTooltip>
                        </td>
                    );
                }
                else if (row[el['columnname']] == null){
                    return(
                        <td class='table-col'>None</td>
                    );
                }
                else{
                    return(
                        <td class='table-col'>{row[el['columnname']]}</td>
                    );
                }
            })}
        </>
    ); 
}

export default ConsortiumSearchRow; 