import _ from 'lodash';
import Fuse from 'fuse.js';

// use js built in functions to search for a value in array of objects using specified keys
const standardSearch = (showDataList, searchCols, value) => {
    // //filter showDataList with input
    // let searchList = [];
    // let splitList = [value.toLowerCase()];
    // for(let i = 0; i < splitList.length; i++){
    //     if( splitList[i] === '' && splitList.length > 1){
    //         splitList.splice(i, 1);
    //         i--;
    //     }
    // }
    // for (let i = 0; i < showDataList.length; i++){    
    //     for (let n = 0; n < splitList.length; n++){
    //         for (let s = 0; s < searchCols.length; s++){
    //             //if the input string is found in any search cols add to showDataList
    //             if (showDataList[i][searchCols[s]].toLowerCase().search(splitList[n]) > -1 && !searchList.includes(showDataList[i])){
    //                 searchList.push(showDataList[i]);
    //             }
    //             // //if then any search cols is included in input string add to showDataList
    //             // else if (splitList[n].search(showDataList[i][searchCols[s]].toLowerCase()) > -1 && !searchList.includes(showDataList[i])){
    //             //     searchList.push(showDataList[i]);
    //             // }
    //         }
    //     }        
    // } 

    // initialize fuse instance with search cols and showDataList 
    const fuse = new Fuse(showDataList, {
        keys: [...searchCols], 
    });

    // use fuse to fuzzy seach show data list with value
    let searchList = fuse.search(value);

    let returnList = [];  
    // transform for table render 
    for (let i = 0; i< searchList.length; i++){
        returnList.push(searchList[i]['item']);
    }

    // return searchList 
    return returnList; 
};

export default standardSearch;