import { useContext, useEffect, useState, useRef } from 'react';
import { CSVLink } from "react-csv";
import PageContext from './PageContext';

// export payout file with companies populated in workflowActionCheckedList from parent
const ExportPayoutsButton = () =>{
    // get context variables from parent
    const {
        workflowActionCheckedList,
    } = useContext(PageContext);

    // string to export
    const [exportString, setExportString] = useState('');

    // populate export string on export click
    const onExportClick = (event, done) =>{
        // clear string
        setExportString(exportString => exportString = '');
        // load into string from workflowActionCheckedList and download file
        if (workflowActionCheckedList.length > 0){
            workflowActionCheckedList.map( el =>{
                setExportString(exportString => exportString = exportString + el['Company'] + ': ' + el['PayoutType'] + '\n');
            }); 
            done(true);
        }
        // if list is empty don't download txt file
        else{
            done(false);
        }   
    }

    return(
        <>
            {/* use CSVLink to export .txt file */}
            <CSVLink data={exportString} filename={'cc-payouts-export.txt'} onClick={(event, done)=>onExportClick(event, done)} asyncOnClick={true}>
                <button class='summary-page-button'>Export Payouts</button>
            </CSVLink>
        </>
    ); 
}

export default ExportPayoutsButton; 