import { useState, useEffect, useContext } from 'react';
import ReadOnlyInputAutoSize from './ReadOnlyInputAutoSize';
import { HttpContext } from '../../api-services/HttpService';

const WorkflowCommentLog = (workflowid) => {
    // initialize http context 
    const {
        GET,  
    } = useContext(HttpContext);
    // initialize state 
    const [commentList, setCommentList] = useState([]);

    //function to call api to get commennt log. takes in a workflowid
    const getComments = (workflowid) => {
        return new Promise((resolve, reject) => {
            GET('/rebate/summary/comment/log', { workflowid: workflowid }).then(res => {
                //set comment log
                setCommentList(commentList => commentList = res.data.CommentLog);
                resolve(res);
            }).catch(err => {
                reject(err);
            });
        });
    }
    // useEffect to run on initial render
    useEffect(() => {
        getComments(workflowid.workflowid).then(res => {
            console.log(res);
        }).catch(err => {
            console.log(err);
        });
    }, [workflowid.workflowid]);


    return(
        <>
            <label><b>Comment Log: </b></label>
            <br/><br/>
            {commentList.map(el => <>
                <ReadOnlyInputAutoSize tag={el['lastupdateduser']} value={el['commenttext']}/>
                <br/><br/>
            </> )}
        </>
    );
}

export default WorkflowCommentLog; 
